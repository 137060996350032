import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { PrimaryButtonStyle } from "./styles/buttons.styles";

const useStyles = makeStyles(PrimaryButtonStyle);

const PrimaryButton = ({
  type,
  title,
  style,
  onClick,
  className,
  ...otherProps
}) => {
  const classes = useStyles();
  return (
    <button
      className={clsx(classes.primary_root, className)}
      type={type}
      onClick={onClick}
      style={style}
      {...otherProps}
    >
      <div className={classes.titleBox}>{title}</div>
    </button>
  );
};

PrimaryButton.propTypes = {
  title: PropTypes.node,
  onClick: PropTypes.func,
};

export default PrimaryButton;
