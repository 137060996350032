import axios, { _axiosWithAuth, _axiosWithNoRole } from "./Api";
import axiosMain from "./ApiMain";

/*###############new endpoints after merging endpoints#############*/
export const getUsersAgencies = ({ page, ...params }) =>
    axiosMain.get("/users/agencies", { params: { page: page || 0, ...params } });
export const getUsersBranches = ({ page, ...params }) =>
    axiosMain.get("/users/branches", { params: { page: page || 0, ...params } });
export const getUsersEmployees = ({ page, ...params }) =>
    axiosMain.get("/users/employees", { params: { page: page || 0, ...params } });
export const viewSingleAgency = id =>
    axiosMain.get(`/agencies/${id}`).then(res => res.data);
export const getAddAgencyDependencies = () =>
    axiosMain.get("/agencies/store/dependencies").then(res => res.data);
export const addAgency = body => axiosMain.post(`/agencies/store`, body);
export const editAgency = (id, body) =>
    axiosMain.put(`/agencies/update/${id}`, body);
export const getAgencySummary = id =>
    axiosMain.get(`/agencies/show-summary/${id}`)
export const getAgencyNotes = (id, params) =>
    axiosMain.get(`/agency-profile/notes/${id}`, { params });
export const getAgencyUsage = id =>
    axiosMain.get(`/agency-profile/usage/${id}`).then(res => res.data);
export const getAgencyProperties = (id, params) =>
    axiosMain.get(`/agency-profile/properties/${id}`, {
    params: { ...params },
  });
export const getAgencyLogs = ({ type, ...params }) =>
    axiosMain.get(`/audits/${type}`, {
        params: { ...params },
    });
export const getUserAdminBranchAssignProperties = ({ id, ...params }) =>
    axiosMain.get(`/branch-profile/${id}/assigned-properties`, { params });
export const getUsersAdmin = ({ page, ...params }) =>
    axiosMain.get("/users/admins", { params: { ...params } });
export const addAdmin = body => axiosMain.post(`/users/admins/store`, body);
export const getAdminNotes = ({ id, offset, type }) =>
    axiosMain.get(`/staff-profile/${id}/notes?type=${type}`);
/*###############end new endpoints after merging endpoints#############*/

export const logoutUser = () => {
  return _axiosWithNoRole
    .get(`/logout`)
};

export const getUsersApplicants = ({ page, ...params }) =>
  axios.get("/users/applicants", { params: { offset: page || 0, ...params } });

export const getUsersSellers = ({ page, ...params }) =>
  axios.get("/users/sellers", { params: { offset: page || 0, ...params } });

export const getUsersSolicitors = ({ page, ...params }) =>
  axios.get("/users/solicitors", { params: { offset: page || 0, ...params } });

// export const getUsersAgencies = ({ page, ...params }) =>
//   axios.get("/users/agencies", { params: { offset: page || 0, ...params } });

// export const getUsersBranches = ({ page, ...params }) =>
//   axios.get("/users/branches", { params: { offset: page || 0, ...params } });
//
// export const getUsersEmployees = ({ page, ...params }) =>
//   axios.get("/users/employees", { params: { offset: page || 0, ...params } });

// export const getUsersAdmin = ({ page, ...params }) =>
//   axios.get("/users/admins", { params: { offset: page || 0, ...params } });
export const getUsersSuperAdmin = ({ page, ...params }) =>
  axios.get("/users/super-admins", {
    params: { offset: page || 0, ...params },
  });

export const getRoleslist = ({ page, ...params }) =>
  axios.get("/roles", { params: { offset: page || 0, ...params } });


// export const getAddAgencyDependencies = () =>
//   axios.get("/agencies/store/dependencies").then(res => res.data);

// export const addAgency = body => axios.post(`/agencies/store`, body);

// export const viewSingleAgency = id =>
//   axios.get(`/agencies/${id}`).then(res => res.data);

// export const editAgency = (id, body) =>
//   axios.post(`/agencies/update/${id}`, body);
  
// export const getAgencyNotes = (id, params) =>
//   axios.get(`/agency-profile/notes/${id}`, { params });

// export const getAgencyUsage = id =>
//   axios.get(`/agency-profile/usage/${id}`).then(res => res.data);

export const getAgencyEmployees = id =>
  axios.get(`/branch-profile/${id}/employees?offset=0`);

// export const getAgencyLogs = ({ type, ...params }) =>
//   axios.get(`/audits/${type}`, {
//     params: { offset: params?.page || 0, ...params },
//   });

// export const getAgencyProperties = (id, params) =>
//   axios.get(`/agency-profile/properties/${id}`, {
//     params: { offset: params?.page || 0, ...params },
//   });

// Solicitor profile
export const viewSingleSolicitor = id =>
  axios.get(`/solicitor-profile/${id}/profile`).then(res => res.data);

export const EditSolicitor = (id, body) =>
  axios.post(`/solicitor-profile/${id}/update`, body).then(res => res.data);


export const AddSolicitor = (body) =>
axios.post(`/solicitor-profile/create`, body).then(res => res.data);

export const getSolicitorSeller = ({ id, ...params }) =>
  axios.get(`/solicitor-profile/${id}/sellers`, {
    params: { offset: params?.page || 0, ...params },
  });

export const getSolicitorApplicant = ({ id, ...params }) =>
  axios.get(`/solicitor-profile/${id}/applicants`, {
    params: { offset: params?.page || 0, ...params },
  });

export const getOwnerBranchInvoices = ({ id, ...params }) =>
  axios.get(`/branches/${id}/invoices`, {
    params: { offset: params?.page || 0, ...params },
  });

export const getSolicitorProperties = (id, params) =>
  axios.get(`/solicitor-profile/${id}/properties`, {
    params: { offset: params?.page || 0, ...params },
  });

// Seller profile
export const viewSingleSeller = id =>
  axios.get(`/user-profile/${id}`).then(res => res.data);

export const EditSeller = (id, body) =>
  axios.post(`/user-profile/${id}/update-seller`, body).then(res => res.data);

export const EditApplicant = (id, body) =>
  axios
    .post(`/user-profile/${id}/update-applicant`, body)
    .then(res => res.data);



export const getSellerOffers = ({ page, ...params }) => {
  return axios.get("/user-profile/seller-offers", {
    params: { offset: page || 0, ...params },
  });
};

export const getSellerProperties = ({ page, ...params }) =>
  axios.get("/user-profile/properties", {
    params: { offset: page || 0, ...params }, 
  });

// Applicant
export const viewSingleApplicant = id =>
  axios.get(`/user-profile/${id}`).then(res => res.data);
export const getDocs = () => axios.get(`/docs`);

// Admin profile
// export const addAdmin = body => axios.post(`/users/admins/store`, body);
export const addSuperAdmin = body => axios.post(`/users/super-admins`, body);

export const viewSingleAdmin = id =>
  axios.get(`/users/admins/${id}`).then(res => res.data);
export const viewSingleSuperAdmin = id =>
  axios.get(`/users/super-admins/${id}`).then(res => res.data);

export const editAdmin = (id, body) =>
  axios.post(`/users/admins/update/${id}`, body);
export const editSuperAdmin = (id, body) =>
  axios.put(`/users/super-admins/${id}`, body);

export const putUserToggleStatus = id =>
  axios.put(`/users/toggle-status/${id}`);

export const putAgencyToggleStatus = id =>
  axios.put(`/agencies/toggle-status/${id}`);

// Admin/UpdateProfileRequests
export const getUpdateProfileIndex = params =>
  axios.get(`/update-profile-requests/index`, { params });

// Admin/User/AmlCheck
export const getAMLCheckIndex = params =>
  axios.get(`/reports/aml-check`, { params });

export const getUpdateProfileView = id =>
  axios.get(`/update-profile-requests/view/${id}`);

export const postUpdateProfileReject = id =>
  axios.post(`/update-profile-requests/reject/${id}`);

export const postUpdateProfileAccept = id =>
  axios.post(`/update-profile-requests/accept/${id}`);

// export const getAdminAssignedStaff = ({ id, offset }) =>
//   axios.get(`/staff-profile/${id}/assigned-agencies`, { params: { offset } });

// export const getAdminNotes = ({ id, offset, type }) =>
//   axios.get(`/staff-profile/${id}/notes?offset=${offset}&type=${type}`);

// Admin/BranchProfile
export const getUserAdminBranchProfile = id =>
  axios.get(`/branch-profile/${id}/profile`);

export const AdminAddBranch = body =>
  axios.post(`/agency-profile/branches/store`, body).then(res => res.data);

export const EditBranch = (id, body) =>
  axios.post(`/branch-profile/${id}/update`, body).then(res => res.data);

// export const getUserAdminBranchAssignProperties = ({ id, ...params }) =>
//   axios.get(`/branch-profile/${id}/assigned-properties`, { params });

export const getUserAdminBranchOffers = ({ id, ...params }) =>
  axios.get(`/branch-profile/${id}/offers`, { params });

export const getUserAdminBranchInvoices = ({ id, ...params }) =>
  axios.get(`/branch-profile/${id}/invoices`, { params });

export const getUserAdminBranchEmployee = ({ id, ...params }) =>
  axios.get(`/branch-profile/${id}/employees`, { params });

export const getUserAdminBranchEmployeeLogs = ({ id, ...params }) =>
  axios.get(`/branch-profile/${id}/employee-logs`, { params });

// Admin/EmployeeProfile
export const getUserAdminEmployeeProfile = id =>
  axios.get(`/employee-profile/${id}/profile`);

export const editEmployee = (id, body) =>
  axios.post(`/employee-profile/${id}/update`, body).then(res => res.data);

export const getUserAdminEmployeeEmployeeLogs = ({ id, ...params }) =>
  axios.get(`/employee-profile/${id}/employee-logs`, { params });

export const getUserAdminEmployeeCommunicationLogs = ({ id, ...params }) =>
  axios.get(`/employee-profile/${id}/communication-logs`, { params });

export const getUserAdminEmployeeAssignProperties = ({ id, ...params }) =>
  axios.get(`/employee-profile/${id}/assigned-properties`, { params });

export const getUserAdminEmployeeINotes = (id, params) =>
  axios.get(`/employee-profile/${id}/notes`, { params });

export const getUserAdminEmployeeAccessControl = ({
  applicant_id: id,
  ...params
}) => axios.get(`/roles/user-roles/${id}`, { params });

export const getUserAdminEmployeeUploaddocz = params =>
  axios.get(`/files/upload-user-document`, { params });

export const postUserAdminEmployeeUploaddocz = (id, body) =>
  axios.post(`/files/upload-user-document/${id}`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });

export const deleteUserAdminEmployeeUploaddocz = id =>
  axios.delete(`/files/upload-user-document/${id}`);

// roles
export const deleteUserAdminRoles = ({ user_id, roleId }) =>
  axios.post(
    `/roles/${roleId}`,
    {},
    {
      params: { user_id },
    },
  );

export const getUserAdminPermissionsAgency = () =>
  axios.get(`/permissions/agency`);

export const postAdminRoles = params =>
  axios.post("/roles/store-and-assign", {}, { params });

export const getUserProfileInvoices = params =>
  axios.get("/user-profile/invoices", { params });

export const loginAsUserWithId = id =>
  _axiosWithAuth.get(`/login-as/${id}`).then(res => res.data);
