import { Link, useHistory } from "react-router-dom";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { topNavStyle } from "../styles/navigationbar.styles";
import { useSelector } from "react-redux";

import TopNavButtons from "./TopNavButtons";
import TopNavProfile from "./TopNavProfile";

// Icons
import uthLogo from "Assets/img/icons/icon_logo_navbar.svg";
import afLogo from "Assets/img/icons/af-logo.png";
import { ReactComponent as LeftArrow } from "Assets/img/icons/icon_Arrow_Left.svg";

import ElasticSearch from "./ElasticSearch";
import {mainRole} from "../../../../Utils/roles";

const useStyles = makeStyles(topNavStyle);

export default function TopNav() {
  const classes = useStyles();
  const history = useHistory();
  const userRole = useSelector(store => mainRole(store?.auth?.user));
  const agencyMembershipType = useSelector(
    store => store?.auth?.user?.agency?.membership_type,
  );
  const isBigScreen = useMediaQuery("(min-width: 992px)");

  // for login as
  const prevUser = useSelector(store => store?.auth?.prevUser);
  const user = useSelector(store => store?.auth?.user);

  const backToPrevUserDashboard = () => {
    const typeOfUser = mainRole(user) === "trader" ? "buyer" : mainRole(user);
    history.push(
      `/${typeOfUser}/handle-login-as/${prevUser.token}/${prevUser.role}/No/No`,
    );
  };

  const handleLogoType = () => {
    return userRole === "af_admin" || agencyMembershipType === "af"
      ? afLogo
      : uthLogo;
  };

  return (
    <>
      {/*start back to prev dashboard___________*/}
      {prevUser?.token && prevUser?.token !== "No" && (
        <div className={classes.backToYourDashboardWrapper}>
          <button
            className={classes.backToYourDashboard}
            onClick={backToPrevUserDashboard}
          >
            <LeftArrow />
            <span>Back to your dashboard</span>
          </button>

          <h5>
            You are login as <span>{user?.first_name}</span>{" "}
            <span>{user?.last_name}</span> in <span>{mainRole(user)}</span>{" "}
            dashboard
          </h5>
        </div>
      )}
      {/*___________end back to prev dashboard*/}

      <div className={classes.top_navbar}>
        {isBigScreen && (
          <div
            className={
              agencyMembershipType === "af"
                ? classes.afTopNavLogo
                : classes.topNavLogo
            }
          >
            <Link to="/">
              <img
                src={handleLogoType()}
                alt="under the hammer"
                width="100%"
                height="100%"
              />
            </Link>
          </div>
        )}

        <div
          className={
            "d-flex align-items-center justify-content-between flex-column flex-xl-row flex-shrink-1 flex-grow-1 py-4 py-xl-0"
          }
        >
          <ElasticSearch />

          <div
            className={
              "profileAndBtnNavWrapper d-flex align-items-center justify-content-between"
            }
          >
            <TopNavButtons />

            <TopNavProfile />
          </div>
        </div>
      </div>
    </>
  );
}
