import { darken } from "@material-ui/core/styles";

export const profilePageStyle = theme => ({
  root: {
    marginTop: 42,
    marginBottom: 65,
  },
  box: {
    // width: "clamp(840px, 500px, 100%)",
    minHeight: "50vh",
    background: theme.theme_type === "light" ? "white" : "rgba(0, 0, 0, 0.2)",
    boxShadow: "0px 0px 10px #00000033",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    padding: 24,
    paddingTop: 92,
    "& .imgDiv": {
      marginBottom: 25,
    },
    "& p.name": {
      ...theme.fonts.s16w600,
    },
  },
  tabMenu: {
    marginTop: 60,
    width: "100%",
    "& .tabLinks": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderBottom: `1px solid ${theme.colors.cyanBlueLight}`,
    },
    "& .tabs": {
      width: "90%",
      display: "grid",
      gridTemplateColumns: "repeat(2, 33%)",
      gridTemplateRows: "auto",
      gridColumnGap: "0px",
      gridRowGap: "0px",
      placeContent: "center",
      [theme.media(768)]: {
        gridTemplateColumns: "repeat(2, 53%)",
      },
      "& .tabItem ": {
        width: "100%",
        textAlign: "center",
        marginBottom: 13,
        cursor: "pointer",
        padding: 10,
        position: "relative",
        ...theme.fonts.s16w500,
        textTransform: "uppercase",
        [theme.breakpoints.down("sm")]: {
          ...theme.fonts.s14w500,
        },
        color: "#9CACBC",
      },
      "& .tabItem.active": {
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        color: theme.theme_type === "light" ? "#333" : "#FFF",
        borderRadius: 5,
        ...theme.fonts.s16w700,
        [theme.breakpoints.down("sm")]: {
          ...theme.fonts.s14w700,
        },
        "&::after": {
          content: '""',
          position: "absolute",
          bottom: -14,
          height: 4,
          width: "100%",
          left: "0",
          right: "0",
          borderRadius: "50px 50px 0px 0px",
          backgroundColor: theme.theme_type === "light" ? "#333" : "#FFF",
        },
      },
      "& > .tabItem:nth-child(2)": {
        marginInline: 20,
      },
    },
  },
  formWrapper: {
    maxWidth: 850,
    "& form": {
      marginTop: 60,
    },
    "& .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before":
      {
        color: "#E50707",
      },
    "& .ant-select-selection-placeholder, & .ant-picker-input input::placeholder":
      {
        color: "#FFF",
      },
    //_______________phone number
    "& .ant-form-item-has-error .ant-input-group-addon, & .ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input:hover, .ant-form-item-has-error .ant-input-affix-wrapper:hover":
      {
        borderColor: "#ff4d4f",
      },
    "& .ant-input-group-addon": {
      backgroundColor: "rgba(19, 21, 27, 0.3) !important",
      border: "1px solid #FFFFFF50",
    },
    "& .ant-select-arrow": {
      color: "#FFF",
    },
    "& .ant-input-group-addon .ant-select-open .ant-select-selector, .ant-input-group-addon .ant-select-focused .ant-select-selector":
      {
        color: "#FFF",
      },
    "& .ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child":
      {
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        borderRight: 0,
      },
    "& .ant-input-number:hover, .ant-input-number:focus, .ant-input-number-focused, .ant-select:not(.ant-select-disabled):hover .ant-select-selector,.ant-input-affix-wrapper .ant-input:focus,.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector":
      {
        borderColor: "transparent !important",
      },
    "& .ant-input-group": {
      "& input": {
        backgroundColor: "rgba(19, 21, 27, 0.3)",
        padding: "7px 11px",
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        border: "1px solid #FFFFFF50",
        borderLeft: 0,
      },
    },
    "& .ant-select-selector": {
      minHeight: "auto",
    },
    //_______________end phone number
    "& .btnWrapper": {
      width: "100%",
      marginTop: 40,
      display: "flex",
      alignItems: "center",
      justifyContent: "between",
      [theme.media(410)]: {
        flexDirection: "column",
        "& > div:first-child": {
          order: 2,
          marginTop: 10,
        },
      },
      "& .save": {
        padding: "8px 15px",
        backgroundColor: theme.colors.green,
        border: "none",
        "&:disabled": {
          backgroundColor: darken(theme.colors.green, 0.5),
          color: darken("#FFF", 0.5),
          cursor: "auto",
        },
      },
      "& .cancel": {
        padding: "8px 30px",
        backgroundColor: "transparent",
        border: "1px solid #FFF",
        marginRight: 16,
      },
      "& .ant-form-item-control-input-content": {
        display: "flex",
        justifyContent: "flex-end",
        [theme.media(650)]: {
          justifyContent: "space-between",
        },
      },
      "& .logOut": {
        ...theme.fonts.s14w700,
        color: theme.palette.primary.main,
        cursor: "pointer",
      },
    },
    "& .message": {
      display: "flex",
      color: theme.colors.green,
      ...theme.fonts.s12w700,
      marginTop: 32,
      "& svg": {
        marginRight: 8,
      },
    },
  },
});
