import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { topNavStyle } from "../styles/navigationbar.styles";
import anonImage from "Assets/img/anon.png";
import Dropdown from "Components/Dropdown";
import { Menu } from "antd";
import Button from "Components/Button/Button";

// redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { logout, updateProfileDetails } from "Store/_actions/auth.action";
import { updateProfileRole } from "Store/_actions/auth.action";

// Icons
import { ReactComponent as LogOutIcon } from "Assets/img/icons/icon_Log out.svg";
import { ReactComponent as SwitchIcon } from "Assets/img/icons/icon_switch.svg";
import { ReactComponent as UserIcon } from "Assets/img/icons/icon_User.svg";
import {mainRole} from "../../../../Utils/roles";

const useStyles = makeStyles(topNavStyle);

export default function TopNavProfile() {
  const classes = useStyles();
  const { user, userRole } = useSelector(
    state => ({
      user: state.auth?.user,
      userRole: mainRole(state.auth?.user),
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleChangeTrader = () => {
    dispatch(updateProfileRole(userRole === "buyer" ? "seller" : "buyer"));
    history.push("/");
  };

  const handleThemeName = theme_name => {
    dispatch(updateProfileDetails({ theme_name }));
  };
  const handleThemeType = theme_type => {
    dispatch(updateProfileDetails({ theme_type }));
  };

  return (
    <>
      <div className={classes.topNavProfile}>
        {(userRole === "seller" || userRole === "buyer") && (
          <Button
            color="secondary"
            onClick={handleChangeTrader}
            className="topnav-profile-switch"
          >
            <span>Switch to {userRole === "buyer" ? "Seller" : "Buyer"}</span>
            <i>
              <SwitchIcon />
            </i>
          </Button>
        )}
        <div className="topnav-profile-name">
          <p>
            {user?.first_name} {user?.last_name}
          </p>
          <p>{mainRole(user)}</p>
        </div>
        <Dropdown
          overlayClassName={classes.profileDropdown}
          placement="bottomRight"
          overlay={
            <Menu>
              <Menu.Item
                key="1"
                icon={<UserIcon />}
                onClick={() => history.push(`/${userRole}/profile`)}
              >
                My Profile
              </Menu.Item>
              <Menu.Item key="2" icon={<LogOutIcon />} onClick={handleLogout}>
                Logout
              </Menu.Item>
            </Menu>
          }
        >
          <div className="topnav-profile-image">
            <img
              src={user?.image_url ? user.image_url : anonImage}
              alt="profile"
            />
          </div>
        </Dropdown>

        {/* <div>
          <Dropdown
            overlayClassName={classes.profileDropdown}
            placement="bottomRight"
            overlay={
              <Menu>
                <Menu.Item key="1" onClick={() => handleThemeName("theme1")}>
                  Theme 1
                </Menu.Item>
                <Menu.Item key="2" onClick={() => handleThemeName("theme2")}>
                  Theme 2
                </Menu.Item>
                <Menu.Item key="3" onClick={() => handleThemeName("theme3")}>
                  Theme 3
                </Menu.Item>
                <Menu.Item key="4" onClick={() => handleThemeName("theme4")}>
                  Theme 4
                </Menu.Item>
                <Menu.Item key="5" onClick={() => handleThemeName("theme5")}>
                  Theme 5
                </Menu.Item>
              </Menu>
            }
          >
            <div className="topnav-profile-theme">Name</div>
          </Dropdown>
        </div>
        <div>
          <Dropdown
            overlayClassName={classes.profileDropdown}
            placement="bottomRight"
            overlay={
              <Menu>
                <Menu.Item key="1" onClick={() => handleThemeType("light")}>
                  Light
                </Menu.Item>
                <Menu.Item key="3" onClick={() => handleThemeType("glass")}>
                  Glass
                </Menu.Item>
              </Menu>
            }
          >
            <div className="topnav-profile-theme ttt">Type</div>
          </Dropdown>
        </div> */}
      </div>
    </>
  );
}
