import axios from "./Api";

// AGENT
export const getAgentConversations = () =>
  axios.get("/messenger/conversations").then(res => res.data);

export const getAgentMessages = contact_id =>
  axios
    .get(`/messenger/messages?contact_id=${contact_id}`)
    .then(res => res.data);

export const postAgentMessagesSend = body =>
  axios.post(`/messenger/messages`, body).then(res => res.data);

export const postAgentSeenMessagesSend = contact_id =>
  axios.post(`messenger/seen/${contact_id}`).then(res => res.data);

// ADMIN
export const getAdminConversations = () =>
  axios.get("communications/conversations").then(res => res.data);

export const getAdminMessages = (agency_id, contact_id) =>
  axios
    .get(
      `/communications/messages?contact_id=${contact_id}&agency_id=${agency_id}`,
    )
    .then(res => res.data);
