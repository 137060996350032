import { useEffect, useRef, useState } from "react";
import { ReactComponent as IconPencil } from "Assets/img/icons/icon_pencil.svg";
import { ReactComponent as IconPlus } from "Assets/img/icons/icon_plus.svg";
import { makeStyles } from "@material-ui/core";
import Spin from "Components/Spinner/Spin";
import { updateProfileImage } from "Services";
import { useDispatch } from "react-redux";
import { avatarUploadStyle } from "./avatarUpload.style";
import clsx from "clsx";
import { updateProfileDetails } from "Store/_actions/auth.action";
import { updateAgencyLogo } from "Services";
import { updateSomeAgencyDetails } from "Store/_actions/auth.action";

const useStyle = makeStyles(avatarUploadStyle);

export default function Index({ defaultImg, className, isAgency = false }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImage] = useState(defaultImg);
  const [error, setError] = useState([]);
  const fileInputRef = useRef();

  const handle_checkValidate = file => {
    let errorArray = [];
    const acceptedImageTypes = ["image/jpg", "image/jpeg", "image/png"];
    const isImage = acceptedImageTypes.includes(file?.type);
    if (!isImage) {
      errorArray.push("You can only upload JPEG/PNG/JPG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      errorArray.push("Image must smaller than 2MB!");
    }
    setError(errorArray);
    return errorArray;
  };

  useEffect(() => {
    setImage(defaultImg);
  }, [defaultImg]);

  const handle_changeImg = async evt => {
    const image = evt.target.files[0];
    const hasError = handle_checkValidate(image);
    if (hasError.length > 0) return null;

    setImage(URL.createObjectURL(image));
    let body = new FormData();
    body.append("image", image);

    setLoading(true);
    if (isAgency) {
      await updateAgencyLogo(body)
        .then(res => {
          dispatch(updateSomeAgencyDetails(res.data));
        })
        .catch(err => setImage(defaultImg));
    } else {
      await updateProfileImage(body)
        .then(res => {
          dispatch(updateProfileDetails(res.data));
        })
        .catch(err => setImage(defaultImg));
    }
    setLoading(false);
  };

  const handle_openInput = evt => {
    evt.preventDefault();
    fileInputRef.current.click();
  };

  return (
    <div>
      <Spin spinning={loading}>
        <div className={clsx([classes.imgSelector, className])}>
          <input
            type="file"
            style={{ display: "none" }}
            ref={fileInputRef}
            accept="image/*"
            onChange={handle_changeImg}
          />
          <div>{imageUrl && <img src={imageUrl} alt="img" />}</div>
          <button onClick={handle_openInput}>
            {imageUrl ? <IconPencil /> : <IconPlus />}
          </button>
        </div>
      </Spin>
      {error.length > 0 && (
        <ul className={classes.errorList}>
          {error.map((err, index) => (
            <li key={index}>{err}</li>
          ))}
        </ul>
      )}
    </div>
  );
}
