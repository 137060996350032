import { Children } from "react";
import { makeStyles } from "@material-ui/core";

import { ReactComponent as IconUser } from "Assets/img/icons/icon_User.svg";
import { ReactComponent as IconCalender } from "Assets/img/icons/icon_Calender.svg";
import { ReactComponent as IconMap } from "Assets/img/icons/icon_map-marker.svg";
import { ReactComponent as IconCallAML } from "Assets/img/icons/icon_callAML.svg";
import { ReactComponent as IconNote } from "Assets/img/icons/icon_note.svg";
import AMLCheckIntro from "Assets/img/AML-check-intro.png";
import { stepWrapperStyle } from "./stepWrapper.style";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 24,
    marginBottom: 125,
  },
  intro: {
    "& p": {
      margin: 0,
    },
    "& .imgDiv": {
      margin: 25,
      textAlign: "center",
    },
    "& .header": {
      marginBottom: 36,
      textAlign: "center",
    },
    "& i": {
      marginRight: 24,
    },
    "& svg": {
      width: 24,
      height: 24,
    },
    "& .title": {
      ...theme.fonts.s24w600,
      margin: 8,
      textTransform: "uppercase",
    },
    "& .subTitle, .subLabel": {
      ...theme.fonts.s14w500,
      color: theme.theme_type === "light" ? "#9CACBC" : "#FFF",
    },
    "& .label": { ...theme.fonts.s16w600, textTransform: "uppercase" },
    "& .list-icon": {
      " & path": {
        fill: theme.theme_type === "dark" ? "#ffffff" : "#9CACBC",
      },
    },
    "& .lists > div": {
      marginBottom: 18,
    },
    "& .infoBox": {
      marginBottom: 25,
      border: "1px solid #9CACBC",
      borderRadius: 2,
      padding: "15px 26px",
      ...theme.fonts.s12w500,
      color: theme.theme_type === "light" ? "#9CACBC" : "#FFF",
      marginTop: 18,
      "& p": {
        textAlign: "center",
      },
      "& svg path": {
        fill: theme.theme_type === "light" ? "#9CACBC" : "#FFF",
      },
    },
    "& button": {
      padding: "8px 16px",
      borderRadius: 5,
      ...theme.fonts.s14w500,
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export default function IntroComponent({ setStart }) {
  const classes = useStyles();
  const LIST = [
    {
      icon: <IconUser />,
      label: "Personal details",
      subLabel: "Tell us something more about you",
    },
    {
      icon: <IconCalender />,
      label: "date of birth",
      subLabel: "The day you were born",
    },
    {
      icon: <IconCallAML />,
      label: "contact details",
      subLabel:
        "In exchange for your contacts, here is our contact no. 034 55 120 115",
    },
    {
      icon: <IconMap />,
      label: "address",
      subLabel: "It’s almost finished",
    },
    {
      icon: <IconNote />,
      label: "review",
      subLabel: "To edit any of this information, use the “previous” button",
    },
  ];
  return (
    <div className={classes.intro}>
      <div className="imgDiv">
        <img src={AMLCheckIntro} alt="" />
      </div>
      <div className="header">
        <p className="title">Complete your AML check</p>
        <p className="subTitle">
          We need to check these information for money laundry regulations.
        </p>
      </div>
      <div className="lists">
        {Children.toArray(
          LIST.map(({ icon, label, subLabel }) => (
            <div className="d-flex">
              <div className="list-icon">
                <i>{icon}</i>
              </div>
              <div>
                <p className="label">{label}</p>
                <p className="subLabel">{subLabel}</p>
              </div>
            </div>
          )),
        )}
      </div>
      <div className="infoBox d-flex align-items-center">
        <i>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.9997 6.66683H14.1663V5.00016C14.1663 2.70016 12.2997 0.833496 9.99967 0.833496C7.69967 0.833496 5.83301 2.70016 5.83301 5.00016V6.66683H4.99967C4.08301 6.66683 3.33301 7.41683 3.33301 8.3335V16.6668C3.33301 17.5835 4.08301 18.3335 4.99967 18.3335H14.9997C15.9163 18.3335 16.6663 17.5835 16.6663 16.6668V8.3335C16.6663 7.41683 15.9163 6.66683 14.9997 6.66683ZM9.99967 14.1668C9.08301 14.1668 8.33301 13.4168 8.33301 12.5002C8.33301 11.5835 9.08301 10.8335 9.99967 10.8335C10.9163 10.8335 11.6663 11.5835 11.6663 12.5002C11.6663 13.4168 10.9163 14.1668 9.99967 14.1668ZM7.49967 6.66683V5.00016C7.49967 3.61683 8.61634 2.50016 9.99967 2.50016C11.383 2.50016 12.4997 3.61683 12.4997 5.00016V6.66683H7.49967Z"
              fill="#9CACBC"
            />
          </svg>
        </i>
        <p>
          Your info will be encrypted, stored securely, and only ever used to
          verify your idenity
        </p>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <button onClick={() => setStart(true)}>Get started</button>
      </div>
    </div>
  );
}
