import React, { useState, useLayoutEffect, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import Spin from "../Spinner/Spin";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    width: "100%",
    overflowY: "scroll",
    "&>:first-child": {
      width: "100%",
    },
  },
  spinner: {
    width: "100%",
    position: "absolute",
    right: 0,
    left: 0,
    bottom: 0,
  },
  perspective: {
    width: "100%",
    background:
      "linear-gradient(179.33deg, rgba(255, 255, 255, 0) 0.58%, rgba(255, 255, 255, 0.8) 33.76%, #FFFFFF 82.95%)",
  },
}));

export default function ScrollingPagination({
  className,
  style,
  apiHandler,
  scrollBox = true,
  distance = 220, // distance from before reaching the bottom of the box for request to api
  logDistance, // console.log height of scroll box and our scroller place in box
  perspective = 100,
  search,
  setSearch,
  searchCondition = { lessEqual: 0, moreEqual: 3 },
  tip = "Loading...",
  customLoading,
  children,
  ...props
}) {
  const classes = useStyles();

  {
    /*
      ? help
      permission, for send request to api
      * reason: !to avoid repeated requests by scrolling or receiving an empty data by pagintion from the api 
    */
  }
  const [permission, setPermission] = useState(true);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const _apiHandler = () => {
    apiHandler({
      page,
      setPage, // use for search input to reset page to 0
      search,
      setSearch,
      permission,
      setPermission,
      loading,
      setLoading,
    });
  };

  const hasOneParentChildren = child => {
    if (React.Children.only(child)) return true;
    else
      throw new Error(
        "children has only one child in ScrollingPagination Component",
      );
  };

  // get api data before render layout
  useLayoutEffect(() => {
    if (searchCondition.moreEqual >= search?.length) {
      _apiHandler();
    } else if (
      search === undefined ||
      search?.length <= searchCondition.lessEqual
    ) {
      _apiHandler();
    }
  }, [page, search]);

  return (
    <div
      className={clsx(
        "custom-scrollbar scroll-pagination-root",
        classes.root,
        className,
      )}
      style={style}
      onScroll={e => {
        if (logDistance) {
          console.log(
            // "test your distance",
            "scroll point for start fetch data api:",
            e.target.scrollHeight,
            "\nyour current scroll position:",
            e.target.scrollTop + distance,
          );
        }
        if (
          e.target.scrollHeight < e.target.scrollTop + distance &&
          permission
        ) {
          setPermission(false);
          setPage(page + 1);
        }
      }}
      {...props}
    >
      {hasOneParentChildren(children) && children}
      {customLoading || (
        <Spin spinning={loading}>
          {loading && (
            <div
              className={classes.perspective}
              style={{ height: perspective }}
            />
          )}
        </Spin>
      )}
    </div>
  );
}
