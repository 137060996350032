import Wrapper from "./Wrapper";
import { ReactComponent as EmptyBoxImg } from "Assets/img/empty_box.svg"; // <img> can not load if internet lost
import { ReactComponent as IconSwitch } from "Assets/img/icons/icon_switch.svg";

export default function GeneralErrorPage({ children }) {
  return (
    <Wrapper>
      <EmptyBoxImg />
      <p>oops!</p>
      {children}
      <button
        onClick={() => window.location.reload()}
        style={{ minWidth: 180, marginBottom: 20 }}
      >
        <IconSwitch />
        &nbsp; Refresh the page
      </button>
    </Wrapper>
  );
}
