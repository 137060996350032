import React, { useState, useCallback, Fragment } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import { Select } from "antd";
import clsx from "clsx";
import PropTypes from "prop-types";

// Components
import SortDropdown from "./TableUtils/SortDropdown";
import TableFilterTeamMember from "./TableUtils/TableFilterTeamMember";

// assets
import { ReactComponent as SearchSvg } from "Assets/img/icons/search.svg";

// styles
import styles from "./styles/table_header.styles";

// destructure Components
const { Option } = Select;

const useStyles = makeStyles(styles);

export default function TableHeader({
  // title,
  // numberOfShowingItems,
  totalItemsCount,
  // extraDetails,
  // viewType,
  // showMainViewType,
  // moreActions,
  // OtherViewIcon,
  // setMainViewType,
  // onViewChange,
  search,
  setSearch,
  showSearch,
  buttons,
  showTeamMemberFilter,
  setTeamMemberId,
  setAdminFilters,
  sorts,
  tableSortType,
  setTableSortType,
  tableSortBy,
  setTableSortBy,
  HeaderContainer = React.Fragment,
  tableClasses,
  handleSearch,
  //! props needed for applicant notification page
  searchProperties,
  showHeaderPageCount,
  setItemsPerPage,
  hideHeaderInputLabel,
  noContainerClass = false,
  customFilter,
  staticRole,
}) {
  const [thisSearch, setThisSearch] = useState("");
  const putSearch = useCallback(() => {
    let searchText;
    switch (searchProperties.label) {
      case true:
        searchText = "Search by";
        break;

      case false:
        break;

      default:
        searchText = searchProperties.label;
        break;
    }
    return searchText;
  }, []);

  // // handle main view change
  // const changeMainView = type => {
  //   setMainViewType(type);
  //   if (onViewChange) onViewChange(type);
  // };

  // handle search
  const handleThisSearch = e => {
    setThisSearch(e?.target?.value);
  };

  const handleSearchWhenPressEnter = e => {
    // 13 is "Enter" key code
    if (e.keyCode === 13) {
      setSearch(thisSearch);
    }
  };

  // create Option List  for items per page
  const generateOptions = () => {
    const values = [];
    for (let i = 10; i < totalItemsCount + 10; i += 10) {
      values.push(i);
    }
    return (
      <>
        {values.map((item, index) => (
          <Option key={item + index} value={item} disable="false">
            {item}
          </Option>
        ))}
      </>
    );
  };

  const classes = useStyles();

  return (
    <HeaderContainer>
      <Grid
        container
        className={clsx(
          !noContainerClass && "container",
          classes.tableHeader_root,
          tableClasses?.header,
          "table-header",
        )}
      >
        <Grid item container className="left-side">
          {/* FILTER  */}
          {showTeamMemberFilter && (
            <TableFilterTeamMember
              setTeamMemberId={setTeamMemberId}
              setAdminFilters={setAdminFilters}
              hideHeaderInputLabel={hideHeaderInputLabel}
              staticRole={staticRole}
            />
          )}
          {customFilter}
          {showSearch && (
            <div className={classes.searchBox}>
              {!hideHeaderInputLabel && (
                <div className="search-name">{putSearch()}</div>
              )}

              <div className="header-search-input">
                <input
                  value={thisSearch}
                  onChange={handleThisSearch}
                  onKeyDown={handleSearchWhenPressEnter}
                  placeholder={
                    searchProperties.placeholder ?? "Location/ Property name..."
                  }
                />
                <span
                  className="d-flex justify-content-center align-items-center"
                  style={{ cursor: "pointer", width: 42, height: 40 }}
                  onClick={() => {
                    if (handleSearch) {
                      handleSearch(thisSearch);
                    } else {
                      setSearch(thisSearch);
                    }
                  }}
                >
                  <SearchSvg />
                </span>
              </div>
            </div>
          )}

          {/* page count dropdown */}
          {showHeaderPageCount && (
            <div className={classes.itemsPerPageCount}>
              <div className="label">Record per page</div>
              <div className="header-pageCount-input">
                <Select
                  defaultValue={10}
                  onChange={setItemsPerPage}
                  dropdownClassName={classes.itemsPerPageCountDropdown}
                >
                  {generateOptions()}
                </Select>
              </div>
            </div>
          )}
        </Grid>

        <Grid item className="right-side">
          {/* BUTTONS  */}
          {buttons &&
            buttons?.map((item, index) => (
              <Fragment key={index}>{item}</Fragment>
            ))}

          {/* SORT  */}
          {sorts?.length && (
            <SortDropdown
              sorts={sorts}
              tableSortType={tableSortType}
              setTableSortType={setTableSortType}
              tableSortBy={tableSortBy}
              setTableSortBy={setTableSortBy}
            />
          )}
        </Grid>
      </Grid>
    </HeaderContainer>
  );
}

TableHeader.propTypes = {
  // title: PropTypes.string,
  // extraDetails: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  // showMainViewType: PropTypes.bool,
  showSearch: PropTypes.bool,
  sorts: PropTypes.array,
  // moreActions: PropTypes.array,
  // buttons: PropTypes.array,
  // numberOfShowingItems: PropTypes.oneOfType([
  //   PropTypes.string,
  //   PropTypes.number,
  // ]),
  // viewType: PropTypes.string,
  // setMainViewType: PropTypes.func,
  search: PropTypes.string,
  setSearch: PropTypes.func,
  showTeamMemberFilter: PropTypes.bool,
  setTeamMemberId: PropTypes.func,
  tableSortType: PropTypes.string,
  setTableSortType: PropTypes.func,
  tableSortBy: PropTypes.string,
  setTableSortBy: PropTypes.func,
  searchProperties: PropTypes.shape({
    label: PropTypes.bool,
    //! change after updating other component change to only string
    placeholder: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOf([null]),
    ]),
  }),
};
