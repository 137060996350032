export const modalStyles = theme => ({
  modal_wrapper: {
    "& .ant-modal-content": {
      borderRadius: 5,
      background: theme.theme_type === "light" ? "white" : "#3a3a3a",
      color: theme.theme_type === "light" ? "#333" : "#ffffff",
      boxShadow: "0px 0px 80px rgba(0, 0, 0, 0.1)",
    },

    "& .ant-modal-close": {
      top: 4,
      right: 7,
      "& svg": {
        "& path": {
          fill: theme.palette.secondary.main,
        },
      },
      "& .ant-modal-close-x": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& path": {
          fill: theme.theme_type === "light" ? "#333" : "#ffffff",
        },
      },
    },
    "& .ant-modal-header": {
      padding: "20px 20px",
      borderRadius: "5px 5px 0 0",
      background: theme.theme_type === "light" ? "white" : "#3a3a3a",
      "& .ant-modal-title": {
        ...theme.fonts.s16w600,
        color: theme.theme_type === "light" ? "#333" : "#ffffff",
        letterSpacing: "-0.03em",
        textTransform: "uppercase",
        lineHeight: "26px",
        [theme.media(360)]: {
          marginRight: 20,
        },
      },
    },
    "& .ant-modal-body": {
      padding: "20px 20px 32px",
      maxHeight: "75vh",
      overflow: "auto",
      borderRadius: "0 0 5px 5px",
      scrollbarWidth: "thin",
      scrollbarColor: "#e9f0f6 #f3f7fa",
      "&::-webkit-scrollbar": {
        width: "5px",
        height: "10px",
        borderRadius: "3px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#3C5670",
        borderRadius: "3px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#7E9CBA",
        borderRadius: "3px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#e9f0f6",
        borderRadius: "3px",
      },
      "@media (max-width: 600px)": {
        padding: 20,
      },
    },
    "& .ant-modal-footer": {
      borderTop: "unset",
      paddingBottom: 30,
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  removeHeaderBorder: {
    "& .ant-modal-header": {
      borderWidth: 0,
    },
  },
});

export const filterBranchModalStyle = theme => ({
  filterBtn: {
    background: theme.theme_type === "light" ? "#E9F0F6" : "#ffffff1a",
    padding: "7px 16px",
    display: "flex",
    alignItems: "center",
    width: "auto !important",
    "& > span": {
      ...theme.fonts.s14w500,
      marginRight: 7,
    },
    "@media(max-width: 576px)": {
      width: "100% !important",
    },
  },
  adminFilterBtn: {
    background: "rgba(255, 255, 255, 0.1)",
    padding: "6px",
    "& path": {
      fill: "#BECFE0",
    },
    "@media(max-width: 576px)": {
      width: "100% !important",
    },
  },
  modalWrapper: {
    "& .ant-modal-body": {
      padding: "20px 20px 32px",
      maxHeight: "70vh",
      overflow: "auto",
      borderRadius: "0 0 5px 5px",
    },
  },
  wrapper: {
    width: "100%",
    borderRadius: 5,
    fontSize: 12,
    fontWeight: 500,
  },

  scroll: {
    maxHeight: 250,
    paddingBottom: 30,
  },

  innerContent: {
    padding: 20,
    background: theme.theme_type === "light" ? "white" : "#00000033",
    border:
      theme.theme_type === "light" ? "1px solid #333" : "1px solid #FFFFFF",
    borderRadius: 5,
    marginTop: 18,
    position: "relative",
    "& .inner-head": {
      display: "flex",
      alignItems: "center",
      "& span": {
        ...theme.fonts.s14w500,
        marginLeft: 10,
      },
      "& path": {
        fill: theme.theme_type === "light" ? "#333" : "#fff",
      },
    },
  },

  selectAll: {
    margin: "21px 0px 14px 0px",
    display: "flex",
    alignItems: "flex-start",
    cursor: "pointer",
    paddingBottom: 10,
    borderBottom: "1px solid rgba(233, 240, 246, 0.25)",

    "& svg": {
      width: "20px",
      height: "20px",
    },
    "& span": {
      marginLeft: 10,
    },
  },

  option: {
    margin: "0 15px 10px 0px",
    display: "flex",
    alignItems: "flex-start",
    cursor: "pointer",

    "& svg": {
      width: "20px",
      height: "20px",
    },

    "& span": {
      marginLeft: 10,
    },
  },

  search: {
    padding: 0,
    marginRight: 5,
    borderRadius: 2.5,
    border: "1px solid rgba(255, 255, 255, 0.25)",
    "& input.ant-input": {
      padding: "7px 16px",
      backgroundColor: theme.theme_type === "light" ? "#E9F0F6" : "#191919",
      color: theme.theme_type === "light" ? "#333" : "white",
      borderRadius: "2.5px 0 0 2.5px",
    },
    "& .ant-input-suffix": {
      background: "#121212",
      margin: 0,
      borderRadius: "0 2.5px 2.5px 0",
      "& i": {
        cursor: "pointer",
        padding: "9px 11px 9px 14px",
        display: "flex",
        "& path": {
          fill: "white",
        },
      },
    },
  },

  select: {
    width: 250,
    background: "#ffffff1a",
    padding: "10px 19px 12px 16px",
    borderRadius: 5,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& i": {
      display: "flex",
      "& path": {
        fill: "white",
      },
    },
  },
  innerFooter: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "60px",
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  yellowBtn: {
    color: "white",
    backgroundColor: "#F9B914",
    borderRadius: 5,
    padding: "3.5px 16px",
    cursor: "pointer",
    margin: "0 12px",
    ...theme.fonts.s14w500,
  },
  blackBtn: {
    color: "white",
    backgroundColor: "#000000",
    border: 0,
    borderRadius: 5,
    padding: "3.5px 16px",
    cursor: "pointer",
    margin: "0 12px",
    ...theme.fonts.s14w500,
  },
});

export const enhancedFilterModalStyle = theme => ({
  adminFilterBtn: {
    background: theme.theme_type === "light" ? "#E9F0F6" : "#ffffff1a",
    padding: "5.5px 12px",
    display: "flex",
    alignItems: "center",
    border: "1px solid #ffffff",
    width: "auto !important",
    "& > span": {
      ...theme.fonts.s14w500,
      marginLeft: 7,
    },

    // "& svg path": {
    //   fill: "#FFF",
    //   transition: "all .3s",
    // },
    "&:hover": {
      "& svg path": {
        fill: "#F9B914",
      },
    },
    "@media(max-width: 576px)": {
      width: "100% !important",
    },
  },
  adminFilterBtnSmall: {
    display: "inline-flex",
    padding: 4,
    borderRadius: 5,
    background: theme.backgrounds.white1,
    cursor: "pointer",
    "& path": {
      fill: theme.theme_type === "light" ? "#333333" : "white",
    },
    "&:hover": {
      background: "rgba(255, 255, 255, 0.2)",
      "& path": {
        fill: theme.palette.primary.main,
      },
    },
  },
  tooltip: {
    width: "unset",
    height: "unset",
    display: "flex",
  },
  modalWrapper: {
    "& .ant-modal-body": {
      padding: "20px 20px 32px",
      maxHeight: "70vh",
      overflow: "auto",
      borderRadius: "0 0 5px 5px",
    },
    "@media(max-width: 576px)": {
      "& .ant-modal-body": {
        height: "100vh",
      },
    },
  },
  wrapper: {
    width: "100%",
    background: "transparent",
    color: "white",
    borderRadius: 5,
    fontSize: 12,
    fontWeight: 500,
  },

  content: {
    padding: 20,
    background: theme.theme_type === "light" ? "#F8FAFC" : "#ffffff1a",
    borderRadius: 5,
    marginTop: 18,
    "& .inner-head": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: 10,
      "& span": {
        marginBottom: -2,
        ...theme.fonts.s10w500,
        color: theme.theme_type === "light" ? "#333" : "#BECFE0",
      },
      "& h4": {
        marginBottom: 0,
        marginRight: 6,
        ...theme.fonts.s14w500,
        color: theme.theme_type === "light" ? "#333" : "white",
      },
    },
  },

  innerContent: {
    height: "40vh",
    overflowY: "scroll",
    paddingRight: 7,
  },

  search: {
    padding: 0,
    marginRight: 5,
    borderRadius: 2.5,
    border: "1px solid rgba(255, 255, 255)",
    "& input.ant-input": {
      padding: "7px 16px",
      backgroundColor: theme.theme_type === "light" ? "#E9F0F6" : "#191919",
      color: theme.theme_type === "light" ? "#333" : "white",
      borderRadius: "2.5px 0 0 2.5px",
    },
    "& .ant-input-suffix": {
      background: "#121212",
      margin: 0,
      borderRadius: "0 2.5px 2.5px 0",
      "& i": {
        cursor: "pointer",
        padding: "9px 11px 9px 14px",
        display: "flex",
        "& path": {
          fill: "white",
        },
      },
    },
  },

  agentItem: {
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
    background: theme.theme_type === "light" ? "#E9F0F6" : "#ffffff1a",
    marginBottom: 10,
    cursor: "pointer",
    "& > h3": {
      ...theme.fonts.s10w500,
      flex: 1,
      color: theme.theme_type === "light" ? "#333" : "#fff",
    },

    "& > div": {
      flex: 0.6,
      "& > span": {
        display: "block",
        ...theme.fonts.s10w500,
        color: "#BECFE0",
      },
    },
  },

  propertyItem: {
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 2,
    background: theme.theme_type === "light" ? "#E9F0F6" : "#ffffff1a",
    marginBottom: 10,
    cursor: "pointer",
    "& > h3": {
      display: "flex",
      ...theme.fonts.s12w500,
      flex: 1,
      color: theme.theme_type === "light" ? "#333" : "#fff",
      "& > svg": {
        width: 12,
        height: 12,
        marginTop: 3,
        marginRight: 6,
        "& path": {
          fill: "#ffffff",
        },
      },
    },

    "& > div": {
      flex: 0.6,
      "& > span": {
        display: "block",
        ...theme.fonts.s10w500,
        color: "#BECFE0",
      },
    },
  },
  activeitem: {
    background: "rgba(2, 176, 117, 0.3) !important",
  },
  innerFooter: {
    width: "100%",
    marginTop: 12,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  yellowBtn: {
    color: "white",
    backgroundColor: "#F9B914",
    borderRadius: 5,
    padding: "3.5px 16px",
    cursor: "pointer",
    margin: "0 12px",
    ...theme.fonts.s14w500,
  },
  blackBtn: {
    color: "white",
    backgroundColor: "#000000",
    border: 0,
    borderRadius: 5,
    padding: "3.5px 16px",
    cursor: "pointer",
    margin: "0 12px",
    ...theme.fonts.s14w500,
  },
});

export const filterTeamMemberStyle = theme => ({
  wrapper: {
    background: theme.theme_type === "light" ? "white" : "#3a3a3a",
    color: theme.theme_type === "light" ? "#333" : "white",
    boxShadow: "0px 0px 20px #00000033",
    borderRadius: 5,
    padding: "16px 4px 0 9px",
    fontSize: 12,
    fontWeight: 500,
  },

  scroll: {
    maxHeight: 400,
  },

  selectAll: {
    margin: "21px 20px 14px 7px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&>i:first-child": {
      marginRight: "11px",
      display: "flex",
      "& svg": {
        width: "16px",
        height: "16px",
        "& path": {
          fill: theme.theme_type === "light" ? "#333" : "white",
        },
      },
    },
    "&>i:last-child": {
      marginLeft: "auto",
      display: "flex",
      "& svg": {
        width: "16px",
        height: "16px",
      },
    },
  },

  option: {
    margin: "0 15px 10px 0px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&>:first-child": {
      marginRight: 4,
      width: "30px",
      height: "30px",
      "& img": {
        borderRadius: 5,
        width: "100%",
        height: "100%",
      },
    },
    "&>i:last-child": {
      marginLeft: "auto",
      display: "flex",
      "& svg": {
        width: "16px",
        height: "16px",
      },
    },
  },

  search: {
    border: 0,
    padding: 0,
    marginRight: 5,
    borderRadius: 2.5,
    "& input.ant-input": {
      padding: "7px 16px",
      background: theme.theme_type === "light" ? "white" : "#191919",
      color: theme.theme_type === "light" ? "#333" : "white",
      borderRadius: "2.5px 0 0 2.5px",
      border: "1px solid #00000040",
    },
    "& .ant-input-suffix": {
      background: theme.theme_type === "light" ? "white" : "#121212",
      border: "1px solid #00000040",
      borderLeft: 0,
      margin: 0,
      borderRadius: "0 2.5px 2.5px 0",
      "& i": {
        cursor: "pointer",
        padding: "9px 11px 9px 14px",
        display: "flex",
        "& path": {
          fill: theme.theme_type === "light" ? "#333" : "white",
        },
      },
    },
  },

  select: {
    // width: 250,
    background: theme.theme_type === "light" ? "#E9F0F6" : "#ffffff1a",
    padding: "10px 19px 12px 16px",
    borderRadius: 5,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& i": {
      display: "flex",
      "& path": {
        fill: theme.theme_type === "light" ? "#333" : "white",
      },
    },
  },
});
