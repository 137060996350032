// import { lazy } from "react";
import lazy from "./lazyWithRetry";

// store
import store from "Store/store";

// utils
import { filterArray } from "Utils/helperFunction";

//Pages
import DashBoardPage from "Pages/Dashboard";

//BuyerSeller Pages
import AlertPage from "Pages/BuyerSeller/Alerts"; // lazy(() => import('Pages/BuyerSeller/Alerts'))
import AuctionOffersPage from "Pages/BuyerSeller/AuctionOffers"; // lazy(() => import('Pages/BuyerSeller/AuctionOffers'))
import WatchListPage from "Pages/BuyerSeller/WatchingList"; // lazy(() => import('Pages/BuyerSeller/WatchingList'))
import MyBidsPage from "Pages/BuyerSeller/MyBids"; // lazy(() => import('Pages/BuyerSeller/MyBids'))
import AMLChecksPage from "Pages/BuyerSeller/AMLChecks"; // lazy(() => import('Pages/BuyerSeller/AMLChecks'))
import ProfilePageBuyerSeller from "Pages/BuyerSeller/ProfilePage"; // lazy(() => import('Pages/BuyerSeller/ProfilePage'))
import DocsPage from "Pages/BuyerSeller/DocsPage";

//Icons
import { ReactComponent as DashboardIcon } from "Assets/img/icons/icon_dashboard.svg";
import { ReactComponent as RealtimeIcon } from "Assets/img/icons/icon_Activity.svg";
import { ReactComponent as ProfileIcon } from "Assets/img/icons/icon_User.svg";
import { ReactComponent as UsersMyteamIcon } from "Assets/img/icons/icon_users-myteam.svg";
import { ReactComponent as OffersIcon } from "Assets/img/icons/icon_offers.svg";
import { ReactComponent as HomeIcon } from "Assets/img/icons/icon_home.svg";
import { ReactComponent as EyeIcon } from "Assets/img/icons/icon_eye.svg";
import { ReactComponent as AlertIcon } from "Assets/img/icons/icon_notication.svg";
import { ReactComponent as InvoicesIcon } from "Assets/img/icons/icon_credit-card.svg";
import { ReactComponent as SettingIcon } from "Assets/img/icons/icon_setting.svg";
import { ReactComponent as LegalpackIcon } from "Assets/img/icons/icon_legalpack.svg";
import { ReactComponent as NoteIcon } from "Assets/img/icons/icon_note-invoice.svg";
import {mainRole} from "../Utils/roles";

function allUsersRoutes() {
  const userState = store?.getState();
  const userAccess = mainRole(userState?.auth?.user);
  const agencyMembershipType = userState?.auth?.user?.agency?.membership_type;

  const layout = `/${userAccess}`;
  const purgeRoutes = user => user.includes(userAccess);

  return filterArray([
    //mutual pages,
    {
      path: "/support",
      name: "Support",
      component: lazy(() => import("Pages/Support")),
      exact: true,
      layout,
      isInMenu: false,
    },
    {
      path: "/support/:ticketId",
      name: "Support",
      component: lazy(() => import("Pages/Support/ViewTicket")),
      exact: true,
      layout,
      isInMenu: false,
    },
    //for login as
    {
      path: "/handle-login-as/:token/:userRole/:prevToken/:prevUserRole",
      name: "Login as handler",
      component: lazy(() => import("Components/LoginAs/LoginAsHandlerPage")),
      exact: true,
      layout,
      isInMenu: false,
    },
    purgeRoutes([
      "owner",
      "agent",
      "team_member",
      "admin",
      "af_admin",
      "uth_admin",
    ]) &&
      filterArray([
        {
          path: "/dashboard",
          name: "Dashboard",
          icon: <DashboardIcon />,
          component: DashBoardPage,
          exact: true,
          layout,
          id: "menu-dashboard",
        },
        {
          path: "/dashboard/outstanding",
          name: "Outstanding Tasks",
          icon: "",
          component: lazy(() =>
            import("Pages/Dashboard/Agent/OutstandingPage"),
          ),
          exact: true,
          layout,
          isInMenu: false,
        },
        purgeRoutes(["admin", "af_admin", "uth_admin"]) && [
          {
            path: "/task-management",
            name: "Task management",
            icon: <UsersMyteamIcon />,
            component: lazy(() => import("Pages/TaskManagement")),
            exact: true,
            layout,
            id: "menu-task-management",
          },
        ],
        {
          path: "/realtime",
          name: "Realtime",
          icon: <RealtimeIcon />,
          component: lazy(() => import("Pages/Realtime")),
          exact: true,
          layout,
          id: "menu-real-time",
        },
        purgeRoutes(["owner", "agent", "team_member"]) && {
          path: "/my-team",
          name: "My Team",
          icon: <UsersMyteamIcon />,
          component: lazy(() => import("Pages/MyTeam")),
          exact: true,
          layout,
          id: "menu-my-team",
        },
        {
          path: "/my-team/applicant-profile/:id",
          name: "Team Member Appplicant Profile",
          icon: "",
          component: lazy(() => import("Pages/MyTeam/ApplicantsProfile")),
          exact: true,
          layout,
          isInMenu: false,
        },
        purgeRoutes(["admin", "af_admin", "uth_admin"]) && [
          {
            path: "/agencies-branches-employees/add-agency",
            name: "Add Agency",
            component: lazy(() => import("Pages/Users/AddPages/AddAgency")),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            path: "/agencies-branches-employees/edit-agency/:agencyId",
            name: "Edit Agency",
            component: lazy(() => import("Pages/Users/EditPages/EditAgency")),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            path: "/agencies-branches-employees/view-agency/:agencyId",
            name: "View Agency",
            component: lazy(() => import("Pages/Users/ViewPages/Agnecy")),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            path: "/admins-roles/add-admin",
            name: "Add Admin",
            component: lazy(() => import("Pages/Users/AddPages/AddAdmin")),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            path: "/admins-roles/edit-admin/:adminId",
            name: "Edit Admin",
            component: lazy(() => import("Pages/Users/EditPages/EditAdmin")),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            path: "/admins-roles/add-super-admin",
            name: "Add Admin",
            component: lazy(() => import("Pages/Users/AddPages/AddSuperAdmin")),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            path: "/admins-roles/edit-super-admin/:adminId",
            name: "Edit Admin",
            component: lazy(() =>
              import("Pages/Users/EditPages/EditSuperAdmin"),
            ),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            path: "/admins-roles/view-admin/:adminId",
            name: "View Admin",
            component: lazy(() => import("Pages/Users/ViewPages/Admin/index")),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            path: "/applicants-sellers-solicitors/view-seller/:sellerId",
            name: "View Seller",
            component: lazy(() => import("Pages/Users/ViewPages/Seller")),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            path: "/applicants-sellers-solicitors/edit-seller/:sellerId",
            name: "Edit Seller",
            component: lazy(() => import("Pages/Users/EditPages/EditSeller")),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            path: "/applicants-sellers-solicitors/view-solicitor/:solicitorId",
            name: "View Solicitor",
            component: lazy(() => import("Pages/Users/ViewPages/Solicitor")),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            path: "/applicants-sellers-solicitors/edit-solicitor/:solicitorId",
            name: "Edit Solicitor",
            component: lazy(() =>
              import("Pages/Users/EditPages/EditSolicitor"),
            ),
            exact: true,
            layout,
            isInMenu: false,
          },
          // {
          //   path: "/auth-token#",
          //   name: "Auth Token",
          //   component: lazy(() => <></>),
          // },
          {
            path: "/applicants-sellers-solicitors/add-solicitor",
            name: "Add solicitor",
            component: lazy(() => import("Pages/Users/AddPages/AddSolicitor")),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            path: "/applicants-sellers-solicitors/edit-applicant/:applicantId",
            name: "Edit Applicant",
            component: lazy(() =>
              import("Pages/Users/EditPages/EditApplicant"),
            ),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            path: "/agencies-branches-employees/add-branch/:agencyId",
            name: "Add Branch",
            component: lazy(() => import("Pages/Users/AddPages/AddBranch")),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            path: "/agencies-branches-employees/edit-branch/:branchId",
            name: "Edit Branch",
            component: lazy(() => import("Pages/Users/EditPages/EditBranch")),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            path: "/agencies-branches-employees/edit-employee/:employeeId",
            name: "Edit Employee",
            component: lazy(() => import("Pages/Users/EditPages/EditEmployee")),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            name: "Update request",
            path: "/users/profile-update-request/:id",
            icon: "",
            component: lazy(() =>
              import("Pages/Users/SubPages/UserUpdateRequestProfile"),
            ),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            path: "/invoices/add-invoice",
            name: "Add Invoice",
            icon: "",
            component: lazy(() => import("Pages/Invoices/AddInvoice")),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            path: "/invoices/edit-invoice/:invoiceID",
            name: "Edit Invoice",
            icon: "",
            component: lazy(() => import("Pages/Invoices/EditInvoice")),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            path: "/invoices/solicitors/edit-invoice/:invoiceID",
            name: "Edit Invoice",
            icon: "",
            component: lazy(() => import("Pages/Invoices/EditInvoice")),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            path: "/invoices/buyers/edit-invoice/:invoiceID",
            name: "Edit Invoice",
            icon: "",
            component: lazy(() => import("Pages/Invoices/EditInvoice")),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            path: "/invoices/agencies/edit-invoice/:invoiceID",

            name: "Edit Invoice",
            icon: "",
            component: lazy(() => import("Pages/Invoices/EditInvoice")),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            path: "/notes",
            name: "All Notes",
            icon: "",
            component: lazy(() => import("Pages/NotePage")),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            path: "/profile",
            name: "Profile",
            icon: <ProfileIcon />,
            component: lazy(() => import("Pages/ProfilePage/AdminProfile")),
            exact: true,
            layout,
            isInMenu: false,
          },
        ],
        purgeRoutes(["owner", "admin", "af_admin", "uth_admin"]) && [
          // {
          //   path: "/my-team/add-branch",
          //   name: "Add Team Branch",
          //   icon: "",
          //   component: lazy(() => import("Pages/MyTeam/Forms/AddBranch")),
          //   exact: true,
          //   layout,
          //   isInMenu: false,
          // },
          {
            path: "/my-team/edit-branch/:id",
            name: "Edit Team Branch",
            icon: "",
            component: lazy(() => import("Pages/MyTeam/Forms/AddBranch")),
            exact: true,
            layout,
            isInMenu: false,
          },
        ],
        {
          path: "/my-team/add-team-member",
          name: "Add Team Member",
          icon: "",
          component: lazy(() => import("Pages/MyTeam/AddTeamMemberPage")),
          exact: true,
          layout,
          isInMenu: false,
        },
        {
          path: "/my-team/edit-team-member/:id",
          name: "Edit Team Member",
          icon: "",
          component: lazy(() => import("Pages/MyTeam/AddTeamMemberPage")),
          exact: true,
          layout,
          isInMenu: false,
        },
        {
          path: "/my-team/employee-profile/:id",
          name: "Employee Profile",
          icon: "",
          component: lazy(() => import("Pages/MyTeam/EmployeeProfile")),
          exact: true,
          layout,
          isInMenu: false,
        },
        {
          path: "/my-team/branch-profile/:id",
          name: "Branch Profile",
          icon: "",
          component: lazy(() => import("Pages/MyTeam/BranchProfile")),
          exact: true,
          layout,
          isInMenu: false,
        },
        {
          name: "Edit property",
          path: "/properties/edit-property/:propertyId",
          icon: "",
          component: lazy(() => import("Pages/Properties/EditProperty")),
          exact: true,
          layout,
          isInMenu: false,
        },
        {
          name: "View property",
          path: "/properties/view-property/:propertyId",
          icon: "",
          component: lazy(() => import("Pages/Properties/ViewProperty")),
          exact: true,
          layout,
          isInMenu: false,
        },
        {
          name: "Completion-step",
          path: "/properties/completion-step/:propertyId",
          icon: "",
          component: lazy(() => import("Pages/Properties/CompletionStep")),
          exact: true,
          layout,
          isInMenu: false,
        },
        {
          name: "Properties",
          path: "/properties",
          icon: <HomeIcon />,
          layout,
          id: "menu-properties",
          routes: [
            purgeRoutes(["agent", "team_member", "owner"]) && {
              name: "Add property",
              path: "/properties/add-property",
              icon: "",
              component: lazy(() => import("Pages/Properties/AddProperty")),
              exact: true,
              layout,
              isInMenu: false,
            },
            {
              name: "live",
              path: "/properties/live",
              layout,
              exact: true,
              component: lazy(() => import("Pages/Properties")),
            },
            {
              name: "sold",
              path: "/properties/sold",
              layout,
              exact: true,
              component: lazy(() => import("Pages/Properties")),
            },
            {
              name: "unsold",
              path: "/properties/unsold",
              layout,
              exact: true,
              component: lazy(() => import("Pages/Properties")),
            },
            {
              name: "complete",
              path: "/properties/complete",
              layout,
              exact: true,
              component: lazy(() => import("Pages/Properties")),
            },
            {
              name: "draft",
              path: "/properties/draft",
              layout,
              exact: true,
              component: lazy(() => import("Pages/Properties")),
            },
            {
              name: "unpublished",
              path: "/properties/unpublished",
              layout,
              exact: true,
              component: lazy(() => import("Pages/Properties")),
            },
            (purgeRoutes(["admin", "uth_admin"]) ||
              (purgeRoutes(["agent", "team_member"]) &&
                agencyMembershipType === "uth")) && {
              name: "pending",
              path: "/properties/pending",
              layout,
              exact: true,
              component: lazy(() => import("Pages/Properties")),
            },
          ],
        },
        {
          path: "/offers",
          name: "Offers",
          icon: <OffersIcon />,
          component: lazy(() => import("Pages/Offers")),
          exact: true,
          layout,
          id: "menu-offers",
        },
        purgeRoutes(["admin", "uth_admin", "af_admin"]) && [
          {
            path: "/requests",
            name: "Requests",
            icon: <LegalpackIcon />,
            component: lazy(() => import("Pages/Requests")),
            exact: true,
            layout,
            isInMenu: true,
          },
          {
            path: "/reports",
            name: "Reports",
            icon: <LegalpackIcon />,
            component: lazy(() => import("Pages/Reports")),
            exact: true,
            layout,
            isInMenu: true,
          },
        ],
        {
          path: "/invoices/view-invoice/:invoiceID",
          name: "View Invoice",
          icon: "",
          component: lazy(() => import("Pages/Invoices/ViewInvoice")),
          exact: true,
          layout,
          isInMenu: false,
        },
        {
          path: "/invoices/view-payable-invoice",
          name: "View Payable Invoice",
          icon: "",
          component: lazy(() => import("Pages/Invoices/ViewNewInvoice")),
          exact: true,
          layout,
          isInMenu: false,
        },
        agencyMembershipType !== 'af' && {
          path: "/invoices",
          name: "Invoices",
          icon: <InvoicesIcon />,
          layout,
          id: "menu-invoices",
          routes: [
            {
              path: "/invoices/payable",
              name: "legalpack account payable",
              layout,
              exact: true,
              id: "invoices-payable-invoices",
              component: lazy(() => import("Pages/Invoices")),
            },
            {
              path: "/invoices/receivable",
              name: "legalpack account receivable",
              id: "invoices-receivable-invoices",
              layout,
              exact: true,
              component: lazy(() => import("Pages/Invoices")),
            },
            (purgeRoutes(["uth_admin", "owner", "admin", "af_admin"]) ||
              purgeRoutes(["buyer"])) && {
              path: "/invoices/buyers",
              name: "buyer invoice",
              layout,
              exact: true,
              id: "buyer-invoices",
              component: lazy(() => import("Pages/Invoices")),
            },
            purgeRoutes(["uth_admin", "owner", "admin", "af_admin"]) && {
              path: "/invoices/solicitors",
              name: "solicitor breakdown",
              id: "solicitor-breakdown-invoices",
              layout,
              exact: true,
              component: lazy(() => import("Pages/Invoices")),
            },
            // (purgeRoutes(["uth_admin", "owner", "admin", "af_admin"]) || /* for when buyers invoice active */
            //   purgeRoutes(["agent", "team_member"])) &&
            {
              path: "/invoices/agencies",
              name: "agent invoice",
              layout,
              exact: true,
              id: "agent-breakdown-invoices",
              component: lazy(() => import("Pages/Invoices")),
            },
          ],
        },
        purgeRoutes(["admin", "uth_admin", "af_admin"]) && [
          {
            name: "Users",
            path: "/users",
            icon: <ProfileIcon />,
            layout,
            id: "menu-users",
            routes: filterArray([
              {
                name: "Applicants / Sellers / Solicitors",
                path: "/users/applicants-sellers-solicitors",
                layout,
                exact: true,
                id: "menu-applicants-sellers-solicitors",
                component: lazy(() => import("Pages/Users/AdminUsers")),
              },
              {
                name:
                  userAccess === "uth_admin"
                    ? "Agencies / Employees"
                    : "Agencies / Branches / Employees",
                path: "/users/agencies-branches-employees",
                layout,
                exact: true,
                id: "menu-agencies-branches-employees",
                component: lazy(() => import("Pages/Users/AdminUsers")),
              },
              purgeRoutes(["admin"]) && {
                name: "Admins / Roles list",
                path: "/users/admins-roles",
                layout,
                exact: true,
                id: "menu-admins-roleslist",
                component: lazy(() => import("Pages/Users/AdminUsers")),
              },
              {
                name: "Update request",
                path: "/users/update-request",
                layout,
                exact: true,
                id: "menu-profile-update-requests",
                component: lazy(() =>
                  import("Pages/Users/Pages/UpdateRequest"),
                ),
              },
              {
                name: "AML Check",
                path: "/users/aml-check",
                layout,
                exact: true,
                id: "menu-profile-aml-check",
                component: lazy(() => import("Pages/Users/Pages/AMLCheck")),
              },
            ]),
          },
          {
            name: "Setting",
            path: "/setting",
            icon: <SettingIcon />,
            layout,
            id: "menu-users",
            routes: filterArray([
              {
                name: "Advertisement",
                path: "/setting/adverts",
                component: lazy(() => import("Pages/AdvertsPage")),
                exact: true,
                id: "menu-advertisement",
                layout,
              },
              {
                name: "Policy",
                path: "/setting/privacy-policy",
                component: lazy(() => import("Pages/PrivacyPolicy")),
                exact: true,
                id: "menu-privacy",
                layout,
              },
            ]),
          },
          {
            path: "/messenger/view",
            name: "Messenger",
            icon: "",
            component: lazy(() => import("Pages/Messenger")),
            exact: true,
            layout,
            isInMenu: false,
          },
          {
            path: "/messenger/send",
            name: "Messenger",
            icon: "",
            component: lazy(() => import("Pages/Messenger")),
            exact: true,
            layout,
            isInMenu: false,
          },
        ],
        {
          name: "Privacy Policy",
          path: "/privacy-policy/edit/:id",
          icon: "",
          component: lazy(() => import("Pages/PrivacyPolicy/Edit")),
          exact: true,
          id: "menu-privacy-edit",
          layout,
          isInMenu: false,
        },
        {
          name: "Privacy Policy",
          path: "/privacy-policy/view/:id",
          icon: "",
          component: lazy(() => import("Pages/PrivacyPolicy/View")),
          exact: true,
          id: "menu-privacy-view",
          layout,
          isInMenu: false,
        },
        {
          path: "/adverts/add",
          name: "Advertisement",
          icon: "",
          component: lazy(() => import("Pages/AdvertsPage/AdvertsPageAddEdit")),
          exact: true,
          layout,
          isInMenu: false,
        },
        {
          path: "/adverts/edit/:id",
          name: "Advertisement",
          icon: "",
          component: lazy(() => import("Pages/AdvertsPage/AdvertsPageAddEdit")),
          exact: true,
          layout,
          isInMenu: false,
        },
        {
          path: "/profile",
          name: "Profile",
          icon: <ProfileIcon />,
          component: lazy(() => import("Pages/ProfilePage")),
          exact: true,
          layout,
          isInMenu: false,
        },
        {
          path: "/notifications",
          name: "Notifications",
          component: lazy(() => import("Pages/applicant/notification")),
          exact: true,
          layout,
          isInMenu: false,
        },
        purgeRoutes(["owner"]) && {
          path: "/profile",
          name: "Profile",
          icon: <ProfileIcon />,
          component: lazy(() => import("Pages/ProfilePage/OwnerProfile")),
          exact: true,
          layout,
          isInMenu: false,
        },
        purgeRoutes(["agent"]) && {
          path: "/messenger",
          name: "Messenger",
          icon: "",
          component: lazy(() => import("Pages/Messenger")),
          exact: true,
          layout,
          isInMenu: false,
        },
      ]),
    purgeRoutes(["buyer"]) && [
      {
        path: "/dashboard",
        name: "Dashboard",
        icon: <DashboardIcon />,
        component: DashBoardPage,
        exact: true,
        layout,
      },
      {
        path: "/watchlist",
        name: "Watch List",
        icon: <EyeIcon />,
        component: WatchListPage,
        exact: true,
        layout,
      },
      {
        path: "/alerts",
        name: "My alerts",
        icon: <AlertIcon />,
        component: AlertPage,
        exact: true,
        layout,
      },
      {
        path: "/Offers",
        name: "Offers",
        icon: <OffersIcon />,
        component: AuctionOffersPage,
        exact: true,
        layout,
      },
      {
        path: "/bids",
        name: "My Bids",
        icon: <HomeIcon />,
        component: MyBidsPage,
        exact: true,
        layout,
      },
      {
        path: "/invoices/view-invoice/:invoiceID",
        name: "View Invoice",
        icon: "",
        component: lazy(() => import("Pages/Invoices/ViewInvoice")),
        exact: true,
        layout,
        isInMenu: false,
      },
      {
        path: "/invoices/view-payable-invoice",
        name: "View Payable Invoice",
        icon: "",
        component: lazy(() => import("Pages/Invoices/ViewNewInvoice")),
        exact: true,
        layout,
        isInMenu: false,
      },
      {
        path: "/invoices/payable",
        name: "Invoices",
        icon: <InvoicesIcon />,
        component: lazy(() => import("Pages/Invoices")),
        exact: true,
        layout,
      },
      {
        path: "/profile",
        name: "Profile",
        icon: <ProfileIcon />,
        component: ProfilePageBuyerSeller,
        exact: true,
        layout,
      },
      {
        name: "AML Docs",
        path: "/docs",
        icon: <NoteIcon />,
        component: DocsPage,
        exact: true,
        layout,
      },
      {
        path: "/AML",
        name: "AML Check",
        icon: "",
        component: AMLChecksPage,
        exact: true,
        layout,
        isInMenu: false,
      },
    ],
    purgeRoutes(["seller"]) && [
      {
        name: "Add property",
        path: "/properties/add-property",
        icon: "",
        component: lazy(() => import("Pages/Properties/AddProperty")),
        exact: true,
        layout,
        isInMenu: false,
      },
      {
        name: "Edit property",
        path: "/properties/edit-property/:propertyId",
        icon: "",
        component: lazy(() => import("Pages/Properties/EditProperty")),
        exact: true,
        layout,
        isInMenu: false,
      },
      {
        name: "View property",
        path: "/properties/view-property/:propertyId",
        icon: "",
        component: lazy(() => import("Pages/Properties/ViewProperty")),
        exact: true,
        layout,
        isInMenu: false,
      },
      {
        name: "Completion-step",
        path: "/properties/completion-step/:propertyId",
        icon: "",
        component: lazy(() => import("Pages/Properties/CompletionStep")),
        exact: true,
        layout,
        isInMenu: false,
      },
      {
        name: "Properties",
        path: "/properties",
        icon: <HomeIcon />,
        layout,
        routes: [
          {
            name: "live",
            path: "/properties/live",
            layout,
            exact: true,
            component: lazy(() => import("Pages/Properties")),
          },
          {
            name: "sold",
            path: "/properties/sold",
            layout,
            exact: true,
            component: lazy(() => import("Pages/Properties")),
          },
          {
            name: "unsold",
            path: "/properties/unsold",
            layout,
            exact: true,
            component: lazy(() => import("Pages/Properties")),
          },
          {
            name: "complete",
            path: "/properties/complete",
            layout,
            exact: true,
            component: lazy(() => import("Pages/Properties")),
          },
          {
            name: "draft",
            path: "/properties/draft",
            layout,
            exact: true,
            component: lazy(() => import("Pages/Properties")),
          },
          {
            name: "unpublished",
            path: "/properties/unpublished",
            layout,
            exact: true,
            component: lazy(() => import("Pages/Properties")),
          },
          (purgeRoutes(["uth_admin"]) ||
            (purgeRoutes(["agent", "team_member"]) &&
              agencyMembershipType === "uth")) && {
            name: "pending",
            path: "/properties/pending",
            layout,
            exact: true,
            component: lazy(() => import("Pages/Properties")),
          },
        ],
      },
      {
        path: "/Offers",
        name: "Offers",
        icon: <OffersIcon />,
        component: AuctionOffersPage,
        exact: true,
        layout,
      },
      {
        path: "/invoices/view-invoice/:invoiceID",
        name: "View Invoice",
        icon: "",
        component: lazy(() => import("Pages/Invoices/ViewInvoice")),
        exact: true,
        layout,
        isInMenu: false,
      },
      {
        path: "/invoices/view-payable-invoice",
        name: "View Payable Invoice",
        icon: "",
        component: lazy(() => import("Pages/Invoices/ViewNewInvoice")),
        exact: true,
        layout,
        isInMenu: false,
      },
      {
        path: "/invoices/payable",
        name: "Invoices",
        icon: <InvoicesIcon />,
        component: lazy(() => import("Pages/Invoices")),
        exact: true,
        layout,
      },
      {
        path: "/profile",
        name: "Profile",
        icon: <ProfileIcon />,
        component: ProfilePageBuyerSeller,
        exact: true,
        layout,
      },
    ],
  ]);
}

export default allUsersRoutes;
