import React, { useState, useRef, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { AutoComplete, Input } from "antd";
import { inputsStyles } from "./styles/inputs.style";
import clsx from "clsx";
import debounce from "lodash.debounce";
import Spin from "Components/Spinner/Spin";

// Services
import { searchWebsite } from "Services";

// Icons
import { ReactComponent as SearchIcon } from "Assets/img/icons/icon_search_input.svg";

const useStyles = makeStyles(inputsStyles);

export default function AutoCompleteInput({
  placeholder,
  className,
  defaultValue,
  form,
  name,
  setSeller,
  disabled,
  setIsWebsiteExist,
  ...restProps
}) {
  const classes = useStyles();

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState(form?.getFieldValue(name));

  const fetchRef = useRef(0);

  // api call
  async function apiCall(value) {
    return searchWebsite({ website: value }).then(data => {
      data?.website ? setIsWebsiteExist(true) : setIsWebsiteExist(false);
    });
  }

  const handleSearch = useMemo(() => {
    const loadOptions = value => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setLoading(true);

      apiCall(value)
        .then(newOptions => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            setValue(value);
            return;
          }

          setOptions(newOptions);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
        });
    };

    return debounce(loadOptions, 800);
  }, []);

  const handleKeyPress = ev => {
    // console.log("handleKeyPress", ev);
  };

  const onSelect = value => {
    form.setFieldsValue({
      [name]: value,
    });
  };

  const handleChange = value => {
    setValue(value);
  };
  return (
    <AutoComplete
      defaultValue={defaultValue}
      options={options}
      onSelect={onSelect}
      onSearch={handleSearch}
      value={value || form?.getFieldValue(name)}
      onChange={handleChange}
      disabled={disabled}
    >
      <Input
        className={clsx(classes.input, classes.inputGrayIcon, className)}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onKeyPress={handleKeyPress}
        value={value || form?.getFieldValue(name)}
        onChange={handleChange}
        suffix={loading ? <Spin spinning size={15} /> : <SearchIcon />}
        {...restProps}
      />
    </AutoComplete>
  );
}
