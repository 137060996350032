import axios from "./Api";

export const getBuyerAlertsApi = params => axios.get("/alerts", { params });

export const postBuyerAlertsApi = body => axios.post("/alerts", body);

export const deleteBuyerAlertsApi = id => axios.delete(`/alerts/${id}`);

// offers
export const getBuyerOffersApi = params => axios.get("/offers", { params });

export const postOfferWithdrawnApi = (id, body) =>
  axios.post(`/offers/withdraw/${id}`, body);

// Trader/Watchlist
export const getWatchListsApi = params => axios.get("/watchlists", { params });

export const postWatchListStatusToggle = id =>
  axios.post(`/watchlists/status-toggle/${id}`);

// Buyer/Bid
export const getBuyerBidApi = params => axios.get("/bids", { params });
