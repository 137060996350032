import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { Input } from "antd";

// internal module
import Modal from "Components/Modal";
import { ScrollingPagination } from "Components/ScrollerBox";

// assets
import { filterBranchModalStyle } from "../modal.styles";
import { ReactComponent as YellowFilterIcon } from "Assets/img/icons/icon_filter-yellow.svg";
import { ReactComponent as UserIcon } from "Assets/img/icons/icon_User.svg";
import { ReactComponent as IconSearch } from "Assets/img/icons/icon_Search.svg";
import { ReactComponent as BranchIcon } from "Assets/img/icons/icon_Branch.svg";
import { ReactComponent as CheckIcon } from "Assets/img/icons/icon_uncheck.svg";
import { ReactComponent as UncheckIcon } from "Assets/img/icons/icon_check-yellow.svg";

// Services
import { getOwnerBranchAndTeamMemersList } from "Services";

const useStyles = makeStyles(filterBranchModalStyle);

export default function FilterBranchModalModal({
  setSelectedIds,
  placeholder,
}) {
  const classes = useStyles();
  // handle modal visibility
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  // handle branches
  const [search, setSearch] = useState("");
  const [branchAllCheck, setBranchAllCheck] = useState("checked"); // indetermine not-checked
  const [employeeAllCheck, setEmployeeAllCheck] = useState("checked"); // indetermine not-checked

  const [branchsList, setBranchsList] = useState([]);
  const [employeesList, setEmployeesList] = useState([]);

  // update employess list when branches list change
  useEffect(() => {
    let employees = [];

    if (branchsList.length) {
      branchsList
        .filter(item => item.selected)
        .forEach(item => {
          employees = [...employees, ...item.employees];
        });
    }

    setEmployeesList(
      employees.map(item => ({
        id: item.id,
        image: null,
        first_name: item.first_name,
        last_name: item.last_name,
        selected: true,
      })) || [],
    );
  }, [branchsList]);

  const [searchPaginationEnable, setSearchPaginationEnable] = useState(false);
  useEffect(() => {
    if (!search) {
      setSearchPaginationEnable(false);
    }
  }, [search]);
  const apiHandler = ({
    page,
    setPage, // use for search input to reset page to 0
    search,
    permission,
    setPermission,
    loading,

    setLoading,
  }) => {
    setLoading(true);
    if (search && !searchPaginationEnable) {
      setPage(0);
    }
    getOwnerBranchAndTeamMemersList({ offset: page, search }).then(data => {
      const newUsersList = data.items.map(item => ({
        id: item.id,
        image: null,
        first_name: item.name,
        last_name: "",
        selected: true,
        employees: item.employees,
      }));

      if (search && page === 0) {
        setBranchsList([...newUsersList]);
      } else {
        setBranchsList([...branchsList, ...newUsersList]);
      }

      if (search && data.items?.length === data.page_count) {
        setSearchPaginationEnable(true);
      }

      // check if we get all pages
      if ((page + 1) * data.page_count < data.total_count) {
        setPermission(true);
      } else {
        setPermission(false);
      }

      // setPermission(true);
      setLoading(false);
    });
  };

  const onChangeOption = (id, type) => {
    if (type === "branch") {
      const temp = branchsList.map(user =>
        user.id === id ? { ...user, selected: !user.selected } : user,
      );
      setBranchsList(temp);
      allCheckFunction(temp, "branch");
    }

    if (type === "employee") {
      const temp = employeesList.map(user =>
        user.id === id ? { ...user, selected: !user.selected } : user,
      );
      setEmployeesList(temp);
      allCheckFunction(temp, "employee");
    }
  };

  const handleSelectAll = type => {
    if (type === "branch") {
      let temp;
      if (branchAllCheck === "checked") {
        temp = branchsList.map(user => ({ ...user, selected: false }));
        setBranchAllCheck("not-checked");
      } else {
        temp = branchsList.map(user => ({ ...user, selected: true }));
        setBranchAllCheck("checked");
      }

      setBranchsList(temp);
    }

    if (type === "employee") {
      let temp;
      if (employeeAllCheck === "checked") {
        temp = employeesList.map(user => ({ ...user, selected: false }));
        setEmployeeAllCheck("not-checked");
      } else {
        temp = employeesList.map(user => ({ ...user, selected: true }));
        setEmployeeAllCheck("checked");
      }

      setEmployeesList(temp);
    }
  };

  // detect and set value of allCheck
  const allCheckFunction = (items, type) => {
    if (type === "branch") {
      const selected = items.filter(user => user.selected);
      if (selected.length === branchsList.length) {
        setBranchAllCheck("checked");
      } else {
        setBranchAllCheck("not-checked");
      }
    }

    if (type === "employee") {
      const selected = items.filter(user => user.selected);
      if (selected.length === branchsList.length) {
        setEmployeeAllCheck("checked");
      } else {
        setEmployeeAllCheck("not-checked");
      }
    }
  };

  // handle steps
  const [step, setStep] = useState(1);

  const goToStep2 = () => {
    // in search mode
    if (search) {
      setSelectedIds(
        branchsList.filter(item => item.selected).map(item => item.id),
      );
      setShowModal(false);
    } else {
      setStep(2);
    }
  };

  const backToStep1 = () => {
    setStep(1);
  };

  const handleClickDone = () => {
    setSelectedIds(
      employeesList.filter(item => item.selected).map(item => item.id),
    );
    setShowModal(false);
  };

  return (
    <>
      <Modal
        title="Filter by employee &amp; branch"
        isModalVisible={showModal}
        wrapClassName={classes.modalWrapper}
        handleCancel={handleHideModal}
        width={400}
      >
        <div className={classes.wrapper}>
          <Input
            placeholder="Search employee"
            className={classes.search}
            onChange={e => setSearch(e.target.value)}
            suffix={
              <i>
                <IconSearch />
              </i>
            }
          />

          <div
            style={{ display: step === 1 || search ? "block" : "none" }}
            className={classes.innerContent}
          >
            <div className="inner-head">
              <BranchIcon />
              <span>Select {search ? "employee" : "branch"} </span>
            </div>
            <div
              className={classes.selectAll}
              onClick={() => handleSelectAll("branch")}
            >
              <i>
                {branchAllCheck === "checked" ? <UncheckIcon /> : <CheckIcon />}
              </i>
              <span>Select all</span>
            </div>

            <ScrollingPagination
              apiHandler={apiHandler}
              className={classes.scroll}
              distance={450}
              search={search}
            >
              <div>
                {branchsList.map(user => (
                  <div
                    key={user.id}
                    className={classes.option}
                    onClick={() => onChangeOption(user.id, "branch")}
                  >
                    <i>{user.selected ? <UncheckIcon /> : <CheckIcon />}</i>
                    <span>
                      {user.first_name} {user.last_name}
                    </span>
                  </div>
                ))}
              </div>
            </ScrollingPagination>
            <div className={classes.innerFooter}>
              <button
                className={clsx("flex-center rad-2", classes.yellowBtn)}
                onClick={goToStep2}
              >
                {search ? "Done" : "Next"}
              </button>
            </div>
          </div>
          {/* STEP 2 */}
          <div
            style={{ display: step === 2 && !search ? "block" : "none" }}
            className={classes.innerContent}
          >
            <div className="inner-head">
              <UserIcon />
              <span>Select employee</span>
            </div>
            <div
              className={classes.selectAll}
              onClick={() => handleSelectAll("employee")}
            >
              <i>
                {employeeAllCheck === "checked" ? (
                  <UncheckIcon />
                ) : (
                  <CheckIcon />
                )}
              </i>
              <span>Select all</span>
            </div>

            <div>
              <div className={clsx(classes.scroll, "custom-scrollbar")}>
                {employeesList.map(user => (
                  <div
                    key={user.id}
                    className={classes.option}
                    onClick={() => onChangeOption(user.id, "employee")}
                  >
                    <i>{user.selected ? <UncheckIcon /> : <CheckIcon />}</i>
                    <span>
                      {user.first_name} {user.last_name}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className={classes.innerFooter}>
              <button
                onClick={backToStep1}
                className={clsx("flex-center rad-2", classes.blackBtn)}
              >
                Back
              </button>
              <button
                onClick={handleClickDone}
                className={clsx("flex-center rad-2", classes.yellowBtn)}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <button onClick={handleShowModal} className={classes.filterBtn}>
        <span>Select branch &amp; employee</span>
        <i className="d-flex">
          <YellowFilterIcon />
        </i>
      </button>
    </>
  );
}
