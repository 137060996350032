import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import propertiesActionType from "Store/_types/properties.type";

const initialPropertiesState = {
// step 1
auction_date_to_be_confirmed: null,
auction_ends_at: null,
auction_starts_at: null,
auto_decline_prior_offer: null,
do_not_show_auction_time: null,
guide_price: null,
occupied_status: null,
reserve_price: null,
starting_price: null,
tenure: null,
uth_recommendation: null,
video_items: null,

  //step 2
  area: null,
  city: null,
  detailed_property_type: null,
  facilities: null,
  first_line_of_address: null,
  latitude: null,
  longitude: null,
  num_bathrooms: null,
  num_bedrooms: null,
  num_doors: null,
  num_reception_rooms: null,
  postcode: null,
  road_name: null,
  second_line_of_address: null,

  //step 3
  applicant_fee_percent: null,
applicant_fee_pound: null,
can_seller_review_offers: null,
completion_timescale: null,
confirm_completion_time: null,
confirm_deposit_amount: null,
deposit_select: null,
seller_city: null,
seller_country: null,
seller_email: null,
seller_fee_percent: null,
seller_fee_pound: null,
seller_first_name: null,
seller_house_number: null,
seller_last_name: null,
seller_mobile: null,
seller_phone: null,
seller_post_code: null,
seller_street_name: null,

//step 4

applicant_fee_percent: null,
applicant_fee_pound: null,
can_seller_review_offers: null,
completion_timescale: null,
confirm_completion_time: null,
confirm_deposit_amount: null,
deposit_select: null,
seller_city: null,
seller_country: null,
seller_email: null,
seller_fee_percent: null,
seller_fee_pound: null,
seller_first_name: null,
seller_house_number: null,
seller_last_name: null,
seller_mobile: null,
seller_phone: null,
seller_post_code: null,
seller_street_name: null,

//step 5

applicant_fee_percent: null,
applicant_fee_pound: null,
can_seller_review_offers: null,
completion_timescale: null,
confirm_completion_time: null,
confirm_deposit_amount: null,
deposit_select: null,
seller_city: null,
seller_country: null,
seller_email: null,
seller_fee_percent: null,
seller_fee_pound: null,
seller_first_name: null,
seller_house_number: null,
seller_last_name: null,
seller_mobile: null,
seller_phone: null,
seller_post_code: null,
seller_street_name: null,
};

//persist may cause problems, but probably better to keep. please check if it effects didferent instances
export const propertiesReducer = persistReducer(
  { storage, key: "propertiesData" },
  (state = initialPropertiesState, { type, payload }) => {
    switch (type) {
      case propertiesActionType.UPDATE_PROPERTIES:
        return {
          ...state,
          ...payload,
        };
      case propertiesActionType.EMPTY_PROPERTIES:
        return {
          ...initialPropertiesState,
        };
      default:
        return state;
    }
  },
);
