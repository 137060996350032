import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  button: props => ({
    padding: "7px 24px",
    borderRadius: "5px",
    fontWeight: 500,
    fontSize: "14px",
    textAlign: "center",
    // textTransform: "init",
    "&:focus": {
      outline: "none",
    },

    ...(props.color === "primary" && {
      background: theme.palette.primary.main,
      "&:hover": {
        background: theme.palette.primary.light,
      },
    }),

    ...(props.color === "secondary" && {
      background: theme.palette.secondary.main,
      "&:hover": {
        background: theme.palette.secondary.light,
      },
    }),

    ...(props.color === "primary_bordered" && {
      background: "#fff",
      border: `1px solid ${theme.palette.primary.main}`,
      "&:hover": {
        background: "#f5f6f8",
        border: `1px solid ${theme.palette.primary.light}`,
      },
    }),

    ...(props.color === "secondary_bordered" && {
      background: "#fff",
      border: `1px solid ${theme.palette.secondary.main}`,
      "&:hover": {
        background: "#f5f6f8",
        border: `1px solid ${theme.palette.secondary.light}`,
      },
    }),

    ...(props.color === "primary_transparent" && {
      background: "transparent",
      border: `1px solid ${theme.palette.primary.main}`,
      "&:hover": {
        background: "transparent",
        border: `1px solid ${theme.palette.primary.main}`,
      },
    }),

    ...(props.color === "secondary_transparent" && {
      background: "transparent",
      border: `1px solid ${theme.palette.secondary.main}`,
      "&:hover": {
        background: "transparent",
        border: `1px solid ${theme.palette.secondary.main}`,
      },
    }),

    ...(props.color === "simple" && {
      background: "transparent",
      border: "1px solid transparent",
      "&:hover": {
        background: "transparent",
        border: "1px solid transparent",
      },
    }),
  }),
}));

export default function Button({ color, className, as, ...props }) {
  const classes = useStyles({ color });

  const ButtonComponent = as || ButtonBase;

  return (
    <ButtonComponent className={clsx(classes.button, className)} {...props} />
  );
}

Button.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "primary_bordered",
    "secondary_bordered",
    "primary_transparent",
    "secondary_transparent",
    "simple",
  ]).isRequired,
  className: PropTypes.string,
  as: PropTypes.elementType,
};
