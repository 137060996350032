import React, { Children, useCallback, useMemo } from "react";
import { ReactComponent as IconNote } from "Assets/img/icons/icon_note-invoice.svg";
import { useDropzone } from "react-dropzone";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  uploader_root: {
    border: "1px dashed #C5C5C5",
    borderRadius: 4.5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 18,
    paddingBottom: 18,
    width: "100%",
    cursor: "pointer",
    "& p": {
      ...theme.fonts.s16w600,
      color: "#9CACBC",
      margin: 0,
    },
  },
  fileWrapper: {
    "& i": {
      display: "flex",
      alignItems: "center",
    },
    "& .infoFile": {
      background: "rgba(0, 0, 0, 0.2)",
      borderRadius: 2,
      padding: "8px 16px",
      marginBottom: 8,
    },
    "& .deletedIcon": {
      color: "#FF5939",
      ...theme.fonts.s14w500,
      cursor: "pointer",
    },
    "& p.fileName": {
      ...theme.fonts.s12w500,
      margin: 0,
      color: "white",
    },
  },
}));

export default function Uploader({
  setFiles,
  files,
  width,
  height,
  dimensionCheck,
  accept,
  multiple,
  maxFiles,
}) {
  const classes = useStyles();

  const activeStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const onDrop = useCallback(async acceptedFiles => {
    const file = acceptedFiles.map(file => file);
    setFiles(prev => [...prev, ...file]);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
    fileRejections,
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    multiple: !!multiple,
    maxFiles,
    accept,
    ...(!!dimensionCheck
      ? {
          getFilesFromEvent: async event => {
            const files = event.target.files;
            const promises = [];
            for (let index = 0; index < files.length; index++) {
              const file = files[index];
              const promise = new Promise((resolve, reject) => {
                const image = new Image();
                image.onload = function () {
                  file.width = image.width;
                  file.height = image.height;
                  resolve(file);
                };
                image.src = URL.createObjectURL(file);
              });
              promises.push(promise);
            }
            return await Promise.all(promises);
          },
          validator: file => {
            // You can access width/height properties
            if (file.width > width && file.height > height) {
              return {
                code: "large-size",
                message: `Image width & height must be (W ${
                  width || ""
                } px - H ${height || ""} px)`,
              };
            }
            return null;
          },
        }
      : {}),
  });

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  const handleDelete = file => {
    const temp = files.filter(item => item.name !== file.name);
    setFiles(temp);
  };

  const handle_showError = () => {
    if (fileRejections.length > 0) {
      const fileRejectionsArray = fileRejections.flatMap(item => item.errors);
      let fileRejectionsArrayCode = fileRejectionsArray.reduce((acc, item) => {
        let msg = item.code;
        if (acc.includes(msg)) return acc;
        acc.push(msg);
        return acc;
      }, []);
      return (
        <>
          {Children.toArray(
            fileRejectionsArrayCode.map(item => {
              if (item === "file-invalid-type") {
                return <span>File type must be image/*,.pdf, .doc</span>;
              }
              if (item === "too-many-files") {
                return <span>4 files are the maximum number of files</span>;
              }
              if (item === "large-size") {
                return (
                  <span>
                    Image width & height must be (W {width || ""}px - H{" "}
                    {height || ""}px)
                  </span>
                );
              }
            }),
          )}
        </>
      );
    }
  };

  return (
    <>
      {files.length > 0 ? (
        <div className={classes.fileWrapper}>
          {Children.toArray(
            files.map(file => (
              <div className="infoFile d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center ">
                  <i className="me-2">
                    <IconNote />
                  </i>
                  <p className="fileName">{file.name}</p>
                </div>
                <div className={"deletedIcon"}>
                  <span
                    onClick={() => handleDelete(file)}
                    className="d-flex align-items-center"
                  >
                    Remove
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.2477 3.87844V4.428H12.8964C13.1999 4.428 13.446 4.67406 13.446 4.97757C13.446 5.28109 13.1999 5.52714 12.8964 5.52714H12.3468V11.5724C12.3468 12.483 11.6087 13.2211 10.6981 13.2211H6.30156C5.391 13.2211 4.65285 12.483 4.65285 11.5724V5.52714H4.10328C3.79976 5.52714 3.55371 5.28109 3.55371 4.97757C3.55371 4.67406 3.79976 4.428 4.10328 4.428H5.75199V3.87844C5.75199 3.2714 6.24409 2.7793 6.85113 2.7793H10.1485C10.7556 2.7793 11.2477 3.2714 11.2477 3.87844ZM6.85113 3.87844H10.1485V4.428H6.85113V3.87844Z"
                        fill="#FF5939"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            )),
          )}
        </div>
      ) : (
        <>
          <div
            {...getRootProps({ style, className: classes.uploader_root })}
            onClick={open}
          >
            <input {...getInputProps()} />
            <p>Drag and Drop or select file</p>
          </div>
          {fileRejections && (
            <p className="errorMsg d-flex flex-column">{handle_showError()}</p>
          )}
        </>
      )}
    </>
  );
}

/*

<div className={classes.fileWrapper}>
  {Children.toArray(
      files.map(file => (
          <div className="infoFile d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center ">
              <i className="me-2">
                <IconNote />
              </i>
              <p className="fileName">{file.name}</p>
            </div>
            <div className={"deletedIcon"}>
                  <span
                      onClick={() => handleDelete(file)}
                      className="d-flex align-items-center"
                  >
                    Remove
                    <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.2477 3.87844V4.428H12.8964C13.1999 4.428 13.446 4.67406 13.446 4.97757C13.446 5.28109 13.1999 5.52714 12.8964 5.52714H12.3468V11.5724C12.3468 12.483 11.6087 13.2211 10.6981 13.2211H6.30156C5.391 13.2211 4.65285 12.483 4.65285 11.5724V5.52714H4.10328C3.79976 5.52714 3.55371 5.28109 3.55371 4.97757C3.55371 4.67406 3.79976 4.428 4.10328 4.428H5.75199V3.87844C5.75199 3.2714 6.24409 2.7793 6.85113 2.7793H10.1485C10.7556 2.7793 11.2477 3.2714 11.2477 3.87844ZM6.85113 3.87844H10.1485V4.428H6.85113V3.87844Z"
                          fill="#FF5939"
                      />
                    </svg>
                  </span>
            </div>
          </div>
      )),
  )}
</div>*/
