import { combineReducers } from "redux";
import { authReducer } from "Store/_reducers/auth.reducer";
import { invoiceReducer } from "Store/_reducers/invoice.reducer"
import { propertiesReducer } from "Store/_reducers/properties.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  invoice: invoiceReducer,
  properties: propertiesReducer
});

export default rootReducer;
