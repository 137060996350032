import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authActionType from "Store/_types/auth.type";

const initialAuthState = {
  user: null,
  authToken: null,
  error: null,
  loading: false,
  notificationUpdate: false,
  prevUser: null, // for login as
};

export const authReducer = persistReducer(
  { storage, key: "v_auth", whitelist: ["user", "authToken", "prevUser"] },
  (state = initialAuthState, { type, payload }) => {
    switch (type) {
      //Login
      case authActionType.LOGIN_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case authActionType.LOGIN_SUCCESS:
        return {
          ...state,
          user: payload.user,
          authToken: payload.api_token,
          loading: false,
        };
      case authActionType.LOGIN_FAILURE:
        return {
          ...state,
          loading: false,
          error: payload.error,
        };

      //Logout
      case authActionType.LOGOUT_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case authActionType.LOGOUT_SUCCESS:
        return {
          ...initialAuthState,
        };

      //Register
      case authActionType.REGISTER_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case authActionType.REGISTER_SUCCESS:
        return {
          ...state,
          user: payload.user,
          authToken: payload.api_token,
          loading: false,
        };
      case authActionType.REGISTER_FAILURE:
        return {
          ...state,
          loading: false,
          error: payload.error,
        };

      //Update Agent
      case authActionType.AGENT_PROFILE_UPDATE_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case authActionType.AGENT_PROFILE_UPDATE_SUCCESS:
        return {
          ...state,
          user: { ...state.user, ...payload },
          loading: false,
        };
      case authActionType.AGENT_PROFILE_UPDATE_FAILURE:
        return {
          ...state,
          loading: false,
          error: payload,
        };

      //Update Agency
      case authActionType.AGENCY_PROFILE_UPDATE_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case authActionType.AGENCY_PROFILE_UPDATE_SUCCESS:
        return {
          ...state,
          user: { ...state.user, agency: { ...state.user.agency, ...payload } },
          loading: false,
        };
      case authActionType.AGENCY_PROFILE_UPDATE_FAILURE:
        return {
          ...state,
          loading: false,
          error: payload,
        };

      // Change role (trader => buyer/seller)
      case authActionType.UPDATE_ROLE:
        return {
          ...state,
          user: {
            ...state.user,
            type: payload,
          },
        };
      // Change some details of user profile
      case authActionType.UPDATE_DETAILS:
        return {
          ...state,
          user: {
            ...state.user,
            ...payload,
          },
        };
      // Change some details of agency
      case authActionType.UPDATE_AGENCY_DETAILS:
        return {
          ...state,
          user: {
            ...state.user,
            agency: {
              ...state.user.agency,
              ...payload,
            },
          },
        };
      // update Terms and Condition
      case authActionType.UPDATE_TERMS:
        return {
          ...state,
          user: {
            ...state.user,
            terms_and_conditions_status: payload,
          },
        };
      // update buyer aml status for aml page
      case authActionType.UPDATE_AML_STATUS:
        return {
          ...state,
          user: {
            ...state.user,
            aml_status: payload,
          },
        };

      // update prev token
      case authActionType.UPDATE_PREV_USER:
        return {
          ...state,
          prevUser: payload,
        };

      // update navbar notification___________________________
      case "UPDATE_NAVBAR_NOTIFICATION":
        return {
          ...state,
          notificationUpdate: !state.notificationUpdate,
        };

      //Delete one time token
      case
      authActionType.ONE_TIME_TOKEN_DELETE
      :
        return {
          ...state,
          user: {
            ...state.user,
            password_onetime_token: null,
          },
        };

      default:
        return state;
    }
  },
);
