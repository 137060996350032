import { Children, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { FadeUpBox } from "Components/Animation";
import AvatarUpload from "./AvatarUploader";
import ProfileForm from "./ProfileForm";
import Spin from "Components/Spinner/Spin";
import { getProfile } from "Services";
import { profilePageStyle } from "./ProfileForm/profilePage.style";

const useStyles = makeStyles(profilePageStyle);

export default function ProfilePageBuyerSeller() {
  const classes = useStyles();
  const profileDetails = useSelector(state => state?.auth?.user);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    {
      key: "trader",
      title: "Personal details",
      component: (key, profile, setReload, setProfile) => (
        <ProfileForm
          type={key}
          profile={profile}
          setReload={setReload}
          setProfile={setProfile}
        />
      ),
    },
    {
      key: "solicitor_detail",
      title: "Solicitors details",
      component: (key, profile, setReload, setProfile) => (
        <ProfileForm
          type={key}
          profile={profile}
          setReload={setReload}
          setProfile={setProfile}
        />
      ),
    },
  ];

  const handle_getProfile = useCallback(async () => {
    await getProfile().then(res => setProfile(res.data));
    setLoading(false);
  }, []);

  useEffect(() => {
    handle_getProfile().catch(err => console.log(err));
  }, [reload]);

  return (
    <div className={classes.root}>
      <div className={"container"}>
        {/*<Breadcrumb icon={<OfferPageIcon />} title={["offers"]} />*/}
        <div className={classes.box}>
          {loading ? (
            <Spin spinning={loading} />
          ) : (
            <>
              <div className="imgDiv">
                <FadeUpBox>
                  <AvatarUpload defaultImg={profileDetails.image_url} />
                </FadeUpBox>
              </div>
              <p className="name">
                {profileDetails.first_name} {profileDetails.last_name}
              </p>
              <div className={classes.formWrapper}>
                <div className={classes.tabMenu}>
                  <div className="tabLinks">
                    <div className="tabs">
                      {Children.toArray(
                        tabs.map((item, index) => (
                          <div
                            onClick={() => setActiveTab(index)}
                            className={`tabItem ${
                              activeTab === index ? "active" : ""
                            }`}
                          >
                            {item.title}
                          </div>
                        )),
                      )}
                    </div>
                  </div>
                </div>
                {tabs[activeTab].component(
                  tabs[activeTab].key,
                  profile,
                  setReload,
                  setProfile,
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
