import { useState, useEffect } from "react";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";

export default function useWindowResize(milliseconds, func) {
  const [state, setState] = useState(false);

  useEffect(() => {
    const thisFunc = () => {
      setState(!state);
      func?.();
    };

    window.addEventListener("resize", debounce(thisFunc, milliseconds));

    return () => {
      window.removeEventListener("resize", debounce(thisFunc, milliseconds));
    };
  }, []);

  return state;
}

useWindowResize.defaultProps = {
  milliseconds: 100,
};

useWindowResize.propTypes = {
  milliseconds: PropTypes.number,
  func: PropTypes.func,
};
