// object-style theme
import { createMuiTheme } from "@material-ui/core";

// import * as themes from "./themes";

export const drawerWidth = 260;

// utils
const merge = (...rest) => Object.assign({}, ...rest);

// constant
const YELLOW_MAIN = "#F9B914";
const SECONDARY_YELLOW = "#F7C91B";
const BLACK_MAIN = "#333333";
const GRAY = "#BECFE0";
const LIGHT_GRAY = "#F8FAFC";
const DARK_GRAY = "#8492A6";
const DARKEST_GRAY = "#3A3A3A";
const CYAN_BLUE_LIGHT = "#E9F0F6";
const CYAN_BLUE_MEDIUM = "#7E9CBA";
const CYAN_BLUE_DARK = "#3C5670";
const LIGHT_BLUE = "#F0F7FD";
const DARK_BLUE = "#151C2E";
const LIGHT_GRAYISH_BLUE = "#F5F6F8";
const CYAN_BLUE_SHADE = "#7E9CBA";
const PINKISH_RED = "#FF5939";
const CYAN_DEEP = "#56C3D3";

export const fonts = {
  s40w900: { fontSize: 40, fontWeight: 900 },
  s48w400: { fontSize: 48, fontWeight: 400 },
  s24w400: { fontSize: 24, fontWeight: 400 },
  s24w600: { fontSize: 24, fontWeight: 600 },
  s18w600: { fontSize: 18, fontWeight: 600 },
  s16w700: { fontSize: 16, fontWeight: 700 },
  s16w600: { fontSize: 16, fontWeight: 600 },
  s16w500: { fontSize: 16, fontWeight: 500 },
  s16w400: { fontSize: 16, fontWeight: 400 },
  s14w700: { fontSize: 14, fontWeight: 700 },
  s14w600: { fontSize: 14, fontWeight: 600 },
  s14w500: { fontSize: 14, fontWeight: 500 },
  s12w700: { fontSize: 12, fontWeight: 700 },
  s12w600: { fontSize: 12, fontWeight: 600 },
  s12w500: { fontSize: 12, fontWeight: 500 },
  s12w300: { fontSize: 12, fontWeight: 300 },
  s10w500: { fontSize: 10, fontWeight: 500 },
};

export const themesGradient = {
  theme1:
    "linear-gradient(90deg, rgba(6,49,80,1) 0%, rgba(36,89,149,1) 32%, rgba(26,84,96,1) 100%)",
  theme2:
    "linear-gradient(90deg, rgba(35,68,44,1) 0%, rgba(36,149,92,1) 32%, rgba(47,87,51,1) 100%)",
  theme3:
    "linear-gradient(90deg, rgba(36,42,37,1) 0%, rgba(0,112,145,1) 32%, rgba(36,42,37,1) 100%)",
  theme4:
    "linear-gradient(90deg, rgba(32,32,32,1) 0%, rgba(97,97,97,1) 32%, rgba(36,42,37,1) 100%)",
  theme5:
    "linear-gradient(90deg, rgba(51,10,103,1) 0%, rgba(155,51,164,1) 32%, rgba(96,26,103,1) 100%)",
};

export const themeStyles = (theme_name, theme_type) => ({
  theme_name,
  theme_type,
  palette: {
    primary: {
      main: YELLOW_MAIN,
      //The light and dark values will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#ffffff1a",
    },
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          WebkitFontSmoothing: "auto",
          fontSize: "62.5%",
        },
        body: {
          padding: 0,
          margin: 0,
          fontFamily:
            '"Open Sans",-apple-system, BlinkMacSystemFont, Roboto, Oxygen Ubuntu, Droid Sans, sans-serif',
          fontSize: "1.4rem",
          msTextSizeAdjust: "100%",
          WebkitTextSizeAdjust: "100%",
          WebkitFontSmoothing: "antialiased",
          textRendering: "optimizeLegibility",
          fontFeatureSettings: "'lnum' 1",
          backgroundColor: LIGHT_GRAY,
          color: theme_type === "light" ? "#333333" : "white",
          "& .ant-form , & .ant-form-item , & .ant-form-item-label>label": {
            color: theme_type === "light" ? "#333" : "#fff",
          },
        },
        a: {
          color: theme_type === "light" ? "#333333" : "white",
          textDecoration: "none",
          "&:hover, &:focus, &:active": {
            textDecoration: "none",
            color: theme_type === "light" ? "#333333" : "white",
          },
        },
        ul: {
          listStyle: "none",
          padding: 0,
          margin: 0,
        },
        button: {
          backgroundColor: "transparent",
          border: "none",
          outline: "none",
          borderRadius: 5,
          textAlign: "center",
          cursor: "pointer",
          color: theme_type === "light" ? "#333333" : "white",
          "&:focus": {
            outline: "none",
          },
        },
      },
    },
  },
  // ##############################
  // // // Variables
  // #############################

  backgrounds: {
    // back: themes[theme_name]?.backgrounds?.back,
    // front:
    //   "linear-gradient(90.73deg, rgba(0, 255, 240, 0.1) 0.34%, rgba(255, 255, 255, 0.1) 105.09%)",

    currentTheme: themesGradient[theme_name],

    // black3 => black with opacity .3
    // white25 => white with opacity .25
    black2: "#00000033",
    black3: "#0000004d",
    black5: "#00000080",
    black7: "#000000b3",
    black8: "#000000cc",
    darkestGray: DARKEST_GRAY,

    white1: "#ffffff1a",
    white25: "#ffffff40",
    white7: "#ffffffb3",
  },

  colors: {
    red: PINKISH_RED,
    orange: "#F98B59",
    lightOrange: "#FFF6F2",
    green: "#02B075",
    lightGreen: "#E8FFF7",
    blue: "#3553FB",
    lightenBlue: "#F3F5FF",

    main: YELLOW_MAIN,
    darkBlue: DARK_BLUE,
    lightGray: LIGHT_GRAY,
    darkGray: DARK_GRAY,
    lightGrayishBlue: LIGHT_GRAYISH_BLUE,
    gray: GRAY,
    cyanBlueDark: CYAN_BLUE_DARK,
    secondaryYellow: SECONDARY_YELLOW,
    black: BLACK_MAIN,
    cyanBlueShade: CYAN_BLUE_SHADE,
    lightBlue: LIGHT_BLUE,
    cyanBlueMedium: CYAN_BLUE_MEDIUM,
    blackMain: BLACK_MAIN,
    cyanBlueLight: CYAN_BLUE_LIGHT,
    pinkishRed: PINKISH_RED,
    deepCyan: CYAN_DEEP,
  },
  fonts,
  // ____________________ shorthand ____________________
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  alignCenter: {
    display: "flex",
    alignItems: "center",
  },
  img100: {
    width: "100%",
    height: "100%",
  },

  // ____________________ utils ____________________
  merge,
  imgDiv: (x, y, br, of) => ({
    width: x,
    height: y,
    borderRadius: br,
    overflow: of ? "hidden" : "unset",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  }),
  avatar: (size, borderRadius, border) => ({
    width: size,
    height: size,
    borderRadius,
    border,
  }),
  media: (number, operator = "max") =>
    `@media (${operator}-width: ${number}px)`,
});

const availableThemesName = ["theme1", "theme2", "theme3", "theme4", "theme5"];
const availableThemesType = ["light", "dark", "glass"];

const theme = (theme_name, theme_type) =>
  createMuiTheme(
    themeStyles(
      availableThemesName.includes(theme_name) ? theme_name : "theme1",

      availableThemesType.includes(theme_type) ? theme_type : "light",
    ),
  );

export default theme;
