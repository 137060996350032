import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => {
  return {
    main_wrapper: {
      minHeight: "100%",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      "& > :last-child": {
        flexGrow: 1,
      },
    },
  };
});

export default function MainWrapper({ children }) {
  const classes = useStyles();
  return <div className={classes.main_wrapper}>{children}</div>;
}
