import { makeStyles } from "@material-ui/core";
import ModalWrapper from "Components/Modal";
import { lighten } from "@material-ui/core/styles";

// styles
const useStyles = makeStyles(theme => ({
  modalContentWrapper: {
    "& .col-12": {
      borderBottom: `1px dashed ${
        theme.theme_type === "light" ? "#333" : "#fff"
      }`,
      padding: 0,
      paddingBottom: 12,
    },
    "& .files": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginLeft: 16,
    },
    "& .title": {
      fontSize: 16,
      fontWeight: 700,
      textTransform: "capitalize",
      margin: 0,
      color: theme.theme_type === "light" ? "#333" : "#FFF",
    },
    "& .dlBtn": {
      ...theme.fonts.s14w500,
      padding: "5px 10px",
      borderRadius: 5,
      textAlign: "center",
      border: "1px solid #BECFE0",
      color: theme.theme_type === "light" ? "#333" : "#fff",
      transition: "all .3s",
      "&:hover": {
        backgroundColor: lighten("#BECFE0", 0.7),
      },
    },
  },
}));

export default function ViewDocsModal({ open, handleCancel }) {
  const classes = useStyles();
  console.log(open);
  return (
    <ModalWrapper
      title="Docs"
      isModalVisible={Object.keys(open).length > 0}
      handleCancel={handleCancel}
      className={classes.modalContentWrapper}
    >
      <div className="row">
        <div className="col-12">
          <div className="files mb-2">
            <p className="title">proof of funds:</p>
            <a
              className="dlBtn"
              href={open.proof_of_funds_url}
              download
              target={"_blank"}
            >
              Download
            </a>
          </div>
          <div className="files">
            <p className="title">Identification:</p>
            <a
              className="dlBtn"
              href={open.identification_url}
              download
              target={"_blank"}
            >
              Download
            </a>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}
