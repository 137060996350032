import { useState, useEffect, useRef, Children } from "react";
import { NavLink } from "react-router-dom";
import { activeRoute } from "Utils/helperFunction";
import { makeStyles } from "@material-ui/core";
import { menuStyle } from "Layouts/NavigationBar/Default/styles/navigationbar.styles";
import clsx from "clsx";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { motion } from "framer-motion";

import { ReactComponent as ArrowDownIcon } from "Assets/img/icons/icon_arrow-down-mini.svg";
import { useSelector } from "react-redux";

const useStyles = makeStyles(menuStyle);

export default function MenuList({ routes }) {
  const classes = useStyles();

  const denied_permissions = [
    ...useSelector(store => [
      ...store?.auth?.user?.denied_permissions,
      store.auth?.user?.type !== "af_admin" &&
        store.auth?.user?.agency?.membership_type !== "af" &&
        "menu-real-time",
    ]),
  ];
  const [selectedMenu, setSelectedMenu] = useState({});

  const closeSubMenu = () => {
    // close sub menu
    setSelectedMenu({});
  };

  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setIsVisible(!!selectedMenu?.routes?.length);
  }, [selectedMenu]);

  const transition = {
    duration: 0.3,
    delay: 0,
    ease: "easeIn",
  };

  const variants = {
    hidden: { y: -60, opacity: 0, transition, zIndex: -1 },
    show: {
      y: 0,
      x: 0,
      opacity: 0.75,
      transition,
    },
  };

  // HANDLE STICKY SUB MENU
  // get scroll position
  const [scrollPosition, setScrollPosition] = useState(0);
  const topMenuHeight = 92;
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <ClickAwayListener onClickAway={closeSubMenu}>
      <div>
        <div
          style={{
            paddingTop: scrollPosition > topMenuHeight ? "90px" : "0px",
          }}
        />
        <div
          className={classes.menuList_wrapper}
          style={{
            position: scrollPosition > topMenuHeight ? "fixed" : "relative",
          }}
        >
          <div className={classes.menu}>
            <ul
              style={{
                margin: scrollPosition > topMenuHeight ? "20px 0" : "30px 0",
              }}
            >
              {routes.map((route, index) => {
                let isActive = activeRoute(route.path);
                let hasSubRoute = route?.routes?.length > 0;
                return !hasSubRoute ? (
                  <li
                    key={index}
                    className={clsx({
                      [classes.activeLink]: isActive,
                      [classes.disabled]: denied_permissions.includes(route.id),
                    })}
                    id={route.id || ""}
                    onClick={() => {
                      setSelectedMenu({});
                    }}
                  >
                    <NavLink
                      to={route?.layout + route?.path}
                      className="menu-link"
                    >
                      <i>{route.icon}</i>&nbsp;
                      <span>{route.name}</span>
                      {/* <i>
                  <ArrowLeftIcon />
                </i> */}
                    </NavLink>
                  </li>
                ) : (
                  <li
                    key={index}
                    className={clsx(
                      {
                        [classes.activeLink]: isActive,
                        [classes.disabled]: denied_permissions.includes(
                          route.id,
                        ),
                      },
                      "menu-link",
                    )}
                    id={route.id || ""}
                    onClick={() => {
                      setSelectedMenu(route);
                    }}
                  >
                    <i>{route.icon}</i>&nbsp;
                    <span>{route.name}</span>
                    <i>
                      <ArrowDownIcon />
                    </i>
                  </li>
                );
              })}
            </ul>
          </div>
          {/* {!!selectedMenu.routes?.length && ( */}

          <motion.div
            ref={ref}
            animate={isVisible ? "show" : "hidden"}
            variants={variants}
            className={classes.subMenu}
          >
            <ul>
              {Children.toArray(
                selectedMenu.routes?.map((route, index) => {
                  const isActive = activeRoute(route.path);
                  return (
                    <li>
                      <NavLink
                        className={clsx(
                          {
                            [classes.activeSubMenu]: isActive,
                            [classes.disabled]: denied_permissions.includes(
                              route.id,
                            ),
                          },
                          "sub-menu-link",
                        )}
                        to={route?.layout + route?.path}
                        onClick={closeSubMenu}
                      >
                        {route.name}
                      </NavLink>
                    </li>
                  );
                }),
              )}
            </ul>
          </motion.div>

          {/* )} */}
        </div>
      </div>
    </ClickAwayListener>
  );
}
