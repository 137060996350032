import { useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useSelector } from "react-redux";

// components
import Breadcrumb from "Components/ui/Breadcrumb";
import EditAuctionOffersModal from "./EditAuctionOffersModal";
import { PrimaryToolTip } from "Components/ToolTip";
import Table from "Components/Table/Table";
import Status from "Components/Status/Status";
import ViewOfferModal from "./ViewOfferModal";

// utils
import { NAString, truncate } from "Utils/helperFunction";
import { primaryTimeFormat } from "Utils/moment";

// services
import { getBuyerOffersApi } from "Services";

// styles
import { auctionOffersStyles } from "./auctionOffers.styles";

// assets
import { ReactComponent as EditIcon } from "Assets/img/icons/icon_pencil-f.svg";
import { ReactComponent as IconActionOfferBox } from "Assets/img/icons/icon_offer_page.svg";
import { ReactComponent as OpenEyeIcon } from "Assets/img/icons/icon_eye-f.svg";
import {mainRole} from "../../../Utils/roles";

const useStyles = makeStyles(auctionOffersStyles);

export default function AuctionOffersPage() {
  const classes = useStyles();

  const [reload, setReload] = useState(false);
  const switchReload = () => setReload(!reload);

  const [showViewModal, setShowViewModal] = useState(false);
  const handle_openViewModal = item => setShowViewModal(item);
  const handle_closeViewModal = () => setShowViewModal(false);

  const [editModal, setEditModal] = useState(null);
  const handle_openEditModal = item => setEditModal(item);
  const handle_closeEditModal = () => setEditModal(false);

  const userAccess = useSelector(userState => mainRole(userState?.auth?.user));
  const isBuyer = userAccess === "buyer";

  const buyerColumns = [
    {
      dataIndex: "img",
      key: "img",
      title: "Image",
      bodyPadding: 8,
      width: 100,
      render: ({ record }) => (
        <div className={classes.buildingImg}>
          <img src={record.thumbnail_image} alt="" />
        </div>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      align: "center",
      expandAlign: "center",
      width: 367,
      render: ({ text, record }) => {
        return (
          <div className={classes.fontStyle}>
            <PrimaryToolTip title={record?.first_line_of_address}>
              {truncate(record?.first_line_of_address, 40, "...")}
            </PrimaryToolTip>
          </div>
        );
      },
    },
    {
      title: "Guide price",
      dataIndex: "guide_price",
      key: "guide_price",
      align: "center",
      width: 166,
      sort: "guide_price",
      render: ({ text, record }) => {
        return (
          <div className={classes.fontStyle}>
            £ {record.guide_price?.toLocaleString()}
          </div>
        );
      },
    },
    {
      title: "Offer amount",
      dataIndex: "offer_amount",
      key: "offer_amount",
      align: "center",
      width: 166,
      sort: "amount",
      render: ({ text, record }) => {
        return (
          <div className={classes.fontStyle}>
            £ {record.amount?.toLocaleString()}
          </div>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
      render: ({ text, record }) => {
        return (
          <span className="cell_type_1">
            {record?.type === "post" ? "Post" : "Prior"}
          </span>
        );
      },
    },
    {
      title: "Date/Time",
      dataIndex: "date_Time",
      key: "date_Time",
      align: "center",
      width: 210,
      sort: "created_at",
      render: ({ text, record }) => {
        return (
          <div className={classes.fontStyle}>
            {primaryTimeFormat(record?.created_at)}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 131,
      render: ({ record }) => {
        return <Status status={record.status} />;
      },
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      align: "center",
      width: 60,
      render: ({ record }) => {
        return (
          <div className={classes.editBtnWrapper}>
            <PrimaryToolTip
              title={
                record.status === "rejected" || record.status === "withdrawn"
                  ? "withdrawn is disabled"
                  : "withdrawn"
              }
              className={classes.icons}
            >
              <button
                className="d-flex"
                onClick={() => handle_openEditModal(record)}
                disabled={
                  record.status === "rejected" || record.status === "withdrawn"
                }
              >
                <EditIcon />
              </button>
            </PrimaryToolTip>
          </div>
        );
      },
    },
  ];

  const sellerColumns = [
    {
      dataIndex: "img",
      key: "img",
      title: "Image",
      bodyPadding: 8,
      width: 100,
      render: ({ record }) => (
        <div className={classes.buildingImg}>
          <img src={record.thumbnail_image} alt="" />
        </div>
      ),
    },
    {
      title: "Property name",
      dataIndex: "offer_amount",
      key: "offer_amount",
      align: "center",
      width: 367,
      render: ({ text, record }) => {
        return (
          <div className={classes.fontStyle}>
            <PrimaryToolTip title={record?.first_line_of_address}>
              {truncate(record?.first_line_of_address, 40, "...")}
            </PrimaryToolTip>
          </div>
        );
      },
    },
    {
      title: "Guide price",
      dataIndex: "guide_price",
      key: "guide_price",
      align: "center",
      width: 166,
      sort: "guide_price",
      render: ({ text, record }) => {
        return (
          <div className={classes.fontStyle}>
            £ {record.guide_price?.toLocaleString()}
          </div>
        );
      },
    },
    {
      title: "Offer amount",
      dataIndex: "buy-offer",
      key: "buy-offer",
      align: "center",
      expandAlign: "center",
      width: 166,
      sort: "amount",
      render: ({ text, record }) => {
        return (
          <div className={classes.fontStyle}>
            £ {record.amount?.toLocaleString()}
          </div>
        );
      },
    },
    {
      title: "Date & Time",
      dataIndex: "offer_date",
      key: "offer_date",
      align: "center",
      width: 210,
      sort: "created_at",
      render: ({ text, record }) => {
        return (
          <div className={classes.fontStyle}>
            {primaryTimeFormat(record.created_at)}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 131,
      render: ({ record }) => {
        return (
          <Status
            status={record.status}
            style={{ backgroundColor: "transparent" }}
          />
        );
      },
    },
    {
      title: "View",
      dataIndex: "view",
      key: "view",
      align: "center",
      width: 60,
      render: ({ record }) => {
        return (
          <div className={classes.editBtnWrapper}>
            <PrimaryToolTip title={"View offer"} className={classes.icons}>
              <button
                className="d-flex"
                onClick={() => handle_openViewModal(record)}
              >
                <OpenEyeIcon />
              </button>
            </PrimaryToolTip>
          </div>
        );
      },
    },
  ];

  const table = useMemo(
    () => ({
      columns: isBuyer ? buyerColumns : sellerColumns,
      showSearch: !isBuyer,
      mobileColumnsKey: isBuyer ? ["address"] : ["buy-offer", "view"],
    }),
    [isBuyer],
  );

  const handleRequest = ({ page, ...prevBody }, sendRequest) => {
    sendRequest({
      ...prevBody,
      offset: page,
    });
  };

  return (
    <div className={clsx(classes.root, "container")}>
      <Breadcrumb
        icon={<IconActionOfferBox />}
        title={["prior auction offers"]}
      />
      <Table
        columns={table.columns}
        breakpoints="@media (max-width: 1200px)"
        tableClasses={{ baseTable: classes.baseTable }}
        mobileColumnsKey={table?.mobileColumnsKey}
        handleRequest={handleRequest}
        api={getBuyerOffersApi}
        showSearch={true}
        reload={reload}
      />

      {editModal && (
        <EditAuctionOffersModal
          open={editModal}
          handleClose={handle_closeEditModal}
          switchReload={switchReload}
        />
      )}

      {showViewModal && (
        <ViewOfferModal
          open={showViewModal}
          handleCancel={handle_closeViewModal}
        />
      )}
    </div>
  );
}
