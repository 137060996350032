import { ReactComponent as MenuIcon } from "Assets/img/icons/icon_menu.svg";
import { ReactComponent as NotificationIcon } from "Assets/img/icons/icon_notication.svg";
import { makeStyles } from "@material-ui/core";
import { mobileScreenMenuStyle } from "Layouts/NavigationBar/Default/styles/navigationbar.styles";
const useStyles = makeStyles(mobileScreenMenuStyle);

export default function MobileScreenMenu({ handleDrawerToggle }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.mobile_wrapper}>
        <div className="d-flex align-items-center justify-content-center">
          <button className="btnBell">
            <i>
              <NotificationIcon />
            </i>
          </button>
        </div>
        <div className="d-flex align-items-center justify-content-center">
          <button className="btnMenu" onClick={() => handleDrawerToggle()}>
            <i>
              <MenuIcon />
            </i>
          </button>
        </div>
      </div>
    </>
  );
}
