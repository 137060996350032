import { useEffect } from "react";
import { useAlert } from "react-alert";

export default function ShowAlert({ message, ...extraOptions }) {
  const alert = useAlert();

  useEffect(() => {
    alert.show(message, { ...extraOptions });
  });

  return null;
}
