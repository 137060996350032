import { ClickAwayListener, makeStyles } from "@material-ui/core";
import { navigationSellerBuyerStyle } from "./navigationbarBS.style";
import { ReactComponent as LogoIcon } from "Assets/img/icons/icon_bsMenu-uth-logo.svg";
import { ReactComponent as MagnifierIcon } from "Assets/img/icons/icon_bsMenu-magnifier.svg";
import { ReactComponent as PersonIcon } from "Assets/img/icons/icon_bsMenu-person.svg";
import { ReactComponent as UthMiniLogo } from "Assets/img/icons/icon_bsMenu-uth-mini-logo.svg";
import { NavLink, useHistory } from "react-router-dom";
import { useState } from "react";
import clsx from "clsx";
import { motion } from "framer-motion";
import Dropdown from "Components/Dropdown";
import { Menu } from "antd";
import MenuDrawer from "./MenuDrawer";
import { ReactComponent as ArrowDownIcon } from "Assets/img/icons/icon_arrow-down-mini.svg";
// redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { logout } from "Store/_actions/auth.action";
import { updateProfileRole } from "Store/_actions/auth.action";
import {handle_redirectToHome} from "../../../Utils/helperFunction";
import {mainRole} from "../../../Utils/roles";

const useStyles = makeStyles(navigationSellerBuyerStyle);

export default function MenuListBs({ router }) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, userRole } = useSelector(
    state => ({
      user: state.auth?.user,
      userRole: mainRole(state.auth?.user)
    }),
    shallowEqual,
  );
  const hostName = window.location.origin;

  const [selectedMenu, setSelectedMenu] = useState({});
  const handle_openSubMenu = routes => {
    if (Object.keys(selectedMenu).length > 0) {
      setSelectedMenu({});
    } else {
      setSelectedMenu(routes);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleChangeTrader = () => {
    dispatch(updateProfileRole(userRole === "buyer" ? "seller" : "buyer"));
    history.push("/");
  };

  const closeMenu = () => {
    setSelectedMenu({});
  };

  const transition = {
    duration: 0.3,
    delay: 0,
    ease: "easeIn",
  };
  const variants = {
    hidden: {
      opacity: 0,
      translateY: -60,
      transition,
      transitionEnd: {
        display: "none",
        visibility: "hidden",
      },
    },
    show: {
      display: "block",
      opacity: 1,
      visibility: "visible",
      translateY: 0,
      transition,
    },
  };

  const token = useSelector(state => state?.auth?.user?.password_onetime_token)
  const handleGoToHome = (pathname) =>{
    handle_redirectToHome(pathname, token, dispatch)
  }

  const handle_renderSubMenu = () => {

    return (
      <motion.div
        animate={Object.keys(selectedMenu).length > 0 ? "show" : "hidden"}
        initial="hidden"
        variants={variants}
        className={classes.subMenu}
      >
        <ul className={"thirdMenu"}>
          {Object.keys(selectedMenu).length > 0 &&
            selectedMenu?.map((route, index) => {
              const isDisabled =
                route.name?.includes("sold") ||
                route.name === "complete" ||
                route.name === "draft" ||
                route.name === "unpublished";
              return (
                <li key={index}>
                  <NavLink
                    to={route.layout + route.path}
                    className={clsx(isDisabled && "navlink-disabled")}
                    onClick={e => isDisabled && e.preventDefault()}
                    disabled={isDisabled}
                  >
                    {route.name}
                  </NavLink>
                </li>
              );
            })}
        </ul>
      </motion.div>
    );
  };

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <div className={classes.menuList_wrapper}>
        <div className={classes.logo}>
          <a href={`${hostName}/`}>
            <LogoIcon />
          </a>
        </div>
        <div className={classes.menu}>
          <ul className="firstMenu">
            <li>
              <a
                onClick={()=>handleGoToHome("properties")}
                className="menu-link"
              >
                LISTINGS
              </a>
            </li>
            <li>
              <a
                onClick={()=>handleGoToHome("bidding-room")}
                className="menu-link"
              >
                BIDDING ROOM
              </a>
            </li>
            <li>
              <a
                className="menu-link"
                onClick={()=>handleGoToHome("search?page=1&latitude=51.4893335&longitude=-0.14405508&location=London%2C+England%2C+United+Kingdom&radius=10&advance=0")}
              >
                <i>
                  <MagnifierIcon />
                </i>
                SEARCH
              </a>
            </li>
            <li onClick={() => setSelectedMenu({})}>
              <Dropdown
                overlayClassName={classes.profileDropdown}
                placement="bottomRight"
                overlay={
                  <Menu>
                    <Menu.Item key="1" onClick={handleChangeTrader}>
                      Switch to {userRole === "buyer" ? "Seller" : "Buyer"}
                    </Menu.Item>
                    <Menu.Item key="2" onClick={handleLogout}>
                      Logout
                    </Menu.Item>
                  </Menu>
                }
              >
                <span className="menu-link">
                  <i>
                    <PersonIcon />
                  </i>
                  MY ACCOUNT
                </span>
              </Dropdown>
            </li>
            <li>
              <span className="menu-link" onClick={() => setMenuOpen(true)}>
                <i>
                  <UthMiniLogo />
                </i>
                MENU
              </span>
            </li>
          </ul>
        </div>
      </div>
      <ClickAwayListener onClickAway={closeMenu}>
        <>
          <div className={classes.menu_2}>
            <ul className="secondMenu">
              {router.map((route, index) => {
                // let isActive = activeRoute(route.path);
                let hasSubRoute = route?.routes?.length > 0;
                const isDisabled = route.name?.includes("nvoice");
                return !hasSubRoute ? (
                  <li
                    key={index}
                    onClick={() => {
                      setSelectedMenu({});
                    }}
                  >
                    <NavLink
                      to={route.layout + route.path}
                      className={clsx(isDisabled && "navlink-disabled")}
                      onClick={e => isDisabled && e.preventDefault()}
                      disabled={isDisabled}
                    >
                      <i>{route.icon}</i>
                      <span>{route.name}</span>
                    </NavLink>
                  </li>
                ) : (
                  <li
                    key={index}
                    onClick={() => {
                      handle_openSubMenu(route?.routes);
                    }}
                  >
                    <i>{route.icon}</i>
                    <span>{route.name}</span>
                    <i>
                      <ArrowDownIcon
                        className={clsx("arrowSvg", {
                          rotate: Object.keys(selectedMenu).length > 0,
                        })}
                      />
                    </i>
                  </li>
                );
              })}
            </ul>
          </div>
          {handle_renderSubMenu()}
        </>
      </ClickAwayListener>

      <MenuDrawer open={menuOpen} close={() => setMenuOpen(false)} />
    </>
  );
}
