export const breadcrumbStyle = theme => ({
  breadcrumb_root: {
    paddingTop: 60,
    paddingBottom: 30,
    "& .breadcrumb": {
      display: "flex",
      alignItems: "center",
      gap: 10,
      "& .icon": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 2.5,
        width: 32,
        height: 32,
        "& svg": {
          "& rect": {
            fill: theme.theme_type === "light" ? "#333333" : "#ffffff1a",
            fillOpacity: theme.theme_type === "light" ? 0.2 : 1,
            opacity: 1,
            rx: 5,
          },
          "& path[fill]": {
            fill: theme.theme_type === "light" ? "#333333" : "white",
          },
          "& path[stroke]": {
            stroke: theme.theme_type === "light" ? "#333333" : "white",
          },
        },
      },
      "& .previousTabs": {
        fontSize: "clamp(1.4rem, 2.5vw, 1.8rem)",
        fontWeight: 600,
        fontStyle: "normal",
        lineHeight: "26px",
        letterSpacing: "-0.03em",
        textTransform: "uppercase",
        // cursor: "pointer",
      },
      "&>svg>path": {
        fill: theme.theme_type === "light" ? "#333333" : "white",
      },
      "& .activeTab ": {
        fontSize: "clamp(1.4rem, 2.5vw, 1.8rem)",
        fontWeight: 600,
        fontStyle: "normal",
        lineHeight: "26px",
        letterSpacing: "-0.03em",
        textTransform: "uppercase",
        color: theme.palette.primary.main,
      },
    },
  },
});
