import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

// components
import Table from "Components/Table/Table";
import Breadcrumb from "Components/ui/Breadcrumb";
import BidsHistoryModal from "./BidsHistoryModal";

// services
import { getBuyerBidApi } from "Services/traderApi";

// utils
import { primaryTimeFormat } from "Utils/moment";

// styles
import { myBidsStyles } from "./myBids.styles";

// assets
import { ReactComponent as IconActionOfferBox } from "Assets/img/icons/icon_offer_page.svg";
import { ReactComponent as IconHistory } from "Assets/img/icons/icon_history.svg";
import { PrimaryToolTip } from "../../../Components/ToolTip";
import { truncate } from "../../../Utils/helperFunction";

const useStyles = makeStyles(myBidsStyles);

export default function MyBidsPage() {
  const classes = useStyles();

  const [toggleHistoryModal, setToggleHistoryModal] = useState(null);
  const handle_openHistoryModal = item => setToggleHistoryModal(true);
  const handle_closeHistoryModal = () => setToggleHistoryModal(false);
  const [id, setId] = useState();

  const columns = [
    {
      dataIndex: "img",
      key: "img",
      title: "Image",
      bodyPadding: false,
      width: 100,
      render: ({ record }) => (
        <div className={classes.buildingImg}>
          <img src={record.thumbnail_image} alt="" />
        </div>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      align: "center",
      width: 405,
      expandAlign: "center",
      render: ({ text, record }) => {
        return (
          <div className={classes.fontStyle}>
            <PrimaryToolTip title={record?.first_line_of_address}>
              {truncate(record?.first_line_of_address, 40, "...")}
            </PrimaryToolTip>
          </div>
        );
      },
    },
    {
      title: "Last bid amount",
      dataIndex: "last_bid_amount",
      key: "last_bid_amount",
      align: "center",
      width: 220,
      render: ({ text, record }) => {
        return (
          <div
            className={clsx(classes.fontStyle, classes.history)}
            onClick={() => {
              setId(record.id);
              handle_openHistoryModal();
            }}
          >
            <i className="d-flex me-2">
              <IconHistory />
            </i>
            <p className="m-0">Bidding history</p>
          </div>
        );
      },
    },
    {
      title: "Date/Time",
      dataIndex: "date_Time",
      key: "date_Time",
      align: "center",
      width: 281,
      sort: "created_at",
      render: ({ text, record }) => {
        return (
          <div className={classes.fontStyle}>
            {primaryTimeFormat(record?.created_at)}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 218,
      sort: "bid_status",
      render: ({ record }) => {
        return (
          <div
            className={clsx(classes.fontStyle, classes.status)}
            style={{
              color:
                record.bid_status === "Auction in progress"
                  ? "#F9B914"
                  : record.bid_status === "Auction ended"
                  ? "#FF5939"
                  : record.bid_status === "Auction not started"
                  ? "#FFFFF"
                  : "#02B075",
            }}
          >
            {record.bid_status}
          </div>
        );
      },
    },
  ];

  const handleRequest = ({ page, ...prevBody }, sendRequest) => {
    sendRequest({
      ...prevBody,
      offset: page,
    });
  };

  return (
    <div className={clsx(classes.root, "container")}>
      <Breadcrumb icon={<IconActionOfferBox />} title={["MY bids"]} />
      <Table
        columns={columns}
        breakpoints="@media (max-width: 1200px)"
        tableClasses={{ baseTable: classes.baseTable }}
        mobileColumnsKey={["address"]}
        handleRequest={handleRequest}
        api={getBuyerBidApi}
        showSearch={true}
      />
      {toggleHistoryModal && (
        <BidsHistoryModal
          open={toggleHistoryModal}
          handleClose={handle_closeHistoryModal}
          id={id}
        />
      )}
    </div>
  );
}
