import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

// Object key name should be equal to api key name

const green = {
  color: "#02B075",
  backgroundColor: "transparent",
  border: "1px solid #02B075",
};
const orange = {
  color: "#F98B59",
  backgroundColor: "transparent",
  border: "1px solid #F98B59",
};
const yellow = {
  color: "#F9B914",
  backgroundColor: "transparent",
  border: "1px solid #F9B914",
};
const blue = {
  color: "#3553FB",
  backgroundColor: "transparent",
  border: "1px solid #3553FB",
};
const purple = {
  color: "#724BEC",
  border: "1px solid #724BEC",
  backgroundColor: "transparent",
};
const darken = {
  color: "#7E9CBA",
  border: "1px solid #7E9CBA",
  backgroundColor: "transparent",
};

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.center,
    borderRadius: 5,
  },
  fix: {
    width: "100%",
    height: "100%",
  },
  pending: {
    color: theme.theme_type === "light" ? "#333" : "#FFF",
    backgroundColor: "transparent",
    border: `1px solid ${theme.theme_type === "light" ? "#333" : "#FFF"}`,
  },
  accepted: {
    text: "Accepted",
    ...green,
  },
  "not-accepted": {
    text: "Not Accepted",
    ...orange,
  },
  rejected: {
    text: "Rejected",
    ...orange,
  },
  deactivate: {
    color: "#F98B59",
    backgroundColor: "transparent",
    text: "Deactivate",
    border: "1px solid #F98B59",
  },
  inactive: {
    color: "#F98B59",
    backgroundColor: "transparent",
    text: "Deactivate",
    border: "1px solid #F98B59",
  },
  active: {
    color: "#02B075",
    backgroundColor: "transparent",
    text: "Active",
    border: "1px solid #02B075",
  },
  archived: {
    color: "#7E7E7E",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    border: "1px solid #7E7E7E",
    text: "Archived",
  },
  offer_completed: {
    color: "#5189F6",
    border: "1px solid #5189F6",
  },
  "time-left": {
    color: "#F98B59",
    backgroundColor: "transparent",
    border: "1px solid #F98B59",
  },
  timeLeft: {
    color: theme.theme_type === "light" ? "#333" : "#FFF",
    backgroundColor: "transparent",
    border: "1px solid #F7C91B",
  },
  reservePrice: { color: "#3C5670", backgroundColor: "#F3F5FF" },
  withdrawn: {
    text: "withdrawn",
    ...yellow,
  },
  //My Bids
  progress: {
    text: "Auction in progress",
    ...yellow,
  },
  ended: {
    text: "Auction ended",
    ...yellow,
  },
  not_started: {
    text: "Auction not started",
    ...blue,
  },
  won: {
    text: "Won",
    ...blue,
  },
  //end Bids
  countMin: {
    ...yellow,
  },
  countMax: {
    ...green,
  },
  // properties status
  complete: {
    color: "#02B075",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    border: "1px solid #02B075",
    text: "COMPLETED",
  },
  completed: {
    ...green,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    text: "COMPLETED",
  },
  booked: {
    ...green,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    text: "Booked in",
  },
  live: {
    ...blue,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    text: "LIVE",
  },
  draft: {
    ...darken,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    text: "DRAFT",
  },
  sold: {
    ...purple,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    text: "SOLD",
  },
  unsold: {
    ...orange,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    text: "UNSOLD",
  },
  unpublish: {
    color: "#333333",
    border: "1px solid #333333",
    backgroundColor: "#F8FAFC",
    text: "UNPUBLISH",
  },
  unpublished: {
    color: theme.theme_type === "light" ? "#333" : "#FFF",
    border: `1px solid ${theme.theme_type === "light" ? "#333" : "#FFF"}`,
    text: "UNPUBLISH",
  },
  waiting: {
    color: theme.theme_type === "light" ? "#333" : "#FFF",
    border: `1px solid ${theme.theme_type === "light" ? "#333" : "#FFF"}`,
    text: "Waiting agent response",
  },
  //advert
  show: {
    ...green,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    text: "Show",
  },
  not_show: {
    border: `1px solid ${theme.theme_type === "light" ? "#333" : "#FFF"}`,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    text: "Hidden",
  },
  // invoices status
  paid: {
    ...green,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    text: "Paid",
  },
  unpaid: {
    ...orange,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    text: "Unpaid",
  },
  // AML CHeck
  passed: {
    color: "#02B075",
    backgroundColor: "transparent",
    border: "1px solid #02B075",
    text: "Passed",
  },
  referred: {
    color: "#B1BDD1",
    backgroundColor: "transparent",
    border: "1px solid #B1BDD1",
    text: "Referred",
  },
  "not-passed": {
    color: "#F98B59",
    backgroundColor: "transparent",
    border: "1px solid #F98B59",
    text: "Rejected",
  },
  failed: {
    color: "#F98B59",
    backgroundColor: "transparent",
    border: "1px solid #F98B59",
    text: "Failed",
  },
  undefined: {
    color: theme.theme_type === "light" ? "#333" : "#FFF",
    text: "N/A",
  },
  // support
  answered: {
    color: "#02B075",
  },
  open: {
    color: "#B1BDD1",
  },
  closed: {
    color: "#8492A6",
  },
  customer_response: {
    color: theme.theme_type === "light" ? "#333" : "#FFF",
  },
}));

const config = {
  accepted: {
    text: "Accepted",
  },
  "not-accepted": {
    text: "Not Accepted",
  },
  rejected: {
    text: "Rejected",
  },
  deactivate: {
    text: "Deactivate",
  },
  inactive: {
    text: "inactive",
  },
  active: {
    text: "Active",
  },
  archived: {
    text: "Archived",
  },
  pending: {
    text: "Pending",
  },
  withdrawn: {
    text: "withdrawn",
  },
  //My Bids
  progress: {
    text: "Auction in progress",
  },
  ended: {
    text: "Auction ended",
  },
  not_started: {
    text: "Auction not started",
  },
  won: {
    text: "Won",
  },
  // properties status
  complete: {
    text: "COMPLETED",
  },
  completed: {
    text: "COMPLETED",
  },
  booked: {
    text: "Booked in",
  },
  live: {
    text: "LIVE",
  },
  draft: {
    text: "DRAFT",
  },
  sold: {
    text: "SOLD",
  },
  unsold: {
    text: "UNSOLD",
  },
  unpublish: {
    text: "UNPUBLISHED",
  },
  unpublished: {
    text: "UNPUBLISHED",
  },
  waiting: {
    text: "Waiting agent response",
  },
  //advert
  show: {
    text: "Show",
  },
  not_show: {
    text: "Hidden",
  },
  // invoices status
  paid: {
    text: "Paid",
  },
  unpaid: {
    text: "Unpaid",
  },
  // AML CHeck
  passed: {
    text: "Passed",
  },
  referred: {
    text: "Referred",
  },
  "not-passed": {
    text: "Rejected",
  },
  failed: {
    text: "Failed",
  },
  undefined: {
    text: "N/A",
  },
};

export default function Status({
  status,
  style,
  className,
  fix = true,
  onClick,
  removeBorder,
  children,
}) {
  const classes = useStyles();

  return (
    <span
      className={clsx(
        "main-status",
        classes.root,
        classes[status],
        fix && classes.fix,
        className,
      )}
      style={Object.assign(
        {},
        removeBorder && { borderWidth: 0 },
        { backgroundColor: "transparent" },
        style,
      )}
      onClick={onClick}
    >
      {children ?? config[status]?.text}
    </span>
  );
}
