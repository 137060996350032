import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { Spin } from "antd";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// components module
import Modal from "Components/Modal";

// assets
import { ReactComponent as CallIcon } from "Assets/img/icons/icon_big-call.svg";

// services
import { getCallInfo, getAgencyCallInfo } from "Services";

const useStyles = makeStyles(theme => ({
  modalContent: {
    width: "100%",
    padding: "20px 20px 10px",
    borderRadius: 5,
    fontSize: 14,
    fontWeight: 500,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 900px)": {
      padding: "30px 0px 0px",
    },

    "& > h5": {
      marginTop: 25,
      ...theme.fonts.s16w500,
    },
    "& > h3": {
      fontSize: 24,
      color: "#FAB400",
      "&>a": {
        color: "inherit",
      },
    },
    "& > h4": {
      fontSize: 24,
    },
  },
}));

export default function CallModal({ visible: userId, handleCancel, isAgency, phone }) {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:992px)");

  const [loading, setLoading] = useState(false);
  const [callInfo, setCallInfo] = useState(null);

  useEffect(() => {
    if(phone) {
      setCallInfo({ prefix: phone });
    } else {
      setLoading(true);
      (isAgency ? getAgencyCallInfo : getCallInfo)(userId)
          .then(data => {
            setCallInfo(data);
            setLoading(false);
          })
          .catch(_err => {
            setLoading(false);
            handleCancel();
          });
    }
  }, []);

  return (
    <Modal
      title="Call now"
      isModalVisible={userId}
      // onOk={handleOk}
      handleCancel={handleCancel}
    >
      <Spin spinning={loading}>
        <div className={classes.modalContent}>
          <CallIcon />
          <h5>Call</h5>
          <h3>
            {callInfo?.prefix ? (
              // matches ? (
              //   callInfo?.prefix
              // ) : (
              //   <a href={`tel:${callInfo?.prefix}`}>{callInfo?.prefix}</a>
              // )
              <a href={`tel:${callInfo?.prefix}`}>{callInfo?.prefix}</a>
            ) : (
              "xxxxxxxxxxx"
            )}
          </h3>
          {/*<h5>Extension is</h5>*/}
          {/*<h4>{callInfo?.call_id ? `${callInfo?.call_id}#` : "xxxx"}</h4>*/}
        </div>
      </Spin>
    </Modal>
  );
}
