import { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import RouteWithSubRoutes from "./RouteWithSubRoutes";
import NotFound from "Components/ResultPage/NotFoundPage";
import { useSelector } from "react-redux";
import { SpinnerRoundFilled } from "spinners-react";

// laravel echo
import LaravelEcho from "LaravelEcho";
import {mainRole} from "../Utils/roles";

export default function BasePage({ routes }) {
  const userRole = useSelector(store => mainRole(store?.auth?.user));

    return (
        <Suspense
            fallback={
                <div
                    style={{
                        width: "25%",
                        height: "50vh",
                        margin: "0 auto",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <SpinnerRoundFilled
                        size={150}
                        thickness={120}
                        speed={100}
                        color="#F9B914"
                        secondaryColor="rgba(172, 172, 172, 0.15)"
                    />
                </div>
            }
        >
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect
                        exact
                        from="/"
                        to={`/${userRole}/${
                            userRole === "seller" ? "properties/live" : "dashboard"
                        }`}
                    />
                }
                {
                    /* Redirect from /admin URL to /dashboard. */
                    <Redirect
                        exact
                        from={`/${userRole}`}
                        to={`/${userRole}/${
                            userRole === "seller" ? "properties/live" : "dashboard"
                        }`}
                    />
                }
                {routes.map((route, i) => (
                    <RouteWithSubRoutes
                        key={i}
                        path={"/" + userRole + route.path}
                        exact={route.exact}
                        component={route.component}
                        subRoute={route?.routes}
                    />
                ))}
                {<Route component={NotFound} />}
            </Switch>
        </Suspense>
    );

  // todo remove if working fine, diabled by feyi
  // return (
  //   <LaravelEcho loading={<>initializing pusher ...</>}>
  //     <Suspense
  //       fallback={
  //         <div
  //           style={{
  //             width: "25%",
  //             height: "50vh",
  //             margin: "0 auto",
  //             display: "flex",
  //             alignItems: "center",
  //             justifyContent: "center",
  //           }}
  //         >
  //           <SpinnerRoundFilled
  //             size={150}
  //             thickness={120}
  //             speed={100}
  //             color="#F9B914"
  //             secondaryColor="rgba(172, 172, 172, 0.15)"
  //           />
  //         </div>
  //       }
  //     >
  //       <Switch>
  //         {
  //           /* Redirect from root URL to /dashboard. */
  //           <Redirect
  //             exact
  //             from="/"
  //             to={`/${userRole}/${
  //               userRole === "seller" ? "properties/live" : "dashboard"
  //             }`}
  //           />
  //         }
  //         {
  //           /* Redirect from /admin URL to /dashboard. */
  //           <Redirect
  //             exact
  //             from={`/${userRole}`}
  //             to={`/${userRole}/${
  //               userRole === "seller" ? "properties/live" : "dashboard"
  //             }`}
  //           />
  //         }
  //         {routes.map((route, i) => (
  //           <RouteWithSubRoutes
  //             key={i}
  //             path={"/" + userRole + route.path}
  //             exact={route.exact}
  //             component={route.component}
  //             subRoute={route?.routes}
  //           />
  //         ))}
  //         {<Route component={NotFound} />}
  //       </Switch>
  //     </Suspense>
  //   </LaravelEcho>
  // );
}
