import authActionType from "Store/_types/auth.type";

import { loginUser, registerUser, loginWithTokenApi } from "Services/auth.crud";
import {
  updateProfile,
  updateAgencyProfile,
  updateThemeApi,
  logoutUser,
} from "Services";
import alert from "Utils/alert";
import {getDeniedPermissions, mainRole} from "../../Utils/roles";

//Register
const requestRegister = () => ({ type: authActionType.REGISTER_REQUEST });
const successRegister = data => ({
  type: authActionType.REGISTER_SUCCESS,
  payload: data,
});
const failureRegister = error => ({
  type: authActionType.REGISTER_FAILURE,
  payload: error,
});

export const register = data => async dispatch => {
  dispatch(requestRegister());
  await registerUser(data)
    .then(res => {
      const type = mainRole(res.user) === "trader" ? "buyer" : mainRole(res.user);
      dispatch(
        successRegister({
          ...res,
          user: { ...res.user, type },
        }),
      );
    })
    .catch(err => {
      const error = err.response && (err.response || err.message);
      dispatch(failureRegister(error));
      throw error;
    });
};

//Login
export const requestLogin = () => ({ type: authActionType.LOGIN_REQUEST });

export const successLogin = auth => ({
  type: authActionType.LOGIN_SUCCESS,
  payload: updateUserWithDeniedPermissions(auth),
});

const updateUserWithDeniedPermissions = (auth) => {
  auth.user.denied_permissions = getDeniedPermissions(auth.user);

  return auth;
};

const failureLogin = error => ({
  type: authActionType.LOGIN_FAILURE,
  payload: error,
});

export const login = data => async dispatch => {
  dispatch(requestLogin());
  await loginUser(data)
    .then(res => {
      const type = mainRole(res.user) === "trader" ? "buyer" : mainRole(res.user);
      dispatch(
        successLogin({
          ...res,
          user: { ...res.user, remember_me: data.rememberMe, type },
        }),
      );
    })
    .catch(err => {
      const error = err.response && (err.response || err.message);
      dispatch(failureLogin(error));
      throw error;
    });
};

export const loginWithToken = token => async dispatch => {
  dispatch(requestLogin());
  await loginWithTokenApi(token)
    .then(res => {
      const type = mainRole(res.user) === "trader" ? "buyer" : mainRole(res.user);
      dispatch(successLogin({ ...res, user: { ...res.user, type } }));
    })
    .catch(err => {
      const error = err.response && (err.response || err.message);
      dispatch(failureLogin(error));
      throw error;
    });
};

export const updatePrevUser = prevUser => async dispatch => {
  dispatch({ type: authActionType.UPDATE_PREV_USER, payload: prevUser });
};

//Logout
const successLogOut = () => ({ type: authActionType.LOGOUT_SUCCESS });

export const logout = () => async dispatch => {
  window.localStorage.clear()
  await logoutUser().then(() => {
    dispatch(successLogOut());
    // needs refresh, to log out.
  });
};

/*
const successLogOut = async () => {
  await logoutUser().then(() => ({ type: authActionType.LOGOUT_SUCCESS }));
};
export const logout = () => dispatch => {

  // localStorage.removeItem("persist:v_auth");
  dispatch(successLogOut());
};
*/

//Update Agent
const _requestAgentProfileUpdate = () => ({
  type: authActionType.AGENT_PROFILE_UPDATE_REQUEST,
});
const _successAgentProfileUpdate = data => ({
  type: authActionType.AGENT_PROFILE_UPDATE_SUCCESS,
  payload: data,
});
const _failureAgentProfileUpdate = error => ({
  type: authActionType.AGENT_PROFILE_UPDATE_FAILURE,
  payload: error,
});

export const updateAgentProfileData = data => async dispatch => {
  dispatch(_requestAgentProfileUpdate());
  return updateProfile(data)
    .then(res => {
      alert.success();
      dispatch(_successAgentProfileUpdate({ ...data, ...res }));
    })
    .catch(err => {
      const error = err.response && (err.response || err.message);
      dispatch(_failureAgentProfileUpdate(error));
      throw error;
    });
};

//Update Agency
const _requestAgencyProfileUpdate = () => ({
  type: authActionType.AGENCY_PROFILE_UPDATE_REQUEST,
});
const _successAgencyProfileUpdate = data => ({
  type: authActionType.AGENCY_PROFILE_UPDATE_SUCCESS,
  payload: data,
});
const _failureAgencyProfileUpdate = error => ({
  type: authActionType.AGENCY_PROFILE_UPDATE_FAILURE,
  payload: error,
});

export const updateAgencyProfileData = data => async dispatch => {
  dispatch(_requestAgencyProfileUpdate());
  return updateAgencyProfile(data)
    .then(res => {
      alert.success();
      dispatch(_successAgencyProfileUpdate({ ...data, ...res }));
    })
    .catch(err => {
      const error = err.response && (err.response || err.message);
      dispatch(_failureAgencyProfileUpdate(error));
      throw error;
    });
};

// Change role (trader => buyer/seller)
const _updateProfileRole = role => ({
  type: authActionType.UPDATE_ROLE,
  payload: role,
});
export const updateProfileRole = role => async dispatch => {
  dispatch(_updateProfileRole(role));
};

// Change some details of user profile
const _updateProfileDetails = data => ({
  type: authActionType.UPDATE_DETAILS,
  payload: data,
});
const _updateAgencyDetails = data => ({
  type: authActionType.UPDATE_AGENCY_DETAILS,
  payload: data,
});
export const updateProfileDetails = data => async dispatch => {
  dispatch(_updateProfileDetails(data));
};
export const updateSomeAgencyDetails = data => async dispatch => {
  dispatch(_updateAgencyDetails(data));
};
export const updateTheme = data => async dispatch => {
  dispatch(_updateProfileDetails(data));
  updateThemeApi(data);
};

// update Terms and Condition
const _updateProfileTermsAndCondition = data => ({
  type: authActionType.UPDATE_TERMS,
  payload: data,
});
export const updateProfileTermsAndCondition = data => dispatch => {
  dispatch(_updateProfileTermsAndCondition(data));
};
// update AML
const _updateProfileAML = data => ({
  type: authActionType.UPDATE_AML_STATUS,
  payload: data,
});
export const updateProfileAML = data => dispatch => {
  dispatch(_updateProfileAML(data));
};

// update Navbar Notification
const _updateNavbarNotification = () => ({
  type: "UPDATE_NAVBAR_NOTIFICATION",
});
export const updateNavbarNotification = () => async dispatch => {
  dispatch(_updateNavbarNotification());
};
