import { cloneElement } from "react";
//Components
import NavigationBar from "./NavigationBar/Default";

//Routes
import routes from "Routes/allRoutes";
import BackgroundTheme from "./BackgroundTheme";
import MainWrapper from "./MainWrapper";

export default function Layout({ children }) {
  const ROUTES = routes();

  return (
    <>
      <BackgroundTheme />
      <MainWrapper>
        <NavigationBar routes={ROUTES} />
        <div>{cloneElement(children, { routes: ROUTES })}</div>
      </MainWrapper>
    </>
  );
}
