import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: "center",
    marginBottom: 14,
    "&>.icon": {
      width: 40,
      minWidth: 40,
      display: "flex",
      justifyContent: "center",
      "& svg": {
        "& path": {
          fill: props =>
            props.iconColor ||
            (theme.theme_type === "light" ? "#9cacbc" : "#ffffff"),
        },
      },
    },
    "&>span": {
      ...theme.s14w500,
      // color: "#3C5670",
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
    },
    "&>.text1": {
      color: theme.theme_type === "light" ? "#333333" : "#CCCCCC",
    },
  },
}));

export default function TextWithIcon({
  className,
  icon,
  text1,
  text2,
  iconColor,
  NADefault,
}) {
  const classes = useStyles({ iconColor });

  return (
    <div
      className={clsx(
        classes.root,
        "fit-size d-flex",
        "text-with-icon",
        className,
      )}
    >
      {!!icon && <span className="fit-size icon">{icon}</span>}
      {!!text1 && (
        <span className="text1 me-2">{text1 || (NADefault && "N/A")}</span>
      )}
      <span className="text2">{text2 || (NADefault && "N/A")}</span>
    </div>
  );
}
