import { makeStyles } from "@material-ui/core";
import headImg from "Assets/img/AML-check-submit.png";
import headImgReferred from "Assets/img/AML-check-submit-refferd.png";
import clsx from "clsx";
import { PrimaryButton } from "Components/ui";
import React, { useState } from "react";
import { ReactComponent as IconCheck } from "Assets/img/icons/icon_check.svg";

import Spin from "Components/Spinner/Spin";
import { submitAMLCheckDocuments } from "Services";
import Uploader from "Components/Uploader/Uploader";
import alert from "Utils/alert";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    "& .header": {
      textAlign: "center",
    },
    "& .title": {
      ...theme.fonts.s16w600,
      color: theme.theme_type === "light" ? "#333" : "#FFF",
    },
    "& .subTitle": {
      ...theme.fonts.s14w500,
      color: theme.theme_type === "light" ? "#9CACBC" : "#FFF",
    },
    "& .msg": {
      ...theme.fonts.s24w600,
      color: "#FFF",
    },
    "& .subMsg": {
      ...theme.fonts.s14w500,
      color: theme.theme_type === "light" ? "#9CACBC" : "#FFF",
      marginBottom: 67,
    },
    "& .imgDiv": {
      marginTop: 86,
      marginBottom: 65,
    },
    "& .btnWrapper": {
      textAlign: "center",
      "& button": {
        padding: "7.5px 10.5px",
        ...theme.fonts.s14w500,
      },
      "& .bG": {
        backgroundColor: "transparent",
        border: "1px solid #FFF",
        marginRight: 16,
      },
    },
    "& .uploadDiv": {
      "& p.head": {
        marginTop: 83,
        color: theme.theme_type === "light" ? "#9CACBC" : "#FFF",
        ...theme.fonts.s16w600,
        textAlign: "center",
      },
      "& p": {
        color: theme.palette.primary.main,
        ...theme.fonts.s12w500,
      },
    },
    "& .uploadBox": {
      marginTop: 50,
      width: "100%",
      "& .uploaderLabel": {
        ...theme.fonts.s14w500,
      },
      "& .errorMsg": {
        color: theme.colors.red,
        ...theme.fonts.s12w700,
      },
    },
    "& .btnWrapperUploader": {
      width: "100%",
      marginTop: 40,
      "& .submit": {
        padding: "8px 10px 8px 20px",
        "&:disabled": {
          backgroundColor: "#A58A39",
          borderColor: "#A58A39",
          color: "#A8AEAE",
          cursor: "none",
          "& svg path": { fill: "#A8AEAE !important" },
        },
        [theme.media(768)]: {
          width: "100%",
        },
      },
      "& .cancel": {
        padding: "8px 30px",
        backgroundColor: "transparent",
        border: "1px solid #FFF",
        marginRight: 16,
        [theme.media(768)]: {
          width: "100%",
          marginRight: 0,
          marginBottom: 16,
        },
      },
    },
    "& .dot": {
      display: "block",
      width: 4,
      height: 4,
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
      marginRight: 6,
    },
  },
}));
export default function Step5({ status }) {
  const classes = useStyles();
  const history = useHistory();
  const [displayUploader, setDisplayUploader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [identificationFiles, setIdentificationFiles] = useState([]);
  const [proofOfFundsFiles, setProofOfFundsFiles] = useState([]);
  // const [counter, setCounter] = React.useState(3);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [successUpload, setSuccessUpload] = useState(false);

  const handle_sendFiles = async () => {
    setLoading(true);
    let body = new FormData();
    body.append("identification_file", identificationFiles[0]);
    body.append("proof_of_funds_file", proofOfFundsFiles[0]);
    await submitAMLCheckDocuments(body)
      .then(res => {
        // const timer =
        //   counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        // alert.success(
        //   `Successfully Send. You're Redirect to Dashboard in ${counter}`,
        // );
        alert.success(`Successfully Send.`);
        setDisabledSubmit(true);
        setSuccessUpload(true);
      })
      .catch(err => console.log(err));
    setLoading(false);
  };

  const handle_renderContent = (imgSrc, msg, subMsg) => (
    <>
      <div className="imgDiv d-flex justify-content-center">
        <img src={imgSrc} alt="" />
      </div>
      <div className="d-flex align-items-center flex-column ">
        <p className="msg">{msg}</p>
        <p className="subMsg">{subMsg}</p>
      </div>
      {status === "passed" ? (
        <div className="btnWrapper">
          {/*<PrimaryButton title={"Buyers guide"} className="bG" />*/}
          {/*<PrimaryButton title={"Start browsing"} className="sB" />*/}
          <PrimaryButton
            title={"Back to Dashboard"}
            className="sB"
            onClick={() => history.replace(`/buyer/dashboard`)}
          />
        </div>
      ) : (
        <div className="uploadDiv">
          <div className="d-flex align-items-center justify-content-center">
            <p className="me-5 d-flex align-items-center ">
              <span className="dot" />
              Identification (Passport/ Driving licence)
            </p>
            <p className="d-flex align-items-center">
              <span className="dot" />
              Proof of funds
            </p>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <PrimaryButton
              onClick={() => setDisplayUploader(true)}
              title={
                <>
                  Upload Here{" "}
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.66683 8.76083L9.41416 8.01416C9.53981 7.89263 9.70817 7.82531 9.88297 7.82671C10.0578 7.8281 10.225 7.8981 10.3487 8.02162C10.4724 8.14514 10.5426 8.3123 10.5443 8.48709C10.5459 8.66189 10.4788 8.83034 10.3575 8.95616L8.4715 10.8422C8.40973 10.9043 8.33629 10.9536 8.2554 10.9872C8.17451 11.0209 8.08777 11.0382 8.00016 11.0382C7.91256 11.0382 7.82582 11.0209 7.74493 10.9872C7.66404 10.9536 7.5906 10.9043 7.52883 10.8422L5.64283 8.95616C5.58093 8.89418 5.53185 8.82061 5.49839 8.73966C5.46492 8.65871 5.44773 8.57195 5.44779 8.48436C5.44785 8.39676 5.46517 8.31003 5.49875 8.22913C5.53233 8.14822 5.58151 8.07473 5.6435 8.01283C5.70548 7.95093 5.77905 7.90185 5.86 7.86839C5.94095 7.83492 6.0277 7.81773 6.1153 7.81779C6.2029 7.81785 6.28962 7.83517 6.37053 7.86875C6.45143 7.90233 6.52493 7.95151 6.58683 8.0135L7.3335 8.76083V5.00016C7.3335 4.82335 7.40373 4.65378 7.52876 4.52876C7.65378 4.40373 7.82335 4.3335 8.00016 4.3335C8.17697 4.3335 8.34654 4.40373 8.47157 4.52876C8.59659 4.65378 8.66683 4.82335 8.66683 5.00016V8.76083ZM4.00016 12.3335H12.0002C12.177 12.3335 12.3465 12.4037 12.4716 12.5288C12.5966 12.6538 12.6668 12.8234 12.6668 13.0002C12.6668 13.177 12.5966 13.3465 12.4716 13.4716C12.3465 13.5966 12.177 13.6668 12.0002 13.6668H4.00016C3.82335 13.6668 3.65378 13.5966 3.52876 13.4716C3.40373 13.3465 3.3335 13.177 3.3335 13.0002C3.3335 12.8234 3.40373 12.6538 3.52876 12.5288C3.65378 12.4037 3.82335 12.3335 4.00016 12.3335Z"
                      fill="white"
                    />
                  </svg>
                </>
              }
              className="sB"
            />
          </div>
        </div>
      )}
    </>
  );
  return (
    <Spin spinning={loading}>
      <div
        className={clsx(classes.root, " d-flex align-items-center flex-column")}
      >
        {!displayUploader ? (
          <>
            <div className="header">
              <p className="title">submit</p>
              <p className="subTitle">The last step</p>
            </div>
            {status === "passed"
              ? handle_renderContent(
                  headImg,
                  "congratulations! you’ve passed",
                  "  You can now place bids and secure properties",
                )
              : handle_renderContent(
                  headImgReferred,
                  status,
                  " Please click on the upload button to upload the following two documents:",
                )}
          </>
        ) : (
          <>
            {successUpload ? (
              <div className="uploadDiv">
                <p className="head">
                  Your evidence will be reviewed and updated soon.
                </p>
                <div className="btnWrapperUploader d-flex flex-column align-items-center">
                  <PrimaryButton
                    title={"Back to Dashboard"}
                    className="sB"
                    onClick={() => history.replace(`/buyer/dashboard`)}
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="uploadDiv">
                  <p className="head">
                    Please upload 2 of the below documents:
                  </p>
                  <div className="d-flex align-items-center justify-content-center">
                    <p className="me-5 d-flex align-items-center">
                      <span className="dot" />
                      Identification (Passport/ Driving licence)
                    </p>
                    <p className="d-flex align-items-center">
                      <span className="dot" />
                      Proof of funds
                    </p>
                  </div>
                </div>
                <div className="uploadBox">
                  <span className="uploaderLabel">Upload Identification</span>
                  <Uploader
                    files={identificationFiles}
                    setFiles={setIdentificationFiles}
                    accept={
                      "image/*,.pdf, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    }
                    maxFiles={4}
                    multiple
                  />
                </div>
                <div className="uploadBox">
                  <span className="uploaderLabel">Upload proof of funds</span>
                  <Uploader
                    files={proofOfFundsFiles}
                    setFiles={setProofOfFundsFiles}
                    accept={
                      "image/*,.pdf, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    }
                    maxFiles={4}
                    multiple
                  />
                </div>
                <div className="btnWrapperUploader d-flex flex-column align-items-center justify-content-end flex-md-row">
                  <PrimaryButton
                    title={"Cancel"}
                    className="cancel"
                    onClick={() => {
                      setIdentificationFiles([]);
                      setProofOfFundsFiles([]);
                      setDisplayUploader(false);
                    }}
                  />
                  <PrimaryButton
                    title={
                      <>
                        Submit
                        <IconCheck />
                      </>
                    }
                    className="submit"
                    onClick={() => handle_sendFiles()}
                    disabled={
                      disabledSubmit ||
                      (identificationFiles.length === 0 &&
                      proofOfFundsFiles.length === 0
                        ? true
                        : identificationFiles.length > 0 &&
                          proofOfFundsFiles.length === 0
                        ? true
                        : identificationFiles.length === 0 &&
                          proofOfFundsFiles.length > 0)
                    }
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </Spin>
  );
}
