import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import invoiceActionType from "Store/_types/invoice.type";

const initialInvoiceState = {
  type: null,
  record: null,
};

export const invoiceReducer = persistReducer(
  { storage, key: "invoiceData" },
  (state = initialInvoiceState, { type, payload }) => {
    switch (type) {
      case invoiceActionType.SET_INVOICE:
        return {
          ...state,
          ...payload,
        };
      case invoiceActionType.EMPTY_INVOICE:
        return {
          ...initialInvoiceState,
        };
      default:
        return state;
    }
  },
);
