import { useState, useEffect } from "react";
import { Form } from "antd";
import { makeStyles } from "@material-ui/core";

// component
import AutoCompleteInput from "Components/Input/AutoComplete";
import Modal from "Components/Modal";
import { PrimaryButton } from "Components/ui";
import { SelectInput, PriceInput, NormalInput } from "Components/Input";

// services
import { getPropertiesTypesApi, postBuyerAlertsApi } from "Services";
import alert from "Utils/alert";
import { SpinnerRoundFilled } from "spinners-react";

const useStyles = makeStyles(theme => ({
  wrapModal: {},
  modalEdit: {
    "& .title": {
      ...theme.fonts.s14w500,
      marginBottom: 24,
    },
  },
  createBtn: {
    width: 130,
    textAlign: "center",
  },
}));

const options = [
  {
    value: 0,
    label: "any",
  },
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
  {
    value: "6",
    label: "6",
  },
  {
    value: "7",
    label: "7",
  },
  {
    value: "8",
    label: "8",
  },
  {
    value: "9",
    label: "9",
  },
  {
    value: "10",
    label: "10",
  },
];

export default function AddAlertsModal({ isVisible, handleCancel, setReload }) {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [typeData, setTypeData] = useState([]);
  const [loading, setLoading] = useState(false);

  const onFinish = async values => {
    const body = {
      ...values,
      max_bedrooms: values.max_bedrooms === 0 ? null : values.max_bedrooms,
      min_bedrooms: values.min_bedrooms === 0 ? null : values.min_bedrooms,
    };
    setLoading(true);
    await postBuyerAlertsApi(body)
      .then(res => {
        alert.success();
        setReload();
        handleCancel();
      })
      .catch(err => console.error(err));
    setLoading(false);
  };

  const getPropertyType = async () => {
    await getPropertiesTypesApi()
      .then(res => {
        setTypeData(res.data);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    getPropertyType();
  }, []);

  return (
    <Modal
      title="Create a property alert"
      isModalVisible={isVisible}
      className={classes.wrapModal}
      handleCancel={handleCancel}
      width={600}
    >
      <div className={classes.modalEdit}>
        <p className="title">Be the first to see new properties</p>
        <Form
          form={form}
          layout="vertical"
          name="basic"
          onFinish={onFinish}
          requiredMark={false}
        >
          <div className="row">
            <div className="col-xs-12 col-md-6 mb-4">
              <Form.Item
                name="location"
                label="Location / Post Code"
                rules={[
                  {
                    required: true,
                    message: "Please enter location / Post Code!",
                  },
                ]}
              >
                <AutoCompleteInput
                  form={form}
                  placeholder="Enter a location / Post Code"
                  name="location"
                  location
                />
              </Form.Item>
            </div>
            <div className="col-xs-12 col-md-6 mb-4">
              <Form.Item
                name="radius"
                label="Search radius"
                rules={[
                  {
                    required: true,
                    message: "Please enter radius!",
                  },
                ]}
              >
                <NormalInput
                  form={form}
                  type="number"
                  name="radius"
                  placeholder="Within 10 miles"
                />
              </Form.Item>
            </div>

            <div className="col-xs-12 col-md-6 mb-4">
              <Form.Item
                label="Min price"
                name="min_price"
                dependencies={["max_price"]}
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please enter minimum guide price!",
                  // },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !getFieldValue("max_price") ||
                        !value ||
                        getFieldValue("max_price") > value
                      ) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error("Min Price must be less then Max Price!"),
                      );
                    },
                  }),
                ]}
              >
                <PriceInput form={form} name="min_price" placeholder="No min" />
              </Form.Item>
            </div>
            <div className="col-xs-12 col-md-6 mb-4">
              <Form.Item
                label="Max price"
                name="max_price"
                dependencies={["min_price"]}
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please enter maximum guide price!",
                  // },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !getFieldValue("min_price") ||
                        !value ||
                        getFieldValue("min_price") < value
                      ) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error("Max Price must be bigger then Min Price!"),
                      );
                    },
                  }),
                ]}
              >
                <PriceInput form={form} name="max_price" placeholder="No max" />
              </Form.Item>
            </div>

            <div className="col-xs-12 col-md-6 mb-4">
              <Form.Item
                name="min_bedrooms"
                label="Min bedroom(s)"
                rules={[
                  // { required: true, message: "Please select!" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !getFieldValue("max_bedrooms") ||
                        !value ||
                        getFieldValue("max_bedrooms") > value
                      ) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(
                          "Min Bedroom(s) must be less then Max Bedrooms!",
                        ),
                      );
                    },
                  }),
                ]}
              >
                <SelectInput
                  form={form}
                  name="min_bedrooms"
                  placeholder="Select Min bedroom"
                  options={options}
                />
              </Form.Item>
            </div>
            <div className="col-xs-12 col-md-6 mb-4">
              <Form.Item
                name="max_bedrooms"
                label="Max bedroom(s)"
                rules={[
                  // { required: true, message: "Please select!" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !getFieldValue("min_bedrooms") ||
                        !value ||
                        getFieldValue("min_bedrooms") < value
                      ) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(
                          "Max Bedrooms must be bigger then Min Bedroom(s)!",
                        ),
                      );
                    },
                  }),
                ]}
              >
                <SelectInput
                  form={form}
                  name="max_bedrooms"
                  placeholder="Select Max bedroom"
                  options={options}
                />
              </Form.Item>
            </div>

            <div className="col-12 mb-4">
              <Form.Item
                name="property_type_id"
                label="Property type"
                // rules={[{ required: true, message: "Please select!" }]}
              >
                <SelectInput
                  form={form}
                  name="property_type_id"
                  placeholder="Select Property type"
                  options={typeData}
                />
              </Form.Item>
            </div>

            <div className="col-12 d-flex align-items-center justify-content-end">
              <Form.Item>
                <PrimaryButton
                  title={
                    loading ? (
                      <SpinnerRoundFilled
                        size={20}
                        thickness={100}
                        speed={100}
                        color="#FFF"
                      />
                    ) : (
                      "Create alert"
                    )
                  }
                  type="submit"
                  className={classes.createBtn}
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
