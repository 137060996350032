import axios from "axios";
import store from "Store/store";
import alert from "Utils/alert";
import { logout } from "Store/_actions/auth.action";
import defaultAxios from "axios";
import {mainRole} from "../Utils/roles";

// Configs
const configs = {
  baseURL: `${process.env.REACT_APP_URL_BASE}`,
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: function (status) {
    return status >= 200 && status < 400;
  },
};

export const showError = ({ response }) => {
  let messages = [];
  if (!response) return messages.push("Network error");
  let showError = true;

  switch (response.status) {
    case 422:
      messages = Object.values(response.data.errors);
      break;
    case 404:
      messages = ["404 Not Found", response.data?.error];
      break;
    case 401:
      messages = ["Error 401", response.data?.message];
      break;
    case 405:
      messages = [response.data?.message];
      break;
    case 500:
      // if (JSON.stringify(response.data?.message).includes("Duplicate entry")) {
      //   messages = [" ", "Duplicate entry, cant have two of the same sorry"];
      // } else {
      messages = ["500 Server Error", response.data?.message];
      // }
      break;
    case 503:
      showError = false;
      break;
    default:
      messages = [
        `Error ${response.status}`,
        response.data?.error || response.data?.message,
      ];
      break;
  }

  if (showError) {
    alert.error(messages);
  }
};

const handleError = error => {
  return Promise.reject(error);
};

// Fetch api type A: axios just with header config
const _axios = axios.create(configs);

// Fetch api type B: axios with authorization, header configs, ...
const api = axios.create(configs);

const getFromStore = () => {
  const state = store.getState();
  return {
    token: state?.auth?.authToken ? `Bearer ${state.auth?.authToken}` : "",
    userRole: mainRole(state?.auth?.user),
  };
};

api.interceptors.request.use(config => {
  const { token } = getFromStore();
  config.headers = { ...config.headers, authorization: token };

  config.url.includes("elastic-search")
    ? (config.baseURL = `${process.env.REACT_APP_URL_BASE}`)
    : (config.baseURL = `${process.env.REACT_APP_URL_BASE}/main`);

  return config;
}, handleError);

const handleWholeErros = [
  response => response,
  error => {
    if (error?.response?.status === 401) {
      store.dispatch(logout()); // logout for unauthenticated
    } else {
      showError(error);
    }
    return Promise.reject(error); //when use showError, we dont need to write this line of code
  },
];

api.interceptors.response.use(...handleWholeErros);

// Fetch api type C: axios with authorization and header configs, without routes configs
const _axiosWithAuth = axios.create(configs);

_axiosWithAuth.interceptors.request.use(config => {
  const { token } = getFromStore();

  config.headers = { ...config.headers, authorization: token };
  return config;
}, handleError);

_axiosWithAuth.interceptors.response.use(...handleWholeErros);

const _axiosWithNoRole = axios.create(configs)

export { _axiosWithAuth, _axios, _axiosWithNoRole};
export default api;

