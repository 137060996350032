import { lighten } from "@material-ui/core/styles";

export const navigationSellerBuyerStyle = theme => ({
  root: {
    position: "relative",
    marginBottom: 20,
    "& .arrowSvg": {
      transition: "all .3s",
      transform: "rotate(0)",
    },
    "& .arrowSvg.rotate": {
      transform: "rotate(180deg)",
    },
    "& ul.thirdMenu, & ul.secondMenu": {
      background: "#CBD5DA",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& li": {
        height: "100%",
        margin: "0 10px",
        padding: "0 10px",
        display: "flex",
        ...theme.fonts.s14w700,
        alignItems: "center",
        textAlign: "center",
        letterSpacing: "0.2em",
        color: "#32383F",
        textTransform: "uppercase",
        [theme.media(1500)]: {
          padding: 0,
          ...theme.fonts.s12w700,
        },
      },
      "& i": {
        marginRight: 8,
        display: "inline-flex",
      },
      "& svg path": {
        transition: "all .3s",
        fill: "#32383F",
      },
      "& a": {
        display: "inline-flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
        textAlign: "center",
        padding: "0 24px",
        backgroundColor: "transparent",
        transition: "all .3s",
        "&.navlink-disabled": {
          color: "#00000040",
          "& path": {
            fill: "#00000040",
          },
        },
        [theme.media(1500)]: {
          padding: "0 5px",
        },
      },
      "& a.active": {
        color: "#FFFFFF",
        background: "#32383F",
        "& svg path": {
          fill: "#FFFFFF",
        },
      },
    },
  },
  menuList_wrapper: {
    padding: "30px 2.8%",
    background: "#32383F",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 9,
  },
  menu: {
    "& ul.firstMenu": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      transition: "all 300ms",
      fontWeight: 700,
      fontSize: "1.6rem",
      [theme.media(1230)]: {
        fontSize: "1.2rem",
      },
      "& li": {
        flexGrow: 1,
        cursor: "pointer",
        "&:not(:last-child)": {
          borderRight: "1px solid #ffffff40",
          "& .menu-link": {
            paddingRight: 29,
          },
        },
      },
      "& .menu-link": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px 0px 10px 29px",
        color: "#FFF",
        letterSpacing: "0.2em",
        "& i": {
          marginRight: 27,
        },
      },
    },
  },
  menu_2: {
    width: "100%",
    height: 60,
  },
  subMenu: {
    width: "100%",
    height: 60,
    position: "absolute",
    "& ul": {
      borderTop: "1px solid #32383F",
    },
  },
  activeLink: {
    color: "#FFFFFF !important",
    background: "#32383F",
    "& svg path": {
      fill: "#FFFFFF !important",
    },
  },
  profileDropdown: {
    "& .ant-dropdown-menu": {
      backgroundColor: "#CBD5DA",
      "& .ant-dropdown-menu-item": {
        display: "flex",
        ...theme.fonts.s14w700,
        letterSpacing: "0.2em",
        color: "#32383F",
        textTransform: "uppercase",
        "&:hover": {
          backgroundColor: lighten("#CBD5DA", 0.3),
        },
      },
    },
  },
  backToYourDashboardWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 24px",
    background: "#32383F",
    width: "100%",
    flexFlow: "wrap",
    "& button": {
      padding: 0,
      marginBottom: 8,
    },
    "& > h5": {
      color: "white",
      "& span": {
        textTransform: "uppercase",
        ...theme.fonts.s12w700,
        color: "#FF5939",
      },
    },
  },
  backToYourDashboard: {
    display: "flex",
    alignItems: "center",
    color: "white",
    "& > svg": {
      width: 16,
      height: 16,
      marginRight: 6,
    },
    "& > span": {
      ...theme.fonts.s14w500,
    },
  },
});

export const mobileMenuSellerBuyerStyle = theme => ({
  root: {},
  topSection: {
    background: "#32383F",
    padding: "26px 20px",
    maxHeigh: 1200,

    "& > header": {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
  },
  toggleMenuButton: {
    cursor: "pointer",
  },
  menu: {
    transition: "all ease-in 400ms",
    "& ul": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      gap: 30,
      transition: "all 300ms",
      fontWeight: 700,
      fontSize: "1.6rem",
      paddingBottom: 20,
      "& li": {
        flexGrow: 1,
        cursor: "pointer",
      },
      "& .menu-link": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#FFF",
        letterSpacing: "0.2em",
        "& i": {
          marginRight: 10,
        },
      },
    },
  },
  bottonSection: {
    background: "#CBD5DA",
    transition: "all ease-in 1000ms",
    "& ul": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      gap: 10,
      fontWeight: 700,
      fontSize: "1.6rem",
      "& li": {
        flexGrow: 1,
        cursor: "pointer",
      },
      "& a": {
        ...theme.fonts.s14w700,
        display: "inline-flex",
        alignItems: "center",
        textAlign: "center",
        letterSpacing: "0.2em",
        color: "#32383F",
        textTransform: "uppercase",
        padding: "21px 24px",
        "&.navlink-disabled": {
          color: "#00000040",
        },
      },
      "& a.active": {
        color: "#FFFFFF",
        background: "#32383F",
      },
    },
  },
  profileDropdown: {
    "& .ant-dropdown-menu": {
      backgroundColor: "#CBD5DA",
      "& .ant-dropdown-menu-item": {
        display: "flex",
        ...theme.fonts.s14w700,
        letterSpacing: "0.2em",
        color: "#32383F",
        textTransform: "uppercase",
        "&:hover": {
          backgroundColor: lighten("#CBD5DA", 0.3),
        },
      },
    },
  },
});

export const drawerMenu = theme => ({
  drawer: {
    "& .MuiDrawer-paper, & .MuiDialog-paper": {
      width: "100%",
      position: "relative",
    },
    "& .header": {
      position: "absolute",
      top: "0",
      left: "0",
      width: "100%",
      zIndex: 9,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "30px",
      "& .closeBtn": {},
    },
  },
  nav: {
    flexGrow: 1,
    height: "100%",
    background: "#32383F",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "right",
    position: "relative",
    overflow: "hidden",
    "& .nav--list": {
      width: "100%",
      height: "100%",
      background: "#32383FE6",
      paddingLeft: "15%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "& li": {
        margin: "5px 0",
        "& a": {
          color: "white",
          textTransform: "uppercase",
          fontSize: 40,
          fontWeight: "bold",
          lineHeight: "47px",
          letterSpacing: "0.2em",
          padding: "10px 0",
          display: "inline-block",
        },
      },
    },
  },
});
