export const navigationStyle = theme => ({
  "@keyframes rotation": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(359deg)",
    },
  },
  main_navbar: {
    borderBottom: `1px solid ${theme.backgrounds.white25}`,
    backgroundColor: "rgb(32,32,32)",
    background: theme.backgrounds.currentTheme,
    position: "relative",
    marginBottom: 20,
  },
  setting: {
    position: "absolute",
    right: 0,
    bottom: -200,
    width: 40,
    height: 36,
    background: theme.theme_type === "light" ? "#00000033" : "#ffffff1a",
    borderRadius: "5px 0 0 5px",
    padding: "8px 5px",
    cursor: "pointer",
    zIndex: 1,
    "& svg": {
      width: "100%",
      height: "100%",
      animation: "$rotation 10s infinite linear",
      "& path": {
        fill: theme.theme_type === "light" ? "#333" : "white",
      },
    },
  },
});

export const menuStyle = theme => {
  return {
    menuList_wrapper: {
      top: 0,
      width: "100%",
      background: theme.backgrounds.currentTheme,
      zIndex: 100,
      marginBottom: 10,
    },
    menu: {
      padding: "0 50px",

      "& ul": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        transition: "all 300ms",
        ...theme.fonts.s14w500,

        "& li": {
          flexGrow: 1,
          cursor: "pointer",
          "&:not(:last-child)": {
            borderRight: "1px solid #ffffff40",
          },
        },
        "& li.menu-link, & a.menu-link": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px 5px",
          color: "white",
          "& i": {
            display: "flex",
            "& svg": {
              transition: "all .2s",
              width: 20,
              height: 20,
            },
            "&:last-child": {
              padding: "0 10px",
              "& svg": {
                // height: 5,
                // width: 10,
              },
            },
          },
        },
      },
    },
    subMenu: {
      position: "absolute",
      width: "100%",
      height: 50,
      borderBottom: "1px solid #ffffff1a",
      // background: theme.theme_type === "light" ? "#0000004d" : "#ffffff1a",
      background: theme.backgrounds?.currentTheme,
      zIndex: 10,
      "& > ul": {
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        "& .sub-menu-link": {
          color: "#fff",
          letterSpacing: "-0.5px",
          ...theme.fonts.s14w500,
          margin: "0 22px",
          padding: "14px 10px",
          textTransform: "uppercase",

          "&:hover": {
            color: "#F9B914",
          },

          "@media(max-width: 1050px)": {
            ...theme.fonts.s12w500,
            padding: "10px 4px",
            margin: "0 10px",
          },
        },
      },
    },
    activeSubMenu: {
      fontWeight: "700 !important",
      color: "#F9B914 !important",
      borderBottom: "4px solid #F9B914",
      paddingBottom: "13px !important",
    },
    activeLink: {
      background: theme.backgrounds.white1,
      color: `${theme.palette.primary.main} !important`,
      "&,& a": {
        color: `${theme.palette.primary.main} !important`,
        "&:hover, &:focus, &:active": {
          color: `${theme.palette.primary.main} !important`,
        },
        "& path": {
          fill: theme.palette.primary.main,
        },
      },
    },
    dropDownMenu: {
      background: theme.backgrounds.white1,
      backdropFilter: "blur(50px)",
      padding: 38,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: 64,
      "& li": {
        ...theme.fonts.s14w500,
        textTransform: "uppercase",
        "&:hover": {
          color: theme.palette.primary.main,
          background: "transparent",
        },
      },
      "& .active": {
        ...theme.fonts.s14w700,
        color: theme.palette.primary.main,
        position: "relative",
        "&::after": {
          content: '""',
          position: "absolute",
          bottom: "-10px",
          height: "4px",
          width: "100%",
          left: "0",
          right: "0",
          borderRadius: "50px 50px 0px 0px",
          backgroundColor: "#FDC500",
        },
      },
      "& span:": {
        paddingRight: 15,
        paddingLeft: 15,
      },
      "& .ant-dropdown-menu-title-content > a:hover": {
        color: theme.palette.primary.main,
      },
      "& .ant-dropdown-menu-title-content > a::after": {
        top: "unset",
      },
    },
    disabled: {
      filter: "blur(1.2px)",
      pointerEvents: "none",
      "& div": {
        pointerEvents: "none",
      },
    },
  };
};

export const mobileScreenMenuStyle = theme => ({
  mobile_wrapper: {
    display: "flex",
    alignContent: "center",
    justifyContent: "space-between",
    margin: "15px 0",
    padding: "0 50px",

    "@media (max-width: 992px)": {
      padding: "0 30px 0 20px",
    },

    "& button, i": {
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
    },
    "& .btnBell": {
      backgroundColor: theme.palette.secondary.main,
      padding: "8px",
      marginRight: "10px",
      borderRadius: "6px",
    },
    "& svg": {
      "& path": {
        fill: "#fff",
      },
    },
  },
});

export const topNavStyle = theme => ({
  top_navbar: {
    display: "flex",
    alignItems: "center",
    padding: "0 30px 0 20px",
    borderBottom: `1px solid ${theme.backgrounds.white25}`,
    [theme.media()]: {},
    "& .profileAndBtnNavWrapper": {
      [theme.media(1200)]: {
        width: "100%",
      },
    },
  },
  afTopNavLogo: {
    height: "97px",
    padding: "26px 20px 26px 0px",
    borderRight: "1px solid #ffffff40",
    marginRight: "40px",
  },

  topNavLogo: {
    padding: "26px 54px 26px 0px",
    borderRight: `1px solid ${theme.backgrounds.white25}`,
    marginRight: 40,
    height: "97px",
  },

  topNavSearch: {
    [theme.media(1200)]: {
      order: 2,
      width: "100%",
      marginTop: 8,
      marginRight: 0,
    },
    marginRight: 40,
    position: "relative",
    flex: "1 1 auto",
    "& .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child)":
      {
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
      },
    "& .ant-input-affix-wrapper": {
      // background: "rgba(4, 4, 4, 0.2)",
      background: theme.backgrounds.black2,
      border: `1px solid ${theme.backgrounds.white25}`,
      "& .ant-input": {
        backgroundColor: "transparent",
        fontSize: 12,
        fontWeight: 500,
        color: "white",
      },
      "& .ant-input-suffix": {
        margin: 0,
        borderRadius: "0 5px 5px 0",
        "& svg": {
          "& path": {
            fill: "#FFF",
          },
        },
      },
    },
    "& .ant-input-search-large .ant-input-search-button": {
      height: 38,
    },
    "& .ant-input-group-addon": {
      backgroundColor: "transparent",
      "& button": {
        // background: "rgba(255, 255, 255, 0.1)",
        background: "rgba(0, 0, 0, 0.5)",
        border: "unset",
        fontSize: 12,
        fontWeight: 400,
        textTransform: "capitalize",
        display: "flex",
        alignItems: "center",
        "& span.btnText": {
          display: "flex",
          alignItems: "center",
          "& svg": {
            width: 18,
            marginLeft: 8,
          },
        },
      },
    },
    "& .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button":
      {
        borderRadius: "0 5px 5px 0",
      },
    "& .resultBox": {
      position: "absolute",
      background: "#4D5554",
      width: "100%",
      borderRadius: 5,
      marginTop: 10,
      zIndex: 101,
      overflow: "hidden",
      "& .header": {
        maxWidth: "60%",
        display: "flex",
        justifyContent: "center",
        margin: "0 auto",
        "& svg": {
          width: 20,
        },
        "& button": {
          background: "transparent",
          flexBasis: 73,
          paddingTop: 8,
          paddingBottom: 8,
          borderRadius: "5px 5px 0px 0px",
          ...theme.fonts.s12w500,
          transition: "all .3s",
          color: "white",
          "&:hover": {
            background: "#3A4546",
          },
          "& svg path": {
            transition: "all .3s",
            fill: "#FFF",
          },
        },
        "& button.active": {
          background: "#3A4546",
          color: theme.palette.primary.main,
          ...theme.fonts.s12w700,
          "& svg path": {
            fill: theme.palette.primary.main,
          },
        },
      },
      "& .content": {
        maxHeight: 250,

        "& .ant-empty": {
          margin: 10,
        },
        "& .ant-empty-image": {
          width: "70px",
          height: "70px",
          margin: "0 auto",
          "& img": {
            width: "100%",
          },
        },
        "& .ant-empty-description": {
          marginTop: 10,
          ...theme.fonts.s12w500,
        },
      },
      "& .itemRow": {
        padding: 20,
        backgroundColor: "#626E6D",
        border: "0.25px solid #ffffff4f",
        borderBottom: "unset",
        display: "flex",
        alignItems: "center",
        transition: "all .3s",
        color: "white",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
        "& .imgDiv": {
          alignSelf: "flex-start",
          ...theme.imgDiv(38, 38, "50%", true),
          marginRight: 20,
          "& img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
          },
        },
        "& p": {
          ...theme.fonts.s12w700,
        },
        "& p.model": {
          textTransform: "capitalize",
        },
        "& p.address": {
          marginBottom: 0,
          ...theme.fonts.s12w500,
        },
        "& p.type": {
          // color: "#F9B914",
          borderLeft: "3px solid #F9B914",
        },
        "& span.searchKey": {
          color: "#F9B914",
        },
      },
    },
  },

  topNavButtons: {
    display: "flex",
    alignItems: "center",
    marginRight: 24,
    "& > div:first-child": {
      [theme.media(480)]: {
        marginBottom: 8,
      },
    },
    [theme.media(480)]: {
      flexDirection: "column",
    },
    "& .MuiBadge-root": {
      marginRight: 16,

      "@media (max-width: 992px)": {
        marginRight: 8,
      },

      "& button": {
        width: 32,
        height: 32,
        backgroundColor: "#72868E",
        borderRadius: 32,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .MuiBadge-badge": {
        background: "#E50707",
        border: "1px solid white",
      },
    },
    "& svg": {
      "& path": {
        fill: "#FFF",
      },
    },
  },

  topNavProfile: {
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    fontWeight: 700,
    textAlign: "right",
    position: "relative",
    "& .topnav-profile-switch": {
      border: "1px solid white",
      padding: "7px 13px",
      alignItems: "center",
      marginRight: 20,
      color: "white",
      "& span": {
        letterSpacing: "-0.5px",
      },
      "& i": {
        display: "flex",
        marginLeft: 2,
      },
    },
    "& .topnav-profile-name": {
      color: "white",
      "& p": {
        margin: 0,
        "&:last-child": {
          fontWeight: 500,
        },
      },
    },
    "& .topnav-profile-image": {
      marginLeft: 10,
      minWidth: 45,
      maxWidth: 45,
      height: 45,
      cursor: "pointer",
      "& img": {
        width: "100%",
        height: "100%",
        borderRadius: 45,
        filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        objectFit: "cover",
      },
    },
    "& .topnav-profile-theme": {
      position: "absolute",
      bottom: -30,
      left: 0,
      color: "white",
      cursor: "pointer",
      "&.ttt": {
        left: "unset",
        right: 0,
      },
    },
  },

  buttonsDropdown: {
    minWidth: "381px !important",
    "@media (max-width: 576px)": {
      minWidth: "300px !important",
    },
    border: `1px solid ${theme.backgrounds.white25}`,
    borderRadius: 5,
    padding: "19px 16px",
    background: "#566970",
    color: "white",
    "@supports (backdrop-filter: none)": {
      background: theme.backgrounds.black3,
      backdropFilter: "blur(50px)",
    },

    "& .topnav-notifications": {
      fontSize: 12,
      fontWeight: 500,
      "&>:first-child": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 12,
        fontSize: 16,
        fontWeight: 600,
      },
      "&>.custom-scrollbar": {
        maxHeight: 207,
        paddingRight: 7,
        marginTop: 12,
        "&>div": {
          marginBottom: 13,
          paddingBottom: 13,
          borderBottom: `1px solid ${theme.backgrounds.white25}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 15,
        },
      },
      "&>:last-child": {
        textAlign: "center",
      },
    },
  },

  profileDropdown: {
    border: `1px solid ${theme.backgrounds.white25}`,
    borderRadius: 5,
    background: "#566970",
    "@supports (backdrop-filter: none)": {
      background: theme.backgrounds.black3,
      backdropFilter: "blur(50px)",
    },
    "& .ant-dropdown-menu": {
      background: "transparent",
      "& .ant-dropdown-menu-item": {
        color: "white",
        "&:hover": {
          background: "transparent",
        },
      },
    },
  },
  notificationNavButton: {
    cursor: "pointer",
    "& > span": {
      padding: "3px 6px",
      background: "rgba(255, 255, 255, 0.1)",
      borderRadius: 5,
      border: "1px solid #FF5939",
      ...theme.fonts.s12w500,
      color: "#FF5939",
      marginLeft: 6,
    },
  },
  backToYourDashboardWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 24px",
    background: "rgba(255, 255, 255, 0.2)",
    width: "100%",
    flexFlow: "wrap",
    "& button": {
      padding: 0,
      marginBottom: 8,
    },
    "& > h5": {
      color: "white",
      "& span": {
        textTransform: "uppercase",
        ...theme.fonts.s12w700,
        color: "#FF5939",
      },
    },
  },
  backToYourDashboard: {
    display: "flex",
    alignItems: "center",
    color: "white",
    "& > svg": {
      width: 16,
      height: 16,
      marginRight: 6,
    },
    "& > span": {
      ...theme.fonts.s14w500,
    },
  },
});
