import { Children, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

// Icons
import { ReactComponent as IconClose } from "Assets/img/icons/icon_close.svg";
import SuccessAlert from "Assets/img/icons/alert_success.png";
import ErrorAlert from "Assets/img/icons/alert_error.png";
import WarningAlert from "Assets/img/icons/alert_warning.png";
import InfoAlert from "Assets/img/icons/alert_info.png";

const useStyles = makeStyles(theme => {
  return {
    alert: {
      background: "transparent",
      borderRadius: 10,
      minWidth: 328,
      maxWidth: 328,
      overflow: "hidden",
      boxShadow: "0px 0px 20px rgb(0 0 0 / 20%)",
      "@media (max-width: 328px)": {
        minWidth: 280,
      },
    },
    alertHeader: {
      fontSize: 16,
      fontWeight: 600,
      color: ({ theme_type }) =>
        theme_type === "light" ? "#32383F" : "#ffffff",
      borderRadius: "10px 10px 0 0",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      background: ({ theme_type }) =>
        theme_type === "light" ? "#ffffff" : "rgba(71,77,78,0.8)",
      padding: "18px 20px",
      textTransform: "uppercase",
      "& i": {
        display: "flex",
        cursor: "pointer",
      },
      "& svg": {
        "& path": {
          fill: ({ theme_type }) =>
            theme_type === "light" ? "#32383F" : "#ffffff",
        },
      },
      "& > p": {
        marginTop: 30,
        marginBottom: 0,
        fontSize: 24,
        fontWeight: 700,
      },
    },
    alertBody: {
      background: ({ theme_type }) =>
        theme_type === "light" ? "#ffffff" : "rgba(64,67,67, .8)",
      padding: "26px 20px 15px",
      fontSize: 12,
      fontWeight: 700,
      color: ({ theme_type }) =>
        theme_type === "light" ? "#32383F" : "#ffffff",
      textAlign: "center",
      "&>:first-child": {
        marginBottom: 8,
      },
      "&>:last-child": {
        fontWeight: 500,
        marginTop: 15,
        maxWidth: "90%",
        margin: "auto",
      },
      "& p.text": {
        fontSize: 14,
        fontWeight: 400,
        color: ({ theme_type }) =>
          theme_type === "light" ? "#32383F" : "#ffffff",
      },
      "& .custom-error-msg": {
        textAlign: "left",
        fontSize: 12,
        fontWeight: 400,
        color: ({ theme_type }) =>
          theme_type === "light" ? "#32383F" : "#ffffff",
      },
      "& .custom-error-msg-box": {
        maxHeight: "50vh",
        overflowY: "scroll",
        scrollbarWidth: "thin",
        scrollbarColor: "#b1bdd1 #4d4d4d",
        "&::-webkit-scrollbar": {
          width: 5,
          height: 10,
          borderRadius: 3,
          backgroundColor: "#4d4d4d",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          borderRadius: 3,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#b1bdd1",
          borderRadius: 3,
        },
      },
    },
  };
});

export default function AlertTemplate({ options, message, close }) {
  const user = useSelector(state => state.auth?.user);
  const classes = useStyles({
    type: options.type,
    theme_type: user?.theme_type,
  });

  useEffect(() => {
    let alertMask = document.getElementById("__react-alert__")?.children;

    if (alertMask?.length) {
      alertMask = [...alertMask];
      alertMask.map(item => {
        item.onclick = function () {
          close();
        };
      });
    }
  }, []);

  return (
    <div className={classes.alert}>
      <div className={classes.alertHeader}>
        <p className="title">
          {options.type === "error"
            ? "WHOOPS!"
            : options.type === "edit"
            ? "ROGER THAT!"
            : options.type === "success"
            ? "WELL DONE, CHAMP."
            : "Warning"}
        </p>

        <i onClick={close}>
          <IconClose />
        </i>
      </div>

      <div className={classes.alertBody}>
        <i>
          {options.type === "error" ? (
            <img src={ErrorAlert} />
          ) : options.type === "edit" ? (
            <img src={InfoAlert} />
          ) : options.type === "success" ? (
            <img src={SuccessAlert} />
          ) : (
            <img src={WarningAlert} />
          )}
        </i>
        {options.type === "error" && (
          <>
            {Array.isArray(message) ? (
              <div className="custom-error-msg-box">
                {Children.toArray(
                  message.map(msg => (
                    <div className="my-3 custom-error-msg">{msg}</div>
                  )),
                )}
              </div>
            ) : (
              <div className="mt-3">{message}</div>
            )}
            <div
              style={{
                textAlign: "left",
                maxWidth: "90%",
                margin: "10px auto",
              }}
            >
              <p className="text">Well, that’s not what should happen!</p>
            </div>
          </>
        )}
        {options.type === "edit" ? (
          <div style={{ textAlign: "left" }}>
            <p className="text">We’ve made that change for you!</p>
          </div>
        ) : options.type === "success" ? (
          <div>
            <p className="text">
              {options?.extraMessage || "That’s all done!"}
            </p>
          </div>
        ) : (
          <div style={{ textAlign: "left" }}>
            <p className="text">
              Contact the under the hammer team for more information
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
