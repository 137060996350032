import api from "./Api";

export const getWebsiteSetting = () =>
  api.get("/website-setting/index").then(res => res.data);

export const getSingleWebsiteSetting = id =>
  api.get(`/website-setting/show/${id}`).then(res => res.data);

export const updateWebsiteSetting = (id, body) =>
  api.post(`/website-setting/update/${id}`, body).then(res => res.data);
