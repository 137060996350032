import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  buttonWrapper: {
    fontSize: "12px",
    borderRadius: 3,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    "& button": {
      background: theme.theme_type === "light" ? "#F4F4F5" : "#ffffff1a",
      padding: "3px 0",
      margin: "1px 0",
      fontWeight: 500,
      borderRadius: 0,
      flexGrow: 1,

      "&:first-child": {
        borderRadius: "3px 0 0 3px",
        paddingLeft: 3,
      },
      "&:last-child": {
        borderRadius: "0 3px 3px 0",
        paddingRight: 3,
      },

      "&>span": {
        padding: "7px 16px",
        borderRadius: 4,
        cursor: "pointer",
        transition: ".3s",
        display: "block",
      },
    },
    "& button.activeButton>span": {
      background: theme.palette.primary.main,
    },
  },
}));

export default function ButtonsArray({
  buttons,
  active,
  setActive,
  className,
}) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.buttonWrapper, "fit-width", className)}>
      {buttons.map(button => (
        <button
          key={button.value}
          className={clsx(active === button.value && "activeButton")}
          onClick={() => setActive(button.value)}
        >
          <span
            style={{
              background:
                active === button.value ? button.activeBackground : "",
            }}
          >
            {button.label}
          </span>
        </button>
      ))}
    </div>
  );
}

ButtonsArray.propTypes = {
  buttons: PropTypes.array.isRequired,
  active: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
};
