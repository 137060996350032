import { Empty } from "antd";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .ant-empty-image": {
      height: "auto",
    },
    "& .ant-empty-description": {
      color: "#C0CCDA",
      letterSpacing: "-0.03em",
      // textTransform: "uppercase",
      marginTop: 27,
    },
  },
}));

export default function EmptyView({ message, style, children, small = false }) {
  const classes = useStyles();

  return (
    <div className={classes.root} style={style}>
      <Empty
        imageStyle={
          small
            ? {
                height: 60,
              }
            : undefined
        }
        description={<span>{message || "Under maintenance."}</span>}
      >
        <h2>We are updating our services and will be back shortly.</h2>
      </Empty>
    </div>
  );
}
