import { useState, useEffect } from "react";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { Tooltip } from "antd";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  title: {
    // color: "black",
    // height: 0,
    // minHeight: 0,
    textAlign: "center",
    // backgroundColor: theme.backgrounds.black7,
    color: "#fff",
    ...theme.fonts.s14w500,
  },
  overlay: {
    padding: 0,
    "& .ant-tooltip-content": {
      marginTop: -10,
      "& .ant-tooltip-inner": {
        background: theme.backgrounds.black7,
        border: `1px dashed ${theme.backgrounds.white1}`,
        borderRadius: 5,
      },

      "& .ant-tooltip-arrow": {
        display: "none",
      },
    },
  },
}));

export default function PrimaryToolTip({
  title,
  placement = "top",
  color = "white",
  className,
  titleClassName,
  overlayClassName,
  children,
  mediaSize = 900,
  operator = "max",
  visible = true,
  notVisible,
  onClick,
  align = {},
}) {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);

  const deviceMediaQuery = `@media (${operator}-width: ${mediaSize}px)`;
  const matchesToMobileAndTabletSize = useMediaQuery(deviceMediaQuery);
  const putProps = notVisible
    ? { visible: false }
    : matchesToMobileAndTabletSize
    ? { visible: isVisible }
    : visible
    ? {}
    : { visible: false };

  useEffect(() => {
    const closeTooltip = () => setIsVisible(false);
    matchesToMobileAndTabletSize &&
      window.addEventListener("click", closeTooltip);

    return () => {
      matchesToMobileAndTabletSize &&
        window.removeEventListener("click", closeTooltip);
    };
  }, []);

  return (
    <Tooltip
      align={align}
      overlayClassName={clsx(overlayClassName, classes.overlay)}
      title={
        <span
          className={clsx(
            classes.title,
            "primary-tooltip-title",
            titleClassName,
          )}
        >
          {title}
        </span>
      }
      placement={placement}
      color={color}
      {...putProps}
    >
      <section
        className={clsx("wh100 primary-tooltip-wrapper", className)}
        onClick={e => {
          e.stopPropagation();
          onClick?.(e);
          if (matchesToMobileAndTabletSize) setIsVisible(!isVisible);
        }}
      >
        {children}
      </section>
    </Tooltip>
  );
}
