export const avatarUploadStyle = theme => ({
  imgSelector: {
    width: 165,
    height: 165,
    position: "relative",
    margin: "0 auto",
    "& > div": {
      width: "100%",
      height: "100%",
      overflow: "hidden",
      borderRadius: "50%",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
    "& button": {
      width: 45,
      height: 45,
      borderRadius: "50%",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      display: "grid",
      placeItems: "center",
      position: "absolute",
      right: 0,
      bottom: 0,
    },
  },
  errorList: {
    "& li": {
      marginTop: 6,
      ...theme.fonts.s12w700,
      color: theme.colors.red,
    },
  },
});
