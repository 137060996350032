import React, { useRef } from "react";
import { useOnScreen } from "Hooks";
import { motion } from "framer-motion";
import PropTypes from "prop-types";

const FadeUpBox = ({
  children,
  initial = "hidden",
  isFirstTimeRender = true,
  ...restProps
}) => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const transition = {
    duration: 0.3,
    delay: 0,
    ease: "easeIn",
  };

  const variants = {
    hidden: { y: 40, opacity: 0, transition },
    show: {
      y: 0,
      x: 0,
      opacity: 1,
      transition,
    },
  };

  return (
    <motion.div
      ref={ref}
      initial={initial}
      animate={isVisible ? "show" : "hidden"}
      // exit="hidden"
      variants={variants}
      {...restProps}
    >
      {children}
    </motion.div>
  );
};

export default FadeUpBox;

FadeUpBox.propTypes = {
  initial: PropTypes.oneOf(["hidden", "show"]),
};
