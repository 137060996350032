export const imgPickerStyles = theme => ({
  imgPickerWrapper: {
    width: "110px",
    height: "110px",
    borderRadius: "50%",
    position: "relative",
    "& .blank": {
      width: "110px",
      height: "110px",
      borderRadius: "50%",
      overflow: "hidden",
      backgroundColor: "#f0f7fd",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
  },
  pickButton: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#222222 !important",
    border: "none",
  },
  whiteIcon: {
    backgroundColor: "#fff",
    border: "1px solid #BECFE0",
    "& svg path": {
      fill: "#9CACBC !important",
    },
  },
});
