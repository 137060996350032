export const inputsStyles = theme => ({
  // general input style
  input: {
    width: "100%",
    borderRadius: 5,
    padding: "7px 16px",
    ...theme.fonts.s14w500,
    border:
      theme.theme_type === "light"
        ? "1px solid #c1c1c1"
        : "1px solid #FFFFFF50",
    background:
      theme.theme_type === "light" ? "white" : "rgba(19, 21, 27, 0.3)",
    color: theme.theme_type === "light" ? "#333" : "white",
    outline: "none",
    "&::placeholder": {
      color:
        theme.theme_type === "light" ? "#00000033" : "rgba(255,255,255,0.7)",
    },
    "& input": {
      ...theme.fonts.s14w500,
      color: theme.theme_type === "light" ? "#333" : "white",
      "&::placeholder": {
        color:
          theme.theme_type === "light" ? "#00000033" : "rgba(255,255,255,0.7)",
      },
      "&.ant-input": {
        backgroundColor: "transparent",
      },
    },
    "& .ant-input-suffix": {
      "& svg[data-icon]": {
        "& path": {
          fill: "#FFF",
        },
      },
    },
    "& .ant-picker-suffix": {
      "& path": {
        fill: theme.theme_type === "light" ? "#333" : "white",
      },
    },

    "&[disabled]": {
      background: "#c1c1c14d",
      border: "none",
      borderColor: "transparent",
      color: " #ccc",
      cursor: "not-allowed",
      opacity: 1,
    },
  },
  inputTypeColor: {
    display: "flex",
    alignItems: "center",
    "& input[type=color]": {
      width: "100%",
      minHeight: 38,
      border: "none",
      borderRadius: 5,
      background: "none",
      padding: 0,
      cursor: "pointer",
    },
    '& input[type="color"]::-webkit-color-swatch-wrapper': { padding: "0" },
    '& input[type="color"]::-webkit-color-swatch': {
      border: "solid 1px transparent",
      borderRadius: 5,
    },
  },
  autoCompleteInput: {
    height: 38,
    "& .ant-input": {
      padding: "0 !important",
      outline: "none",
      border: "none !important",
      "&::placeholder": {
        color:
          theme.theme_type === "light" ? "#00000033" : "rgba(255,255,255,0.7)",
      },
    },
    "& .ant-input:focus": {
      border: "none !important",
      outline: "none",
      padding: "0 !important",
    },
    "&.input-wrapper-disabled": {
      background: "#c1c1c14d",
      border: "none",
      borderColor: "transparent",
      color: " #ccc",
      cursor: "not-allowed",
      opacity: 1,
    },
  },

  // price input
  priceInputWrapper: {
    position: "relative",
    "& > svg": {
      position: "absolute",
      top: 7,
      left: 6,
      width: 18,
      "& path": {
        fill: theme.theme_type === "light" ? "#333" : "#fff",
      },
    },
    "&.input-wrapper-disabled .ant-input-number": {
      background: "#c1c1c14d",
      border: "none",
      borderColor: "transparent",
      color: " #ccc",
      cursor: "not-allowed",
      opacity: 1,
      "& input": {
        color: "#ccc",
      },
    },
  },
  inputWithLeftIcon: {
    padding: "3px 6px 3px 24px",
    "& input": {
      ...theme.fonts.s14w500,
    },
  },
  // number input
  numberInputWrapper: {
    position: "relative",
    "& > svg": {
      position: "absolute",
      top: 10,
      left: 6,
      "& path": {
        fill: theme.theme_type === "light" ? "#333" : "#fff",
      },
    },
    "&.input-wrapper-disabled .ant-input-number": {
      background: "#c1c1c14d",
      border: "none",
      borderColor: "transparent",
      color: " #ccc",
      cursor: "not-allowed",
      opacity: 1,
      "& input": {
        color: "#ccc",
      },
    },
  },
  inputWithoutLeftIcon: {
    padding: "3px 6px 3px 6px",
    "& input": {
      ...theme.fonts.s14w500,
    },
  },
  // select input
  select: {
    "& .ant-select-selector": {
      width: "100% !important",
      minHeight: "38px !important",
      borderRadius: "5px !important",
      padding: "3px 16px !important",
      color: theme.theme_type === "light" ? "#333" : "white",
      backgroundColor:
        theme.theme_type === "light"
          ? "white"
          : "rgba(19, 21, 27, 0.3) !important",
      border:
        theme.theme_type === "light"
          ? "1px solid #c1c1c1 !important"
          : "1px solid #FFFFFF50 !important",
      ...theme.fonts.s14w500,
    },
    "& .ant-select-selection-item-content": {
      color: "#000",
    },
    "& .ant-select-arrow": {
      "& path": {
        fill: theme.theme_type === "light" ? "#333" : "#fff",
      },
    },
  },
  selectDropdown: {
    background: theme.theme_type === "light" ? "white" : "#333939",
    "& .ant-select-item-option-selected:not(.ant-select-item-option-disabled)":
      {
        background: "rgba(255, 255, 255, 0.1)",
      },
    "& .ant-select-item": {
      color: theme.theme_type === "light" ? "#333" : "white",
      background: "transparent",
    },
  },
  selectOption: {
    "&:hover": {
      backgroundColor: theme.backgrounds.white1,
    },
  },
  // checkbox
  checkbox: {
    ...theme.fonts.s14w700,
    "& > span": {
      color: theme.theme_type === "light" ? "#333" : "#fff",
      ...theme.fonts.s14w500,
    },
    "& .ant-checkbox-inner": {
      width: 20,
      height: 20,
    },
    "& .ant-checkbox-inner::after": {
      left: "30%",
    },
    "& .ant-checkbox-checked .ant-checkbox-inner": {
      borderColor: theme.colors.main,
      background: theme.colors.main,
    },
  },

  // gray icon for input
  inputGrayIcon: {
    "& input": {
      background: "transparent",
    },
    "& .ant-input-suffix": {
      left: 5,
      position: "relative",
      "& .spinner_spin_wrapper": {
        left: -10,
        top: -5,
      },
      "& path": {
        fill: theme.theme_type === "light" ? "#333" : "#fff",
      },
    },
  },
  root_birthday: {},
  birthday_error: {
    background: "transparent !important",
    border: "1px solid #FF0046 !important",
    borderRadius: 3.5,
  },
});
