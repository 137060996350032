import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

// components
import AddAlertsModal from "./AddAlertsModal";
import { PrimaryToolTip } from "Components/ToolTip";
import ConfirmDeleteModal from "Components/Modal/ConfirmDeleteModal";
import Table from "Components/Table/Table";
import Breadcrumb from "Components/ui/Breadcrumb";

// services
import { getBuyerAlertsApi, deleteBuyerAlertsApi } from "Services";

//styles
import { alertsStyles } from "./alerts.styles";

// assets
import { ReactComponent as IconAlertBox } from "Assets/img/icons/icon_alert-box.svg";
import { ReactComponent as IconDelete } from "Assets/img/icons/icon_delete.svg";
import { truncate } from "Utils/helperFunction";
import alert from "Utils/alert";
import { PlusOutlined } from "@ant-design/icons";

const useStyles = makeStyles(alertsStyles);

export default function AlertPage() {
  const classes = useStyles();
  const [reload, setReload] = useState();

  const [openAddModal, setOpenAddModal] = useState(false);
  const handle_closeAddModal = () => setOpenAddModal(false);
  const handle_openAddModal = () => setOpenAddModal(true);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handle_closeDeleteModal = () => setOpenDeleteModal(false);
  const handle_openDeleteModal = item => setOpenDeleteModal(item);

  const switchReload = () => setReload(!reload);

  const handle_delete = async () => {
    await deleteBuyerAlertsApi(openDeleteModal.id)
      .then(res => {
        switchReload();
        handle_closeDeleteModal();
        alert.success();
      })
      .catch(err => {});
  };

  const columns = [
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      align: "center",
      expandAlign: "center",
      width: 365,
      sort: "location",
      render: ({ text, record }) => {
        return (
          <div className={classes.fontStyle}>
            <PrimaryToolTip title={record.location}>
              {truncate(record.location, 40, "...")}
            </PrimaryToolTip>
          </div>
        );
      },
    },
    {
      title: "Radius",
      dataIndex: "radius",
      key: "radius",
      align: "center",
      width: 166,
      sort: "radius",
      render: ({ text, record }) => {
        return <div className={classes.fontStyle}>{record.radius} mile</div>;
      },
    },
    {
      title: "Min price",
      dataIndex: "min_price",
      key: "min_price",
      align: "center",
      width: 166,
      sort: "min_price",
      render: ({ text, record }) => {
        return (
          <div className={classes.fontStyle}>
            {record.min_price?.toLocaleString()
              ? `£ ${record.min_price?.toLocaleString()}`
              : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Max price",
      dataIndex: "max_price",
      key: "max_price",
      align: "center",
      width: 166,
      sort: "max_price",
      render: ({ text, record }) => {
        return (
          <div className={classes.fontStyle}>
            {record.max_price?.toLocaleString()
              ? `£ ${record.max_price?.toLocaleString()}`
              : "N/A"}
          </div>
        );
      },
    },
    {
      title: "Min \nbedroom(s)",
      dataIndex: "min",
      key: "min",
      align: "center",
      width: 60,
      sort: "min_bedrooms",
      render: ({ text, record }) => {
        return (
          <div className={classes.fontStyle}>
            {record.min_bedrooms === null ? "any" : record.min_bedrooms}
          </div>
        );
      },
    },
    {
      title: "Max \nbedroom(s)",
      dataIndex: "max",
      key: "max",
      align: "center",
      width: 60,
      sort: "max_bedrooms",
      render: ({ text, record }) => {
        return (
          <div className={classes.fontStyle}>
            {record.max_bedrooms === null ? "any" : record.max_bedrooms}
          </div>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
      width: 145,
      render: ({ text, record }) => {
        return (
          <div className={classes.fontStyle}>{record?.type?.type ?? "any"}</div>
        );
      },
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      width: 60,
      render: ({ record }) => {
        return (
          <div className={classes.eyeIcon}>
            <PrimaryToolTip title={"Delete"} className={classes.icons}>
              <button
                className="d-flex"
                onClick={() => handle_openDeleteModal(record)}
              >
                <IconDelete />
              </button>
            </PrimaryToolTip>
          </div>
        );
      },
    },
  ];

  const handleRequest = ({ page, ...prevBody }, sendRequest) => {
    const body = {
      ...prevBody,
      order_type: "desc",
      offset: page,
    };

    sendRequest(body);
  };

  return (
    <div className={clsx(classes.root, "container")}>
      <Breadcrumb icon={<IconAlertBox />} title={["alerts"]} />
      <Table
        columns={columns}
        breakpoints="@media (max-width: 1200px)"
        tableClasses={{ baseTable: classes.baseTable }}
        api={getBuyerAlertsApi}
        mobileColumnsKey={["location", "delete"]}
        handleRequest={handleRequest}
        showSearch={true}
        reload={reload}
        buttons={[
          <button
            className={classes.addAlertBtn}
            onClick={() => handle_openAddModal()}
          >
            Create new alert
            <PlusOutlined style={{ marginLeft: 8 }} />
          </button>,
        ]}
      />

      {openAddModal && (
        <AddAlertsModal
          isVisible={openAddModal}
          handleCancel={handle_closeAddModal}
          setReload={switchReload}
        />
      )}

      {openDeleteModal && (
        <ConfirmDeleteModal
          isVisible={openDeleteModal}
          handleCancel={handle_closeDeleteModal}
          handleDelete={handle_delete}
          modalText={"Are you sure you want to delete this alert?"}
        />
      )}
    </div>
  );
}
