import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { SpinnerRoundFilled } from "spinners-react";

const useStyles = makeStyles(theme => ({
  spin_root: {
    position: "relative",
  },

  spin_wrapper: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    display: "flex",
    textAlign: "center",
    fontSize: 16,
    // background: "rgba(0,0,0,0.7)",
    zIndex: 1500,
    transition: "opacity 500ms ease-in",
  },

  spin: {
    margin: "0 auto",
    "&>div": {
      top: ({ size }) => `calc(min(30vh, 50%) - ${size / 2}px)`,
      margin: "0 auto",
      position: "relative",
      maxHeight: "100%",
      "& svg": {
        color:
          theme.theme_type === "light"
            ? "#333333 !important"
            : "white !important",
      },
    },
  },
}));

export default function Spin({
  children,
  spinning,
  tip,
  size = 70,
  parentSpinClassName,
  ...restProps
}) {
  const classes = useStyles({ size });

  return (
    <div
      className={clsx(
        classes.spin_root,
        "spinner_spin_root",
        parentSpinClassName,
      )}
    >
      {spinning && (
        <div className={clsx(classes.spin_wrapper, "spinner_spin_wrapper")}>
          <div className={clsx(classes.spin, "spinner_spin")}>
            <div>
              <SpinnerRoundFilled
                size={size}
                thickness={180}
                speed={73}
                {...restProps}
              />
            </div>
            {tip && <div>{tip}</div>}
          </div>
        </div>
      )}
      <div
        style={{
          opacity: spinning ? 0.3 : 1,
          width: "100%",
          height: "100%",
        }}
        className="spinner_children_wrapper"
      >
        {children}
      </div>
    </div>
  );
}
