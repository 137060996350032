import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { inputsStyles } from "./styles/inputs.style";
import clsx from "clsx";
import moment from "moment";

import NumberFormat from "react-number-format";

const useStyles = makeStyles(inputsStyles);

const birthDayDefaultFormatter = data => {
  if (data?.length) {
    return data?.slice(0, 10)?.split("-")?.reverse()?.join("");
  } else return "";
};

export default function BirthdayInput({
  style,
  rootClassName,
  className,
  name,
  form,
  onChange,
  disabled,
}) {
  const classes = useStyles();

  const [birthday, setBirthday] = useState(() =>
    birthDayDefaultFormatter(form.getFieldValue(name)),
  );

  const handleChange = value => {
    // format: 02/02/1999
    const formattedDate = moment(value, "DDMMYYYY").format("YYYY-MM-DD");

    setBirthday(value);

    form.setFieldsValue({
      [name]: formattedDate,
    });

    onChange?.(value, formattedDate);
  };

  return (
    <div
      className={clsx(classes.root_birthday, "root_birthday", rootClassName)}
    >
      <NumberFormat
        format="##/##/####"
        placeholder="--/--/----"
        mask="-"
        className={clsx(
          classes.input,
          classes.inputWithoutLeftIcon,
          className,
          "birthday-error",
        )}
        onValueChange={e => handleChange(e.value)}
        defaultValue={birthday}
        style={Object.assign({ padding: "8px" }, style)}
        disabled={disabled || false}
      />
    </div>
  );
}
