import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

// Components
import Breadcrumb from "Components/ui/Breadcrumb";
import Table from "Components/Table/Table";
import { PrimaryToolTip } from "Components/ToolTip";

// services
import {
  getWatchListsApi,
  postWatchListStatusToggle,
} from "Services/traderApi";

// utils
import { truncate } from "Utils/helperFunction";
import { primaryTimeFormat } from "Utils/moment";
import alert from "Utils/alert";

// assets
import { ReactComponent as IconEyeBox } from "Assets/img/icons/icon_eye_rect.svg";
import { ReactComponent as WhiteHeartIcon } from "Assets/img/icons/icon_heart_white.svg";
import { ReactComponent as YellowHeartIcon } from "Assets/img/icons/icon_heart_yellow.svg";

// styles
import { watchListStyles } from "./watchList.styles";
import Spin from "../../../Components/Spinner/Spin";
import InfoBoxTooltip from "../../../Components/InfoBox/InfoBoxTooltip";

const useStyles = makeStyles(watchListStyles);

export default function WatchListPage() {
  const classes = useStyles();
  const [reload, setReload] = useState(true);
  const [loading, setLoading] = useState(false);

  const handle_watchList = async id => {
    setLoading(true);
    await postWatchListStatusToggle(id)
      .then(res => {
        setReload(!reload);
        alert.success();
      })
      .catch(err => console.error(err));
    setLoading(false);
  };

  const columns = [
    {
      dataIndex: "img",
      key: "img",
      title: "Image",
      bodyPadding: 8,
      width: 85,
      render: ({ record }) => (
        <div className={classes.buildingImg}>
          <img src={record.thumbnail_image} alt="" />
        </div>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      align: "center",
      expandAlign: "center",
      width: 437,
      render: ({ text, record }) => {
        return (
          <div className={classes.fontStyle}>
            {truncate(record?.first_line_of_address, 40, "...")}
          </div>
        );
      },
    },
    {
      title: "Guide price",
      dataIndex: "guide_price",
      key: "guide_price",
      align: "center",
      width: 307,
      sort: "guide_price",
      render: ({ text, record }) => {
        return (
          <div className={classes.fontStyle}>
            £ {record?.guide_price?.toLocaleString()}
          </div>
        );
      },
    },
    {
      title: "Time left",
      dataIndex: "time_left",
      key: "time_left",
      align: "center",
      width: 284,
      sort: "auction_starts_at",
      render: ({ text, record }) => {
        return (
          <div className={classes.fontStyle}>
            {record?.auction_date_to_be_confirmed
              ? "Auction date to be confirmed"
              : primaryTimeFormat(record?.auction_starts_at)
            }
          </div>
        );
      },
    },
    {
      title: "Watch",
      dataIndex: "watch",
      key: "watch",
      align: "center",
      width: 60,
      render: ({ record }) => {
        return (
          <div className={classes.eyeIcon}>
            <PrimaryToolTip
              title={"Remove from watching list"}
              className={classes.icons}
            >
              <button
                className="d-flex"
                onClick={() => handle_watchList(record?.id)}
              >
                {record?.status === "active" ? (
                  <YellowHeartIcon />
                ) : (
                  <WhiteHeartIcon />
                )}
              </button>
            </PrimaryToolTip>
          </div>
        );
      },
    },
  ];

  const handleRequest = ({ page, ...prevBody }, sendRequest) => {
    sendRequest({
      ...prevBody,
      offset: page,
    });
  };

  return (
    <div className={clsx(classes.root, "container")}>
      <Breadcrumb icon={<IconEyeBox />} title={["watching list"]} />
      <Spin spinning={loading}>
        <Table
          columns={columns}
          breakpoints="@media (max-width: 1200px)"
          tableClasses={{ baseTable: classes.baseTable }}
          mobileColumnsKey={["address", "watch"]}
          handleRequest={handleRequest}
          api={getWatchListsApi}
          showSearch={true}
          reload={reload}
        />
      </Spin>
    </div>
  );
}
