import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Table from "Components/Table/Table";
import Breadcrumb from "Components/ui/Breadcrumb";
import { getDocs } from "Services";
import { ReactComponent as OpenEyeIcon } from "Assets/img/icons/icon_eye-f.svg";
import { ReactComponent as IconInvoice } from "Assets/img/icons/icon_note_invoices.svg";
import { primaryTimeFormat } from "Utils/moment";
import ViewDocsModal from "./ViewDocsModal";
import clsx from "clsx";
import { NAString } from "../../../Utils/helperFunction";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%",
    padding: "0 0px 170px",

    "& .ant-table-tbody > tr": {
      "& > td": {
        padding: "0",
        "@media (max-width: 1200px)": {
          "&:first-child": {
            borderRightWidth: 0,
          },
        },
      },
    },

    "& .tableWrapper": {
      minHeight: "9.4vh",
    },
  },
  breadCrumb: {
    "& .icon": {
      background: "#FFFFFF1A",
    },
    [theme.media(576)]: {
      paddingBlock: "0px 30px",
    },
  },
  editBtnWrapper: {
    width: "100%",
    height: "100%",
    padding: "0 !important",
    backgroundColor: "transparent",
    transition: "all .3s",
    "& svg path": {
      fill: "rgba(0, 0, 0, 0.5)",
      transition: "all .3s",
    },
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      "& svg path": {
        fill: theme.palette.primary.main,
      },
    },
  },
}));

export default function DocsPage() {
  const classes = useStyles();

  const [showViewModal, setShowViewModal] = useState(false);
  const handle_openViewModal = item => setShowViewModal(item);
  const handle_closeViewModal = () => setShowViewModal(false);

  const columnsAction = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      align: "center",
      expandAlign: "center",
      bodyPadding: false,
      width: 50,
      render: ({ text, record }) => {
        return (
          <span className={clsx("cell_type_1")}>{NAString(record.id)}</span>
        );
      },
    },
    {
      title: "Date & Time",
      dataIndex: "date_time",
      key: "date_time",
      align: "center",
      width: 400,
      render: ({ text, record }) => {
        return <span>{primaryTimeFormat(record.created_at)}</span>;
      },
    },
    {
      title: "View",
      dataIndex: "view",
      key: "view",
      align: "center",
      width: 60,
      render: ({ record }) => {
        return (
          <div className={classes.editBtnWrapper}>
            <button
              className="d-flex"
              onClick={() => handle_openViewModal(record)}
            >
              <OpenEyeIcon />
            </button>
          </div>
        );
      },
    },
  ];

  const handleRequest = ({ page: offset, search, ...rest }, sendRequest) => {
    sendRequest({
      offset,
      search,
      ...rest,
    });
  };

  return (
    <div className={classes.root}>
      {showViewModal && (
        <ViewDocsModal
          open={showViewModal}
          handleCancel={handle_closeViewModal}
        />
      )}

      <div className={"container"}>
        <Breadcrumb
          className={classes.breadCrumb}
          icon={<IconInvoice />}
          title={["AML Docs"]}
        />

        <div className="tableWrapper">
          <Table
            columns={columnsAction}
            breakpoints="@media (max-width: 1200px)"
            handleRequest={handleRequest}
            api={getDocs}
            // dataSource={[
            //   {
            //     id: 6,
            //     identification_path: "awda",
            //     proof_of_funds_path: "awda",
            //     created_at: "2021-12-23T09:06:20.000000Z",
            //     updated_at: "2021-12-23T09:06:20.000000Z",
            //     identification_url:
            //       "https://s3.eu-west-2.amazonaws.com/uth-storage/awda?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA4SG7TUY3AL2ILWZU%2F20211223%2Feu-west-2%2Fs3%2Faws4_request&X-Amz-Date=20211223T121324Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1200&X-Amz-Signature=8b9a6a17fb7b9e9b8e12dac4717733006bc1b69e45e63041bf53811180074d9d",
            //     proof_of_funds_url:
            //       "https://s3.eu-west-2.amazonaws.com/uth-storage/awda?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA4SG7TUY3AL2ILWZU%2F20211223%2Feu-west-2%2Fs3%2Faws4_request&X-Amz-Date=20211223T121324Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1200&X-Amz-Signature=8b9a6a17fb7b9e9b8e12dac4717733006bc1b69e45e63041bf53811180074d9d",
            //   },
            // ]}
            showSearch={false}
            mobileColumnsKey={["date_time", "view"]}
          />
        </div>
      </div>
    </div>
  );
}
