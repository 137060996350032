import ReactDOM from "react-dom";
import { transitions, Provider as AlertProvider } from "react-alert";

import AlertTemplate from "./AlertTemplate";
import ShowAlert from "./ShowAlert";
import "./allert.scss";

//Redux
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "Store/store";

const options = {
  position: "middle",
  timeout: 0,
  transition: transitions.SCALE,
};

let mountPoint;
mountPoint = window.document.createElement("div");
window.document.body.appendChild(mountPoint);

export default function alert(message, extraOptions = {}) {
  if (mountPoint)
    ReactDOM.render(
      <Provider store={store}>
        <PersistGate
          persistor={persistor}
          loading={<>loading persist gate ...</>}
        >
          <AlertProvider template={AlertTemplate} {...options}>
            <ShowAlert message={message} {...extraOptions} />
          </AlertProvider>
        </PersistGate>
      </Provider>,
      mountPoint,
    );
}

alert.success = (message, extraOptions = {}) => {
  alert(message, { type: "success", ...extraOptions });
};
alert.info = (message, extraOptions = {}) => {
  alert(message, { type: "info", ...extraOptions });
};
alert.error = (message, extraOptions = {}) => {
  alert(message, { type: "error", ...extraOptions });
};

alert.warning = (message, extraOptions = {}) => {
  alert(message, { type: "warning", ...extraOptions });
};

alert.edit = (message, extraOptions = {}) => {
  alert(message, { type: "edit", ...extraOptions });
};
