import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { topNavStyle } from "../styles/navigationbar.styles";
import Badge from "@material-ui/core/Badge";
import Dropdown from "Components/Dropdown";
import ButtonsArray from "Components/Button/ButtonsArray";
import { useSelector } from "react-redux";
import { getUserNotificationsForNavbar } from "Services";
import moment from "moment";
import EmptyView from "Components/ResultPage/EmptyView";

// Icons
import { ReactComponent as NotificationIcon } from "Assets/img/icons/icon_notication.svg";
import {mainRole} from "../../../../Utils/roles";

const useStyles = makeStyles(topNavStyle);

export default function Notification() {
  const classes = useStyles();
  const history = useHistory();
  const userRole = useSelector(store => mainRole(store?.auth?.user));

  const { notificationUpdate } = useSelector(state => state?.auth);

  // Get notifications
  const [allNotificationData, setAllNotificationData] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [countNewNotifications, setCountNewNotifications] = useState(0);
  const [isNewNotifications, setIsNewNotifications] = useState(false);
  const [status, setStatus] = useState("");
  const getNotifications = async () => {
    await getUserNotificationsForNavbar()
      .then(res => {
        setAllNotificationData(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    // for admin we don't have internal notification
    if (userRole?.includes("admin")) {
      setStatus("applicant");
    } else {
      // trader don't have applicant notification
      setStatus("internal");
    }
    getNotifications();
  }, [userRole, notificationUpdate]);

  const handleNotificationType = () => {
    setIsNewNotifications(
      allNotificationData.internal?.unseen_counts ||
        allNotificationData.applicant?.unseen_counts,
    );
    if (status === "applicant") {
      setNotifications(allNotificationData?.applicant?.items || []);
      setCountNewNotifications(
        allNotificationData.applicant?.unseen_counts || 0,
      );
    }

    if (status === "internal") {
      setNotifications(allNotificationData?.internal?.items || []);
      setCountNewNotifications(
        allNotificationData.internal?.unseen_counts || 0,
      );
    }
  };

  useEffect(() => {
    handleNotificationType();
  }, [status, allNotificationData]);

  const moveToNotificationPage = () => {
    history.push(`/${userRole}/notifications`);
    setToggleDropDown(false);
  };

  const [toggleDropDown, setToggleDropDown] = useState(false);
  return (
    <Dropdown
      closeOnClick={false}
      overlayClassName={classes.buttonsDropdown}
      placement="bottomCenter"
      isVisible={toggleDropDown}
      onVisibleChange={visible => setToggleDropDown(visible)}
      overlay={
        <div className="topnav-notifications">
          <div>
            <div
              className={classes.notificationNavButton}
              onClick={() => moveToNotificationPage()}
            >
              NOTIFICATIONS
              {!!countNewNotifications && (
                <span>{countNewNotifications} new</span>
              )}
            </div>
            {userRole !== "buyer" &&
              userRole !== "seller" &&
              !userRole?.includes("admin") && (
                <ButtonsArray
                  buttons={[
                    { label: "Internal", value: "internal" },
                    { label: "Applicant", value: "applicant" },
                  ]}
                  active={status}
                  setActive={setStatus}
                />
              )}
          </div>

          <div className="custom-scrollbar">
            {notifications?.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <EmptyView small message="No Notification" />
              </div>
            ) : (
              notifications?.map(item => (
                <div key={item?.id}>
                  <div>{item?.subject}</div>
                  <div>
                    {moment(item?.created_at).isValid()
                      ? moment(item?.created_at).format("hh:mm:a")
                      : ""}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      }
    >
      <Badge variant="dot" overlap="circle" invisible={!isNewNotifications}>
        <button>
          <NotificationIcon />
        </button>
      </Badge>
    </Dropdown>
  );
}
