export const baseTableStyle = theme => ({
  table_root: {
    "& .ThirdlyUI-table-wrapper": {
      "& div.cell": {
        width: "100%",
        height: "50px",
        background: theme.theme_type === "light" ? "white" : "#0000004d",
        boxShadow: "0px 0px 10px #00000033",
        padding: "0px",
        boxSizing: "border-box",
        borderRadius: "3px",
        textAlign: "center",
        fontWeight: "bold",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        "@media only screen and (max-width: 600px)": {
          height: 80,
        },
      },
      "& div.cell-mobile": {
        width: "100%",
        height: "50px",
        background: theme.theme_type === "light" ? "white" : "#0000004d",
        boxShadow: "0px 0px 10px #00000033",
        padding: "0",
        boxSizing: "border-box",
        borderRadius: "3px",
        textAlign: "center",
        fontWeight: "bold",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        margin: "8px 0",
        "& > :first-child": {
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        },
      },
      "& div.cell-mobile-head": {
        width: "100%",
        height: "50px",
        backgroundColor: "transparent",
        padding: "0",
        boxSizing: "border-box",
        borderRadius: "3px",
        textAlign: "center",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        overflow: "hidden",
        margin: "8px 4px 8px 0",
        "& > :first-child": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },

    "& .ant-table": {
      background: "transparent",
      color: theme.theme_type === "light" ? "#333333" : "white",
      lineHeight: 1.1,
      "& .ant-table-thead > tr > th": {
        background: "transparent",
        padding: "16px",
        textAlign: "center",
        fontSize: "14px",
        fontWeight: 600,
        color: theme.theme_type === "light" ? "#333333" : "white",
        borderWidth: "0px",
      },
      "& .ant-table-thead > tr > th::before": {
        backgroundColor: "transparent !important",
      },
      "& .primary-tooltip-wrapper": {
        "& svg": {
          cursor: "pointer",
        },
        "& path[fill]": {
          fill: theme.theme_type === "light" ? "#333333" : "white",
        },
        "& path[stroke]": {
          stroke: theme.theme_type === "light" ? "#333333" : "white",
        },
        "&:hover": {
          "& path[fill]": {
            fill: theme.palette.primary.main,
          },
          "& path[stroke]": {
            stroke: theme.palette.primary.main,
          },
        },
      },
    },
  },

  cellAlign_left: {
    "& > :first-child": {
      justifyContent: "flex-start",
    },
  },
  cellAlign_center: {
    "& > :first-child": {
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
    },
  },
  cellAlign_right: {
    "& > :first-child": {
      justifyContent: "flex-end",
    },
  },
  bodyPadding: {
    "& > :first-child": {
      paddingRight: 16,
      paddingLeft: 16,
    },
  },
  theadItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sortButtons: {
    "& path": {
      fill: theme.theme_type === "light" ? "#333" : "#fff",
    },
  },
});
