export const watchListStyles = theme => ({
  roleList_root: {
    "& .header": {
      marginTop: 60,
      "& .filter_select": {
        "& label": {
          ...theme.fonts.s14w500,
          color: "#7E7E7E",
        },
      },
      "& .ant-select-single:not(.ant-select-customize-input) .ant-select-selector ":
        {
          border: "1px solid #E9F0F7",
          borderRadius: 2.5,
          padding: "4px 16px",
          height: 40,
        },
      "& .ant-input:focus, .ant-input-focused, ,.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector":
        {
          borderColor: theme.palette.primary.main,
          boxShadow: "unset",
        },
      "& .ant-select-arrow": {
        top: "35%",
      },
    },
  },
  fontStyle: {
    ...theme.fonts.s14w500,
  },
  imgDiv: {
    ...theme.imgDiv(44, 44, 2, true),
    "& img": {
      ...theme.img100,
    },
  },
  status: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    height: "100%",
    width: "100%",
  },
  buildingImg: {
    borderRadius: 2,
    width: 84,
    height: 44,
    backgroundSize: "cover",
    backgroundPosition: "center",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  eyeIcon: {
    width: "100%",
    height: "100%",
    padding: "0 !important",
    backgroundColor: "transparent",
    transition: "all .3s",
    "& svg path": {
      transition: "all .3s",
      fill: "#F9B914 !important",
    },
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
    "& .primary-tooltip-wrapper": {
      display: "grid",
      placeItems: "center",
    },
  },
});
