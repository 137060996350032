import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

import { Upload, message, Button } from "antd";
import { ReactS3Client } from "Utils/awsConfig";

// icons
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { ReactComponent as EditIcon } from "Assets/img/icons/icon_pencil-gray.svg";

import { imgPickerStyles } from "./imagepicker.styles";
import clsx from "clsx";

const useStyles = makeStyles(imgPickerStyles);

// function getBase64(img, callback) {
//   const reader = new FileReader();
//   reader.addEventListener("load", () => callback(reader.result));
//   reader.readAsDataURL(img);
// }

// validation before upload
function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

const ImagePicker = ({
  uploadHandler = "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  defaultImageUrl,
  updateProfile,
  noPicker = false,
  whiteChangeImageIcon,
  uploadInAWS,
  setImage,
}) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null || defaultImageUrl);

  const handleChange = info => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "error") {
      updateProfile();
      setLoading(false);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      updateProfile();
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    setImageUrl(defaultImageUrl);
  }, [defaultImageUrl]);

  // const uploadProps
  const classes = useStyles();

  const handleUploadInAws = async file => {
    setLoading(true);
    await ReactS3Client.uploadFile(file, file.name)
      .then(data => {
        if (setImage) setImage(data?.location);
      })
      .catch(err => {
        console.error(err);
      });
    setLoading(false);
  };

  return (
    <div className={classes.imgPickerWrapper}>
      <Upload
        name="avatar"
        showUploadList={false}
        action={uploadInAWS ? handleUploadInAws : uploadHandler}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        <div className="blank">
          {loading ? (
            <LoadingOutlined />
          ) : (
            imageUrl && <img src={imageUrl} alt="avatar" />
          )}
        </div>
        {!noPicker && (
          <Button
            className={clsx([
              classes.pickButton,
              whiteChangeImageIcon && classes.whiteIcon,
            ])}
            icon={!imageUrl ? <PlusOutlined color="#fff" /> : <EditIcon />}
          />
        )}
      </Upload>
    </div>
  );
};

ImagePicker.propTypes = {
  uploadHandler: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  defaultImageUrl: PropTypes.string,
};

export default ImagePicker;
