import axios from "./Api";
import axiosMain from "./ApiMain";

/*###############new endpoints after merging endpoints#############*/
export const getDashboard = () => axiosMain.get("/dashboard").then(res => res.data);
/*###############end new endpoints after merging endpoints#############*/


// DASHBOARD
// export const getDashboard = () => axios.get("/dashboard").then(res => res.data);

export const getDashboardAdmin1 = () =>
  axios.get("/dashboard/section_i").then(res => res.data);
export const getDashboardAdmin2 = () =>
  axios.get("/dashboard/section_ii").then(res => res.data);
export const getDashboardAdmin3 = () =>
  axios.get("/dashboard/section_iii").then(res => res.data);

export const updateReminderTime = body =>
  axios.post("/profile/update-reminder", body).then(res => res.data);

export const getDashboardTotalCommissions = body =>
  axios.post("/dashboard/total-commissions", body).then(res => res.data);

export const getDashboardNetIncome = body =>
  axios.post("/dashboard/net-income", body).then(res => res.data);

export const getDashboardNetIncomeAdmin = params =>
  axios.get("/dashboard/chart", { params }).then(res => res.data);

export const getDashboardOutstandingTasks = ({ page = 0, ...params }) =>
  axios
    .get("/dashboard/outstanding-tasks", {
      params: { offset: page, ...params },
    })
    .then(res => res.data);

// REALTIME
export const getRealtime = () => axios.get("/real-time").then(res => res.data);

export const getRealtimeAdmin1 = () =>
  axios.get("/real-time/section_i").then(res => res.data);
export const getRealtimeAdmin2 = () =>
  axios.get("/real-time/section_ii").then(res => res.data);

export const getRealtimeVBP = body =>
  axios.post("/real-time/viewers-by-properties", body).then(res => res.data);

export const getRealtimeVBPAdmin = params =>
  axios
    .get("/real-time/viewers-by-properties", { params })
    .then(res => res.data);

export const getRealtimeActivities = body =>
  axios.post("/real-time/activities", body).then(res => res.data);

export const getRealtimeActivitiesAdmin = params =>
  axios.get("/real-time/recent-activities", { params }).then(res => res.data);

export const getRealtimeWhoIsWatching = body =>
  axios.post("/real-time/who-is-watching", body).then(res => res.data);

// amdin dashboard filters
export const getDashboardAuctionsOpen = ({ page, ...params }) =>
  axios
    .get("/dashboard/auctions-open-and-bid", {
      params: { offset: page || 0, ...params },
    })
    .then(res => res.data);

export const getDashboardSoldLots = ({ page, ...params }) =>
  axios
    .get("/dashboard/sold-lots", {
      params: { offset: page || 0, ...params },
    })
    .then(res => res.data);

export const getDashboardUpcomingViewing = ({ page, ...params }) =>
  axios
    .get("/dashboard/upcoming-viewings", {
      params: { offset: page || 0, ...params },
    })
    .then(res => res.data);

export const getDashboardPushedLive = ({ page, ...params }) =>
  axios
    .get("/dashboard/pushed-live", {
      params: { offset: page || 0, ...params },
    })
    .then(res => res.data);

export const getDashboardApplicantNotifications = ({ page, ...params }) =>
  axios
    .get("/dashboard/applicant-notifications", {
      params: { offset: page || 0, ...params },
    })
    .then(res => res.data);

export const getDashboardMap = ({ page, ...params }) =>
  axios
    .get("/dashboard/map", {
      params: { offset: page || 0, ...params },
    })
    .then(res => res.data);

export const getDashboardPropertyEdited = ({ page, ...params }) =>
  axios
    .get("/audits/property-edits", {
      params: { offset: page || 0, ...params },
    })
    .then(res => res.data);

export const getDashboardOutstandingTasksFilters = ({ page, ...params }) =>
  axios
    .get("/dashboard/outstanding-tasks-with-filter", {
      params: { offset: page || 0, ...params },
    })
    .then(res => res.data);
