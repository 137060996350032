import React from "react";
import { makeStyles } from "@material-ui/core";
import { Input } from "antd";
import { inputsStyles } from "./styles/inputs.style";
import clsx from "clsx";

const useStyles = makeStyles(inputsStyles);

export default function NormalInput({
  placeholder,
  className,
  defaultValue,
  form,
  name,
  onChange,
  ...restProps
}) {
  const classes = useStyles();

  const handleChange = e => {
    form.setFieldsValue({
      [name]: e.target.value,
    });

    if (onChange) onChange(e.target.value, name);
  };
  return (
    <div className={classes.inputTypeColor}>
      <Input
        type="color"
        className={className || ""}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={handleChange}
        {...restProps}
      />
    </div>
  );
}
