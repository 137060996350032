import { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import { ScrollingPagination } from "Components/ScrollerBox";
import { Input } from "antd";
import anonImage from "Assets/img/anon.png";
import Dropdown from "Components/Dropdown";
import { filterTeamMemberStyle } from "../modal.styles";

// Icons
import { ReactComponent as IconArrowDownMini } from "Assets/img/icons/icon_arrow-down-mini.svg";
import { ReactComponent as IconUser } from "Assets/img/icons/icon_User.svg";
import { ReactComponent as IconExpand } from "Assets/img/icons/icon_expand.svg";
import { ReactComponent as IconSelect } from "Assets/img/icons/icon_Select.svg";
import { ReactComponent as IconSearch } from "Assets/img/icons/icon_Search.svg";

import { getAgentTeamsMember } from "Services";

const useStyles = makeStyles(filterTeamMemberStyle);

export default function FilterTeamMember({ setSelectedIds }) {
  const classes = useStyles();
  const [userList, setUserList] = useState([]);
  const [allCheck, setAllCheck] = useState("checked"); // indetermine not-checked

  // for fit overlay width with trigger
  const triggerRef = useRef();

  const [search, setSearch] = useState("");
  const [searchPaginationEnable, setSearchPaginationEnable] = useState(false);
  useEffect(() => {
    if (!search) {
      setSearchPaginationEnable(false);
    }
  }, [search]);

  const apiHandler = ({
    page,
    setPage, // use for search input to reset page to 0
    search,
    setPermission,
    setLoading,
  }) => {
    setLoading(true);
    if (search && !searchPaginationEnable) {
      setPage(0);
    }
    getAgentTeamsMember({ search }).then(({ data }) => {
      const newUsersList = data.items.map(item => ({
        id: item.id,
        image: item.image,
        first_name: item.first_name,
        last_name: item.last_name,
        selected: true,
      }));

      if (
        (search && page === 0) ||
        !searchPaginationEnable ||
        (searchPaginationEnable && !search)
      ) {
        setUserList([...newUsersList]);
      } else {
        setUserList([...userList, ...newUsersList]);
      }

      if (search && data.items?.length === data.page_count) {
        setSearchPaginationEnable(true);
      }

      // check if we get all pages
      if ((page + 1) * data.page_count < data.total_count) {
        setPermission(true);
      } else {
        setPermission(false);
      }

      setLoading(false);
    });
  };

  const onChangeOption = id => {
    const temp = userList.map(user =>
      user.id === id ? { ...user, selected: !user.selected } : user,
    );
    setUserList(temp);
    allCheckFunction(temp);
  };

  const handleSelectAll = () => {
    let temp;
    if (allCheck === "checked") {
      temp = userList.map(user => ({ ...user, selected: false }));
      setAllCheck("not-checked");
    } else {
      temp = userList.map(user => ({ ...user, selected: true }));
      setAllCheck("checked");
    }

    setUserList(temp);
  };

  // detect and set value of allCheck
  const allCheckFunction = items => {
    const selected = items.filter(user => user.selected);
    if (selected.length === userList.length) {
      setAllCheck("checked");
    } else {
      setAllCheck("not-checked");
    }
  };

  const onVisibleChange = e => {
    if (!e) {
      const temp = userList.filter(item => item.selected).map(item => item.id);
      setSelectedIds(temp);
    }
  };

  return (
    <Dropdown
      closeOnClick={false}
      overlayStyle={{ maxWidth: triggerRef.current?.offsetWidth }}
      overlay={
        <div className={classes.wrapper}>
          <Input
            placeholder="Search name"
            className={classes.search}
            onChange={e => setSearch(e.target.value)}
            suffix={
              <i>
                <IconSearch />
              </i>
            }
          />

          <div className={classes.selectAll} onClick={handleSelectAll}>
            <i>
              <IconUser width="16" height="16" />
            </i>
            <div>Select all</div>
            <i>{allCheck === "checked" ? <IconExpand /> : <IconSelect />}</i>
          </div>

          <ScrollingPagination
            apiHandler={apiHandler}
            className={classes.scroll}
            distance={450}
            search={search}
          >
            <div>
              {userList.map(user => (
                <div
                  className={classes.option}
                  onClick={() => onChangeOption(user.id)}
                >
                  <div>
                    <img src={user.image ? user.image : anonImage} alt="user" />
                  </div>

                  <div>
                    {user.first_name} {user.last_name}
                  </div>

                  <i>{user.selected ? <IconExpand /> : <IconSelect />}</i>
                </div>
              ))}
            </div>
          </ScrollingPagination>
        </div>
      }
      onVisibleChange={onVisibleChange}
    >
      <div className={classes.select} ref={triggerRef}>
        <div>Team Member</div>
        <i>
          <IconArrowDownMini />
        </i>
      </div>
    </Dropdown>
  );
}
