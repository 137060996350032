const authActionType = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT_REQUEST: "USERS_LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "USERS_LOGOUT_SUCCESS",

  AGENT_PROFILE_UPDATE_REQUEST: "AGENT_PROFILE_UPDATE_REQUEST",
  AGENT_PROFILE_UPDATE_SUCCESS: "AGENT_PROFILE_UPDATE_SUCCESS",
  AGENT_PROFILE_UPDATE_FAILURE: "AGENT_PROFILE_UPDATE_FAILURE",

  AGENCY_PROFILE_UPDATE_REQUEST: "AGENCY_PROFILE_UPDATE_REQUEST",
  AGENCY_PROFILE_UPDATE_SUCCESS: "AGENCY_PROFILE_UPDATE_SUCCESS",
  AGENCY_PROFILE_UPDATE_FAILURE: "AGENCY_PROFILE_UPDATE_FAILURE",

  UPDATE_ROLE: "UPDATE_ROLE",
  UPDATE_DETAILS: "UPDATE_DETAILS",
  UPDATE_AGENCY_DETAILS: "UPDATE_AGENCY_DETAILS",
  UPDATE_TERMS: "UPDATE_TERMS",
  UPDATE_AML_STATUS: "UPDATE_AML_STATUS",
  UPDATE_PREV_USER: "UPDATE_PREV_USER",

  ONE_TIME_TOKEN_DELETE: "ONE_TIME_TOKEN_DELETE",
};

export default authActionType;