import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { BirthdayInput, NormalInput, AutoComplete } from "Components/Input";
import { PrimaryButton, SecondaryButton} from "Components/ui";
import { updateProfile, updateSolicitor } from "Services";
import Spin from "Components/Spinner/Spin";
import { useDispatch, useSelector } from "react-redux";
import { logout, updateProfileDetails } from "Store/_actions/auth.action";
import alert from "Utils/alert";
import {
  handle_validBirthDate,
  handle_validPhoneNumber,
} from "Utils/helperFunction";
import PhoneInput from "Components/Input/PhoneInput";

export default function Index({ type, profile, setReload, setProfile }) {
  const aml_status = useSelector(store => store?.auth?.user?.aml_status);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [birthday, setBirthday] = useState("");

  const dispatch = useDispatch();
  const onFinish = async values => {
    const body = {
      ...values,
      birthday: birthday || values.birthday,
    };

    let api = type === "trader" ? updateProfile : updateSolicitor;
    setLoading(true);
    await api(body)
      .then(res => {
        dispatch(
          updateProfileDetails(
            type === "trader"
              ? {
                  middle_name: values.middle_name,
                  mobile: values.phone,
                  phone: values.phone,
                  email: values.email,
                }
              : body,
          ),
        );
        setProfile(prev => ({
          ...prev,
        }));
        setReload(prev => !prev);
        alert.success();
      })
      .catch(err => console.error(err));
    setLoading(false);
  };

  const [resetDefault, setRestDefault] = useState(false);

  useEffect(() => {
    if (profile?.has_pending_update_request) {
      setShowMessage(true);
    }
    form.resetFields();
    form.setFieldsValue({
      first_name: profile[type] ? profile[type].first_name : "",
      last_name: profile[type] ? profile[type].last_name : "",
      middle_name: profile[type] ? profile[type].middle_name : "",
      birthday: profile[type] ? profile[type].birthday : "",
      email: profile[type] ? profile[type].email : "",
      mobile: profile[type] ? profile[type].mobile : "",
      phone: profile[type] ? profile[type].phone : "",
      post_code: profile[type] ? profile[type].post_code : "",
      country: profile[type] ? profile[type].country : "",
      city: profile[type] ? profile[type].city : "",
      second_line_of_address: profile[type]
        ? profile[type].second_line_of_address
        : "",
      first_line_of_address: profile[type]
        ? profile[type].first_line_of_address
        : "",
    });
  }, [type, profile, resetDefault]);

  const handleLogout = () => {
    dispatch(logout());
  };

  const isDisableWhenHasPendingChanges =
    type === "trader" && profile?.has_pending_update_request;

  const isDisabledWhenAmlStatusPassed =
    type === "trader" && aml_status === "passed";

  return (
    <Spin spinning={loading}>
      <Form layout="vertical" name="basic" form={form} onFinish={onFinish}>
        <div className="row gx-50px">
          <div className="col-12 col-md-6">
            <Form.Item
              label="First name"
              name="first_name"
              rules={[{ required: true, message: "First Name is required!" }]}
            >
              <NormalInput
                form={form}
                name="first_name"
                placeholder="Enter your name"
                disabled={
                  isDisableWhenHasPendingChanges ||
                  isDisabledWhenAmlStatusPassed
                }
              />
            </Form.Item>

            <Form.Item
              label="Last name"
              name="last_name"
              rules={[{ required: true, message: "Last name is required!" }]}
            >
              <NormalInput
                form={form}
                name="last_name"
                placeholder="Enter your Last name"
                disabled={
                  isDisableWhenHasPendingChanges ||
                  isDisabledWhenAmlStatusPassed
                }
              />
            </Form.Item>

            <Form.Item
              name="phone"
              label="Contact number"
              rules={[
                {
                  required: true,
                  message: "Please input your Contact number!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    return handle_validPhoneNumber(value);
                  },
                }),
              ]}
            >
              <PhoneInput
                name="phone"
                form={form}
                disabled={isDisableWhenHasPendingChanges || isDisabledWhenAmlStatusPassed}
              />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Email is required!",
                },
              ]}
            >
              <NormalInput
                form={form}
                name="email"
                placeholder="example@mail.com"
                disabled={isDisableWhenHasPendingChanges || isDisabledWhenAmlStatusPassed}
              />
            </Form.Item>
          </div>

          <div className="col-12 col-md-6">
            <Form.Item label="Middle name" name="middle_name">
              <NormalInput
                form={form}
                name="middle_name"
                placeholder="Enter your Middle name"
                disabled={isDisableWhenHasPendingChanges || isDisabledWhenAmlStatusPassed}
              />
            </Form.Item>

            <Form.Item
              label="Date of birth"
              name="birthday"
              rules={[
                {
                  required: true,
                  message: "birthdate is required!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    return handle_validBirthDate(value);
                  },
                }),
              ]}
            >
              <BirthdayInput
                form={form}
                name="birthday"
                placeholder="Select date of birth"
                style={{ padding: "7px 8px" }}
                onChange={(_value, formattedDate) => setBirthday(formattedDate)}
                disabled={
                  isDisableWhenHasPendingChanges ||
                  isDisabledWhenAmlStatusPassed
                }
              />
            </Form.Item>

            <Form.Item
              name="mobile"
              label="Mobile number"
              rules={[
                {
                  required: true,
                  message: "Please input your Contact number!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    return handle_validPhoneNumber(value);
                  },
                }),
              ]}
            >
              <PhoneInput
                name="mobile"
                form={form}
                disabled={isDisableWhenHasPendingChanges || isDisabledWhenAmlStatusPassed}
              />
            </Form.Item>

            <Form.Item
              name="post_code"
              label="Post code"
              rules={[
                {
                  required: true,
                  message: "Post code is required!",
                },
              ]}
            >
              <AutoComplete
                form={form}
                name="post_code"
                placeholder="Enter post code"
                disabled={
                  isDisableWhenHasPendingChanges ||
                  isDisabledWhenAmlStatusPassed
                }
              />
            </Form.Item>
          </div>

          <div className="col-12">
            <Form.Item
              name="first_line_of_address"
              label="First Line Address"
              rules={[
                {
                  required: true,
                  message: "Address is required!",
                },
              ]}
            >
              <NormalInput
                form={form}
                name=""
                placeholder="Enter address"
                disabled={
                  isDisableWhenHasPendingChanges ||
                  isDisabledWhenAmlStatusPassed
                }
              />
            </Form.Item>

            <Form.Item
              name="second_line_of_address"
              label="Second Line Address"
            >
              <NormalInput
                form={form}
                name=""
                placeholder="Enter address"
                disabled={
                  isDisableWhenHasPendingChanges ||
                  isDisabledWhenAmlStatusPassed
                }
              />
            </Form.Item>

            <div className="row">
              <div className="col-6">
                <Form.Item
                  name="country"
                  label="Country"
                  rules={[
                    {
                      required: true,
                      message: "Country is required!",
                    },
                  ]}
                >
                  <NormalInput
                    form={form}
                    placeholder="Enter here"
                    name="country"
                    disabled={
                      isDisableWhenHasPendingChanges ||
                      isDisabledWhenAmlStatusPassed
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  name="city"
                  label="City / Town"
                  rules={[
                    {
                      required: true,
                      message: "City / Town is required!",
                    },
                  ]}
                >
                  <NormalInput
                    form={form}
                    placeholder="Enter here"
                    name="city"
                    disabled={
                      isDisableWhenHasPendingChanges ||
                      isDisabledWhenAmlStatusPassed
                    }
                  />
                </Form.Item>
              </div>
            </div>

            {type === "trader" && showMessage && (
              <p className="message">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0629 18.3334C5.43068 18.3334 1.67578 14.6025 1.67578 10C1.67578 5.39752 5.43068 1.66669 10.0629 1.66669C14.6951 1.66669 18.45 5.39752 18.45 10C18.45 14.6025 14.6951 18.3334 10.0629 18.3334ZM10.0629 16.6667C11.8424 16.6667 13.549 15.9643 14.8073 14.7141C16.0656 13.4638 16.7726 11.7681 16.7726 10C16.7726 8.23191 16.0656 6.53622 14.8073 5.28598C13.549 4.03573 11.8424 3.33335 10.0629 3.33335C8.28336 3.33335 6.57673 4.03573 5.31842 5.28598C4.06011 6.53622 3.3532 8.23191 3.3532 10C3.3532 11.7681 4.06011 13.4638 5.31842 14.7141C6.57673 15.9643 8.28336 16.6667 10.0629 16.6667ZM10.0629 8.33335C10.2853 8.33335 10.4986 8.42115 10.6559 8.57743C10.8132 8.73371 10.9016 8.94567 10.9016 9.16669V13.3334C10.9016 13.5544 10.8132 13.7663 10.6559 13.9226C10.4986 14.0789 10.2853 14.1667 10.0629 14.1667C9.84044 14.1667 9.62711 14.0789 9.46982 13.9226C9.31253 13.7663 9.22417 13.5544 9.22417 13.3334V9.16669C9.22417 8.94567 9.31253 8.73371 9.46982 8.57743C9.62711 8.42115 9.84044 8.33335 10.0629 8.33335ZM10.0629 7.50002C9.84044 7.50002 9.62711 7.41222 9.46982 7.25594C9.31253 7.09966 9.22417 6.8877 9.22417 6.66669C9.22417 6.44567 9.31253 6.23371 9.46982 6.07743C9.62711 5.92115 9.84044 5.83335 10.0629 5.83335C10.2853 5.83335 10.4986 5.92115 10.6559 6.07743C10.8132 6.23371 10.9016 6.44567 10.9016 6.66669C10.9016 6.8877 10.8132 7.09966 10.6559 7.25594C10.4986 7.41222 10.2853 7.50002 10.0629 7.50002Z"
                    fill="#02B075"
                  />
                </svg>
                Your changes will be reviewed and updated soon.
              </p>
            )}

            <Form.Item>
              <div className=" d-flex align-items-center justify-content-between btnWrapper">
                <div
                  className={"logOut d-flex align-items-center"}
                  onClick={() => handleLogout()}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.0577 11.0379H13.6892C13.797 11.0379 13.9037 11.0591 14.0033 11.1004C14.1029 11.1416 14.1934 11.2021 14.2697 11.2783C14.3459 11.3546 14.4063 11.4451 14.4476 11.5447C14.4889 11.6443 14.5101 11.751 14.5101 11.8588C14.5101 11.9666 14.4889 12.0733 14.4476 12.1729C14.4063 12.2725 14.3459 12.363 14.2697 12.4393C14.1934 12.5155 14.1029 12.576 14.0033 12.6172C13.9037 12.6585 13.797 12.6797 13.6892 12.6797H9.0577L9.97875 13.6007C10.1327 13.7548 10.2191 13.9636 10.219 14.1814C10.219 14.3992 10.1324 14.608 9.97834 14.7619C9.82431 14.9158 9.61543 15.0022 9.39768 15.0022C9.17992 15.0021 8.97111 14.9155 8.81718 14.7615L6.49569 12.44C6.41907 12.3639 6.35826 12.2734 6.31677 12.1738C6.27527 12.0741 6.25391 11.9672 6.25391 11.8592C6.25391 11.7512 6.27527 11.6443 6.31677 11.5447C6.35826 11.445 6.41907 11.3545 6.49569 11.2784L8.81636 8.95694C8.97029 8.8029 9.1791 8.71632 9.39685 8.71625C9.61461 8.71617 9.82348 8.8026 9.97752 8.95653C10.1316 9.11045 10.2181 9.31926 10.2182 9.53702C10.2183 9.75478 10.1319 9.96365 9.97793 10.1177L9.0577 11.0379ZM16.9268 4.61194C17.1445 4.61194 17.3533 4.69843 17.5073 4.85237C17.6612 5.00632 17.7477 5.21512 17.7477 5.43283V18.5672C17.7477 18.7849 17.6612 18.9937 17.5073 19.1476C17.3533 19.3016 17.1445 19.388 16.9268 19.388C16.7091 19.388 16.5003 19.3016 16.3463 19.1476C16.1924 18.9937 16.1059 18.7849 16.1059 18.5672V5.43283C16.1059 5.21512 16.1924 5.00632 16.3463 4.85237C16.5003 4.69843 16.7091 4.61194 16.9268 4.61194V4.61194Z"
                      fill="#F7C91B"
                    />
                  </svg>
                  <p className="m-0">Log out</p>
                </div>
                <div>
                  <PrimaryButton
                    type="button"
                    title={"Cancel"}
                    className="cancel"
                    onClick={() => setRestDefault(!resetDefault)}
                  />
                  {isDisableWhenHasPendingChanges || isDisabledWhenAmlStatusPassed
                      ? <SecondaryButton
                          title={"Already saved"}
                          className="save"
                          onClick={(event) => event.preventDefault()}
                          style={{ color: "#dddddd", borderColor: "#dddddd", cursor: "not-allowed" }}
                        />
                      : <PrimaryButton
                          title={"Save changes"}
                          className="save"
                        />
                  }
                </div>
              </div>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Spin>
  );
}
