//todo remove this method once all endpoints have been collapsed to one main file
export const mainRole = (user) => {
  if(user?.roles.length > 0) {
    let role = user?.roles[0];
    let roleSudoName;

    switch(role?.name) {
      case 'Super Admin':
        roleSudoName = 'admin';
        break;
      case 'Admin':
        roleSudoName = 'admin';
        break;
      case 'Super AF Agent':
        roleSudoName = 'owner';
        break;
      case 'Super UTH Agent':
        roleSudoName = 'agent';
        break;
      case 'Super AF Branch Agent':
        roleSudoName = 'owner';
        break;
      case 'Super UTH Branch Agent':
        roleSudoName = 'agent';
        break;
      case 'Team AF Member':
        roleSudoName = 'team_member';
        break;
      case 'Team UTH Member':
        roleSudoName = 'team_member';
        break;
      case 'Buyer':
        roleSudoName = 'trader'; //todo confirm this
        break;
      case 'Seller':
        roleSudoName = 'trader'; //todo confirm this
        break;
      default :
        break;
    }

    return roleSudoName;
  }
};

//rename this to mainRole after deleting the top
export const originalRole = (user) => {
  if(user?.roles.length > 0) {
    return user?.roles[0].name;
  }
};

export const mainRoleForDisplay = (user) => {
  return formatRole(user?.roles[0]?.name);
};

export const availableRoles = (role) => {
  return formatRole(role);
};

export const getDeniedPermissions = (user) => {
  let role = user?.roles[0];
  let denied_permissions;

  switch(role?.name) {
    case 'Super Admin':
      denied_permissions = [];
      break;
    case 'Admin':
      denied_permissions = [];
      break;
    case 'Super AF Agent':
      denied_permissions = shareDeniedPermission();
      break;
    case 'Super UTH Agent':
      denied_permissions = shareDeniedPermission();
      break;
    case 'Super AF Branch Agent':
      denied_permissions = shareDeniedPermission();
      break;
    case 'Super UTH Branch Agent':
      denied_permissions = shareDeniedPermission();
      break;
    case 'Team AF Member':
      denied_permissions = shareDeniedPermission();
      break;
    case 'Team UTH Member':
      denied_permissions = shareDeniedPermission();
      break;
    case 'Buyer':
      denied_permissions = [

      ];
      break;
    case 'Seller':
      denied_permissions = [

      ];
      break;
    default :
      denied_permissions = [...shareDeniedPermission(), ...[]]//todo add buyer and seller here also
      break;
  }

  return denied_permissions;
};

const shareDeniedPermission = () => {
  return [
    "menu-task-management",
    "menu-users",
    "menu-applicants-sellers-solicitors",
    "menu-agencies-branches-employees",
    "menu-admins-roleslist",
    "menu-profile-update-requests",
    "menu-advertisement",
    "dashboard-staff-pipeline",
    "dashboard-total-commissions",
    "dashboard-auctions-open-and-bid",
    "dashboard-upcoming-viewings",
    "dashboard-new-branches-and-agency-accounts",
    "dashboard-sales-progression",
    "dashboard-accounts-payable",
    "dashboard-new-properties-pushed-live",
    "dashboard-property-edited-notifications",
    "dashboard-top-ten-agencies",
    "dashboard-applicant-notifications",
    "dashboard-heat-map",
    "realtime-total-logged-in-applicants",
    "realtime-total-anonymous-users",
    "realtime-total-auction-franchisee",
    "realtime-total-uth-agents",
    "realtime-heat-map",
    "realtime-live-users",
    "task-management-add-new-column",
    "task-management-add-new-column",
    "task-management-edit-column",
    "task-management-delete-column",
    "task-management-add-new-task",
    "task-management-edit-task",
    "users-add-agency",
    "users-edit-agency",
    "users-view-agency",
    "users-add-admin",
    "users-edit-admin",
    "users-view-admin",
    "ticket-support-index",
    "ticket-support-view",
    "ticket-support-response",
    "advert-add-new",
    "advert-edit",
    "advert-delete"
  ];
}

const formatRole = (role) => {
  let displayName;

  switch(role) {
    case 'Super Admin':
      displayName = 'Super Admin';
      break;
    case 'Admin':
      displayName = 'Admin';
      break;
    case 'Super AF Agent':
      displayName = 'Super Agent (AF)';
      break;
    case 'Super UTH Agent':
      displayName = 'Super Agent (UTH)';
      break;
    case 'Super AF Branch Agent':
      displayName = 'Super Branch Agent (AF)';
      break;
    case 'Super UTH Branch Agent':
      displayName = 'Super Branch Agent (UTH)';
      break;
    case 'Team AF Member':
      displayName = 'Team Member (AF)';
      break;
    case 'Team UTH Member':
      displayName = 'Team Member (UTH)';
      break;
    case 'Buyer':
      displayName = 'Buyer';
      break;
    case 'Seller':
      displayName = 'Seller';
      break;
    default :
      break;
  }

  return displayName;
};