import React, { useState, useRef, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { AutoComplete, Input, Form } from "antd";
import { inputsStyles } from "./styles/inputs.style";
import clsx from "clsx";
import debounce from "lodash.debounce";
import Spin from "Components/Spinner/Spin";

// Services
import { getUsersEmployees } from "Services";

// Icons
import { ReactComponent as SearchIcon } from "Assets/img/icons/icon_search_input.svg";

const useStyles = makeStyles(inputsStyles);

export default function SearchEmployeeInput({
  placeholder,
  className,
  defaultValue,
  form,
  name,
  employeeIdName,
  ...restProps
}) {
  const classes = useStyles();

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchRef = useRef(0);

  // api call
  async function apiCall(value) {
    return getUsersEmployees({ search: value }).then(res => {
      return (res.data?.items || []).map(item => ({
        value: item.first_name + " " + item.last_name,
        id: item.id,
      }));
    });
  }

  const handleSearch = useMemo(() => {
    const loadOptions = value => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setLoading(true);

      apiCall(value)
        .then(newOptions => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }

          setOptions(newOptions);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
        });
    };

    return debounce(loadOptions, 800);
  }, []);

  const handleKeyPress = ev => {
    // console.log("handleKeyPress", ev);
  };

  const onSelect = (value, selectedItem) => {
    form.setFieldsValue({
      [name]: value,
      [employeeIdName]: selectedItem.id,
    });
  };

  return (
    <>
      <AutoComplete
        defaultValue={defaultValue || form?.getFieldValue(name)}
        options={options}
        onSelect={onSelect}
        onSearch={handleSearch}
      >
        <Input
          className={clsx(classes.input, classes.inputGrayIcon, className)}
          placeholder={placeholder}
          defaultValue={defaultValue || form?.getFieldValue(name)}
          onKeyPress={handleKeyPress}
          suffix={loading ? <Spin spinning size={15} /> : <SearchIcon />}
          {...restProps}
        />
      </AutoComplete>

      <div style={{ display: "none" }}>
        <Form.Item name={employeeIdName}>
          <Input type="hidden" />
        </Form.Item>
      </div>
    </>
  );
}
