import { ReactComponent as UserIcon } from "Assets/img/icons/icon_User.svg";
import { ReactComponent as OfferIcon } from "Assets/img/icons/icon_offers.svg";
import { ReactComponent as CreditCardIcon } from "Assets/img/icons/icon_credit-card.svg";
import { ReactComponent as TimeIcon } from "Assets/img/icons/icon_Timer.svg";
import { ReactComponent as CalenderIcon } from "Assets/img/icons/icon_Calender.svg";
import { ReactComponent as SmsBlackIcon } from "Assets/img/icons/icon_sms_black.svg";
import { ReactComponent as HomeIcon } from "Assets/img/icons/icon_home.svg";
import TextWithIcon from "Components/Text/TextWithIcon";
import { NAString } from "Utils/helperFunction";
import { primaryTimeFormat } from "Utils/moment";
import Modal from "Components/Modal";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  viewOffer: {
    background: "rgba(0, 0, 0, 0.2)",
    padding: 20,
    borderRadius: 5,
    "& .viewOfferBox": {
      "& .text1": {
        ...theme.fonts.s14w500,
        color: "#9CACBC",
      },
      "& .text2": {
        ...theme.fonts.s14w500,
      },
      "& .offerAmount": {
        color: "#F9B914",
      },
      "& svg": {
        width: 18,
        "& path": {
          fill: "#9CACBC",
        },
      },
      "& svg.offerAmount": {
        "& path": {
          fill: "#F9B914",
        },
      },
      "& svg.status": {
        "& path": {
          fill: "transparent",
          stroke: "#9CACBC",
        },
      },
      "& .statusText": {
        background: "rgba(0, 0, 0, 0.3)",
        borderRadius: 5,
        padding: "5px 14px",
        textTransform: "capitalize",
      },
      "& .statusText.reject": {
        color: theme.colors.orange,
      },
      "& .statusText.accepted": {
        color: theme.colors.green,
      },
      "& .statusText.pending": {
        color: "#FFF",
      },
      "& .statusText.withdrawn": {
        color: theme.palette.primary.main,
      },
    },
  },
  property: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    borderRadius: 5,
    padding: 20,
    transition: "all .3s",
    marginBottom: 20,
    "& .imgDiv": {
      marginRight: 17,
      ...theme.imgDiv("100%", "100%", 4, true),
      [theme.media(767)]: {
        width: "auto",
        marginRight: 0,
      },
    },
    "& p": {
      ...theme.fonts.s14w500,
      color: "#FFFFF",
      margin: 0,
      "& span": {
        color: "#9CACBC",
        ...theme.fonts.s14w700,
        marginLeft: 7,
      },
    },
    "& .content": {
      "& p": {
        ...theme.fonts.s14w500,
      },
      "& p.city": {
        ...theme.fonts.s14w700,
      },
      "& .type": {
        color: "#F9B914",
        "& svg": {
          width: 18,
          "& path": {
            fill: "#F9B914",
          },
        },
      },
    },
  },
}));

export default function ViewOfferModal({ open, handleCancel }) {
  const classes = useStyles();
  return (
    <Modal
      title={"VIEW OFFER"}
      isModalVisible={!!open.id}
      handleCancel={handleCancel}
    >
      <div className={classes.property}>
        <div className="row">
          <div className="col-12 col-md-4 mb-5 mb-md-0">
            <div className="imgDiv">
              <img src={open?.thumbnail_image} alt="" />
            </div>
          </div>
          <div className="col-12 col-md-8 ps-4">
            <div className="content">
              <div className="type">
                <i>
                  <HomeIcon />
                </i>
              </div>
              <div>
                <p className="address">
                  {NAString(open?.first_line_of_address)}
                </p>
              </div>
              <div>
                <p className="city">{open?.city}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.viewOffer}>
        <div className={"viewOfferBox"}>
          <TextWithIcon
            icon={<UserIcon />}
            text1={"Applicant’s ID:"}
            text2={<span>{open?.property_id}</span>}
          />
          <TextWithIcon
            icon={<OfferIcon className={"offerAmount"} />}
            text1={<span className="offerAmount">Offer amount:</span>}
            text2={"£ " + open?.amount?.toLocaleString("en-UK")}
          />
          <TextWithIcon
            icon={<CreditCardIcon />}
            text1={"Guide price:"}
            text2={"£ " + open?.amount?.toLocaleString("en-UK")}
          />
          <TextWithIcon icon={<TimeIcon />} text1={"Time left:"} text2={""} />
          <TextWithIcon
            icon={<CalenderIcon />}
            text1={"Offer’s date:"}
            text2={primaryTimeFormat(open?.created_at, "")}
          />
          <TextWithIcon
            icon={<SmsBlackIcon className={"status"} />}
            text1={"Status:"}
            text2={
              <span className={`statusText ${open?.status}`}>
                {open?.status}
              </span>
            }
          />
        </div>
      </div>
    </Modal>
  );
}
