import axios from "./Api";

export const adminsGetTasks = () => axios.get(`/tasks`);

export const adminsCreateTasks = body => axios.post(`/tasks`, body);

export const adminsUpdateTask = (id, body) => axios.put(`/tasks/${id}`, body);

export const adminsChangeTaskBoard = ({
  task_id,
  board_id,
  previous_task_id,
}) =>
  axios.post(
    `/tasks/move/${task_id}?previous_task_id=${
      previous_task_id === 0 ? "" : previous_task_id
    }&column_id=${board_id}`,
  );

export const adminsCreateTasksBoards = body =>
  axios.post(`/task-columns`, body);

export const adminsUpdateTaskBoards = (id, body) =>
  axios.put(`/task-columns/${id}`, body);

export const adminsDeleteTaskBoards = id => axios.delete(`/task-columns/${id}`);
