import { makeStyles } from "@material-ui/core";
import { Select } from "antd";
import { inputsStyles } from "./styles/inputs.style";
import clsx from "clsx";

// Icons
import { ReactComponent as IconArrowDown } from "Assets/img/icons/icon_arrow-down-mini.svg";

const { Option } = Select;

const useStyles = makeStyles(inputsStyles);

export default function SelectInput({
  placeholder,
  className,
  options,
  name,
  form,
  onChange,
  disabledFields = [],
  disabled,
  ...restProps
}) {
  const classes = useStyles();
  function handleOnChange(value) {
    if (onChange) onChange(value);
    form.setFieldsValue({
      [name]: value,
    });
  }

  return (
    <Select
      showSearch
      className={clsx(classes.select, className || "")}
      dropdownClassName={classes.selectDropdown}
      placeholder={placeholder}
      optionFilterProp="children"
      onChange={handleOnChange}
      suffixIcon={<IconArrowDown width="16" height="16" />}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabledFields?.includes(name) || disabled}
      {...restProps}
    >
      {options.length &&
        options.map(item => (
          <Option
            key={item.value}
            value={item.value}
            className={classes.selectOption}
          >
            {item.label}
          </Option>
        ))}
    </Select>
  );
}
