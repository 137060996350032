import { ReactComponent as HomeIcon } from "Assets/img/icons/icon_home.svg";
import { ReactComponent as UserIcon } from "Assets/img/icons/icon_User.svg";
import { ReactComponent as StoreIcon } from "Assets/img/icons/icon_store.svg";
import { ReactComponent as SearchIcon } from "Assets/img/icons/icon_Search.svg";
import React, { Children, useRef, useState } from "react";
import { Input } from "antd";
import { motion } from "framer-motion";
import { elasticSearch } from "Services";
import { makeStyles } from "@material-ui/core";
import { topNavStyle } from "../styles/navigationbar.styles";
import { Link } from "react-router-dom";
import EmptyView from "Components/ResultPage/EmptyView";
import { useSelector } from "react-redux";
import {mainRole} from "../../../../Utils/roles";
const { Search } = Input;
const useStyles = makeStyles(topNavStyle);

export default function ElasticSearch() {
  const classes = useStyles();
  const [result, setResult] = useState({
    properties: [],
    users: [],
    stores: [],
  });
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  let prevSearchText = useRef("");
  const userRole = useSelector(store => mainRole(store?.auth?.user));
  const closeSearchResult = () => {
    setActiveTab(0);
    prevSearchText.current = "";
    return setResult({ properties: [], users: [], stores: [] });
  };

  const tabs = [
    {
      name: "All",
      component: (result, userRole, closeSearchResult) => (
        <AllItems
          result={result}
          closeSearchResult={closeSearchResult}
          userRole={userRole}
          wrapSearchKey={wrapSearchKey}
        />
      ),
    },
    {
      name: <HomeIcon />,
      component: (result, userRole, closeSearchResult) => (
        <PropertyItem
          properties={result.properties}
          closeSearchResult={closeSearchResult}
          userRole={userRole}
          wrapSearchKey={wrapSearchKey}
        />
      ),
    },
    {
      name: <UserIcon />,
      component: (result, userRole, closeSearchResult) => (
        <UserItem
          users={result.users}
          closeSearchResult={closeSearchResult}
          userRole={userRole}
          wrapSearchKey={wrapSearchKey}
        />
      ),
    },
    {
      name: <StoreIcon />,
      component: (result, userRole, closeSearchResult) => (
        <StoreItem
          stores={result.stores}
          closeSearchResult={closeSearchResult}
          userRole={userRole}
          wrapSearchKey={wrapSearchKey}
        />
      ),
    },
  ];

  const handleSearch = async searchText => {
    if (!searchText) return null;
    if (prevSearchText.current === searchText) return null;
    prevSearchText.current = searchText;
    setLoading(true);
    try {
      const data = await elasticSearch(searchText);
      const properties = data.filter(item => item.model === "Property");
      const users = data.filter(
        item =>
          item.model === "Owner" ||
          item.model === "Team_member" ||
          item.model === "Agent",
      );
      const stores = data.filter(
        item => item.model === "Agency" || item.model === "Branch",
      );
      setResult(() => ({
        properties,
        users,
        stores,
        all: data,
      }));
    } catch (error) {
      setResult({ properties: [], users: [], stores: [] });
    }
    setLoading(false);
  };

  // change color of search key
  const wrapSearchKey = text => {
    if (!text) return "";
    const regex = new RegExp(prevSearchText.current, "gi");
    return text?.replace?.(regex, s => `<span class="searchKey">${s}</span>`);
  };

  const onChange = e => {
    if (!e.target.value) {
      prevSearchText.current = "";
      return setResult({ properties: [], users: [], stores: [] });
    }
  };

  const transition = {
    duration: 0.3,
    delay: 0,
    ease: "easeInOut",
  };

  const variants = {
    closed: {
      opacity: 0,
      visibility: "hidden",
      // Transform
      translateX: "10rem",
      translateY: "-5rem",
      scale: 0,
      // Transform origin
      originX: 1,
      originY: 0,
      // transition
      transition: {
        when: "beforeChildren",
        ...transition,
      },
    },
    open: {
      opacity: 1,
      visibility: "visible",
      scale: 1,
      translateX: 0,
      translateY: 0,
      transition: {
        stiffness: 20,
        restDelta: 2,
        ...transition,
      },
    },
  };

  return (
    <div className={classes.topNavSearch}>
      <Search
        placeholder="Search properties, employees and branches..."
        allowClear
        enterButton={
          <span className="btnText">
            search
            <SearchIcon />
          </span>
        }
        size="large"
        onSearch={handleSearch}
        loading={loading}
        onChange={onChange}
      />
      <motion.div
        className="resultBox"
        variants={variants}
        initial="closed"
        animate={
          !!result.stores.length ||
          !!result.users.length ||
          !!result.properties.length
            ? "open"
            : "closed"
        }
      >
        <div className="header">
          {Children.toArray(
            tabs.map((tab, index) => (
              <button
                onClick={() => setActiveTab(index)}
                className={`${activeTab === index ? "active" : ""}`}
              >
                {tab.name}
              </button>
            )),
          )}
        </div>
        <div className="content custom-scrollbar">
          {tabs[activeTab].component(result, userRole, closeSearchResult)}
        </div>
      </motion.div>
    </div>
  );
}

const PropertyItem = ({
  properties,
  userRole,
  closeSearchResult,
  wrapSearchKey,
}) => {
  if (!properties.length)
    return (
      <div>
        <EmptyView />
      </div>
    );

  const renderBody = (image, model, title, address) => (
    <div
      className="itemRow"
      onClick={() => (userRole === "buyer" ? null : closeSearchResult())}
    >
      <div className="imgDiv">
        <img src={image} alt="property" />
      </div>
      <div className="d-flex flex-column ">
        <div className="d-flex align-items-center">
          <p className="me-2">{model}</p>
          <p
            className="type ps-2"
            dangerouslySetInnerHTML={{
              __html: wrapSearchKey(title),
            }}
          />
        </div>
        <div>
          <p
            className="address"
            dangerouslySetInnerHTML={{
              __html: "Address: " + wrapSearchKey(address),
            }}
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      {Children.toArray(
        properties.map(({ image, model, title, attributes, href }, index) => {
          if (userRole === "buyer") {
            return renderBody(image, model, title, attributes.Address);
          }
          return (
            <Link to={`/${userRole + href}`}>
              {renderBody(image, model, title, attributes.Address)}
            </Link>
          );
        }),
      )}
    </>
  );
};

const UserItem = ({ users, userRole, closeSearchResult, wrapSearchKey }) => {
  if (!users.length)
    return (
      <div>
        <EmptyView />
      </div>
    );
  return (
    <>
      {Children.toArray(
        users.map(({ image, model, title, attributes, href }, index) => (
          <Link to={`/${userRole + href}`}>
            <div className="itemRow" onClick={() => closeSearchResult()}>
              <div className="imgDiv">
                <img src={image} alt="property" />
              </div>
              <div className="d-flex flex-column ">
                <div className="d-flex align-items-center">
                  <p className="model me-2">{model.split("_").join(" ")}</p>
                  <p
                    className="type ps-2"
                    dangerouslySetInnerHTML={{
                      __html: wrapSearchKey(title),
                    }}
                  />
                </div>
                <div>
                  <p
                    className="address"
                    dangerouslySetInnerHTML={{
                      __html: "Email: " + wrapSearchKey(attributes.Email),
                    }}
                  />
                  <p
                    className="address"
                    dangerouslySetInnerHTML={{
                      __html: "Phone: " + wrapSearchKey(attributes.Phone),
                    }}
                  />
                </div>
              </div>
            </div>
          </Link>
        )),
      )}
    </>
  );
};

const StoreItem = ({ stores, userRole, closeSearchResult, wrapSearchKey }) => {
  if (!stores.length)
    return (
      <div>
        <EmptyView />
      </div>
    );
  return (
    <>
      {Children.toArray(
        stores.map(({ image, model, title, attributes, href }, index) => (
          <Link to={`/${userRole + href}`}>
            <div className="itemRow" onClick={() => closeSearchResult()}>
              <div className="imgDiv">
                <img src={image} alt="property" />
              </div>
              <div className="d-flex flex-column ">
                <div className="d-flex align-items-center">
                  <p className="me-2">{model}</p>
                  <p
                    className="type ps-2"
                    dangerouslySetInnerHTML={{
                      __html: wrapSearchKey(title),
                    }}
                  />
                </div>
                <div>
                  <p
                    className="address mb-1"
                    dangerouslySetInnerHTML={{
                      __html: "Phone: " + wrapSearchKey(attributes.Phone),
                    }}
                  />
                  <p
                    className="address mb-1"
                    dangerouslySetInnerHTML={{
                      __html: "Email: " + wrapSearchKey(attributes.Email),
                    }}
                  />
                  <p
                    className="address mb-1"
                    dangerouslySetInnerHTML={{
                      __html: "Address: " + wrapSearchKey(attributes.Address),
                    }}
                  />
                </div>
              </div>
            </div>
          </Link>
        )),
      )}
    </>
  );
};

const AllItems = ({ result, ...rest }) => {
  if (
    !result.properties.length &&
    !result.users.length &&
    !result.stores.length
  )
    return (
      <div>
        <EmptyView />
      </div>
    );
  return (
    <>
      {!!result.properties.length && (
        <PropertyItem properties={result.properties} {...rest} />
      )}
      {!!result.users.length && <UserItem users={result.users} {...rest} />}
      {!!result.stores.length && <StoreItem stores={result.stores} {...rest} />}
    </>
  );
};
