const defaultStyle = {
  padding: "7.5px 16px",
  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "25px",
  textAlign: "center",
  letterSpacing: "-0.5px",
  borderRadius: "5px",
};

export const PrimaryButtonStyle = theme => ({
  primary_root: {
    ...defaultStyle,
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    "& path": {
      fill:
        theme.theme_type === "light" ? "#333 !important" : "#fff !important",
    },
  },
  titleBox: {
    display: "flex",
    gap: "5px",
    justifyContent: "center",
    alignItems: "center",
  },
});
export const BlackButtonStyles = theme => ({
  black_root: {
    ...defaultStyle,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    backgroundColor: "transparent",
    border: "1px solid #151C2E",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },

    "& svg": {
      width: "18px",
      height: "18px",
    },
    "& svg path": {
      fill: theme.theme_type === "light" ? "#333" : "#fff",
    },
  },
  black_bordered_root: {
    ...defaultStyle,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    border:
      theme.theme_type === "light" ? "1px solid #333333" : "1px solid #ffffff",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },

    "& svg": {
      width: "18px",
      height: "18px",
    },
    "& svg path": {
      fill: theme.theme_type === "light" ? "#333" : "#fff",
    },
  },
  small: {
    ...theme.fonts.s12w500,
    padding: "4px 14px !important",
    lineHeight: "18px",
    [theme.breakpoints.down("sm")]: {
      padding: "4px 14px !important",
      width: "fit-content",
    },
  },
});

export const SecondaryButtonStyle = theme => ({
  secondary_root: {
    ...defaultStyle,
    border: `1px solid ${theme.colors.gray}`,
  },
});
