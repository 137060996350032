import React from "react";
import GeneralErrorPage from "Components/ResultPage/GeneralErrorPage";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  render() {
    if (this.state.error) {
      if (this.state.error?.name === "ChunkLoadError") {
        return (
          <GeneralErrorPage>
            <p>Page content can not be loaded</p>
            <p>Please check your connection and try again.</p>
          </GeneralErrorPage>
        );
      } else {
        return (
          <GeneralErrorPage>
            <p>Something went wrong...</p>
            <p>We are working on fixing the peoblem. please try again.</p>
          </GeneralErrorPage>
        );
      }
    }

    return this.props.children;
  }
}
