import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Components
import BaseTable from "./BaseTable";
import TableHeader from "./TableHeader";
import { Pagination } from "Components/Pagination";
import Spin from "Components/Spinner/Spin";

// hooks
import { useDeepEffect } from "Hooks";

export default function Table({
  columns,
  mobileColumnsKey,
  api,
  serverSide,
  handleRequest,
  reload: reloadParent,
  showTeamMemberFilter,
  title,
  // extraDetails,
  // showMainViewType = true,
  showSearch = true,
  showTableHeader = true,
  showPagination = true,
  sorts,
  // moreActions,
  buttons,
  OtherView,
  OtherViewIcon,
  onViewChange,
  TableContainer = Fragment,
  HeaderContainer,
  PaginationContainer = Fragment,
  customFilter,
  // custom default view type
  // currentViewType = "table",
  dataSource,
  pageSource,
  CardComponent,
  cardGridProps,
  tableClasses,
  wrapperGridProps,
  //! props needed for applicant notification page
  showPaginationDataOnly = false,
  showHeaderPageCount = false,
  searchProperties = {
    label: true,
    placeholder: null,
  },
  customExpandable = null,
  customExpandIcon = null,
  expandedByDefault = false,
  expandedData = null,
  hideHeaderInputLabel, // for hide input label in header(like search, filters, ...)
  differentToggleIcon = false,
  expandedIcon,
  nonExpandedIcon,
  loading: parentLoading,
  noContainerClass,
  setDataToParent = () => {},
  customFilterValues = {},
  staticRole,
  newPagination,
  ...restProps // props passed to antd table
}) {
  // handle local reload
  const [reloadLocal, setReloadLocal] = useState(1);

  // handle number of shown items
  const [numberOfShowingItems, setNumberOfShowingItems] = useState(0);

  // handle items per page count
  const [itemsCountPerPage, setItemsCountPerPage] = useState(10);

  // search
  const [search, setSearch] = useState("");

  // // handle main view type [table, other]
  // const [viewType, setViewType] = useState(
  //   currentViewType === "table" || currentViewType === "other"
  //     ? currentViewType
  //     : "table",
  // );

  // handle table filters (only team member)
  const [teamMemberId, setTeamMemberId] = useState([]);

  // handle admin filters (enhanced filter)
  const [adminFilters, setAdminFilters] = useState({});

  // handle table sort
  const [tableSortType, setTableSortType] = useState("desc"); // asc or desc
  const [tableSortBy, setTableSortBy] = useState("");

  // general states
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // pagination
  const [page, setPage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const handlePagination = currentPage => {
    setPage(currentPage);
  };

  // set pagination member
  const computeMember = () => {
    const clientCount = page * 25 - 25;

    if (page === 1) {
      return data.length === 0 ? "0" : `1 - ${data.length}`;
    }
    return `${clientCount + 1} - ${clientCount + data?.length}`;
  };

  // we have 2 way to manage data in table:
  // 1- API
  // 2- Handle Server Side

  // 1- handle data with API (when api props available)
  const handleDataWithApiCall = () => {
    setLoading(true);

    const requestBody = {
      page: newPagination ? page : (page - 1),
      search: search,
      team_member_ids: teamMemberId,
      order_by: tableSortBy,
      order_type: tableSortType,
      items_count: itemsCountPerPage,
      ...adminFilters,
      ...customFilterValues,
    };

    const sendRequest = (body = {}, customThen, customCatch) => {
      api(body)
        .then(res => {
          customThen?.({ data: res?.data, page });

          // add key to items
          const dataWithKey = res.data?.items.length
              ? res.data?.items?.map((item, index) => ({ key: index, ...item }))
              : [];

          // set data
          setDataToParent(dataWithKey);
          setData(dataWithKey);
          setTotalItemsCount(res.data.total_count);
          setItemsPerPage(res.data.page_count);
          setNumberOfShowingItems(res.data?.items?.length);
          setLoading(false);
        })
        .catch(err => {
          customCatch?.(err);
          setLoading(false);
        });
    };

    const sendRequestNew = (body = {}, customThen, customCatch) => {
      api(body)
          .then(res => {
            customThen?.({ data: res?.data, page });

            // add key to items
            const dataWithKey = res.data?.data.length
                ? res.data?.data?.map((item, index) => ({ key: index, ...item }))
                : [];

            // set data
            setDataToParent(dataWithKey);
            setData(dataWithKey);
            setTotalItemsCount(res.data.total);
            setItemsPerPage(res.data.per_page);
            setNumberOfShowingItems(res.data?.data?.length);
            setLoading(false);
          })
          .catch(err => {
            customCatch?.(err);
            setLoading(false);
          });
    };

    if (handleRequest) {
      newPagination
        ? handleRequest(requestBody, sendRequestNew)
        : handleRequest(requestBody, sendRequest);
    } else {
      sendRequest({ ...requestBody });
    }
  };

  // 2- handle data with ServerSide
  const handleDataWithServerSide = () => {
    const callback = ({
      data: serverData,
      totalItemsCount: serverTotalItemsCount,
      itemsPerPage: serverItemsPerPage,
    }) => {
      // add key to items
      const dataWithKey = serverData.length
        ? serverData.map((item, index) => ({ key: index, ...item }))
        : [];
      // set data
      setData(dataWithKey);
      setTotalItemsCount(serverTotalItemsCount);
      setItemsPerPage(serverItemsPerPage);
      setLoading(false);
    };

    const callbackError = err => {
      setLoading(false);
    };

    setLoading(true);

    serverSide(callback, callbackError, {
      page: newPagination ? page : page - 1,
      search: search,
      team_member_ids: teamMemberId,
      order_by: tableSortBy,
      order_type: tableSortType,
      ...adminFilters,
    });
  };

  const handleStaticData = () => {
    setData(dataSource);
    setTotalItemsCount(pageSource?.total_count);
    setItemsPerPage(pageSource?.page_count);
  };

  const getData = () => {
    if (api) {
      handleDataWithApiCall();
    } else if (serverSide) {
      handleDataWithServerSide();
    } else {
      handleStaticData();
    }
  };

  // useDeepEffect because some dependencies are array
  useDeepEffect(() => {
    getData();
  }, [
    api,
    page,
    search,
    teamMemberId,
    tableSortBy,
    tableSortType,
    reloadParent,
    reloadLocal,
    dataSource,
    pageSource,
    itemsCountPerPage,
    adminFilters,
    customFilterValues,
  ]);

  useEffect(() => {
    if (parentLoading !== undefined) setLoading(prev => prev || parentLoading);
  }, [parentLoading]);

  return (
    <TableContainer>
      {showTableHeader && (
        <TableHeader
          HeaderContainer={HeaderContainer}
          // title={title}
          numberOfShowingItems={numberOfShowingItems}
          totalItemsCount={totalItemsCount}
          // extraDetails={extraDetails}
          // viewType={viewType}
          // setMainViewType={setViewType}
          onViewChange={onViewChange}
          //! props needed for applicant notification page
          searchProperties={searchProperties}
          search={search}
          setSearch={setSearch}
          showSearch={showSearch}
          buttons={buttons}
          showTeamMemberFilter={showTeamMemberFilter}
          setTeamMemberId={setTeamMemberId}
          setAdminFilters={setAdminFilters}
          customFilter={customFilter}
          sorts={sorts}
          tableSortType={tableSortType}
          setTableSortType={setTableSortType}
          tableSortBy={tableSortBy}
          setTableSortBy={setTableSortBy}
          // moreActions={moreActions}
          OtherViewIcon={OtherViewIcon}
          tableClasses={tableClasses}
          //! props needed for applicant notification page
          showHeaderPageCount={showHeaderPageCount}
          setItemsPerPage={setItemsCountPerPage}
          hideHeaderInputLabel={hideHeaderInputLabel}
          noContainerClass={noContainerClass}
          staticRole={staticRole}
        />
      )}

      <Spin spinning={loading}>
        <div className="custom-body-container container">
          <BaseTable
            columns={columns}
            mobileColumnsKey={mobileColumnsKey}
            customExpandable={customExpandable}
            customExpandIcon={customExpandIcon}
            expandedByDefault={expandedByDefault}
            data={data}
            tableClasses={tableClasses}
            expandedData={expandedData}
            differentToggleIcon={differentToggleIcon}
            expandedIcon={expandedIcon}
            nonExpandedIcon={nonExpandedIcon}
            tableSortType={tableSortType}
            setTableSortType={setTableSortType}
            tableSortBy={tableSortBy}
            setTableSortBy={setTableSortBy}
            {...restProps}
          />
        </div>
      </Spin>

      {showPagination && totalItemsCount > 0 && (
        <div className={"paginationContainer container"}>
          <div
            className={clsx(
              "ThirdlyUI-table-pagination",
              tableClasses?.pagination,
            )}
          >
            <span>
              Showing {computeMember()} of {totalItemsCount} results
            </span>
            {!showPaginationDataOnly && (
              <Pagination
                onChange={pageSource?.setPage ?? handlePagination}
                current={pageSource?.page ?? page}
                total={totalItemsCount}
                pageSize={itemsPerPage}
              />
            )}
          </div>
        </div>
      )}
    </TableContainer>
  );
}

Table.propTypes = {
  /**
   * const columns = [
   *   {
   *    title: "Id",
   *     dataIndex: "id",
   *     key: "id",
   *     align: "center",
   *     render: (text, record) => {
   *       return <a>{text}</a>;
   *     }
   *   }
   * ];
   */
  columns: PropTypes.array.isRequired,
  mobileColumnsKey: PropTypes.array,
  /**
   * Available in next version
   */
  serverSide: PropTypes.func,
  /**
   * a function return a api call promise with this args { page = 0, search = "", filters = "", sort = "", sortType = "asc" }
   */
  api: PropTypes.func,
  reload: PropTypes.any,
  /**
   * [
   *   {
   *     name: "status",
   *     showText: "Status",
   *     options: [
   *       { label: "Disable", value: "disable" },
   *       { label: "Enable", value: "enable" }
   *     ]
   *   }
   * ]
   */
  showTeamMemberFilter: PropTypes.bool,
  /**
   * table title
   */
  // title: PropTypes.string,
  /**
   * [ { label: "Total Projects", value: 20 }} ]
   */
  // extraDetails: PropTypes.func,
  // showMainViewType: PropTypes.bool,
  showSearch: PropTypes.bool,
  showTableHeader: PropTypes.bool,
  showPagination: PropTypes.bool,
  /**
   * [ { value: "date", label: "Date" } ];
   */
  sorts: PropTypes.array,
  /**
   * { icon: "♣", label: "Project Templates", onClick: () => console.log("test") }
   */
  // moreActions: PropTypes.array,
  /**
   * [reactElement, reactElement]
   */
  // buttons: PropTypes.array,
  OtherView: PropTypes.func,
  OtherViewIcon: PropTypes.object,
  onViewChange: PropTypes.func,
  dataSource: PropTypes.array,
  CardComponent: PropTypes.func,
  cardGridProps: PropTypes.object,
  //! props needed for applicant notification page
  searchProperties: PropTypes.shape({
    label: PropTypes.bool,
    //! change after updating other component change to only string
    placeholder: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOf([null]),
    ]),
  }),
};
