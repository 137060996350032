import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    height: "100%",
    ...theme.fonts.s14w500,
    padding: "0px 5px",
    backgroundColor: theme.backgrounds.white1,
    borderRadius: "2px",
    lineHeight: "25px",
    textAlign: "center",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    gap: "5px",
    "& svg": {
      width: "18px",
      height: "18px",
    },
    "& svg path": {
      fill: "#fff !important",
    },
  },
}));

const Badge = ({ children, onClick = () => {}, className, ...restProps }) => {
  const classes = useStyles();
  return (
    <div
      onClick={onClick}
      className={clsx([classes.root, className])}
      {...restProps}
    >
      {children}
    </div>
  );
};

export default Badge;
