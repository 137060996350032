export const stepWrapperStyle = theme => ({
  stepWrapper: {
    width: "100%",
    height: "100%",
    position: "relative",
    "& .custom-steps-root": {
      padding: "40px 30px",
      [theme.media(650)]: {
        padding: "20px 10px",
        marginBottom: 60,
        "& .ant-steps-item-content": {
          display: "none",
        },
      },
      "& .ant-steps-item": {
        "& .ant-steps-item-container": {
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          "& .ant-steps-item-content": {
            "& .ant-steps-item-title": {
              ...theme.fonts.s14w600,
              textTransform: "uppercase",
              [theme.media(990)]: {
                display: "none",
              },
              transition: "all .3s",
              "&::after": {
                display: "none",
              },
            },
          },
        },
      },
      "& > .ant-steps-item:last-child": {
        display: "none",
      },
      "& .ant-steps-item-icon": {
        zIndex: 5,
        "& .ant-steps-icon": {
          ...theme.fonts.s16w600,
        },
      },
      "& .ant-steps-item-wait": {
        "& .ant-steps-item-icon": {
          backgroundColor: "#13151B",
          borderColor: "#9CACBC",
          "& span": {
            color: "#9CACBC",
          },
        },
        "& .ant-steps-item-title": {
          color: "#9CACBC",
        },
      },
      "& .ant-steps-item-process": {
        "& .ant-steps-item-icon": {
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
          "& span": {
            color: "#13151B",
          },
        },
        "& .ant-steps-item-title": {
          color: theme.palette.primary.main,
        },
      },
      "& .ant-steps-item-finish": {
        "& .ant-steps-item-icon": {
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
          "& svg path": { fill: "#13151B" },
        },
        "& .ant-steps-item-title": {
          color: theme.theme_type === "light" ? "#9CACBC" : "#FFF",
        },
      },
    },
    "& .formWrapper": {
      maxWidth: 610,
      margin: "0 auto",
      "& .ant-select-selection-placeholder, & .ant-picker-input input::placeholder":
        {
          color: "#FFF",
        },
      //_______________phone number
      "& .ant-form-item-has-error .ant-input-group-addon, & .ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input:hover, .ant-form-item-has-error .ant-input-affix-wrapper:hover":
        {
          borderColor: "#ff4d4f",
        },
      "& .ant-input-group-addon": {
        backgroundColor: "rgba(255, 255, 255, 0.1) !important",
        border: "1px solid #FFFFFF50",
      },
      "& .ant-input-group-addon .ant-select-open .ant-select-selector, .ant-input-group-addon .ant-select-focused .ant-select-selector":
        {
          color: "#FFF",
        },
      "& .ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child":
        {
          borderTopLeftRadius: 5,
          borderBottomLeftRadius: 5,
        },
      "& .ant-input-group": {
        "& input": {
          backgroundColor: "rgba(19, 21, 27, 0.3)",
          padding: "7px 11px",
          borderTopRightRadius: 5,
          borderBottomRightRadius: 5,
          border: "1px solid #FFFFFF50",
        },
      },
      "& .ant-select-selector": {
        minHeight: "auto",
      },
      //_______________end phone number
    },
    "& .btnWrapper": {
      textAlign: "right",
      "& .nextBtn": {
        padding: "8px 44px",
        "&:disabled": {
          backgroundColor: "#A58A39",
          borderColor: "#A58A39",
          color: "#A8AEAE",
          cursor: "none",
        },
      },
      "& .prevBtn": {
        padding: "8px 30px",
        backgroundColor: "transparent",
        border: "1px solid #FFF",
        marginRight: 16,
      },
      "& .ant-form-item-control-input-content": {
        display: "flex",
        justifyContent: "flex-end",
        [theme.media(650)]: {
          justifyContent: "space-between",
        },
      },
    },
    "& .line": {
      position: "absolute",
      width: "71%",
      top: "49px",
      left: "0",
      right: "0",
      [theme.media(650)]: {
        top: "31px",
      },
    },
  },
});
