import { useEffect, useState, lazy } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import Spin from "Components/Spinner/Spin";
import { makeStyles } from "@material-ui/core";
import { getDashboard } from "Services";
import {mainRole} from "../../Utils/roles";

const AgentDashboardPage = lazy(() => import("./Agent").then());
const ApplicantDashboardPage = lazy(() => import("./Applicant").then());
const AdminDashboardPage = lazy(() => import("./Admin").then());

const useStyles = makeStyles(theme => ({
  dashboard: {
    margin: "70px 0px",
  },
}));

const DashBoardPage = () => {
  const classes = useStyles();
  const userType = useSelector(store => mainRole(store?.auth?.user));
  const [loading, setLoading] = useState();
  const [dashboardData, setDashboardData] = useState({});

  const handle_getData = async () => {
    setLoading(true);
    await getDashboard().then(res => {
      setDashboardData(res);
    });
    setLoading(false);
  };

  useEffect(() => {
    if (!userType?.includes("admin")) {
      handle_getData().catch(() => setLoading(false));
    }
  }, []);

  const handle_renderDashboard = () => {
    if (userType?.includes("admin")) {
      return <AdminDashboardPage />;
    } else if (
      userType === "agent" ||
      userType === "owner" ||
      userType === "team_member"
    ) {
      return <AgentDashboardPage data={dashboardData} loading={loading} />;
    } else if (userType === "buyer" || userType === "seller") {
      return <ApplicantDashboardPage data={dashboardData} />;
    }
  };

  return (
    <Spin spinning={loading}>
      <div className={clsx(classes.dashboard)}>{handle_renderDashboard()}</div>
    </Spin>
  );
};

export default DashBoardPage;
