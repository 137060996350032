import React from "react";
import { makeStyles } from "@material-ui/core";
import { InputNumber } from "antd";
import { inputsStyles } from "./styles/inputs.style";
import clsx from "clsx";

import { ReactComponent as PoundSignIcon } from "Assets/img/icons/icon_pound.svg";

const useStyles = makeStyles(inputsStyles);

export default function PriceInput({
  placeholder,
  className,
  defaultValue,
  name,
  form,
  onChange,
  customError,
  disabledFields = [],
  disabled,
  ...restProps
}) {
  const classes = useStyles();

  const handleChange = value => {
    form.setFieldsValue({
      [name]: value,
    });
    if (onChange) onChange(value);
  };

  return (
    <div
      className={clsx(
        classes.priceInputWrapper,
        (disabledFields?.includes(name) || disabled) &&
          "input-wrapper-disabled",
      )}
    >
      <InputNumber
        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        parser={value => value.replace(/\$\s?|(,*)/g, "")}
        className={clsx(classes.input, classes.inputWithLeftIcon, className)}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={handleChange}
        disabled={disabledFields?.includes(name) || disabled}
        min={0}
        {...restProps}
      />
      <PoundSignIcon />
      {customError?.condition && (
        <div
          style={{ position: "absolute" }}
          className="ant-form-item-explain ant-form-item-explain-error"
        >
          <div role="alert">{customError?.message}</div>
        </div>
      )}
    </div>
  );
}
