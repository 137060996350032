import moment from "moment";

function timesFormat(format, time) {
  return moment(time).format(format);
}

// result: 21 Mar, 2020 | 12:30 pm
export const primaryTimeFormat = (time, chars = ",") =>
  moment(time).format(`D MMM${chars} YYYY | hh:mm a`);

export const viewTicketCreatedOnTimeFormat = (time, chars = ",") =>
  moment(time).format(`MMM D${chars} YYYY | hh:mm a`);

export const timeFormattedAndSeparated = (time, chars = ",") => {
  const formattedDate = moment(time).format(`D MMM${chars} YYYY`);
  const formattedTime = moment(time).format(`hh:mm a`);
  return { date: formattedDate, time: formattedTime };
};

export const momentFormat = {
  time: timesFormat.bind(null, "hh:mm a"), // 02:58 pm
  dataWithTime: timesFormat.bind(null, "MM/DD/YYYY | hh:mm a"), // 08/03/2021 | 02:58 pm
};

export const birthDayFormatter = data => {
  return moment(data, "DDMMYYYY").format("YYYY-MM-DD");
};

export const convertUploadDate = time => {
  return moment(time, "YYYY-MM-DD hh:mm:ss").format("D MMM, YYYY | hh:mm:ss");
};
