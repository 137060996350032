import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { useState } from "react";
import CallModal from "Components/Modal/CallModal";
import {
  AgencyBranchBox,
  ApplicantBox,
  PropertyBox,
  TeamAgentOwner,
} from "./index";

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    background: "transparent",
  },
}))(Tooltip);

export default function InfoBoxTooltip({ info, children, type }) {
  // handle call modal
  const [showCallModal, setShowCallModal] = useState(false);

  const box_render = () => {
    switch (type) {
      case "agency":
        return <AgencyBranchBox info={info} type={type} />;
      case "branch":
        return <AgencyBranchBox info={info} type={type} />;
      case "applicant":
        return <ApplicantBox info={info} setShowCallModal={setShowCallModal} />;
      case "team_agent_owner":
        return <TeamAgentOwner info={info} />;
      case "property":
        return <PropertyBox info={info} />;
      default:
        break;
    }
  };

  return (
    <>
      {!!showCallModal && (
        <CallModal
          visible={showCallModal}
          handleCancel={() => setShowCallModal(false)}
          phone={info?.phone}
        />
      )}
      <CustomTooltip placement="top" title={box_render()} interactive={true}>
        {children}
      </CustomTooltip>
    </>
  );
}

InfoBoxTooltip.defaultProps = {
  type: "agency",
};

InfoBoxTooltip.propTypes = {
  type: PropTypes.oneOf([
    "agency",
    "branch",
    "applicant",
    "property",
    "team_agent_owner",
  ]),
};
