import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { mobileMenuSellerBuyerStyle } from "./navigationbarBS.style";
import { NavLink, useHistory } from "react-router-dom";
import {activeRoute, handle_redirectToHome} from "Utils/helperFunction";
import clsx from "clsx";
import Dropdown from "Components/Dropdown";
import { Menu } from "antd";
// redux
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { logout } from "Store/_actions/auth.action";
import { updateProfileRole } from "Store/_actions/auth.action";

import { ReactComponent as LogoIcon } from "Assets/img/icons/icon_bsMenu-uth-logo.svg";
import { ReactComponent as OpenMenuIcon } from "Assets/img/icons/trader-mobile-menu.svg";
import { ReactComponent as CloseMenuIcon } from "Assets/img/icons/trader-close-menu.svg";
import { ReactComponent as MagnifierIcon } from "Assets/img/icons/icon_bsMenu-magnifier.svg";
import { ReactComponent as PersonIcon } from "Assets/img/icons/icon_bsMenu-person.svg";
import {mainRole} from "../../../Utils/roles";
import authActionType from "../../../Store/_types/auth.type";

const useStyles = makeStyles(mobileMenuSellerBuyerStyle);

export default function MenuListBsMobile({ router }) {
  const classes = useStyles();
  const history = useHistory();
  const hostName = window.location.origin;
  const dispatch = useDispatch();
  const { user, userRole } = useSelector(
    state => ({
      user: state.auth?.user,
      userRole: mainRole(state?.auth?.user),
    }),
    shallowEqual,
  );

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleChangeTrader = () => {
    setShowMenu(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(updateProfileRole(userRole === "buyer" ? "seller" : "buyer"));
    history.push("/");
  };

  const [showMenu, setShowMenu] = useState(false);

  const token = useSelector(state => state?.auth?.user?.password_onetime_token)
  const handleGoToHome = (pathname) =>{
    handle_redirectToHome(pathname, token, dispatch)
  }

  return (
    <div className={classes.root}>
      <div className={classes.topSection}>
        <header>
          <a href={`${hostName}/`}>
            <LogoIcon />
          </a>
          <button
            onClick={() => setShowMenu(!showMenu)}
            className={classes.toggleMenuButton}
          >
            {showMenu ? <CloseMenuIcon /> : <OpenMenuIcon />}
          </button>
        </header>
        <div
          className={classes.menu}
          style={{
            maxHeight: showMenu ? "1200px" : "0px",
            opacity: showMenu ? 1 : 0,
            visibility: showMenu ? "visible" : "hidden",
            marginTop: showMenu ? "60px" : "0px",
          }}
        >
          <ul>
            <li>
              <a
                onClick={()=>handleGoToHome("properties")}
                className="menu-link"
                rel="noreferrer"
              >
                LISTINGS
              </a>
            </li>
            <li>
              <a
                onClick={()=>handleGoToHome("bidding-room")}
                className="menu-link"
                rel="noreferrer"
              >
                BIDDING ROOM
              </a>
            </li>

            <li>
              <Dropdown
                overlayClassName={classes.profileDropdown}
                placement="bottomRight"
                overlay={
                  <Menu>
                    <Menu.Item key="1" onClick={handleChangeTrader}>
                      Switch to {userRole === "buyer" ? "Seller" : "Buyer"}
                    </Menu.Item>
                    <Menu.Item key="2" onClick={handleLogout}>
                      Logout
                    </Menu.Item>
                  </Menu>
                }
              >
                <span className="menu-link">
                  <i>
                    <PersonIcon />
                  </i>
                  MY ACCOUNT
                </span>
              </Dropdown>
            </li>

            <li>
              <a
                rel="noreferrer"
                className="menu-link"
                onClick={()=>handleGoToHome("search?page=1&latitude=51.4893335&longitude=-0.14405508&location=London%2C+England%2C+United+Kingdom&radius=10&advance=0")}
              >
                <i>
                  <MagnifierIcon />
                </i>
                SEARCH
              </a>
            </li>

            <li>
              <a
                rel="noreferrer"
                className="menu-link"
                onClick={()=>handleGoToHome("properties")}
              >
                LISTINGS
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                className="menu-link"
                onClick={()=>handleGoToHome("bidding-room")}
              >
                BIDDING ROOM
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                className="menu-link"
                onClick={()=>handleGoToHome("buying")}
              >
                BUYING
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                className="menu-link"
                onClick={()=>handleGoToHome("selling")}
              >
                SELLING
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                className="menu-link"
                onClick={()=>handleGoToHome("contact")}
              >
                CONTACT US
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div
        className={classes.bottonSection}
        style={{
          maxHeight: showMenu ? "1200px" : "0px",
          opacity: showMenu ? 1 : 0,
          visibility: showMenu ? "visible" : "hidden",
          padding: showMenu ? "26px" : "0px",
        }}
      >
        <ul>
          {router.length &&
            router?.map((route, index) => {
              const isActive = activeRoute(route.path);
              //buyers see own invoices
              const isDisabled = route.name?.includes("nvoice");
              return (
                <li key={index}>
                  <NavLink
                    className={clsx(
                      { active: isActive },
                      isDisabled && "navlink-disabled",
                    )}
                    to={route.layout + route.path}
                    onClick={e => {
                      if (isDisabled) e.preventDefault();
                      else {
                        setShowMenu(false);
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }
                    }}
                    disabled={isDisabled}
                  >
                    {route.name}
                  </NavLink>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
}
