import { cloneElement } from "react";
//Components
import BuyerSellerNavigationBar from "./NavigationBar/BuyerSeller";
//Routes
import routes from "Routes/allRoutes";
import BackgroundTheme from "./BackgroundTheme";
import MainWrapper from "./MainWrapper";
import Footer from "./Footer";

export default function Layout({ children }) {
  const ROUTES = routes();

  return (
    <>
      <BackgroundTheme />
      <MainWrapper>
        <BuyerSellerNavigationBar routes={ROUTES} />
        <div>{cloneElement(children, { routes: ROUTES })}</div>
        <Footer />
      </MainWrapper>
    </>
  );
}
