import axios from "./Api";
export const getTickets = ({ page, order_by, order_type }) =>
  axios.get(
    `/tickets?offset=${page}&order_type=${order_type}&order_by=${order_by}`,
  );

export const addNewTicket = data => axios.post(`/tickets`, data);

export const getSingleTicket = id => axios.get(`/tickets/${id}`);
export const getTicketMessages = id => axios.get(`/tickets/${id}/messages`);
export const addNewTicketMessage = (id, data) =>
  axios.post(`/tickets/${id}/messages`, data);
export const closeTicket = id => axios.get(`/tickets/${id}/close`);
