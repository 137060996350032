import { Drawer, makeStyles } from "@material-ui/core";
import { Col, Row } from "antd";
import { themesGradient } from "Utils/theme";
import { useSelector, useDispatch } from "react-redux";
import { updateTheme } from "Store/_actions/auth.action";

import { ReactComponent as CheckIcon } from "Assets/img/icons/icon_check.svg";
import { ReactComponent as CloseIcon } from "Assets/img/icons/icon_close.svg";

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: "1501 !important",
    "& .MuiDrawer-paper": {
      padding: "30px 20px",
    },
  },
  box: {
    maxWidth: 340,
    "& p": {
      marginTop: 30,
    },
    "& button": {
      display: "flex",
      alignItems: "center",
      "& svg path": {
        fill: "#333",
      },
    },
    "& span": {
      width: 100,
      height: 100,
      borderRadius: 5,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      border: "1px solid black",
      [theme.media(320)]: {
        width: 80,
        height: 80,
      },
    },
    "&:last-child": {
      "& path": {
        fill: "#333",
      },
    },
  },
}));

export default function SettingSideBar({ handleSettingToggle, open }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth?.user);

  const handleThemeName = theme_name => {
    dispatch(updateTheme({ theme_name, theme_type: user.theme_type }));
  };
  const handleThemeType = theme_type => {
    dispatch(updateTheme({ theme_name: user.theme_name, theme_type }));
  };

  return (
    <Drawer
      anchor={"right"}
      open={open}
      className={classes.root}
      onClose={handleSettingToggle}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <div className={classes.box}>
        <button onClick={() => handleSettingToggle()}>
          <CloseIcon />
        </button>
        <p>Themes</p>
        <Row gutter={[0, 20]}>
          {Object.entries(themesGradient).map(([key, value]) => (
            <Col xs={8} key={key}>
              <span
                style={{ background: value }}
                onClick={() => handleThemeName(key)}
              >
                {user.theme_name === key && <CheckIcon />}
              </span>
            </Col>
          ))}
        </Row>
      </div>

      <div className={classes.box}>
        <p>Modes</p>
        <Row gutter={[0, 20]}>
          <Col xs={8}>
            <span onClick={() => handleThemeType("light")}>
              Light
              {user.theme_type === "light" && <CheckIcon />}
            </span>
          </Col>
          <Col xs={8}>
            <span onClick={() => handleThemeType("glass")}>
              Dark
              {user.theme_type === "glass" && <CheckIcon />}
            </span>
          </Col>
        </Row>
      </div>
    </Drawer>
  );
}
