import S3 from "react-aws-s3";

const config = {
  bucketName: "uth-storage",
  region: "eu-west-2",
  accessKeyId: "AKIA4SG7TUY3AL2ILWZU",
  secretAccessKey: "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",
};

export const ReactS3Client = new S3(config);
