import NOTFoundImg from "Assets/img/not_found.svg";
import Wrapper from "./Wrapper";

export default function NotFound() {
  return (
    <Wrapper img={NOTFoundImg}>
      <p>oops!</p>
      <p>we can’t find the page you’re looking for</p>
      <p>THe page is missing or you assembled the link incorrectly.</p>
    </Wrapper>
  );
}
