import { useEffect } from "react";
import { Checkbox } from "antd";
import { makeStyles } from "@material-ui/core";
import { inputsStyles } from "./styles/inputs.style";
import clsx from "clsx";

const useStyles = makeStyles(inputsStyles);

export default function CheckboxInput({
  defaultChecked,
  children,
  className,
  form,
  name,
  // onChange,
  // value,
  ...restProps
}) {
  const classes = useStyles();

  const handleClick = localValue => {
    form.setFieldsValue({
      [name]: localValue.target.checked,
    });
  };

  return (
    <Checkbox
      className={clsx(classes.checkbox, className)}
      defaultChecked={defaultChecked}
      onClick={handleClick}
      {...restProps}
    >
      {children}
    </Checkbox>
  );
}
