import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useMediaQuery, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";

// components
import EmptyView from "Components/ResultPage/EmptyView";

// Icons
import { ReactComponent as TableToggleOpenIcon } from "Assets/img/icons/table-toggle-open.svg";
import { ReactComponent as TableToggleCloseIcon } from "Assets/img/icons/table-toggle-close.svg";
import { ReactComponent as UpIcon } from "Assets/img/icons/icon_small_up_arrow.svg";
import { ReactComponent as DownIcon } from "Assets/img/icons/icon_small_down_arrow.svg";
import { componentPropsValidator } from "Utils/helperFunction";
import { FadeUpBox } from "Components/Animation";

// styles
import { baseTableStyle } from "./styles/base_table.styles";

// destructure Components
const { Column } = Table;

const useStyles = makeStyles(baseTableStyle);

export default function BaseTableComponent({
  columns,
  mobileColumnsKey,
  expandIconColumnIndex = 0,
  data,
  breakpoints,
  tableClasses,
  customExpandable,
  customExpandIcon = null,
  expandedByDefault,
  expandedData,
  differentToggleIcon,
  expandedIcon,
  nonExpandedIcon,
  tableSortType,
  setTableSortType,
  tableSortBy,
  setTableSortBy,
  ...restProps
}) {
  const classes = useStyles();

  const matches = useMediaQuery(breakpoints || "@media (max-width:992px)");

  const [mobileColumns, setMobileColumns] = useState({ first: [], second: [] });

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  // handle mobile columns
  // first => columns on top
  // second => columns on expanded
  useEffect(() => {
    if (mobileColumnsKey?.length) {
      const first = columns.filter(
        value => mobileColumnsKey.indexOf(value.key) > -1,
      );
      const second = columns.filter(
        value => mobileColumnsKey.indexOf(value.key) === -1,
      );
      setMobileColumns({ first, second });
    } else {
      const first = columns.slice(0, 1);
      const actionColumn = columns.find(value => value.key === "Actions");
      if (actionColumn) first.push(actionColumn);
      const second = columns.slice(1).filter(value => value.key !== "Actions");
      setMobileColumns({ first, second });
    }
  }, [columns, mobileColumnsKey]);
  const expandable = customExpandable ?? {
    expandedRowRender: record => (
      <>
        <div className="d-flex expandTable-container">
          <div>
            {mobileColumns.second.map((column, index) => {
              return (
                <FadeUpBox
                  key={Math.random() + "cell-mobile-head"}
                  initial="show"
                >
                  <div
                    className={`cell-mobile-head common-mobile-cell common-mobile-cell-${index} cell-mobile-head-${index}`}
                  >
                    <div>{column.title}</div>
                  </div>
                </FadeUpBox>
              );
            })}
          </div>
          <div style={{ flexGrow: 1 }}>
            {mobileColumns.second.map((column, index) => {
              const { render: Render } = column;
              return (
                <FadeUpBox
                  key={Math.random() + "cell-mobile-body"}
                  initial="show"
                >
                  <div
                    className={`cell-mobile common-mobile-cell common-mobile-cell-${index} cell-mobile-${index}  ${column.className}`}
                  >
                    <Render record={record} />
                  </div>
                </FadeUpBox>
              );
            })}
          </div>
        </div>
        {expandedData && componentPropsValidator(expandedData, record)}
      </>
    ),
    // expandIcon: ({ expanded, onExpand, record }) => (
    //   <div className="cell">
    //     {expanded ? (
    //       <div onClick={e => onExpand(record, e)}>
    //         <TableToggleCloseIcon />
    //       </div>
    //     ) : (
    //       <div onClick={e => onExpand(record, e)}>
    //         <TableToggleOpenIcon />
    //       </div>
    //     )}
    //   </div>
    // ),
    expandedRowKeys: expandedByDefault ? data.map(item => item.key) : null,
    expandIcon: customExpandIcon
      ? customExpandIcon
      : ({ expanded, onExpand, record }) => (
          <div className={`cell ${expanded ? "expanded" : "nonExpanded"}`}>
            {expanded ? (
              <div onClick={e => onExpand(record, e)}>
                {differentToggleIcon ? expandedIcon : <TableToggleCloseIcon />}
              </div>
            ) : (
              <div onClick={e => onExpand(record, e)}>
                {differentToggleIcon ? (
                  nonExpandedIcon
                ) : (
                  <TableToggleOpenIcon />
                )}
              </div>
            )}
          </div>
        ),

    // expandRowByClick: true,
  };

  // handle sort
  const [showSortArrow, setShowSortArrow] = useState(false);
  const [sortClickCounter, setSortClickCouter] = useState(1);

  const handleSortClick = column => {
    setTableSortBy(column?.sort);

    if (showSortArrow) {
      setTableSortType(tableSortType === "desc" ? "asc" : "desc");
    } else {
      setTableSortType("desc");
    }

    setSortClickCouter(sortClickCounter + 1);
    setShowSortArrow(true);
  };

  // reset counter to 1 when tableSortBy changed
  useEffect(() => {
    setSortClickCouter(1);
  }, [tableSortBy]);

  useEffect(() => {
    if (sortClickCounter % 3 === 0) {
      setTableSortBy("");
      setTableSortType("desc");
      setShowSortArrow(false);
    }
  }, [sortClickCounter]);
  return (
    <div className={clsx(classes.table_root, tableClasses?.baseTable)}>
      <div className="ThirdlyUI-table-wrapper">
        <Table
          dataSource={data}
          pagination={false}
          expandable={matches ? expandable : customExpandable}
          expandIconColumnIndex={expandIconColumnIndex}
          locale={{
            emptyText: <EmptyView style={{ minHeight: 360 }} />,
          }}
          {...restProps}
        >
          {matches ? (
            <>
              {mobileColumns.first.map((column, index) => {
                const {
                  render: Render,
                  align,
                  bodyPadding = true,
                  expandBodyPadding,
                  mobileWidth,
                  expandAlign,
                  expandTitle,
                  className,
                  ...columnPorps
                } = column;
                const cellAlign = expandAlign || align || "center";

                return (
                  <Column
                    key={index}
                    align={cellAlign}
                    width={mobileWidth}
                    render={(text, record) => {
                      return (
                        <div
                          className={clsx(
                            "cell",
                            classes[`cellAlign_${cellAlign}`],
                            bodyPadding && classes.bodyPadding,
                            className,
                          )}
                          style={{
                            padding:
                              expandBodyPadding ??
                              (typeof bodyPadding === "number" && bodyPadding),
                          }}
                        >
                          <Render text={text} record={record} />
                        </div>
                      );
                    }}
                    {...columnPorps}
                  />
                );
              })}
            </>
          ) : (
            columns.map((column, index) => {
              const {
                render: Render,
                align = "center",
                bodyPadding = true,
                className,
                hideColumn,
                title,
                ...columnProps
              } = column;
              if (hideColumn) {
                return undefined;
              }

              return (
                <Column
                  key={index}
                  align={align}
                  render={(text, record) => {
                    return (
                      <FadeUpBox
                        key={Math.random()}
                        initial={isFirstRender ? "hidden" : "show"}
                      >
                        <div
                          className={clsx([
                            "cell",
                            classes[`cellAlign_${align}`],
                            typeof bodyPadding === "boolean" &&
                              bodyPadding &&
                              classes.bodyPadding,
                            className,
                          ])}
                          style={{
                            padding:
                              typeof bodyPadding === "number" && bodyPadding,
                          }}
                        >
                          <Render text={text} record={record} />
                        </div>
                      </FadeUpBox>
                    );
                  }}
                  title={
                    <div
                      className={classes.theadItem}
                      style={{ cursor: !!column.sort && "pointer" }}
                      onClick={
                        !!column.sort ? () => handleSortClick(column) : null
                      }
                    >
                      <span>{title}</span>
                      {!!column.sort &&
                        showSortArrow &&
                        tableSortBy === column?.sort &&
                        !(sortClickCounter % 3 === 0) && (
                          <div className={classes.sortButtons}>
                            {tableSortType === "asc" ? (
                              <button>
                                <UpIcon />
                              </button>
                            ) : (
                              <button>
                                <DownIcon />
                              </button>
                            )}
                          </div>
                        )}
                    </div>
                  }
                  {...columnProps}
                />
              );
            })
          )}
        </Table>
      </div>
    </div>
  );
}

BaseTableComponent.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
};
