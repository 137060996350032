import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import IntroComponent from "./IntroComponent";
import { useState } from "react";
import StepWrapper from "./StepWrapper";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 24,
    marginBottom: 125,
  },
  box: {
    width: "clamp(840px, 500px, 100%)",
    minHeight: "820px",
    // background: "rgba(0, 0, 0, 0.2)",
    // filter: "blur(10px)",
    boxShadow: "0px 0px 20px rgb(0 0 0 / 20%)",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    padding: 24,
  },
}));

export default function AMLChecksPage() {
  const classes = useStyles();
  const [start, setStart] = useState(false);

  const amlStatus = useSelector(store => store?.auth.user.aml_status);

  if (amlStatus === "passed") return <Redirect to={"/buyer/dashboard"} />;

  return (
    <div
      className={clsx(classes.root, "container d-flex justify-content-center")}
    >
      <div className={classes.box}>
        {start ? <StepWrapper /> : <IntroComponent setStart={setStart} />}
      </div>
    </div>
  );
}
