import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  phoneNumberContainer: {
    "& .form-control": {
      padding: "7px 0 7px 60px !important",
      height: "auto",
      width: "100%",
      transition: "all 0.3s",
      borderRadius: 5,
      ...theme.fonts.s14w500,
      border:
        theme.theme_type === "light"
          ? "1px solid #c1c1c1"
          : "1px solid #FFFFFF50",
      background:
        theme.theme_type === "light" ? "white" : "rgba(19, 21, 27, 0.3)",
      color: theme.theme_type === "light" ? "#333" : "white",
      outline: "none",
      "&::placeholder": {
        color:
          theme.theme_type === "light" ? "#00000033" : "rgba(255,255,255,0.7)",
      },
      "&:disabled": {
        color: "#ccc",
        border: "none",
        cursor: "not-allowed",
        opacity: 1,
        background: "#c1c1c14d",
        borderColor: "transparent",
      },
    },
    "& .flag-dropdown, .selected-flag": {
      borderRadius: "5px 0 0 5px",
      borderRight: "transparent",
      transition: "all 0.3s",
    },
    "& .selected-flag .arrow": {
      borderTopColor:
        theme.theme_type === "light" ? "#00000033" : "rgba(255,255,255,0.7)",
    },
    "& .selected-flag .arrow.up": {
      borderBottomColor:
        theme.theme_type === "light" ? "#00000033" : "rgba(255,255,255,0.7)",
    },
    "& .country-list": {
      backgroundColor: "#FFF",
      color: "#333",
    },
  },
  phoneNumberInput: {},
}));

export default function PhoneInput({ name, form, ...restProps }) {
  const classes = useStyles();

  const handleChange = (value, data, event, formattedValue) => {
    form.setFieldsValue({
      [name]: value,
    });
  };

  return (
    <ReactPhoneInput
      countryCodeEditable={restProps.countryCodeEditable ? true : false}
      country={"gb"}
      containerClass={classes.phoneNumberContainer}
      inputClass={classes.phoneNumberInput}
      onChange={handleChange}
      value={form.getFieldValue()[name]}
      disabled={restProps.disabled || ""}
      inputProps={{
        name,
      }}
    />
  );
}
