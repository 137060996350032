import { useState } from "react";
import ModalWrapper from "Components/Modal";
import { makeStyles } from "@material-ui/core";
import { confirmDeleteModalStyles } from "./styles/confirmDeleteModal.styles";
import Spin from "Components/Spinner/Spin";

const useStyles = makeStyles(confirmDeleteModalStyles);

export default function ConfirmDeleteModal({
  modalTitle,
  modalText,
  deleteBtnText,
  isVisible,
  handleCancel,
  handleDelete,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const onDelete = async () => {
    setLoading(true);
    await handleDelete(isVisible);
    setLoading(false);
  };

  return (
    <ModalWrapper
      handleCancel={handleCancel}
      isModalVisible={!!isVisible?.id}
      title={modalTitle || "Confirm Delete"}
      width={600}
    >
      <Spin spinning={loading} size={40}>
        <div className={classes.body_root}>
          <p>{modalText || "Are you sure you want to delete this?"}</p>
          <div className="btnWrapper">
            <button className="btnCancel" onClick={handleCancel}>
              Cancel
            </button>
            <button className="btnDelete" onClick={onDelete}>
              {deleteBtnText || "Delete"}
            </button>
          </div>
        </div>
      </Spin>
    </ModalWrapper>
  );
}
