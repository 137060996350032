import { useState, useEffect, useMemo, useRef } from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { Input } from "antd";
import debounce from "lodash.debounce";

// internal module
import Modal from "Components/Modal";
import PrimaryToolTip from "Components/ToolTip/PrimaryToolTip";

// assets
import { enhancedFilterModalStyle } from "./modal.styles";
import { ReactComponent as YellowFilterIcon } from "Assets/img/icons/icon_filter-yellow.svg";
import { ReactComponent as IconSearch } from "Assets/img/icons/icon_Search.svg";
import { ReactComponent as MapMarkerIcon } from "Assets/img/icons/icon_map-marker-f.svg";

// Services
import { enhancedFilter } from "Services";
import Spin from "../Spinner/Spin";

const useStyles = makeStyles(enhancedFilterModalStyle);

export default function EnhancedFilter({ setFilters, small }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  // handle modal visibility
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState("");

  const [agencies, setAgencies] = useState([]);
  const [agents, setAgents] = useState([]);
  const [branches, setBranches] = useState([]);
  const [properties, setProperties] = useState([]);

  const getItems = () => {
    setLoading(true);
    enhancedFilter({
      search,
      agent_ids: "",
    })
      .then(data => {
        setAgencies(data?.agencies?.items);
        setAgents(data?.agents?.items);
        setProperties(data?.properties?.items);
        setBranches(data?.branches?.items);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  useEffect(() => {
    if (showModal) {
      const isAlreadyFetched =
        agencies?.length +
          agents?.length +
          branches?.length +
          properties?.length >
        1;

      if (!isAlreadyFetched) {
        getItems();
      }
    }
  }, [showModal]);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };

  // handle api call with delay
  const fetchRef = useRef(0);

  // api call
  async function apiCall(value) {
    return enhancedFilter({
      search: value,
      agent_ids: "",
    }).then(data => data);
  }
  const handleSearch = useMemo(() => {
    const loadOptions = value => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setSearch(value);
      setAgencies([]);
      setAgents([]);
      setProperties([]);
      setBranches([]);
      setLoading(true);

      apiCall(value)
        .then(data => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }

          setAgencies(data?.agencies?.items);
          setAgents(data?.agents?.items);
          setProperties(data?.properties?.items);
          setBranches(data?.branches?.items);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
        });
    };

    return debounce(loadOptions, 800);
  }, []);

  // handle select items
  const [selectedAgencies, setSelectedAgencies] = useState([]);
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);

  const handleSelect = (type, itemId) => {
    if (type === "agency") {
      const temp = selectedAgencies.includes(itemId)
        ? selectedAgencies.filter(item => item !== itemId)
        : [...selectedAgencies, itemId];
      setSelectedAgencies(temp);
    }
    if (type === "branch") {
      const temp = selectedBranches.includes(itemId)
        ? selectedBranches.filter(item => item !== itemId)
        : [...selectedBranches, itemId];
      setSelectedBranches(temp);
    }
    if (type === "agent") {
      const temp = selectedAgents.includes(itemId)
        ? selectedAgents.filter(item => item !== itemId)
        : [...selectedAgents, itemId];
      setSelectedAgents(temp);
    }
    if (type === "property") {
      const temp = selectedProperties.includes(itemId)
        ? selectedProperties.filter(item => item !== itemId)
        : [...selectedProperties, itemId];
      setSelectedProperties(temp);
    }
  };

  const handleSelectAll = type => {
    if (type === "agency") {
      const temp = agencies.map(item => item.id);
      setSelectedAgencies(temp);
    }
    if (type === "branch") {
      const temp = branches.map(item => item.id);
      setSelectedBranches(temp);
    }
    if (type === "agent") {
      const temp = agents.map(item => item.id);
      setSelectedAgents(temp);
    }
    if (type === "property") {
      const temp = properties.map(item => item.id);
      setSelectedProperties(temp);
    }
  };

  const handleReset = type => {
    if (type === "agency") {
      setSelectedAgencies([]);
    }
    if (type === "branch") {
      setSelectedBranches([]);
    }
    if (type === "agent") {
      setSelectedAgents([]);
    }
    if (type === "property") {
      setSelectedProperties([]);
    }
  };

  const handleActiveClass = (type, itemId) => {
    if (type === "agency") {
      return selectedAgencies.includes(itemId);
    }
    if (type === "branch") {
      return selectedBranches.includes(itemId);
    }
    if (type === "agent") {
      return selectedAgents.includes(itemId);
    }
    if (type === "property") {
      return selectedProperties.includes(itemId);
    }
  };

  // handle submit
  const handleClickDone = () => {
    setFilters({
      agency_id: selectedAgencies,
      branch_id: selectedBranches,
      agent_id: selectedAgents,
      property_id: selectedProperties,
    });
    setShowModal(false);
  };

  return (
    <>
      <Modal
        title="Enhanced filtering"
        isModalVisible={showModal}
        wrapClassName={classes.modalWrapper}
        handleCancel={handleHideModal}
        width={400}
      >
        <Spin spinning={loading}>
          <div className={classes.wrapper}>
            <Input
              placeholder="Agent name/number/location..."
              className={classes.search}
              onChange={e => handleSearch(e.target.value)}
              suffix={
                <i>
                  <IconSearch />
                </i>
              }
            />
            <div className={classes.content}>
              <div className={clsx("custom-scrollbar", classes.innerContent)}>
                {/* Agencies  */}
                <div className="inner-head">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h4>Agencies </h4>
                    <span>({agencies?.length} results)</span>
                  </div>
                  {!!agencies?.length && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <button onClick={() => handleSelectAll("agency")}>
                        Select all
                      </button>
                      <button onClick={() => handleReset("agency")}>
                        Reset
                      </button>
                    </div>
                  )}
                </div>
                {agencies.map(item => (
                  <div
                    key={item.id}
                    onClick={() => handleSelect("agency", item.id)}
                    className={clsx(
                      classes.agentItem,
                      handleActiveClass("agency", item.id) &&
                        classes.activeitem,
                    )}
                  >
                    <h3>{item.name}</h3>
                    <div>
                      <span>{item.phone}</span>
                    </div>
                  </div>
                ))}

                {/* Branches  */}
                <div className="inner-head">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h4>Branches</h4>
                    <span>{branches?.length} results</span>
                  </div>
                  {!!branches?.length && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <button onClick={() => handleSelectAll("branch")}>
                        Select all
                      </button>
                      <button onClick={() => handleReset("branch")}>
                        Reset
                      </button>
                    </div>
                  )}
                </div>
                {branches.map(item => (
                  <div
                    key={item.id}
                    onClick={() => handleSelect("branch", item.id)}
                    className={clsx(
                      classes.agentItem,
                      handleActiveClass("branch", item.id) &&
                        classes.activeitem,
                    )}
                  >
                    <h3>{item.name}</h3>
                    <div>
                      <span>({item.agency_name})</span>
                      <span>{item.agency_phone}</span>
                    </div>
                  </div>
                ))}

                {/* Agents  */}
                <div className="inner-head">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h4>Agents</h4>
                    <span>{agents?.length} results</span>
                  </div>
                  {!!agents?.length && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <button onClick={() => handleSelectAll("agent")}>
                        Select all
                      </button>
                      <button onClick={() => handleReset("agent")}>
                        Reset
                      </button>
                    </div>
                  )}
                </div>
                {agents.map(item => (
                  <div
                    key={item.id}
                    onClick={() => handleSelect("agent", item.id)}
                    className={clsx(
                      classes.agentItem,
                      handleActiveClass("agent", item.id) && classes.activeitem,
                    )}
                  >
                    <h3>
                      {item.first_name} {item.last_name}
                    </h3>
                    <div>
                      <span>({item.agency_name})</span>
                      <span>{item.agency_phone}</span>
                    </div>
                  </div>
                ))}

                {/* PropertiesTab  */}
                <div className="inner-head">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h4>Properties</h4>
                    <span>{properties?.length} results</span>
                  </div>
                  {!!properties?.length && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <button onClick={() => handleSelectAll("property")}>
                        Select all
                      </button>
                      <button onClick={() => handleReset("property")}>
                        Reset
                      </button>
                    </div>
                  )}
                </div>
                {properties?.map(item => (
                  <div
                    key={item.id}
                    onClick={() => handleSelect("property", item.id)}
                    className={clsx(
                      classes.propertyItem,
                      handleActiveClass("property", item.id) &&
                        classes.activeitem,
                    )}
                  >
                    <h3>
                      <MapMarkerIcon />
                      <span>
                        {item.first_line_of_address}, {item.postcode} -{" "}
                        {item.city}{" "}
                      </span>
                    </h3>
                    <div>
                      <h6>Agent name</h6>
                      <span>({item.agency_name})</span>
                      <span>{item.agency_phone}</span>
                    </div>
                  </div>
                ))}
              </div>
              <div className={classes.innerFooter}>
                <button
                  className={clsx("flex-center rad-2", classes.yellowBtn)}
                  onClick={handleClickDone}
                >
                  Done
                </button>
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
      <PrimaryToolTip title={"Enhanced filtering"} className={classes.tooltip}>
        {!small ? (
          <button onClick={handleShowModal} className={classes.adminFilterBtn}>
            {/*<FilterIcon />*/}
            <YellowFilterIcon />
            <span>Enhanced filtering</span>
          </button>
        ) : (
          <button
            onClick={handleShowModal}
            className={classes.adminFilterBtnSmall}
          >
            <YellowFilterIcon />
          </button>
        )}
      </PrimaryToolTip>
    </>
  );
}
