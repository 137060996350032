import TopNav from "./TopNav";
import MenuList from "./MenuList";
import MobileScreenMenu from "./MobileScreenMenu";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { navigationStyle } from "Layouts/NavigationBar/Default/styles/navigationbar.styles";
import SideBar from "./SideBar";
import SettingSideBar from "./SettingSideBar";
import { useState } from "react";

import { ReactComponent as SettingIcon } from "Assets/img/icons/icon_setting-1.svg";

const useStyles = makeStyles(navigationStyle);

export default function NavigationBar({ routes }) {
  const classes = useStyles();

  const isMobileScreen = useMediaQuery("(max-width: 992px)");

  const routerInMenu = routes.filter(item => item?.isInMenu !== false);

  const [toggleDrawer, setToggleDrawer] = useState(false);
  const handleDrawerToggle = () => {
    setToggleDrawer(!toggleDrawer);
  };

  const [toggleSetting, setToggleSetting] = useState(false);
  const handleSettingToggle = () => {
    setToggleSetting(!toggleSetting);
  };

  return (
    <div className={classes.main_navbar}>
      <TopNav />

      {isMobileScreen ? (
        <MobileScreenMenu handleDrawerToggle={handleDrawerToggle} />
      ) : (
        <MenuList routes={routerInMenu} />
      )}

      <span className={classes.setting} onClick={handleSettingToggle}>
        <SettingIcon />
      </span>

      <SideBar
        routes={routerInMenu}
        handleDrawerToggle={handleDrawerToggle}
        open={toggleDrawer}
      />

      <SettingSideBar
        handleSettingToggle={handleSettingToggle}
        open={toggleSetting}
      />
    </div>
  );
}
