import axios, { _axios, _axiosWithAuth } from "./Api";

export const getOwnerBranchAndTeamMemersList = params =>
  axios.get("/branches", { params }).then(res => res.data);

export const getPropertiesTypesApi = () => _axios.get("/properties/types");

export const searchSeller = ({ email }) =>
  axios.get(`/find-seller-by-email?email=${email}`).then(res => res.data);

export const searchWebsite = ({ website }) =>
  axios
    .get(`/agencies/find-agency-by-website?website=${website}`)
    .then(res => res.data);

export const searchSolicitor = ({ email }) =>
  axios.get(`/find-solicitor-by-email?email=${email}`).then(res => res.data);

export const enhancedFilter = params =>
  axios.get(`/enhanced-filter`, { params }).then(res => res.data);

export const elasticSearch = value =>
  axios
    .post(`elastic-search/execute`, "", {
      params: { phrase: value },
    })
    .then(res => res.data);

export const searchLocation = value =>
  _axiosWithAuth.get(
    `/locations?search=${value}&limit=10&countrycodes=gb&filter_by=postcode`,
  );

export const searchGoogleLocation = search =>
  _axiosWithAuth
    .get(`/locations/google/search/postcode`, {
      params: { search },
    })
    .then(res => res.data);

export const searchGoogleLocationDetails = place_id =>
  _axiosWithAuth
    .get(`/locations/google/details`, { params: { place_id } })
    .then(res => res.data);

export const getCallInfo = userId =>
  _axiosWithAuth.get(`/call-id/${userId}`).then(res => res.data);

export const getAgencyCallInfo = userId =>
  _axiosWithAuth.get(`/agency/call-id/${userId}`).then(res => res.data);

export const updateThemeApi = body => axios.put(`/theme/update`, body);

// Requests

export const getLegalpackRequests = ({ page, ...params }) =>
  axios.get("/legalpack-requests", {
    params: { offset: page || 0, ...params },
  });

export const uploadLegalpackFromRequest = (id, body) =>
  axios.post(`/legalpack-requests/upload/${id}`, body);

export const getValuationRequests = ({ page, ...params }) =>
  axios.get("/valuation/index", {
    params: { offset: page || 0, ...params },
  });

// Admin/Solicitor/Documents
export const getSolicitorRequests = ({ page, ...params }) =>
  axios.get("/solicitor/documents/index", {
    params: { offset: page || 0, ...params },
  });

export const getSolicitorFiles = id =>
  axios.get(`/solicitor/documents/${id}/files`);

export const getSolicitorFilesAccept = id =>
  axios.put(`/solicitor/documents/accept-file/${id}`);

export const getSolicitorFilesReject = id =>
  axios.put(`/solicitor/documents/reject-file/${id}`);
