import { Empty } from "antd";
import { makeStyles } from "@material-ui/core";
import emptyBox from "Assets/img/icons/empty_box.svg";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .ant-empty-image": {
      height: "auto",
    },
    "& .ant-empty-description": {
      ...theme.fonts.s18w600,
      color: "#C0CCDA",
      letterSpacing: "-0.03em",
      // textTransform: "uppercase",
      marginTop: 27,
    },
  },
}));

export default function EmptyView({ message, style, children, small = false }) {
  const classes = useStyles();

  return (
    <div className={classes.root} style={style}>
      <Empty
        image={emptyBox}
        imageStyle={
          small
            ? {
                height: 60,
              }
            : undefined
        }
        description={<span>{message || "There is nothing to show"}</span>}
      >
        {children}
      </Empty>
    </div>
  );
}
