//--?? this component import from uth home landing page
//
import makeStyles from "@material-ui/core/styles/makeStyles";
// import { useSelector } from "react-redux";
// import { Input } from "antd";
// import Button from "Components/Button/Button";

// assets
import { ReactComponent as FB } from "Assets/img/icons/social/fb.svg";
import { ReactComponent as Insta } from "Assets/img/icons/social/insta.svg";
import { ReactComponent as Linkedin } from "Assets/img/icons/social/linkedin_f.svg";
import { ReactComponent as EmailIcon } from "Assets/img/icons/email.svg";
import { ReactComponent as PhoneIcon } from "Assets/img/icons/phone.svg";
import { ReactComponent as LogoIcon } from "Assets/img/icons/uth-footer.svg";
import rics from "Assets/img/rics.png";
import tpos from "Assets/img/tpos.png";
import clsx from "clsx";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  footer: {
    background: "#32383F",
    color: "#FFF",
    padding: "86px 2.8% 48px",
    "& hr": {
      borderTop: 0,
      border: "1px solid white",
      margin: "108px 0 0",
    },
    "& a:hover": {
      color: "#FFF",
    },
  },
  footerBottom: {
    marginTop: 28,
    padding: "0 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontWeight: 400,
    "&>:last-child": {
      fontSize: 14,
      fontWeight: 400,
      "& a": {
        color: "white",
      },
    },
    "& .logoContainer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 12,
      width: "50%",
      "@media (max-width: 576px)": {
        flexWrap: "wrap",
        justifyContent: "center",
        gap: 20,
      },
    },
    "@media (max-width: 992px)": {
      flexDirection: "column-reverse",
      gap: 10,
      "& .logoContainer, & .copyRight": {
        order: 1,
      },
      "& .logoContainer": {
        width: "100%",
      },
    },
    "@media (max-width: 576px)": {
      "& .logoContainer, & .copyRight": {
        textAlign: "center",
      },
    },
  },
  content: {
    "& p.label": {
      fontWeight: 700,
      fontSize: 16,
      lineHeight: "33px",
      marginBottom: 32,
      "@media (max-width: 768px)": {
        marginBottom: 8,
      },
    },
    "& p:not(.label)": {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "22px",
    },
    "& a": {
      display: "inline-block",
      color: "white",
      margin: "4px 0",
      padding: "10px 0",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "22px",
    },
    "& .content--first": {
      display: "flex",
      flexDirection: "column",
      gap: 20,
      "& .footer--logo": {
        minWidth: 100,
        maxWidth: 210,
        height: 90,
        display: "block",
      },
      "& i": {
        marginRight: 15,
      },
      "& a.logoWrapper": {
        marginBottom: 32,
        padding: 0,
        marginTop: 0,
        maxWidth: 266,
      },
      "& .contactUs": {
        "& svg path": {
          fill: "#FFF",
        },
      },
    },
    "& .content--fourth": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      "& .ant-input-group-wrapper": {
        marginTop: 24,
        "& .ant-input-group": {
          height: "100%",
          "& input": {
            fontSize: 14,
            height: "100%",
            background: "transparent",
            borderRadius: "5px 0 0 5px",
            borderRight: 0,
            color: "white",
          },
          "& .ant-input-group-addon": {
            padding: 0,
            border: 0,
            borderRadius: "0 5px 5px 0",
            "& button": {
              borderRadius: "inherit",
            },
          },
        },
      },
      "& button": {
        fontSize: 14,
        fontWeight: "bold",
        letterSpacing: "0.2em",
        padding: "8px 12px",
      },
      "& .signUp": {
        "& p": {
          marginBottom: 0,
        },
      },
      "& .joinUs": {
        marginTop: 15,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        "& p": {
          margin: 0,
          marginTop: 16,
          "& span": {
            margin: "15px 0 -15px",
            display: "block",
            fontWeight: 400,
            fontSize: 14,
            lineHeight: "22px",
          },
        },
        "@media (max-width: 992px)": {
          width: "max-content",
          "& p": {
            marginRight: 8,
          },
        },
        "@media (max-width: 768px)": {
          width: "100%",
          flexDirection: "column",
          textAlign: "center",
          gap: 10,
          "& a, button": {
            width: "85%",
          },
        },
      },
    },
    "& .socialIcon": {},
    "@media (max-width: 992px)": {
      "& .content--first": {
        alignItems: "center",
      },
      "& .content--second, & .content--third, & .content--fourth": {
        display: "flex",
        flexDirection: "column",
      },
    },
  },
  input: {
    "& input": {
      padding: "7px",
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const hostName = window.location.origin;

  return (
    <footer className={classes.footer}>
      <div className={clsx(classes.content, "row m-0")}>
        <div className="col-12 col-md-6 col-lg-3  content--first">
          <Link href="/">
            <a className={"logoWrapper"} aria-label="link to home">
              <i className="d-flex">
                <LogoIcon width="100%" height="100%" />
              </i>
            </a>
          </Link>

          <p>Join in socially:</p>

          <div>
            <a
              href="https://www.facebook.com/underthehammerofficial/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="socialIcon fb">
                <FB />
              </i>
            </a>
            <a
              href="https://www.instagram.com/underthehammerofficial/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="socialIcon insta">
                <Insta />
              </i>
            </a>
            <a
              href="https://twitter.com/underthehammer0"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="socialIcon twitter">
                <Linkedin />
              </i>
            </a>
          </div>

          <p>Or let’s talk:</p>

          <div className={"contactUs"}>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <i>
                <PhoneIcon />
              </i>
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <i>
                <EmailIcon />
              </i>
            </a>
          </div>
        </div>

        <div className="col-6 col-md-6 col-lg-3  content--second mt-5 mt-md-0">
          <p className="label">About Us</p>
          <ul>
            {/*<li>*/}
            {/*  <a href={`${hostName}/about-us`}>About Us</a>*/}
            {/*</li>*/}
            <li>
              <a href={`${hostName}`}>Get the App (coming soon)</a>
            </li>
            <li>
              <a href={`${hostName}/faqs`}>FAQ’s</a>
            </li>
            {/*<li>*/}
            {/*  <a href={`${hostName}/careers`}>Careers</a>*/}
            {/*</li>*/}
            <li>
              <a href={`${hostName}/contact`}>Contact Us</a>
            </li>
          </ul>
        </div>

        <div className="col-6 col-md-6 col-lg-3  content--third mt-5 mt-lg-0">
          <p className="label">Important Links</p>
          <ul>
            <li>
              <a href={`${hostName}/policies`}>Policies</a>
            </li>
            <li>
              <a href={`${hostName}/policies/gdpr`}>GDPR</a>
            </li>
            <li>
              <a href={`${hostName}`}>Values</a>
            </li>
            <li>
              <a href={`${hostName}`}>Corporate & Social Responsibility</a>
            </li>
            <li>
              <a href={`${hostName}/finance`}>Finance</a>
            </li>
          </ul>
        </div>

        {/*<div className="col-12 col-md-6 col-lg-3  content--fourth mt-5 mt-lg-0">*/}
        {/*  <div className="signUp">*/}
        {/*    <p className="label">Get priority alerts</p>*/}

        {/*    <Input*/}
        {/*      placeholder="Enter your email"*/}
        {/*      className={classes.input}*/}
        {/*      addonAfter={<Button color="secondary">SIGN UP</Button>}*/}
        {/*    />*/}
        {/*  </div>*/}

        {/*  <div className="joinUs">*/}
        {/*    <p className="label">*/}
        {/*      Are you an agent? <br />*/}
        {/*      <span>Auction Franchise</span>*/}
        {/*    </p>*/}
        {/*    <Link href="/join-us">*/}
        {/*      <a>*/}
        {/*        <Button color="primary">JOIN US</Button>*/}
        {/*      </a>*/}
        {/*    </Link>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>

      <hr />

      <div className={classes.footerBottom}>
        <div className="copyRight">
          © 2021 | Under The Hammer | All Rights Reserved
        </div>

        <div className="logoContainer">
          <div>
            <img
              src={rics}
              alt="National Association of Valuers and Auctioneers"
              width="110"
              height="75"
            />
          </div>
          <div>
            <img
              src={tpos}
              alt="The Property Ombudsman"
              width="140"
              height="63"
            />
          </div>
        </div>

        <div>
          <a href={`${hostName}/policies/terms-of-use`}>Terms of use</a>
          &nbsp;|&nbsp;
          <a href={`${hostName}/policies/privacy-policy`}>Privacy Policy</a>
        </div>
      </div>
    </footer>
  );
}
