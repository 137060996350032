import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { Popover } from "antd";

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: "center",
    color: "#fff",
    ...theme.fonts.s14w500,
  },
  overlay: {
    padding: 0,
    maxWidth: "100%",

    "& .ant-popover-inner": {
      background: "#566970",
      "@supports (backdrop-filter: none)": {
        background: `${theme.backgrounds.black2} !important`,
        backdropFilter: "blur(50px)",
      },
    },
    "& .ant-popover-arrow": {
      display: "none",
      // background: `${theme.backgrounds.black2} !important`,
      // width: "18px",
      // height: "18px",
      // borderRadius: "5px",
      // boxShadow: "none",
      // border: "none !important",
      // top: "-5px !important",
      // "@media only screen and (max-width: 992px)": {
      //   left: "10% !important",
      // },
      // "@media only screen and (max-width: 769px)": {
      //   left: "12% !important",
      // },
      // "@media only screen and (max-width: 500px)": {
      //   left: "19% !important",
      // },
    },
    "& .ant-popover-arrow-content": {
      background: `${theme.backgrounds.black2} !important`,
    },
    "& .ant-popover-title": {
      border: "none",
      color: "unset !important",
    },
  },
}));

const NavLargeTooltip = ({ title, className, children, align = {} }) => {
  const classes = useStyles();

  const [visible, setVisible] = useState(false);

  const handleVisibleChange = () => {
    setVisible(prev => !prev);
  };

  return (
    <Popover
      align={align}
      overlayClassName={clsx([classes.overlay])}
      // arrowPointAtCenter={true}
      title={() => title(handleVisibleChange)}
      // color="rgba(0, 0, 0, 0.3)"
      placement="bottom"
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <div className={clsx("wh100 primary-tooltip-wrapper", className)}>
        {children}
      </div>
    </Popover>
  );
};

export default NavLargeTooltip;
