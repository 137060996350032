export const confirmDeleteModalStyles = theme => ({
  body_root: {
    borderRadius: 2,
    padding: "40px 59px 30px",
    "@media (max-width: 600px)": {
      padding: "40px 20px 30px",
    },
    "& p": {
      ...theme.fonts.s16w600,
      textAlign: "center",
      marginBottom: 24,
    },
    "& .btnWrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      marginTop: 52,
      gap: 16,
      "& button": {
        width: 120,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...theme.fonts.s14w500,
      },
      "& .btnCancel": {
        padding: "9px 16px",
        transition: "all .3s",
        border:
          theme.theme_type === "light" ? "1px solid #333" : "1px solid #FFF",
        backgroundColor: "transparent",
      },
      "& .btnDelete": {
        padding: "9px 16px",
        transition: "all .3s",
        background: "#FF5939",
      },
    },
  },
});
