import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

// Filters
import FilterBranchModal from "Components/Modal/FilterBranchModal";
import FilterTeamMember from "Components/Modal/FilterBranchModal/FilterTeamMember";
import EnhancedFilter from "Components/Modal/EnhancedFilter";
import {mainRole} from "../../../Utils/roles";

const useStyles = makeStyles(theme => ({
  filter: {
    width: props => (props.userRole?.includes("admin") ? "auto" : 250),
    "& .filter-name": {
      ...theme.fonts.s14w500,
      color: "white",
      marginBottom: 8,
    },
    "&>:last-child": {
      width: "100%",
      height: 41,
      "& .ant-select-selector": {
        height: 41,
        border: "1px solid #E9F0F7",
        "&:first-child": {
          paddingTop: 5,
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
  },
}));

export default function TableFilterTeamMember({
  setTeamMemberId,
  setAdminFilters,
  hideHeaderInputLabel,
  staticRole,
}) {
  const globalUserRole = useSelector(store => mainRole(store.auth.user));
  const [userRole, setUserRole] = useState(staticRole || globalUserRole);
  useEffect(() => {
    setUserRole(staticRole || globalUserRole);
  }, [staticRole]);
  const classes = useStyles({ userRole });

  return userRole !== "team_member" ? (
    <>
      <div className={`${classes.filter} filter`}>
        {!hideHeaderInputLabel && (
          <div
            className="filter-name"
            style={{ visibility: userRole?.includes("admin") && "hidden" }}
          >
            Filter by
          </div>
        )}

        {userRole === "owner" ? (
          <FilterBranchModal setSelectedIds={setTeamMemberId} />
        ) : userRole?.includes("admin") ? (
          <EnhancedFilter setFilters={setAdminFilters} />
        ) : (
          <FilterTeamMember setSelectedIds={setTeamMemberId} />
        )}
      </div>
    </>
  ) : null;
}

// <div className={clsx(classes.filter)}>
//   {!hideHeaderInputLabel && (
//     <div className="filter-name"> Filter by </div>
//   )}

//   <Select
//     className={classes.selectBox}
//     defaultValue={filters[0].value}
//     onChange={data => {
//       setTableFilters(data);
//     }}
//   >
//     {filters.map(({ name, value, disable }) => (
//       <Option value={value} disable={disable}>
//         {name}
//       </Option>
//     ))}
//   </Select>
// </div>
