export const myBidsStyles = theme => ({
  roleList_root: {
    "& .header": {
      marginTop: 60,
      "& .filter_select": {
        "& label": {
          ...theme.fonts.s14w500,
          color: "#7E7E7E",
        },
      },
      "& .ant-select-single:not(.ant-select-customize-input) .ant-select-selector ":
        {
          border: "1px solid #E9F0F7",
          borderRadius: 2.5,
          padding: "4px 16px",
          height: 40,
        },
      "& .ant-input:focus, .ant-input-focused, ,.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector":
        {
          borderColor: theme.palette.primary.main,
          boxShadow: "unset",
        },
      "& .ant-select-arrow": {
        top: "35%",
      },
    },
  },
  baseTable: {
    "& .ant-table-tbody .ant-table-row div.cell": {
      height: 60,
    },
  },
  history: {
    width: "100%",
    height: "100%",
    backgroundColor:
      theme.theme_type === "light" ? "transparent" : "rgba(0, 0, 0, 0.7)",
    border: "1px solid #FFF",
    transition: "all .3s",
    cursor: "pointer",
    "& button": {
      width: "inherit",
      height: "inherit",
    },
    "& svg path": {
      fill: theme.theme_type === "light" ? "#333" : "#fff",
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
  },
  fontStyle: {
    ...theme.fonts.s14w500,
  },
  imgDiv: {
    ...theme.imgDiv(44, 44, 2, true),
    "& img": {
      ...theme.img100,
    },
  },
  status: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    height: "100%",
    width: "100%",
  },
  buildingImg: {
    borderRadius: 2,
    width: 84,
    height: 44,
    backgroundSize: "cover",
    backgroundPosition: "center",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  editBtnWrapper: {
    width: "100%",
    height: "100%",
    padding: "0 !important",
    backgroundColor: "transparent",
    transition: "all .3s",
    "& svg path": {
      fill: "#FFF",
      transition: "all .3s",
    },
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      "& svg path": {
        fill: theme.palette.primary.main,
      },
    },
    "& .primary-tooltip-wrapper": {
      display: "grid",
      placeItems: "center",
    },
  },
});

export const bidModalStyles = theme => ({
  wrapModal: {},
  modalEdit: {
    "& .watchListItem": {
      backgroundColor: theme.theme_type === "light" ? "#F8FAFC" : "#ffffff1a",
      borderRadius: 3,
      padding: 20,
      transition: "all .3s",
      marginBottom: 20,
      "& .imgDiv": {
        marginRight: 17,
        ...theme.imgDiv(105, "100%", 4, true),
        [theme.media(767)]: {
          width: "auto",
          marginRight: 0,
        },
      },
      "& p": {
        ...theme.fonts.s14w500,
        margin: 0,
        "& span": {
          ...theme.fonts.s14w700,
          marginLeft: 7,
        },
      },
      "& .content": {
        "& > div": {
          display: "flex",
          marginBottom: 7,
        },
        "& > div:last-child": {
          marginBottom: 0,
        },
        "& i": {
          marginRight: 10,
          "& svg path": {
            fill: theme.theme_type === "light" ? "#333" : "#9CACBC",
          },
        },
      },
    },
    "& .historyBox": {
      "& .header": {
        backgroundColor: theme.theme_type === "light" ? "#E9F0F6" : "#353A3A",
        border: "1px solid rgba(0, 0, 0, 0.2)",
        borderRadius: 2,
        padding: "12px 16px",
        "& p": {
          ...theme.fonts.s14w500,
          color: theme.theme_type === "light" ? "#333" : "#9CACBC",
        },
      },
      "& .historyItemWrapper": {
        padding: "12px 16px",
        backgroundColor: theme.theme_type === "light" ? "#F8FAFC" : "#00000033",
      },
      "& .historyItem": {
        maxHeight: 300,
        overflowX: "hidden",
        "& span": {
          color: "#9CACBC",
          ...theme.fonts.s12w500,
        },
      },
      "& .price-items": {
        cursor: "pointer",
        paddingBlock: 5,
        "&:hover": {
          background: "rgba(0, 0, 0, 0.1)",
        },
      },
    },
  },
});
