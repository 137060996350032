import { useEffect, useRef } from "react";

export default function useNotFirstEffect(func, deps) {
  const firstRender = useRef(true);

  useEffect(() => {
    if (!firstRender.current) func();
    else firstRender.current = false;
  }, deps);
}
