export const auctionOffersStyles = theme => ({
  roleList_root: {
    "& .header": {
      marginTop: 60,
      "& .filter_select": {
        "& label": {
          ...theme.fonts.s14w500,
          color: "#7E7E7E",
        },
      },
      "& .ant-select-single:not(.ant-select-customize-input) .ant-select-selector ":
        {
          border: "1px solid #E9F0F7",
          borderRadius: 2.5,
          padding: "4px 16px",
          height: 40,
        },
      "& .ant-input:focus, .ant-input-focused, ,.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector":
        {
          borderColor: theme.palette.primary.main,
          boxShadow: "unset",
        },
      "& .ant-select-arrow": {
        top: "35%",
      },
    },
  },
  fontStyle: {
    fontSize: "clamp(1.2rem, 2vw, 1.4rem)",
    fontWeight: 500,
  },
  imgDiv: {
    ...theme.imgDiv(44, 44, 2, true),
    "& img": {
      ...theme.img100,
    },
  },
  status: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    height: "100%",
    width: "100%",
  },
  editBtnWrapper: {
    width: "100%",
    height: "100%",
    padding: "0 !important",
    backgroundColor: "transparent",
    transition: "all .3s",
    "& svg path": {
      fill: "#FFF",
      transition: "all .3s",
    },
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      "& svg path": {
        fill: theme.palette.primary.main,
      },
    },
    "& button:disabled": {
      "& svg path": {
        fill: "rgba(255, 255, 255, 0.1)",
      },
    },
    "& .primary-tooltip-wrapper": {
      display: "grid",
      placeItems: "center",
    },
  },
  baseTable: {
    "& .ant-table-tbody .ant-table-row div.cell": {
      height: 60,
    },
  },
  buildingImg: {
    borderRadius: 2,
    width: 84,
    height: 44,
    backgroundSize: "cover",
    backgroundPosition: "center",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
});

export const editModalStyles = theme => ({
  wrapModal: {},
  modalEdit: {
    "& .watchListItem": {
      backgroundColor: theme.theme_type === "light" ? "#F8FAFC" : "#0000004d",
      borderRadius: 3,
      padding: 20,
      transition: "all .3s",
      marginBottom: 20,
      "& .imgDiv": {
        marginRight: 17,
        ...theme.imgDiv(145, "100%", 4, true),
        [theme.media(767)]: {
          width: "auto",
          marginRight: 0,
        },
      },
      "& p": {
        ...theme.fonts.s14w500,
        margin: 0,
        "& span": {
          ...theme.fonts.s14w700,
          marginLeft: 7,
        },
      },
      "& .content": {
        "& > div": {
          display: "flex",
          marginBottom: 7,
        },
        "& > div:last-child": {
          marginBottom: 0,
        },
        "& i": {
          marginRight: 10,
          "& path": {
            fill: theme.theme_type === "light" ? "#333" : "white",
          },
        },
      },
    },
    "& .textAreaWrapper": {
      marginBottom: 20,

      "& p": {
        marginBottom: 12,
        ...theme.fonts.s14w500,
      },
      "& textarea": {
        border:
          theme.theme_type === "light" ? "1px solid #333" : "1px solid #FFFFFF",
        borderRadius: "5px",
        width: "100%",
        padding: 15,
        backgroundColor: "transparent",
        resize: "none",
        "&::placeholder": {
          ...theme.fonts.s12w500,
          color: "#BECFE0 !important",
        },
      },
    },
  },
});
