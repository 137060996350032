import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  background: {
    backgroundColor: "white",
    background:
      theme.theme_type === "glass" ? theme.backgrounds.currentTheme : "",
    backgroundAttachment: "fixed",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    minHeight: "100vh",

    // for working in safari
    zIndex: -1,
    position: "fixed",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
  },
}));

export default function BackgroundTheme() {
  const classes = useStyles();
  return <div className={classes.background} />;
}
