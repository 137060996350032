import { Form } from "antd";
import { NormalInput, SelectInput } from "Components/Input";
import DatePickerComponent from "Components/Input/DatePicker";
import FormHeader from "./FormHeader";
import moment from "moment";
export default function Step1({ form, defaultValue }) {
  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      initialValues={{
        ...defaultValue,
        data: moment(defaultValue.date),
      }}
    >
      <FormHeader
        title={"Personal details"}
        subTitle={"Tell us something more about you"}
      />
      <Form.Item
        name="title"
        label="Title"
        rules={[{ required: true, message: "Please select title!" }]}
      >
        <SelectInput
          form={form}
          name="title"
          placeholder="Choose a title"
          options={[
            {
              label: "Mr",
              value: "mr",
            },
            {
              label: "Ms",
              value: "ms",
            },
            {
              label: "Custom",
              value: "custom",
            },
          ].map(({ label, value }) => ({
            label,
            value,
          }))}
        />
      </Form.Item>

      <Form.Item
        label="Name"
        name="first_name"
        rules={[{ required: true, message: "Name is required!" }]}
      >
        <NormalInput
          form={form}
          name="first_name"
          placeholder="Enter your name"
        />
      </Form.Item>

      <Form.Item label="Middle name" name="middle_name">
        <NormalInput
          form={form}
          name="middle_name"
          placeholder="Enter your Middle name"
        />
      </Form.Item>

      <Form.Item
        label="Last name"
        name="last_name"
        rules={[{ required: true, message: "Last name is required!" }]}
      >
        <NormalInput
          form={form}
          name="last_name"
          placeholder="Enter your Last name"
        />
      </Form.Item>
      <Form.Item
        name="birth_date"
        label="Date of birth"
        rules={[{ required: true, message: "Please select Your Birthday!" }]}
      >
        <DatePickerComponent form={form} name="birth_date" />
      </Form.Item>
    </Form>
  );
}
