import React, { useState, useRef, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { AutoComplete, Input, Form } from "antd";
import { inputsStyles } from "./styles/inputs.style";
import clsx from "clsx";
import debounce from "lodash.debounce";
import {
  searchLocation,
  searchGoogleLocationDetails,
  searchGoogleLocation,
} from "Services";
import Spin from "Components/Spinner/Spin";

// Icons
import { ReactComponent as SearchIcon } from "Assets/img/icons/icon_search_input.svg";

const useStyles = makeStyles(inputsStyles);

export default function AutoCompleteInput({
  placeholder,
  className,
  defaultValue,
  form,
  name,
  customNames,
  disabled,
  location,
  disabledFields = [],
  setLatLon = true,
  setRoadName = false,
  ...restProps
}) {
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchRef = useRef(0);

  // api call
  async function apiCall(value) {
    return searchGoogleLocation(value).then(res =>
      res.data?.length
        ? res.data?.map(item => ({
            label: location ? item.description : item?.post_code,
            value: location ? item.description : item?.post_code,
            ...item,
          }))
        : [],
    );
  }

  const handleSearch = useMemo(() => {
    const loadOptions = value => {
      if (value) {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setLoading(true);

        apiCall(value)
          .then(newOptions => {
            if (fetchId !== fetchRef.current) {
              // for fetch callback order
              return;
            }
            setOptions(newOptions);
            setLoading(false);
          })
          .catch(err => {
            setLoading(false);
          });
      }
    };

    return debounce(loadOptions, 800);
  }, []);

  const handleKeyPress = ev => {
    // console.log("handleKeyPress", ev);
  };

  const onSelect = (value, selectedItem) => {
    const placeId = selectedItem?.place_id;
    setLoading(true);
    searchGoogleLocationDetails(placeId)
      .then(data => {
        if (setLatLon) {
          form.setFields([
            {
              name: "latitude",
              value: data?.lat,
            },
            {
              name: "longitude",
              value: data?.lon,
            },
          ]);
        }
        if (setRoadName) {
          form.setFields([
            {
              name: "road_name",
              value: data?.route || null,
            },
          ]);
        }

        form.setFieldsValue({
          [name]: value,
          [customNames?.country || "country"]: data?.country,
          [customNames?.countryCode || "country_code"]: data?.country_code,
          [customNames?.city || "city"]: data?.city,
          [customNames?.firstLineOfAddress || "first_line_of_address"]:
            data?.first_line_of_address,
        });
        if (setLatLon) {
          form.setFieldsValue({
            latitude: data?.lat,
            longitude: data?.lon,
          });
        }
        if (setRoadName) {
          form.setFieldsValue({
            road_name: data?.route || null,
          });
        }
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  };
  return (
    <>
      <AutoComplete
        defaultValue={defaultValue ?? form.getFieldValue(name)}
        options={options}
        onSelect={onSelect}
        onSearch={handleSearch}
        disabled={disabledFields?.includes(name) || disabled}
      >
        <Input
          className={clsx(
            classes.input,
            classes.inputGrayIcon,
            classes.autoCompleteInput,
            className,
            (disabledFields?.includes(name) || disabled) &&
              "input-wrapper-disabled",
          )}
          placeholder={placeholder}
          defaultValue={defaultValue ?? form.getFieldValue(name)}
          onKeyPress={handleKeyPress}
          suffix={loading ? <Spin spinning size={15} /> : <SearchIcon />}
          {...restProps}
        />
      </AutoComplete>
      {setLatLon && (
        <>
          <div style={{ display: "none" }}>
            <Form.Item name="latitude">
              <Input type="hidden" />
            </Form.Item>
          </div>
          <div style={{ display: "none" }}>
            <Form.Item name="longitude">
              <Input type="hidden" />
            </Form.Item>
          </div>
        </>
      )}
      {setRoadName && (
        <div style={{ display: "none" }}>
          <Form.Item name="road_name">
            <Input type="hidden" />
          </Form.Item>
        </div>
      )}
    </>
  );
}
