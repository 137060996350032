import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "antd";

export default function DropdownComponent({
  closeOnClick = true, // if false, dropdown wont close when click on overlay
  overlay,
  onVisibleChange,
  isVisible,
  ...restProps
}) {
  const [visible, setVisible] = useState(!!isVisible);

  const handleOverlayClick = () => {
    if (closeOnClick) setVisible(false);
  };

  useEffect(() => {
    setVisible(!!isVisible);
  }, [isVisible]);

  return (
    <Dropdown
      overlay={React.cloneElement(overlay, { onClick: handleOverlayClick })}
      trigger={["click"]}
      placement="bottomRight"
      visible={visible}
      onVisibleChange={e => {
        setVisible(e);
        onVisibleChange?.(e);
      }}
      {...restProps}
    />
  );
}

DropdownComponent.propTypes = {
  closeOnClick: PropTypes.bool,
  overlay: PropTypes.element.isRequired,
  onVisibleChange: PropTypes.func,
};
