import React, { useState, useRef, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { AutoComplete, Input } from "antd";
import { inputsStyles } from "./styles/inputs.style";
import clsx from "clsx";
import debounce from "lodash.debounce";
import Spin from "Components/Spinner/Spin";

// Services
import { searchSolicitor } from "Services";

// Icons
import { ReactComponent as SearchIcon } from "Assets/img/icons/icon_search_input.svg";

const useStyles = makeStyles(inputsStyles);

export default function AutoCompleteInput({
  placeholder,
  className,
  defaultValue,
  form,
  name,
  setSolicitor,
  ...restProps
}) {
  const classes = useStyles();

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState(form?.getFieldValue(name));

  const fetchRef = useRef(0);

  // api call
  async function apiCall(value) {
    return searchSolicitor({ email: value }).then(data => {
      
      const solicitor = {
        seller_solicitor_first_name: data.first_name,
        seller_solicitor_middle_name: data.middle_name,
        seller_solicitor_last_name: data.last_name,
        seller_solicitor_birthday: data.birthday || null,
        seller_solicitor_email: data.email,
        seller_solicitor_contact_number: data?.phone ? data?.phone : "+44",
        seller_solicitor_mobile_number: data?.mobile ? data?.mobile : "+44",
        seller_solicitor_post_code: data.post_code,
        seller_solicitor_first_line_of_address: data.first_line_of_address,
        seller_solicitor_second_line_of_address: data.second_line_of_address,
        seller_solicitor_country: data.country,
        seller_solicitor_city: data.city,
      };
      setSolicitor({ ...solicitor, seller_solicitor_email: value });
    });
  }

  const handleSearch = useMemo(() => {

    const loadOptions = value => {
      console.log(value, "alex added this is search sol input");
      const emailRegx = /(.+)@(.+){2,}\.(.+){2,}/;
      if (emailRegx.test(value) || value === "") {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setLoading(true);

        apiCall(value)
          .then(newOptions => {
            if (fetchId !== fetchRef.current) {
              // for fetch callback order
              setValue(value);
              return;
            }

            setOptions(newOptions);
            setLoading(false);
          })
          .catch(err => {
            setLoading(false);
          });
      }
    };

    return debounce(loadOptions, 800);
  }, []);

  const handleKeyPress = ev => {
    // console.log("handleKeyPress", ev);
  };

  const onSelect = value => {
    form.setFieldsValue({
      [name]: value,
    });
  };

  const handleChange = value => {
    setValue(value);
  };
  return (
    <AutoComplete
      defaultValue={defaultValue}
      options={options}
      onSelect={onSelect}
      onSearch={handleSearch}
      value={value || form?.getFieldValue(name)}
      onChange={handleChange}
    >
      <Input
        className={clsx(classes.input, classes.inputGrayIcon, className)}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onKeyPress={handleKeyPress}
        value={value || form?.getFieldValue(name)}
        onChange={handleChange}
        suffix={loading ? <Spin spinning size={15} /> : <SearchIcon />}
        {...restProps}
        name = {name}
      />
    </AutoComplete>
  );
}
