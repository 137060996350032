import useMediaQuery from "@material-ui/core/useMediaQuery";

import { truncate } from "Utils/helperFunction";
import PrimaryToolTip from "./PrimaryToolTip";

export default function LimitedTextWithToolTip({
  text,
  limit,
  matchLimited,
  className,
  toolTipClassName,
  onClick,
  As = "span",
  mediaSize = 900,
  operator = "max",
  props = {},
}) {
  // for show tooltip we need to standard text length with match to mobile and tablet device
  const deviceMediaQuery = `@media (${operator}-width: ${mediaSize}px)`;
  const matchesToMobileAndTabletSize = useMediaQuery(deviceMediaQuery);
  const hasLimitedSizeToShowTooltip =
    matchesToMobileAndTabletSize &&
    (matchesToMobileAndTabletSize ? matchLimited || limit : limit);
  const limitedText = matchesToMobileAndTabletSize
    ? matchLimited || limit
    : limit || text?.length + 1;
  const context = truncate(text, limitedText, "N/A");

  return (
    <PrimaryToolTip
      className={toolTipClassName}
      title={text}
      visible={hasLimitedSizeToShowTooltip}
    >
      <As className={className} onClick={onClick} {...props}>
        {context}
      </As>
    </PrimaryToolTip>
  );
}
