import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core";
import { drawerMenu } from "./navigationbarBS.style";
import { ReactComponent as LogoIcon } from "Assets/img/icons/icon_bsMenu-uth-logo.svg";
import { ReactComponent as CloseIcon } from "Assets/img/icons/icon_close.svg";
import { motion } from "framer-motion";
import {useDispatch, useSelector} from "react-redux";
import {handle_redirectToHome} from "../../../Utils/helperFunction";

const useStyles = makeStyles(drawerMenu);



const routes = [
  {
    name: "LISTINGS",
    link: "/properties",
  },
  {
    name: "BIDDING ROOM",
    link: "/bidding-room",
  },
  {
    name: "BUYING",
    link: "/buying",
  },
  {
    name: "SELLING",
    link: "/selling",
  },
  {
    name: "CONTACT US",
    link: "/contact",
  },
];

export default function MenuDrawer({ open, close }) {
  const classes = useStyles();
  const hostName = window.location.origin;
  const dispatch = useDispatch();

  const container = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
        ease: "linear",
      },
    },
  };

  const item = {
    hidden: {
      x: 1200,
      opacity: 0,
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.8,
        delayChildren: 0.3,
        staggerChildren: 0.2,
        ease: [0.15, 0.44, 0.62, 0.98],
      },
    },
  };

  const token = useSelector(state => state?.auth?.user?.password_onetime_token)
  const handleGoToHome = (pathname) =>{
    handle_redirectToHome(pathname, token, dispatch)
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={close}
      className={classes.drawer}
      fullScreen
    >
      <div className="header">
        <div className="logo">
          <LogoIcon />
        </div>
        <button className="close" onClick={close}>
          <CloseIcon width={50} height={50} />
        </button>
      </div>
      <div className={classes.nav}>
        <div className="nav--list">
          <motion.ul variants={container} initial="hidden" animate="visible">
            {routes.map(route => {
              return (
                <motion.li variants={item} key={route.name}>
                  <a onClick={()=>handleGoToHome(route?.link)} >
                    {route.name}
                  </a>
                </motion.li>
              );
            })}
          </motion.ul>
        </div>
      </div>
    </Drawer>
  );
}
