import { makeStyles } from "@material-ui/core";
import { useState } from "react";
import useStateCallback from "Hooks/useStateCallback";
import { Steps, Form } from "antd";
import { PrimaryButton } from "Components/ui";

import Step1 from "./Step_1";
import Step2 from "./Step_2";
import Step3 from "./Step_3";
import Step4 from "./Step_4";
import Step5 from "./Step_5";
import clsx from "clsx";
import moment from "moment";
import { postAMLCheck } from "Services";
import { stepWrapperStyle } from "./stepWrapper.style";
import { SpinnerRoundFilled } from "spinners-react";
const { Step } = Steps;

const steps = [
  {
    title: "Personal details",
    content: ({ form, formValues }) => (
      <Step1 form={form} defaultValue={formValues} />
    ),
  },
  {
    title: "contact details",
    content: ({ form, formValues }) => (
      <Step2 form={form} defaultValue={formValues} />
    ),
  },
  {
    title: "address",
    content: ({ form, formValues }) => (
      <Step3 form={form} defaultValue={formValues} />
    ),
  },
  {
    title: "preview",
    content: ({ formValues, setCurrentStep }) => (
      <Step4 setCurrentStep={setCurrentStep} formValues={formValues} />
    ),
  },
  {
    title: "",
    content: ({ situationStatus }) => <Step5 status={situationStatus} />,
  },
];

const useStyles = makeStyles(stepWrapperStyle);

export default function StepWrapper() {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [situationStatus, setSituationStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useStateCallback({});

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handle_postData = async () => {
    setLoading(true);
    await postAMLCheck({
      ...formValues,
      birth_date: moment(formValues.birth_date).format("YYYY-MM-DD"),
    })
      .then(res => {
        // console.log("res_______", res);
        setSituationStatus(res.status);
        next();
      })
      .catch(err => console.log(err));
    setLoading(false);
  };

  const handle_submit = () => {
    form
      .validateFields()
      .then(values => {
        setFormValues({
          ...formValues,
          ...values,
        });
        currentStep === 3 ? handle_postData() : next();
      })
      .catch(errorInfo => {
        /*
      errorInfo:
        {
          values: {
            username: 'username',
            password: 'password',
          },
          errorFields: [
            { name: ['password'], errors: ['Please input your Password!'] },
          ],
          outOfDate: false,
        }
      */
      });
  };

  return (
    <div className={clsx(classes.stepWrapper)}>
      <Steps current={currentStep} className="custom-steps-root ">
        {steps.map(item => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <hr className="line" />
      <div className="formWrapper">
        {steps[currentStep].content({
          form,
          formValues,
          setCurrentStep,
          currentStep,
          situationStatus,
        })}
        {currentStep !== 4 && (
          <Form.Item className="btnWrapper">
            {currentStep > 0 ? (
              <PrimaryButton
                type="submit"
                title={"Previous"}
                className="prevBtn"
                onClick={() => prev()}
              />
            ) : (
              <div />
            )}
            {currentStep < steps.length - 1 && (
              <PrimaryButton
                type="submit"
                onClick={() => handle_submit()}
                title={
                  loading ? (
                    <SpinnerRoundFilled
                      size={20}
                      thickness={100}
                      speed={100}
                      color="#FFF"
                    />
                  ) : (
                    "Next"
                  )
                }
                className="nextBtn"
                disabled={loading}
              />
            )}
          </Form.Item>
        )}
      </div>
    </div>
  );
}
