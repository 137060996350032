import { lazy } from "react";

const retry = (fn, retriesLeft = 5, interval = 1500) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

export default function lazyWithRetry(fn) {
  return lazy(() => retry(fn));
}
