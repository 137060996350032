import { useEffect, useRef } from "react";
import usePrevious from "./usePrevious";

export default function useDeepEffect(effectFunc, deps) {
  const prevDeps = usePrevious(deps);
  const isFirst = useRef(true);

  useEffect(() => {
    const isSame = JSON.stringify(prevDeps) === JSON.stringify(deps); // deep equality

    if (isFirst.current || !isSame) {
      effectFunc();
    }

    isFirst.current = false;
  }, deps);
}
