import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  formHeader: {
    "& .title": {
      ...theme.fonts.s16w600,
      textTransform: "uppercase",
      letterSpacing: -0.3,
      color: theme.theme_type === "light" ? "#333" : "#fff",
    },
    "& .subTitle": {
      ...theme.fonts.s14w500,
      color: "#9CACBC",
      letterSpacing: -0.5,
    },
    "& hr": {
      border: "none",
      borderTop: ".25px solid #E9F0F7",
      marginTop: 20,
      marginBottom: 25,
    },
  },
}));
export default function FormHeader({ title, subTitle }) {
  const classes = useStyles();
  return (
    <div className={classes.formHeader}>
      <p className="title">{title}</p>
      <p className="subTitle">{subTitle}</p>
      <hr />
    </div>
  );
}
