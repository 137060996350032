import axios from "./Api";

export const getInvoices = ({ type, page, ...params }) =>
  axios.get(`/invoices/legalpack/${type}`, {
    params: { offset: page || 0, ...params },
  });

export const getInvoicesPayout = ({ type, page, ...params }) =>
  axios.get(`/invoices/payable/index`, {
    params: { type: type, page: page || 1, ...params },
  });

export const getViewInvoice = id =>
  axios.get(`/invoices/view/${id}`).then(res => res.data);

export const getPayableInvoice = (id,{ type}) =>
  axios
    .get(`/invoices/payable/view/${id}`, { params: { type: type } })
    .then(res => res.data);

export const paymentInvoice = (id, body) =>
  axios.post(`/invoices/pay/${id}`, body);

export const paymentInvoiceConfirm = id =>
  axios.post(`/invoices/confirm/${id}`);

// only admin
export const createInvoices = body => axios.post(`/invoices/create`, body);

export const markAsPayInvoices = id =>
  axios.post(`/invoices/legalpack/mark-as-pay/${id}`);

export const markPayableInvoicesAsPaid = (id, body) =>
  axios.post(`/invoices/payable/mark-as-pay/${id}`, body);

export const sendPayableEmail = (id, body) =>
  axios.post(`/invoices/payable/send-email/${id}`, body);

export const updateInvoices = (id, body) =>
  axios.post(`/invoices/update/${id}`, body);

export const updatePayableInvoices = (id, type, body) =>
  axios.put(`/invoices/payable/update/${id}`, {
     ...type, ...body },
  );

export const deleteInvoices = id => axios.delete(`/invoices/delete/${id}`);
