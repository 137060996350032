import axios from "./Api";

export const getAgentOfferApi = ({ page, ...params }) =>
  axios.get("/offers", {
    params: { offset: page || 0, ...params },
  });

export const getAgentOfferApiFiltration = ({ page, ...params }) =>
  axios.get("/offers", {
    params: {
      property_statuses: ["unsold", "live", "draft", "pending", "unpublished"],
      offset: page || 0,
      ...params,
    },
  });

export const postAgentOfferUpdateApi = (id, params) =>
  axios.post("/offers/update/" + id, {}, { params });

export const postAgentOfferWithdrawApi = (id, params) =>
  axios.post("/offers/withdraw/" + id, {}, { params });

export const postAgentOfferArchiveApi = id =>
  axios.post("/offers/archive/" + id);

export const offersMultiArchive = selectedIds =>
  axios.post("/offers/multi-archive", {
    offer_ids: selectedIds,
  });

export const offersReactivateToken = id =>
  axios.post(`/offers/re-active-token/${id}`);
