import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Form } from "antd";
import alert from "Utils/alert";

// Components
import Modal from "Components/Modal";
import { PrimaryButton } from "Components/ui";
import { Textarea } from "Components/Input";
import Spin from "Components/Spinner/Spin";

// services
import { postOfferWithdrawnApi } from "Services/traderApi";

// styles
import { editModalStyles } from "./auctionOffers.styles";

const useStyles = makeStyles(editModalStyles);

export default function EditAuctionOffersModal({
  open,
  handleClose,
  switchReload,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async ({ reason }) => {
    setLoading(true);
    await postOfferWithdrawnApi(open.id, reason)
      .then(res => {
        alert.success();
        switchReload();
        handleClose();
      })
      .catch(err => console.error(err));
    setLoading(false);
  };

  return (
    <Modal
      title="withdrawn offer"
      isModalVisible={!!open?.id}
      className={classes.wrapModal}
      handleCancel={handleClose}
      footer={null}
    >
      <div className={classes.modalEdit}>
        <div className="watchListItem">
          <div className="row">
            <div className="col-12 col-md-4 mb-5 mb-md-0">
              <div className="imgDiv">
                <img src={open?.thumbnail_image} alt="" />
              </div>
            </div>
            <div className="col-12 col-md-8 ps-4">
              <div className="content">
                <div>
                  <i>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 16.5487C5.49975 11.9812 3.75 8.73146 3.75 6.79871C3.75 5.40632 4.30312 4.07096 5.28769 3.0864C6.27226 2.10183 7.60761 1.54871 9 1.54871C10.3924 1.54871 11.7277 2.10183 12.7123 3.0864C13.6969 4.07096 14.25 5.40632 14.25 6.79871C14.25 8.73146 12.5002 11.9812 9 16.5487ZM9 9.79871C9.79565 9.79871 10.5587 9.48264 11.1213 8.92003C11.6839 8.35742 12 7.59436 12 6.79871C12 6.00306 11.6839 5.23999 11.1213 4.67739C10.5587 4.11478 9.79565 3.79871 9 3.79871C8.20435 3.79871 7.44129 4.11478 6.87868 4.67739C6.31607 5.23999 6 6.00306 6 6.79871C6 7.59436 6.31607 8.35742 6.87868 8.92003C7.44129 9.48264 8.20435 9.79871 9 9.79871Z"
                        fill="white"
                      />
                    </svg>
                  </i>
                  <p className="address">{open?.first_line_of_address}</p>
                </div>
                <div>
                  <i>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.70797 13.2606L9.69995 12.2686L10.6919 13.2606L15.0169 8.93559L15.7816 6.44371L14.039 4.70178L13.282 4.9375L12.161 3.81644L13.6223 3.36114C14.12 3.20647 14.6625 3.34046 15.031 3.7091L16.7729 5.45103C17.1399 5.81809 17.2743 6.3578 17.1223 6.85411L16.2559 9.68203L10.6926 15.2453L8.70726 13.2613L8.70797 13.2606ZM10.5671 3.21381L12.3083 4.95574C12.6753 5.3228 12.8097 5.86251 12.6576 6.35882L11.7912 9.18464L6.22802 14.7486L0.77002 9.29197L6.3115 3.75119L9.15765 2.86585C9.6554 2.71118 10.1979 2.84517 10.5663 3.21381H10.5671ZM2.75468 9.29197L6.22731 12.7646L10.5516 8.4396L11.3163 5.94772L9.57367 4.20579L7.05864 4.98801L2.75468 9.29197ZM7.96293 7.55566C7.5449 7.15191 7.53332 6.48572 7.93707 6.06769C8.34082 5.64965 9.007 5.63807 9.42504 6.04182C9.43381 6.05029 9.44243 6.05892 9.4509 6.06769C9.85037 6.48981 9.832 7.15585 9.40987 7.55531C9.00425 7.93916 8.36943 7.93931 7.96363 7.55565L7.96293 7.55566ZM12.7937 8.27263L13.3424 6.25359C13.658 6.36023 13.872 6.48159 13.9857 6.61699C14.3591 7.06219 14.301 7.72581 13.8559 8.09935C13.6524 8.27053 13.2982 8.32805 12.7937 8.27263Z"
                        fill="white"
                      />
                    </svg>
                  </i>
                  <p className="price">
                    <span>Offer amount:</span> £{" "}
                    {open?.amount?.toLocaleString()}
                  </p>
                </div>
                <div>
                  <i>
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 7.17603V5.67603H3V7.17603H15ZM15 9.42603H3V13.176H15V9.42603ZM3 4.17603H15C15.3978 4.17603 15.7794 4.33406 16.0607 4.61537C16.342 4.89667 16.5 5.2782 16.5 5.67603V13.176C16.5 13.5738 16.342 13.9554 16.0607 14.2367C15.7794 14.518 15.3978 14.676 15 14.676H3C2.60218 14.676 2.22064 14.518 1.93934 14.2367C1.65804 13.9554 1.5 13.5738 1.5 13.176V5.67603C1.5 5.2782 1.65804 4.89667 1.93934 4.61537C2.22064 4.33406 2.60218 4.17603 3 4.17603V4.17603ZM4.5 10.926H5.25C5.44891 10.926 5.63968 11.005 5.78033 11.1457C5.92098 11.2863 6 11.4771 6 11.676C6 11.8749 5.92098 12.0657 5.78033 12.2064C5.63968 12.347 5.44891 12.426 5.25 12.426H4.5C4.30109 12.426 4.11032 12.347 3.96967 12.2064C3.82902 12.0657 3.75 11.8749 3.75 11.676C3.75 11.4771 3.82902 11.2863 3.96967 11.1457C4.11032 11.005 4.30109 10.926 4.5 10.926ZM8.25 10.926H12C12.1989 10.926 12.3897 11.005 12.5303 11.1457C12.671 11.2863 12.75 11.4771 12.75 11.676C12.75 11.8749 12.671 12.0657 12.5303 12.2064C12.3897 12.347 12.1989 12.426 12 12.426H8.25C8.05109 12.426 7.86032 12.347 7.71967 12.2064C7.57902 12.0657 7.5 11.8749 7.5 11.676C7.5 11.4771 7.57902 11.2863 7.71967 11.1457C7.86032 11.005 8.05109 10.926 8.25 10.926Z"
                        fill="white"
                      />
                    </svg>
                  </i>
                  <p className="time">
                    <span>Guide price:</span> £{" "}
                    {open?.guide_price?.toLocaleString()}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Spin spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
            initialValues={open}
            className="textAreaWrapper"
          >
            <div className="">
              <p>Please write your reason for withdrawning this offer:</p>
              <Form.Item name="reason">
                <Textarea
                  form={form}
                  name="reason"
                  rows={8}
                  placeholder="Type your reason here..."
                />
              </Form.Item>
            </div>
            <div className="d-flex align-items-center justify-content-end">
              <PrimaryButton title="withdrawn" />
            </div>
          </Form>
        </Spin>
      </div>
    </Modal>
  );
}
