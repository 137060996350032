import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { DatePicker } from "antd";
import { inputsStyles } from "./styles/inputs.style";
import clsx from "clsx";
import moment from "moment";

const useStyles = makeStyles(inputsStyles);

export default function DatePickerComponent({
  placeholder,
  className,
  showTime,
  format,
  minuteStep,
  defaultValue,
  form,
  onChange,
  name,
  value,
  disabledFields = [],
  disabled,
  ...restProps
}) {
  const classes = useStyles();

  const handleChange = (date, dateString) => {
    form.setFieldsValue({
      [name]: dateString,
    });
    if (onChange) onChange(dateString);
  };

  const localValue = form?.getFieldValue(name);

  useEffect(() => {
    if (localValue && !moment.isMoment(localValue)) {
      form?.setFieldsValue({
        [name]: moment(localValue),
      });
    }
  }, [localValue]);

  return (
    <DatePicker
      className={clsx(
        classes.input,
        className || "",
        (disabledFields?.includes(name) || disabled) &&
          "input-wrapper-disabled",
      )}
      placeholder={placeholder}
      showTime={showTime}
      format={format}
      minuteStep={minuteStep}
      defaultValue={defaultValue || form?.getFieldValue(name)}
      disabled={disabledFields?.includes(name) || disabled}
      onChange={handleChange}
      {...restProps}
    />
  );
}
