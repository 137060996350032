import { Form } from "antd";
import { NormalInput } from "Components/Input";
import FormHeader from "./FormHeader";
import { handle_validPhoneNumber } from "Utils/helperFunction";
import PhoneInput from "Components/Input/PhoneInput";
import React from "react";

export default function Step2({ form }) {
  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      initialValues={{
        contact_number_prefix: "44",
        mobile_prefix: "44",
      }}
    >
      <FormHeader
        title={"contact details"}
        subTitle={
          "In exchange for your contacts, here is our contact no. 034 55 120 115"
        }
      />
      <Form.Item
        name="contact_number"
        label="Contact number"
        rules={[
          {
            required: true,
            message: "Please input your number!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              return handle_validPhoneNumber(value);
            },
          }),
        ]}
      >
        <PhoneInput name="contact_number" form={form} />
      </Form.Item>

      <Form.Item
        name="mobile"
        label="Mobile number"
        rules={[
          {
            required: true,
            message: "Please input your number!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              return handle_validPhoneNumber(value);
            },
          }),
        ]}
      >
        <PhoneInput name="mobile" form={form} />
      </Form.Item>

      <Form.Item
        name="email_address"
        label="Email address"
        type="email"
        rules={[
          {
            required: true,
            message: "Please enter email address!",
          },
        ]}
      >
        <NormalInput
          form={form}
          name="email_address"
          placeholder="Enter email address"
          type="email"
        />
      </Form.Item>
    </Form>
  );
}
