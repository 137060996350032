import React from "react";
import { Pagination } from "antd";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  paginationBox: {
    "& .ant-pagination-item": {
      backgroundColor: "transparent",
      borderWidth: 0,
      "& a": {
        color: theme.theme_type === "light" ? "#333333" : "white",
      },
    },
    "& .ant-pagination-disabled": {
      "& button": {
        backgroundColor: "transparent",
        borderWidth: 0,
      },
      "& svg": {
        fill: "#9E9E9E",
      },
    },
    "& .ant-pagination-prev, .ant-pagination-next": {
      "& button": {
        backgroundColor: "transparent",
        borderWidth: 0,
      },
    },
    "& .ant-pagination-item-active": {
      background: theme.theme_type === "light" ? "#E9F0F6" : "#ffffff1a",
    },
    "& .ant-pagination-item-ellipsis": {
      color:
        theme.theme_type === "light"
          ? "#333333 !important"
          : "white !important",
    },
    "& .ant-pagination-item-link": {
      "& svg": {
        fill: theme.theme_type === "light" ? "#333333" : "white",
      },
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: 45,
    },
  },
}));

export default function PaginationComponent({
  onChange,
  total,
  current,
  disabled,
  pageSize,
}) {
  const classes = useStyles();

  return (
    <Pagination
      onChange={onChange}
      total={total}
      current={current}
      disabled={disabled}
      pageSize={pageSize || 25}
      showSizeChanger={false}
      className={classes.paginationBox}
    />
  );
}

PaginationComponent.propTypes = {
  onChange: PropTypes.func,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  current: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  pageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
