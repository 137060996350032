import React from "react";
import { makeStyles } from "@material-ui/core";
import { InputNumber } from "antd";
import { inputsStyles } from "./styles/inputs.style";
import clsx from "clsx";

const useStyles = makeStyles(inputsStyles);

export default function NumberInput({
  placeholder,
  className,
  defaultValue,
  name,
  form,
  customIcon,
  disabledFields = [],
  disabled,
  ...restProps
}) {
  const classes = useStyles();

  const handleChange = value => {
    form.setFieldsValue({
      [name]: value,
    });
  };
  return (
    <div
      className={clsx(
        classes.numberInputWrapper,
        (disabledFields?.includes(name) || disabled) &&
          "input-wrapper-disabled",
      )}
    >
      <InputNumber
        className={
          customIcon
            ? clsx(classes.input, classes.inputWithLeftIcon, className)
            : clsx(classes.input, classes.inputWithoutLeftIcon, className)
        }
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={handleChange}
        disabled={disabledFields?.includes(name) || disabled}
        {...restProps}
      />
      {customIcon}
    </div>
  );
}
