import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { SecondaryButtonStyle } from "./styles/buttons.styles";
import clsx from "clsx";

const useStyles = makeStyles(SecondaryButtonStyle);

const SecondaryButton = ({
  title,
  className,
  onClick = () => {},
  ...restProps
}) => {
  const classes = useStyles();
  return (
    <button
      className={clsx([classes.secondary_root, className])}
      onClick={onClick}
      {...restProps}
    >
      {title}
    </button>
  );
};

SecondaryButton.propTypes = {
  title: PropTypes.any,
  onClick: PropTypes.func,
};

export default SecondaryButton;
