import { useState } from "react";
import { makeStyles } from "@material-ui/core";

// components
import Modal from "Components/Modal";
import { ScrollingPagination } from "Components/ScrollerBox";

// services
import { getBuyerBidApi } from "Services/traderApi";

// utils
import { filterArray, NAString } from "Utils/helperFunction";

// styles
import { bidModalStyles } from "./myBids.styles";
import moment from "moment";

const useStyles = makeStyles(bidModalStyles);

export default function BidsHistoryModal({ open, handleClose, id, setReload }) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState();
  const [isFirstFetchApi, setIsFirstFetchApi] = useState(true);

  const apiHandler = ({
    page,
    setPermission, // stop request to backend
    setLoading,
  }) => {
    setLoading(true);
    getBuyerBidApi({ offset: page, property_id: id, order_by: "created_at" })
      .then(res => {
        if (res.data?.items.length) {
          setPermission(true);
          if (isFirstFetchApi) {
            setSelectedData(res.data?.items?.[0]);
            setIsFirstFetchApi(false);
          }
          const items = filterArray([data, res.data.items]);
          setData(items);
        } else {
          setPermission(false);
        }
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  };

  return (
    <Modal
      title="previous bids on this property"
      isModalVisible={open}
      className={classes.wrapModal}
      handleCancel={handleClose}
      footer={null}
    >
      <div className={classes.modalEdit}>
        <div className="watchListItem">
          <div className="row">
            <div className="col-12 col-md-3 mb-5 mb-md-0">
              <div className="imgDiv">
                <img src={selectedData?.thumbnail_image} alt="" />
              </div>
            </div>
            <div className="col-12 col-md-9 ps-4">
              <div className="content">
                <div>
                  <i>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 16.5487C5.49975 11.9812 3.75 8.73146 3.75 6.79871C3.75 5.40632 4.30312 4.07096 5.28769 3.0864C6.27226 2.10183 7.60761 1.54871 9 1.54871C10.3924 1.54871 11.7277 2.10183 12.7123 3.0864C13.6969 4.07096 14.25 5.40632 14.25 6.79871C14.25 8.73146 12.5002 11.9812 9 16.5487ZM9 9.79871C9.79565 9.79871 10.5587 9.48264 11.1213 8.92003C11.6839 8.35742 12 7.59436 12 6.79871C12 6.00306 11.6839 5.23999 11.1213 4.67739C10.5587 4.11478 9.79565 3.79871 9 3.79871C8.20435 3.79871 7.44129 4.11478 6.87868 4.67739C6.31607 5.23999 6 6.00306 6 6.79871C6 7.59436 6.31607 8.35742 6.87868 8.92003C7.44129 9.48264 8.20435 9.79871 9 9.79871Z"
                        fill="white"
                      />
                    </svg>
                  </i>
                  <p className="address">
                    {NAString(selectedData?.first_line_of_address)}
                  </p>
                </div>
                <div>
                  <i>
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 7.17603V5.67603H3V7.17603H15ZM15 9.42603H3V13.176H15V9.42603ZM3 4.17603H15C15.3978 4.17603 15.7794 4.33406 16.0607 4.61537C16.342 4.89667 16.5 5.2782 16.5 5.67603V13.176C16.5 13.5738 16.342 13.9554 16.0607 14.2367C15.7794 14.518 15.3978 14.676 15 14.676H3C2.60218 14.676 2.22064 14.518 1.93934 14.2367C1.65804 13.9554 1.5 13.5738 1.5 13.176V5.67603C1.5 5.2782 1.65804 4.89667 1.93934 4.61537C2.22064 4.33406 2.60218 4.17603 3 4.17603V4.17603ZM4.5 10.926H5.25C5.44891 10.926 5.63968 11.005 5.78033 11.1457C5.92098 11.2863 6 11.4771 6 11.676C6 11.8749 5.92098 12.0657 5.78033 12.2064C5.63968 12.347 5.44891 12.426 5.25 12.426H4.5C4.30109 12.426 4.11032 12.347 3.96967 12.2064C3.82902 12.0657 3.75 11.8749 3.75 11.676C3.75 11.4771 3.82902 11.2863 3.96967 11.1457C4.11032 11.005 4.30109 10.926 4.5 10.926ZM8.25 10.926H12C12.1989 10.926 12.3897 11.005 12.5303 11.1457C12.671 11.2863 12.75 11.4771 12.75 11.676C12.75 11.8749 12.671 12.0657 12.5303 12.2064C12.3897 12.347 12.1989 12.426 12 12.426H8.25C8.05109 12.426 7.86032 12.347 7.71967 12.2064C7.57902 12.0657 7.5 11.8749 7.5 11.676C7.5 11.4771 7.57902 11.2863 7.71967 11.1457C7.86032 11.005 8.05109 10.926 8.25 10.926Z"
                        fill="white"
                      />
                    </svg>
                  </i>
                  <p className="time">
                    <span>Guide price:</span> £{" "}
                    {selectedData?.guide_price?.toLocaleString()}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="historyBox">
          <div className="header">
            <div className="row">
              <div className="col-6">
                <p className="m-0">Bid amount</p>
              </div>
              <div className="col-6">
                <p className="m-0">Date and Time</p>
              </div>
            </div>
          </div>
          <ScrollingPagination
            apiHandler={apiHandler}
            className={classes.scroll}
            distance={450}
            logDistance
          >
            <div className="historyItemWrapper historyItem custom-scrollbar">
              {data?.map(values => (
                <div
                  className="price-items row mb-4 mt-4"
                  onClick={() => {
                    setSelectedData(values);
                  }}
                >
                  <div className="col-6">
                    <p className="m-0">£ {values?.amount?.toLocaleString()}</p>
                  </div>
                  <div className="col-6">
                    <span>
                      {moment
                        .utc(values?.created_at)
                        .local()
                        .format(`D MMM,YYYY | hh:mm a`)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </ScrollingPagination>
        </div>
      </div>
    </Modal>
  );
}
