import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as IconBack } from "Assets/img/icons/icon_back.svg";
import { useSelector } from "react-redux";
import {mainRole} from "../../Utils/roles";
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
    marginTop: 128,
    marginBottom: 20,
    "& p:nth-child(2)": {
      ...theme.fonts.s40w900,
    },
    "& p:nth-child(3)": {
      ...theme.fonts.s24w600,
      color: theme.colors.cyanBlueShade,
    },
    "& p:nth-child(4)": {
      ...theme.fonts.s14w500,
      // color: theme.colors.cyanBlueDark,
      marginBottom: 40,
    },
    "& button": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: " #fff",
      backgroundColor: theme.palette.primary.main,
      padding: "8px 10px",
      ...theme.fonts.s14w600,
      borderRadius: 5,
      "& svg": {
        "& path": {
          fill: "#fff",
        },
      },
    },
  },
}));
export default function Wrapper({ children, img }) {
  const history = useHistory();
  const classes = useStyles();
  const userRole = useSelector(store => mainRole(store?.auth?.user));
  return (
    <div className={classes.root}>
      {img && (
        <div>
          <img src={img} alt="" />
        </div>
      )}
      {children}
      <button
        onClick={() =>
          userRole === "seller"
            ? history.replace(`seller/properties/live`)
            : history.replace(`/${userRole}/dashboard`)
        }
      >
        <IconBack />
        Go to My dashboard
      </button>
    </div>
  );
}
