import axios from "./Api";
// get profile all kind of user type
export const getProfile = () => axios.get("/profile");
export const updateProfile = data => axios.post("/profile", data);
export const updateProfileImage = data =>
  axios.post("/profile/change-image", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
export const updateAgencyLogo = data =>
  axios.post("/profile/agency/change-image", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
// Agent
export const getAgencyProfile = () => axios.get("/profile/agency");
export const updateAgencyProfile = data => axios.post("/profile/agency", data);

// applicant
export const postAMLCheck = data => {
  return axios.post(`/aml-check`, data).then(res => res.data);
};
export const postTermsAndCondition = () => {
  return axios.post(`/accept-terms-and-conditions`).then(res => res.data);
};

export const submitAMLCheckDocuments = data => {
  return axios.post(`/submit-documents`, data).then(res => res.data);
};

export const updateSolicitor = data =>
  axios.post("/profile/solicitor-detail", data);
