export default theme => ({
  tableHeader_root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // marginBottom: 20,
    gap: 20,
    "& .left-side": {
      display: "flex",
      alignItems: "center",
      width: "unset",
      gap: 20,
      "&>div": { marginBottom: 16 },
      "& .total-project": {
        marginLeft: "14px",
      },
      "& .change-view-type": {
        display: "flex",
        alignItems: "center",
        "& .views": {
          display: "flex",
          alignItems: "center",
          marginLeft: "15px",
          "& > button": {
            background: "none",
            border: "none",
            outline: "none",
            margin: "0 5px",
            padding: "0",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          },
          "& .active-main-view-type-icon svg path": {
            fill: "#182c49",
          },
        },
      },

      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .right-side": {
      display: "flex",
      alignItems: "stretch",
      "& > button": { marginLeft: 20 },
      "& > button:first-child": { marginLeft: 0 },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .separator": {
      width: "1px",
      height: "18px",
      background: "#c0ccda",
      margin: "0 28px",
    },
  },
  selectBox: {
    "& .ant-select-selector": {
      backgroundColor: "rgba(255, 255, 255, 0.1) !important",
      border: "none !important",
      color: "#ffffff !important",
    },
    "& .ant-select-arrow": {
      color: "#ffffff",
    },
  },
  searchBox: {
    width: 300,
    "& .search-name": {
      ...theme.fonts.s14w500,
      minWidth: "20px",
      minHeight: "20px",
      marginBottom: 8,
    },
    "& .header-search-input": {
      display: "flex",
      alignItems: "center",
      height: 40,
      borderRadius: 2.5,
    },
    "& .header-search-input > input": {
      flex: 1,
      border: "none",
      paddingLeft: 13.5,
      background: "none",
      backgroundColor:
        theme.theme_type === "light" ? "#E9F0F6" : "rgba(0, 0, 0, 0.3)",
      outline: "none",
      fontSize: "12px",
      fontWeight: 500,
      borderTopLeftRadius: 2.5,
      borderBottomLeftRadius: 2.5,
      height: "100%",
      // color: "#ffffff",
      "&::placeholder": {
        // color: "#ffffff",
        ...theme.fonts.s14w500,
      },
    },
    "& .header-search-input > span": {
      background: "rgba(0, 0, 0, 0.5)",
      borderTopRightRadius: 2.5,
      borderBottomRightRadius: 2.5,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  itemsPerPageCount: {
    width: 150,
    "& .label": {
      ...theme.fonts.s14w500,
      marginBottom: 8,
    },
    "& .header-pageCount-input": {
      width: "100%",
      height: "100%",
      "& .ant-select": {
        backgroundColor: theme.backgrounds.white1,
        width: "100%",
        height: "100%",
        "& .ant-select-selector": {
          border: "none",
          outline: "none",
          backgroundColor: theme.backgrounds.white1,
          borderRadius: "5px",
        },
        "& .ant-select-arrow": {
          color: "#fff",
        },
      },
    },
    "& .ant-select-selector": {
      width: "100% !important",
      minHeight: "38px !important",
      borderRadius: "5px !important",
      padding: "3px 16px !important",
      color: theme.theme_type === "light" ? "#333" : "white",
      backgroundColor:
        theme.theme_type === "light"
          ? "white"
          : "rgba(19, 21, 27, 0.3) !important",
      border:
        theme.theme_type === "light"
          ? "1px solid #c1c1c1 !important"
          : "1px solid #FFFFFF50 !important",
      ...theme.fonts.s14w500,
    },
    "& .ant-select-selection-item-content": {
      color: "#000",
    },
    "& .ant-select-arrow": {
      "& path": {
        fill: theme.theme_type === "light" ? "#333" : "#fff",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  itemsPerPageCountDropdown: {
    background: theme.theme_type === "light" ? "white" : "#333939",
    "& .ant-select-item-option-selected:not(.ant-select-item-option-disabled)":
      {
        background: "rgba(255, 255, 255, 0.1)",
      },
    "& .ant-select-item": {
      color: theme.theme_type === "light" ? "#333" : "white",
      background: "transparent",
    },
  },
});
