import { Form } from "antd";
import { NormalInput } from "Components/Input";
import FormHeader from "./FormHeader";
import AutoCompleteInput from "Components/Input/AutoComplete";

export default function Step3({ form }) {
  return (
    <Form form={form} layout="vertical" requiredMark={false}>
      <FormHeader title={"address"} subTitle={"It’s almost finished"} />
      <Form.Item
        name="house_number"
        label="House number"
        rules={[
          {
            required: true,
            message: "Please enter House number!",
          },
        ]}
      >
        <NormalInput
          form={form}
          name="house_number"
          placeholder="House number"
        />
      </Form.Item>

      <Form.Item
        name="street_name"
        label="Street name"
        rules={[
          {
            required: true,
            message: "Please enter Street name!",
          },
        ]}
      >
        <NormalInput form={form} name="street_name" placeholder="Street name" />
      </Form.Item>

      {/*___________________________________________________________________city*/}
      {/*<Form.Item*/}
      {/*  name="city"*/}
      {/*  label="City"*/}
      {/*  rules={[{ required: true, message: "Please select City!" }]}*/}
      {/*>*/}
      {/*  <SelectInput*/}
      {/*    form={form}*/}
      {/*    name="city"*/}
      {/*    placeholder="Choose a City"*/}
      {/*    options={[...Array(5).keys()].map(number => ({*/}
      {/*      label: number,*/}
      {/*      value: number,*/}
      {/*    }))}*/}
      {/*  />*/}
      {/*</Form.Item>*/}
      <Form.Item
        name="post_code"
        label="Post code"
        rules={[
          {
            required: true,
            message: "Please enter Post code!",
          },
        ]}
      >
        <AutoCompleteInput
          form={form}
          name="post_code"
          placeholder="Post code"
        />
      </Form.Item>

      <Form.Item
        name="city"
        label="City"
        rules={[
          {
            required: true,
            message: "Please enter your City / Town!",
          },
        ]}
      >
        <NormalInput form={form} placeholder="Enter a location" name="city" />
      </Form.Item>
      {/*___________________________________________________________________city*/}
    </Form>
  );
}
