import { ReactComponent as SupportIcon } from "Assets/img/icons/icon_support-headphone.svg";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  blackBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: " #fff",
    backgroundColor: theme.colors.darkBlue,
    padding: "8px 10px",
    ...theme.fonts.s14w600,
    "& svg": {
      "& path": {
        fill: "#fff",
      },
    },
  },
}));

export default function SupportBtn() {
  const classes = useStyles();

  return (
    <button className={classes.blackBtn}>
      <i className="me-2 d-flex">
        <SupportIcon />
      </i>
      Support
    </button>
  );
}
