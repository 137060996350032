import React from "react";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ButtonBase from "@material-ui/core/ButtonBase";

import { ReactComponent as ArrowRightIcon } from "Assets/img/icons/icon_Arrow_Right.svg";
import { ReactComponent as ArrowLeftIcon } from "Assets/img/icons/icon_Arrow_Left.svg";

const useStyles = makeStyles(theme => ({
  tabMenu_root: {
    "& .tabLinkWrapper": {
      borderBottom: `1px solid ${
        theme.theme_type === "light" ? "#5a5a5a40" : "#E9F0F640"
      }`,
    },
  },
  tabs: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-between",
      gap: 30,
      "& button": {
        flexGrow: 1,
        minWidth: 80,
        maxWidth: "unset",
        padding: "10px 14px",
        fontSize: 16,
        fontWeight: 500,
        fontFamily: "inherit",
        marginBottom: 11,
        borderRadius: 5,
        letterSpacing: "unset",
        width: "fit-content",
        "&.Mui-selected": {
          fontWeight: 600,
          background: theme.backgrounds.white1,
        },
        "&>span": {
          display: "unset",
        },
      },
    },
    "& .MuiTabs-indicator": {
      height: 4,
      borderRadius: "5px 5px 0 0",
      background: theme.theme_type === "light" ? "#333333" : "white",
    },
    "& .MuiTabs-scrollButtons": {
      marginTop: -10,
      "&:first-child": {
        left: -5,
      },
      "&:last-child": {
        right: -5,
      },
      "& path": {
        fill: theme.theme_type === "light" ? "#333333" : "white",
      },
    },
  },
}));

const MyTabScrollButton = React.forwardRef((props, ref) => {
  const { direction, ...other } = props;
  return (
    <ButtonBase
      component="div"
      ref={ref}
      style={{ opacity: other.disabled ? 0 : 1 }}
      {...other}
    >
      {direction === "left" ? <ArrowLeftIcon /> : <ArrowRightIcon />}
    </ButtonBase>
  );
});

// const TabPanel = ({ children, activeTab, index, ...restProps }) => (
//   <div hidden={activeTab !== index} {...restProps}>
//     {activeTab === index && children}
//   </div>
// );

export default function TabMenu({
  className,
  tabs,
  setActiveTab,
  activeTab,
  components,
  fullWidth,
  noContainer,
}) {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div className={clsx(classes.tabMenu_root, className)}>
      <div className="tabLinkWrapper">
        <div className={clsx({ container: !noContainer })}>
          <div className="tabLinks d-flex justify-content-between align-items-start">
            <Tabs
              value={activeTab}
              onChange={handleChange}
              className={clsx(
                classes.tabs,
                "tabs",
                fullWidth ? "w-100" : "fit-width",
              )}
              variant="scrollable"
              scrollButtons="auto"
              ScrollButtonComponent={MyTabScrollButton}
            >
              {tabs.map(tab => (
                <Tab key={tab.name + Math.random().toString(2)} label={tab.name} className="tabItem" />
              ))}
            </Tabs>
            {components?.(activeTab)}
          </div>
        </div>
      </div>
      <div className="mainBody">
        <div className="container">
          {/* {tabs.map((tab, index) => (
            <TabPanel activeTab={activeTab} index={index}>
              {tab.component}
            </TabPanel>
          ))} */}
          {tabs[activeTab].component}
        </div>
      </div>
    </div>
  );
}

TabMenu.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired,
    }),
  ),
  setActiveTab: PropTypes.func.isRequired,
  activeTab: PropTypes.number.isRequired || PropTypes.string.isRequired,
  showAddBtn: PropTypes.bool,
};
