import axios from "./Api";
export const getAgentNotification = ({
  page = 0,
  item_count = 10,
  search = "",
  ...rest
}) =>
  axios.get(`/notification`, {
    params: { page, item_per_page: item_count, search, ...rest },
  });
export const storeAgentNote = data =>
  axios.post(`/notification/note/store`, data);
export const markAsReadAgentNotification = data =>
  axios.post(`/notification/mark-as-read`, data);
export const getUserNotificationsForNavbar = () =>
  axios.get(`/user-notifications?offset=0`);
export const getApplicantUserNotificationsForNavbar = () =>
  axios.get(`/notification?offset=0`);
export const markAllAsReadNotificationsForNavbar = () =>
  axios.post(`/user-notifications/mark-as-read`);
