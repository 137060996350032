import { Drawer, makeStyles } from "@material-ui/core";
import { ReactComponent as IconSms } from "Assets/img/icons/icon_sms_black.svg";
import { ReactComponent as IconEmail } from "Assets/img/icons/icon_email.svg";
import { ReactComponent as IconCallBack } from "Assets/img/icons/icon_call.svg";

import SupportBtn from "./SupportBtn";
import { activeRoute } from "../../../Utils/helperFunction";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { Dropdown, Menu } from "antd";
import { ReactComponent as ArrowDown } from "../../../Assets/img/icons/icon_arrow-down-mini.svg";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  menuDrawer: {
    "& .MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
    "& .MuiPaper-elevation16": {
      boxShadow: "unset",
    },
    "& .footer": {
      borderTop:
        theme.theme_type === "light"
          ? "1px solid #333333"
          : "1px solid #E9F0F7",
      padding: "30px 10px",
      "& .rightSide": {
        flexBasis: 225,
        "& svg": {
          width: 30,
          height: 30,
          "& path": {
            fill: theme.theme_type === "light" ? "#333" : "#fff",
          },
        },
      },
    },
  },
  drawerPaper: {
    width: "100%",
    background: theme.theme_type === "light" ? "#eeeeee" : "#333",
    color: "white",
  },
  paperAnchorBottom: {
    top: 189,
  },
  menuWrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
    gridTemplateRows: "auto",
    gridColumnGap: 16,
    gridRowGap: 30,
    "& li": {
      padding: "10px",
      marginBottom: "1rem",
      position: "relative",
      ...theme.fonts.s16w500,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      "& a": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        color: theme.theme_type === "light" ? "#333" : "#ffffff",
      },
    },
    "& svg": {
      width: 20,
      height: 20,
      "& path": {
        fill: theme.theme_type === "light" ? "#333" : "#fff",
      },
    },

    "& .ant-dropdown-trigger": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      color: theme.theme_type === "light" ? "#333" : "#ffffff",
    },
  },
  activeLink: {
    backgroundColor: theme.backgrounds.white1,
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: "-10px",
      height: "4px",
      width: "100%",
      left: "0",
      right: "0",
      borderRadius: "50px 50px 0px 0px",
      backgroundColor: "#FDC500",
    },
  },
  disabled: {
    filter: "blur(1.2px)",
    pointerEvents: "none",
    "& div": {
      pointerEvents: "none",
    },
  },
  dropDownMenu: {
    backgroundColor: theme.colors.lightGray,
    "& li": {
      ...theme.fonts.s14w500,
      color: theme.colors.black,
      textTransform: "uppercase",
      marginTop: 16,
      marginBottom: 16,
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    "& .active": {
      ...theme.fonts.s14w700,
      color: theme.theme_type === "light" ? "#333" : "#ffffff",
      position: "relative",
      "&::after": {
        content: '""',
        position: "absolute",
        bottom: "-10px",
        height: "4px",
        width: "100%",
        left: "0",
        right: "0",
        borderRadius: "50px 50px 0px 0px",
        backgroundColor: "#FDC500",
      },
    },
    "& span:": {
      paddingRight: 15,
      paddingLeft: 15,
    },
    "& .ant-dropdown-menu-title-content > a:hover": {
      color: theme.palette.primary.main,
    },
    "& .ant-dropdown-menu-title-content > a::after": {
      top: "unset",
    },
  },
}));
export default function SideBar({ routes, handleDrawerToggle, open }) {
  const classes = useStyles();

  const denied_permissions = [
    ...useSelector(store => [
      ...store?.auth?.user?.denied_permissions,
      store.auth?.user?.type !== "af_admin" &&
        store.auth?.user?.agency?.membership_type !== "af" &&
        "menu-real-time",
    ]),
  ];
  // const [toggleSubmenu, setToggleMenu] = useState(false);
  const menu = item => (
    <Menu className={classes.dropDownMenu}>
      {item.map(({ name, path, layout }, index) => (
        <Menu.Item key={index} onClick={() => handleDrawerToggle()}>
          <NavLink
            key={index}
            to={layout + path}
            className="d-flex align-items-center justify-content-center flex-column "
          >
            <span>{name}</span>
          </NavLink>
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Drawer
      variant="temporary"
      anchor={"bottom"}
      open={open}
      className={classes.menuDrawer}
      classes={{
        paper: classes.drawerPaper,
        paperAnchorBottom: classes.paperAnchorBottom,
      }}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <div style={{ paddingTop: 30 }}>
        <div className="container">
          <ul className={classes.menuWrapper}>
            {routes.map((route, index) => {
              let isActive = activeRoute(route.path);
              let hasSubRoute = route?.routes?.length > 0;
              return !hasSubRoute ? (
                <li
                  key={index}
                  className={clsx({
                    [classes.activeLink]: isActive,
                    [classes.disabled]: denied_permissions.includes(route.id),
                  })}
                  onClick={() => handleDrawerToggle()}
                >
                  <NavLink to={route.layout + route.path}>
                    <i>{route.icon}</i>
                    <span>{route.name}</span>
                  </NavLink>
                </li>
              ) : (
                // <>
                //   <li
                //     key={index}
                //     className={clsx({
                //       [classes.activeLink]: isActive,
                //     })}
                //     onClick={() => setToggleMenu(prev => !prev)}
                //   >
                //     <i>{route.icon}</i>
                //     <div className="d-flex">
                //       {route.name} <ArrowDown />
                //     </div>
                //     {toggleSubmenu && (
                //       <>
                //         {route?.routes.map(({ name, path, layout }, index) => (
                //           <NavLink
                //             key={index}
                //             to={layout + path}
                //             className="d-flex align-items-center justify-content-center flex-column "
                //           >
                //             <span>{name}</span>
                //           </NavLink>
                //         ))}
                //       </>
                //     )}
                //   </li>
                // </>
                <Dropdown
                  key={index}
                  overlay={() => menu(route?.routes)}
                  trigger={["click"]}
                  placement="bottomCenter"
                >
                  <li
                    className={clsx({
                      [classes.activeLink]: isActive,
                      [classes.disabled]: denied_permissions.includes(route.id),
                    })}
                  >
                    <i>{route.icon}</i>
                    <div
                      className="d-flex align-items-end"
                      style={{ gap: "6px" }}
                    >
                      {route.name} <ArrowDown />
                    </div>
                  </li>
                </Dropdown>
              );
            })}
          </ul>
          <div className="footer d-flex align-items-center justify-content-between">
            <div className="leftSide">
              <SupportBtn />
            </div>
            <div className="rightSide d-flex align-items-center justify-content-between">
              <button>
                <IconSms />
              </button>
              <button>
                <IconEmail />
              </button>
              <button>
                <IconCallBack />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
