import React from "react";
import moment from "moment";
import authActionType from "../Store/_types/auth.type";
import {useDispatch} from "react-redux";

export const activeRoute = routeName => {
  return window.location.href.indexOf(routeName) > -1;
};

//
export const componentPropsValidator = (component, props) => {
  if (React.isValidElement(component)) {
    return component;
  } else if (component instanceof Function) {
    let Component = component;
    return <Component {...props} />;
  } else return component;
};

// get color based on text for priority in task
export const getPriorityBadgeColor = priority => {
  switch (priority?.toLowerCase()) {
    case "medium":
      return "#FFBE0B";
    case "high":
      return "#FF5939";
    case "low":
      return "#56C3D3";
    default:
      return "#fff";
  }
};

export const getStatusTextColor = status => {
  switch (status?.toLowerCase()) {
    case "answered":
      return "#02B075";
    case "open":
      return "#B1BDD1";
    case "closed":
      return "#8492A6";
    default:
      return "#fff";
  }
};

/* _________________________ data utils _________________________ */

// type
export const exactTypeOf = data => {
  if (React.isValidElement(data)) return "reactElement";
  return Object.prototype.toString.call(data);
};

// -----------> object
export const getValuesByKeys = (object, keys = []) =>
  keys.map(data => object[data]);
//
export const filterArray = routesArray => routesArray?.flat()?.filter(Boolean);

// boolean
export const everyArray = (constant, variables = []) =>
  variables.every(data => constant === data);

export const someArray = (constant, variables = []) =>
  variables.some(data => constant === data);

// -----------> string
export function mergeString(seperate = " ", ...rest) {
  const text = [...rest]
    .filter(data => {
      if (data === 0) return "0";
      return data;
    })
    .join(seperate);

  const hasData = text.replaceAll(seperate, "").length;
  if (hasData) return text;
  else return "N/A";
}
mergeString.one = function (data) {
  if (data === 0) return "0";
  return data || "N/A";
};

// if all array indexes to be undefined, return N/A
export const NAString = (
  (seperate = " ") =>
  (...rest) => {
    return rest.length === 1 || !rest.length
      ? mergeString.one(rest[0])
      : mergeString(seperate, ...rest);
  }
)();

NAString.seperat = (seperate = "", ...rest) =>
  rest.length === 1 || !rest.length
    ? mergeString.one(rest[0])
    : mergeString(seperate, ...rest);
// Example:: NAString.seperat(', ', name, undefined, family) or NAString.seperat('', 'address')

NAString.seperateWithObject = (seperate = "", object, keys) => {
  const rest = getValuesByKeys(object, keys);
  rest.length === 1 || !rest.length
    ? mergeString.one(rest[0])
    : mergeString(seperate, ...rest);
};
//
export const truncate = (text, limit, initialValueForNull) => {
  if (!text || !limit) return initialValueForNull;
  else if (text.length < limit) return text;
  else {
    return text.trim().slice(0, limit) + "...";
  }
};
//
export const getKeysByValuesCondition = (values, func = Number) =>
  Object.entries(values)
    .map(data => (data[1] ? func(data[0]) : undefined))
    .filter(Boolean);
//
export function thousands_separators(num, twoFixes = true) {
  if (num === 0) return twoFixes ? "0.00" : "0";

  if (!num) return "";

  let num_parts = Number(num)
    .toFixed(twoFixes ? 2 : 0)
    .split(".");

  num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return num_parts.join(".");
}

export const ifValueExistAddComma = value => {
  return value ? `${value}, ` : "";
};

export const createTextListWithComma = list => {
  const lastIndex = list?.length - 1;
  let text = "";
  list?.forEach((item, index) => {
    if (index === lastIndex) {
      text += item || "";
    } else {
      text += ifValueExistAddComma(item);
    }
  });
  return text;
};

// Property address generator
export const propertyAddressGenerator = (property, maxLength) => {
  const address = createTextListWithComma([
    property?.num_doors,
    property?.first_line_of_address,
    property?.second_line_of_address,
    // property?.postcode,
    property?.city,
  ]);

  return maxLength && address.length > maxLength
    ? address.substring(0, maxLength) + "..."
    : address;
};

export const createNameString = data => {
  return `${data?.first_name ?? ""} ${data?.middle_name ?? ""} ${
    data?.last_name ?? ""
  }`;
};

// replace _ and make title case
const _replaceUnderscoreToSpace = item => {
  return item?.replace("_", " ");
};
export const convertToTitleCase = item => {
  if (item && item?.length > 0) {
    return item[0]?.toUpperCase() + item?.substr(1)?.toLowerCase();
  }
};
export const createReadableTitleCaseString = item => {
  const spacedString = _replaceUnderscoreToSpace(item);
  return convertToTitleCase(spacedString);
};

//
export const formatPhoneNumber = str => {
  const cleaned = ("" + str).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{1,7})(\d{3})(\d{2})(\d{2})$/);

  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}-${match[4]}`;
  }

  return str;
};

//
// const _urlWithFileNameRegex = /(?<=\/)[^\/\?#]+(?=[^\/]*$)/;
const _urlWithFileNameRegex = /(?:\/)[^\/\?#]+(?=[^\/]*$)/;
export const getFileNameFromUrl = fileName => {
  return _urlWithFileNameRegex.exec(fileName)[0];
};

// for number of bedroom , bathroom , ...
export const optionsFrom1To40 = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
  { label: "16", value: 16 },
  { label: "17", value: 17 },
  { label: "18", value: 18 },
  { label: "19", value: 19 },
  { label: "20", value: 20 },
  { label: "21", value: 21 },
  { label: "22", value: 22 },
  { label: "23", value: 23 },
  { label: "24", value: 24 },
  { label: "25", value: 25 },
  { label: "26", value: 26 },
  { label: "27", value: 27 },
  { label: "28", value: 28 },
  { label: "29", value: 29 },
  { label: "30", value: 30 },
  { label: "31", value: 31 },
  { label: "32", value: 32 },
  { label: "33", value: 33 },
  { label: "34", value: 34 },
  { label: "35", value: 35 },
  { label: "36", value: 36 },
  { label: "37", value: 37 },
  { label: "38", value: 38 },
  { label: "39", value: 39 },
  { label: "40", value: 40 },
];

export const handle_dateFromNow = date => {
  return moment().diff(moment(date), "days") === 0
    ? "Today"
    : moment().diff(moment(date), "days") === 1
    ? "Yesterday"
    : moment(date).format("D MMM YYYY");
};

export const handle_validBirthDate = value => {
  const data = value?.split("-").reverse().join("");
  const isValid = moment(data, "DDMMYYYY", true).isValid();

  if (!data || (data && isValid && data?.length === 8)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error("Please enter a valid Birth date"));
};

export const handle_validPhoneNumber = value => {
  const mainNumber = value?.slice(value.length - 10);
  if (!mainNumber || mainNumber.length === 10) {
    return Promise.resolve();
  }
  return Promise.reject(new Error("Your Contact number must be 10 digit!"));
};

export const handle_redirectToHome = (pathname, token, dispatch) => {
  const deleteOneTimeToken = () => ({ type: authActionType.ONE_TIME_TOKEN_DELETE });
  dispatch(deleteOneTimeToken())
  window.location = `${process.env.REACT_APP_NEXT_APP_HOST}/${pathname}/${token ? "auth-token"+"/#"+token : ""}`
}

// export const uniqueID = (_length = 13) => {
//   // Math.random to base 36 (numbers, letters),
//   // grab the first 9 characters
//   // after the decimal.
//   return "_" + Math.random().toString(36).substr(2, _length); // max _length should be less then 13
// };
// //
// export const defaultNA = data => {
//   const emptyText = data === undefined || data === "" || data === null;
//   return emptyText ? "" : data;
// };
// //
// export const objectParamsToQueryString = (objectParams, startWith = "?") =>
//   Object.entries(objectParams)
//     .map((data, index) => {
//       if (index) {
//         return `&${data[0]}=${data[1]}`;
//       } else {
//         return `${startWith}${data[0]}=${data[1]}`;
//       }
//     })
//     .join("");
// //array
// export const spreadObjectIndexToArray = (data, defaultReturn) => {
//   if (exactTypeOf(data) === "[object Object]") {
//     return Object.entries(data).map(data => ({ [data[0]]: data[1] }));
//   }
//   return defaultReturn;
// };
