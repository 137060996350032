import { shallowEqual, useSelector } from "react-redux";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
//Components
import BasePage from "Routes/BasePage";
import Layout_1 from "Layouts/Layout_1";
import Layout_2 from "Layouts/Layout_2";
// handle if page content failed with lazy loading
import ErrorBoundary from "Routes/ErrorBoundary";
import {mainRole} from "../Utils/roles";

export default function Routes() {
  const { isAuthorized, type } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      type: mainRole(auth.user),
    }),
    shallowEqual,
  );

  const location = useLocation();
  const Layout = type === "seller" || type === "buyer" ? Layout_2 : Layout_1;

  return (
    <Switch>
      {!isAuthorized && !location.pathname.includes("auth-token") ? (
        <Route
          render={() => {
            window.location = `${process.env.REACT_APP_NEXT_APP_HOST}/login`;
          }}
        />
      ) : (
        [
          <Redirect key="1" from="/auth" to="/" />,
					!location.pathname.includes("auth-token") &&
          <ErrorBoundary key="2">
            <Layout>
              <BasePage />
            </Layout>
          </ErrorBoundary>,
        ]
      )}
    </Switch>
  );
}
