import axios from "./Api";
import axiosMain from "./ApiMain";

/*###############new endpoints after merging endpoints#############*/
export const createNoteGlobal = params =>
  axiosMain.post(`/notes/store/global`, {}, { params });

// /roles/not-paginate; changed to below
export const getAllAgentRoles = () => axiosMain.get("/roles/assignable-roles");

export const getAgentTeamsMember = params =>
    axiosMain.get("/team-members", {params});

export const getBranchesApi = params => axiosMain.get("/branches", { params });

export const getOwnerEmployeeAllApi = params =>
    axiosMain.get("/employee/all", { params });
/*###############end new endpoints after merging endpoints#############*/

// team members api
// export const getAgentTeamsMember = params =>
//   axios.get("/team-members", {
//     params,
//   });

export const postAgentTeamsMember = body => axios.post("/team-members", body);

export const getAgentTeamsMemberIds = id => axios.get(`/team-members/${id}`);

export const putAgentTeamsMemberId = (id, body) =>
  axios.put(`/team-members/${id}`, body);

export const getAgentTeamsMemberProperties = id =>
  axios.get(`/team-members/${id}/properties`);

export const postAgentChangeStatus = id =>
  axios.post(`/team-members/${id}/change-status`);

// roles api
export const getAgentRoles = params => axios.get("/roles", { params });

// export const getAllAgentRoles = () => axios.get("/roles/not-paginate");

export const postAgentRoles = params => axios.post("/roles", {}, { params });

export const getAgentRolesById = id => axios.get(`/roles/${id}`);

export const putAgentRolesById = (id, params) =>
  axios.put(`/roles/${id}`, {}, { params });

export const deleteAgentRolesById = id => axios.delete(`/roles/${id}`);

export const getAgentRolesPermisionById = id =>
  axios.get(`/roles/${id}/permissions`);

// properties
export const getAgentProperties = params =>
  axios.get("/properties", { params });

// Agent/Permissions
export const getAgentPermissions = () => axios.get("/permissions");

// braches
// export const getBranchesApi = params => axios.get("/branches", { params });

export const postBranchesStoreApi = body => axios.post("/branches/store", body);

export const putBranchesProfileByIdApi = (id, body) =>
  axios.put(`/branches/${id}/update`, body);

export const getBranchesProfileByIdApi = id =>
  axios.get(`/branches/${id}/profile`);

export const getBranchesAssignedPropertiesByIdApi = ({ id, offset, ...rest }) =>
  axios.get(`/branches/${id}/assigned-properties`, {
    params: { offset, ...rest },
  });

export const getBranchesOffersByIdApi = ({ id, offset, status, ...rest }) =>
  axios.get(`/branches/${id}/offers`, { params: { offset, status, ...rest } });

export const getBranchesEmployeesByIdApi = ({ id, offset, status, ...rest }) =>
  axios.get(`/branches/${id}/employees`, {
    params: { offset, status, ...rest },
  });

export const getBranchesEmployeeLogsByIdApi = ({
  id,
  offset,
  status,
  ...rest
}) =>
  axios.get(`/branches/${id}/employee-logs`, {
    params: { offset, status, ...rest },
  });

// owner api for employee(team-member)
// export const getOwnerEmployeeAllApi = params =>
//   axios.get("/employee/all", { params });

export const putOwnerEmployeeToggleStatusApi = id =>
  axios.put(`/employee/${id}/toggle-status`);

export const getOwnerEmployeeProfileApi = id =>
  axios.get(`/employee/${id}/profile`);

export const getOwnerEmployeeLogsApi = ({ id, offset }) =>
  axios.get(`/employee/${id}/log/employee-logs`, { params: { offset } });

export const getOwnerEmployeeCommunicationLogsApi = ({ id, offset }) =>
  axios.get(`/employee/${id}/log/communication-logs`, { params: { offset } });

export const getOwnerEmployeeAssignedPropertiesApi = ({
  id,
  offset,
  ...rest
}) =>
  axios.get(`/employee/${id}/assigned-properties`, {
    params: { offset, ...rest },
  });

export const postOwnerEmployeeStoreApi = body =>
  axios.post(`/employee/store`, body);

export const putOwnerEmployeeUpdateStoreApi = (id, body) =>
  axios.put(`/employee/${id}/update`, body);

export const putOwnerEmployeeUpdateApi = id =>
  axios.put(`/employee/${id}/toggle-status`);

// Invoices
export const getOwnerVoicesPayableApi = params =>
  axios.get("/invoices/payable", { params });

export const getOwnerVoicesReceivableApi = params =>
  axios.get("/invoices/receivable", { params });

// Applicants
export const getOwnerUserProfileApi = id => axios.get(`/user-profile/${id}`);

export const getOwnerUserProfileViewingApi = params =>
  axios.get(`/user-profile/viewing-requests`, { params });

export const getOwnerUserProfileNotesApi = (id, params) =>
  axios.get(`/user-profile/notes`, {
    params: {
      applicant_id: id,
      ...params,
    },
  });

export const getPropertyNotesApi = (id, params) =>
  axios.get(`/notes/properties`, {
    params: {
      property_id: id,
      ...params,
    },
  });

export const getOwnerUserProfileWatchingApi = params =>
  axios.get(`/user-profile/watching`, { params });

export const getOwnerUserProfileLegalpackApi = params =>
  axios.get(`/user-profile/legalpack-download`, { params });

export const getOwnerUserProfileOffersApi = params =>
  axios.get(`/user-profile/offers`, { params });

// notes
export const postMyTeamNotesApi = params =>
  axios.post(`/notes/store/on-applicant`, {}, { params });

// export const createNoteGlobal = params =>
//   axios.post(`/notes/store/global`, {}, { params });

export const readNoteGlobal = params =>
  axios.get(`/notes/store/global`, {}, { params });
