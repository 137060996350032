import axios from "./Api";
import axiosMain from "./ApiMain";

/*###############new endpoints after merging endpoints#############*/
// AGENT
export const getAgentProperties = ({ page, ...params }) =>
    axiosMain.get("/properties", { params: { page: page || 0, ...params } });
export const getAgentSingleProperty = ({ id }) =>
    axiosMain.get(`/properties/${id}`).then(res => res.data);
export const getAdminAddPropertiesDependencis = propertyId =>
    axiosMain.get("/property-dependencies/" + propertyId).then(res => res.data);
export const getAgentAddPropertiesDependencis = params =>
    axiosMain.get("/property-dependencies", { params: { is_edit: params?.isEdit } })
        .then(res => res.data);
/*###############end new endpoints after merging endpoints#############*/

// AGENT
// export const getAgentProperties = ({ page, ...params }) =>
//   axios.get("/properties", { params: { page: page || 0, ...params } });

export const getAgentPropertyBids = ({ page, ...params }) =>
  axios.get("/bids", { params: { offset: page || 0, ...params } });

export const getAgentPropertyRegisterToBidBids = ({ page, ...params }) =>
  axios.get("/bids/registers", { params: { offset: page || 1, ...params } });

export const getAgentPropertyWatchers = ({ page, ...params }) =>
  axios.get("/watchlists", { params: { offset: page || 0, ...params } });

export const getAgentPropertyLegalPackLog = ({ page, ...params }) =>
  axios.get("/legalpack-downloads/users", {
    params: { offset: page || 0, ...params },
  });

export const getAgentPropertyLegalPackFiles = ({ page, ...params }) =>
  axios.get("/legalpack-downloads", {
    params: { offset: page || 0, ...params },
  });

export const getAgentPropertyViewingRequest = ({ page, ...params }) =>
  axios.get("/viewing-requests", {
    params: { offset: page || 0, ...params },
  });

export const getBankDetails = ({ page, ...params }) =>
  axios.get("/bank-cards", {
    params: { offset: page || 0, ...params },
  });

export const getExchangeDetails = ({ page, ...params }) =>
  axios.get("/exchange-details", {
    params: { offset: page || 0, ...params },
  });

export const deleteExchangeDetails = id =>
  axios.delete(`/exchange-details/${id}`);

// export const getAgentAddPropertiesDependencis = params =>
//   axios
//     .get("/property-dependencies", { params: { is_edit: params?.isEdit } })
//     .then(res => res.data);

// export const getAdminAddPropertiesDependencis = propertyId =>
//   axios.get("/property-dependencies/" + propertyId).then(res => res.data);

// export const getAgentSingleProperty = ({ id }) =>
//   axios.get(`/properties/${id}`).then(res => res.data);

export const agentAddProperty = body =>
  axios.post("/properties", body).then(res => res.data);

export const agenBookViewingRequest = ({ id, body }) =>
  axios.post(`/viewing-requests/book/${id}`, body).then(res => res.data);

export const agentEditProperty = body =>
  axios.put(`/properties/${body.id}`, body).then(res => res.data);

export const agentUpdatePropertyStatus = ({ id, status }) =>
  axios
    .put(`/properties/update-status/${id}?status=${status}`)
    .then(res => res.data);

export const getAgentPropertyLogs = ({ page, ...params }) =>
  axios.get("/logs/property", {
    params: { offset: page || 0, ...params },
  });

export const getAgentCommunicationLogs = ({ page, ...params }) =>
  axios.get("/logs/property", {
    params: { offset: page || 0, ...params },
  });

export const getAgentPriceLogs = ({ page, ...params }) =>
  axios.get("/logs/prices", {
    params: { offset: page || 0, ...params },
  });

// outstanding tasks
export const agentRequestLegalpack = ({ id, payer }) =>
  axios.post(`/properties/request-legalpack/${id}`, { payer });

export const agentRequestLegalpackSolicitor = ({ id }) =>
  axios.post(`/properties/request-legalpack-solicitor/${id}`);

// Completion Steps

export const getPropertyCompletionSteps = id =>
  axios
    .get(`/properties/completion-steps?property_id=${id}`)
    .then(res => res.data);

export const submitCompletionSteps = body =>
  axios.post("/properties/completion-steps", body).then(res => res.data);

export const getEmployeesForAssignTo = () =>
  axios.get(`/employee/all?&order_type=desc&offset=0`).then(res => res.data);

export const addFacilityApi = body =>
  axios.post("/properties/facility/store", body).then(res => res.data);

// Admin Send Alert
export const postSendAlert = id => axios.post(`/properties/send-alerts/${id}`);
export const getSendAlert = id =>
  axios.get(`/properties/alert-receiver-counts/${id}`).then(res => res.data);
export const getSendAlertLog = ({ page, ...params }) => {
  const { id, items_count, order_by, order_type } = params;

  return axios.get(`/properties/alert-emails/${id}`, {
    params: { offset: page || 0, items_count, order_by, order_type },
  });
};

export const getUthRecommendationDate = (agency_id) =>{
  return axios.get(`/properties/next-auction-start?agency_id=${agency_id}`)
  .then(res => {
    return res.data
  })
}