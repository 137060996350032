import FormHeader from "./FormHeader";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as IconPencil } from "Assets/img/icons/icon_pencil.svg";
import { truncate } from "../../../Utils/helperFunction";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    "& hr": {
      display: "none",
    },
    "& .contentInfo": {
      "& p": { ...theme.fonts.s16w600, margin: 0 },
      "& .title": { color: "#9CACBC", marginRight: 6 },
      "& .label": { color: theme.theme_type === "light" ? "#333" : "#fff" },
    },
  },
  borderBox: {
    padding: "10px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid #F4F4F5",
    borderRadius: 2,
    marginBottom: 12,
    marginTop: 12,
    "& > p": {
      textTransform: "uppercase",
    },
    "& button": {
      display: "flex",
      alignItems: "center",
      color: theme.theme_type === "light" ? "#333" : "#fff",
      transition: "all .3s",
      ...theme.fonts.s14w500,
      "& svg": {
        width: 16,
        marginLeft: 5,
      },
      "& svg path": {
        fill: theme.theme_type === "light" ? "#333" : "#fff",
        transition: "all .3s",
      },
      "&:hover": {
        color: theme.palette.primary.main,
        "& svg path": {
          fill: theme.palette.primary.main,
        },
      },
    },
  },
}));
export default function Step4({ setCurrentStep, formValues }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FormHeader
        title={"preview"}
        subTitle={"To edit any of this information, use the “previous” button"}
      />
      <div className="row contentInfo">
        <div className="col-12">
          <div className={classes.borderBox}>
            <p>Personal details</p>
            <button onClick={() => setCurrentStep(0)}>
              Edit
              <IconPencil />
            </button>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="d-flex mb-3">
                <p className="title">Title</p>
                <p className="label">{formValues.title}</p>
              </div>
              <div className="d-flex mb-3">
                <p className="title">First name</p>
                <p className="label">{formValues.first_name}</p>
              </div>
              <div className="d-flex">
                <p className="title">date of birth</p>
                <p className="label">
                  {moment(formValues.birth_date).format("YYYY-MM-DD")}
                </p>
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex mb-3">
                <p className="title">Middle name</p>
                <p className="label">{formValues.middle_name}</p>
              </div>
              <div className="d-flex mb-3">
                <p className="title">Last name</p>
                <p className="label">{formValues.last_name}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className={classes.borderBox}>
            <p>contact details</p>
            <button onClick={() => setCurrentStep(1)}>
              Edit
              <IconPencil />
            </button>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center mb-3">
                <p className="title">Contact number</p>
                <p className="label">+{formValues.contact_number}</p>
              </div>
              <div className="d-flex align-items-center mb-3">
                <p className="title">Mobile number</p>
                <p className="label">+{formValues.mobile}</p>
              </div>
              <div className="d-flex align-items-center mb-3">
                <p className="title">Email</p>
                <p className="label">{formValues.email_address}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className={classes.borderBox}>
            <p>address</p>
            <button onClick={() => setCurrentStep(2)}>
              Edit
              <IconPencil />
            </button>
          </div>
          <div className="row mb-5">
            <div className="col-6">
              <div className="d-flex align-items-center mb-3">
                <p className="title">House number</p>
                <p className="label">{formValues.house_number}</p>
              </div>
              <div className="d-flex align-items-center mb-3">
                <p className="title">Street</p>
                <p className="label">{formValues.street_name}</p>
              </div>
            </div>
            <div className="col-6">
              <div className="d-flex align-items-center mb-3">
                <p className="title">City</p>
                <p className="label">{truncate(formValues.city, 20, "...")}</p>
              </div>
              <div className="d-flex align-items-center mb-3">
                <p className="title">Post code</p>
                <p className="label">{formValues.post_code}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
