import axios from "./Api";
import axiosMain from "./ApiMain";

/*###############new endpoints after merging endpoints#############*/
export const getUserCommunicationsLogs = ({ page, id, ...params }) =>
    axiosMain.get(`/audits/users/${id}/communications`, {
    params: { ...params },
  });
/*###############end new endpoints after merging endpoints#############*/

// export const getUserCommunicationsLogs = ({ page, id, ...params }) =>
//     axios.get(`/audits/users/${id}/communications`, {
//         params: { offset: page || 0, ...params },
//     });

export const getUserInternalsLogs = ({ page, id, ...params }) =>
  axios.get(`/audits/users/${id}/internals`, {
    params: { offset: page || 0, ...params },
  });

export const getPropertiesLogs = ({ page, ...params }) =>
  axios.get(`/audits/properties`, {
    params: { offset: page || 0, ...params },
  });

export const getAgenciesLogs = ({ page, ...params }) =>
  axios.get(`/audits/agencies`, {
    params: { offset: page || 0, ...params },
  });
