import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// Styles
import "antd/dist/antd.css";
import "react-multi-carousel/lib/styles.css";
import "Assets/scss/styles.scss";
import "video-react/dist/video-react.css";
//Router
import { BrowserRouter } from "react-router-dom";

//Redux
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "Store/store";

const { PUBLIC_URL } = process.env;

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={<>loading persist gate ...</>}>
      <BrowserRouter basename={PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
