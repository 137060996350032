import PropTypes from "prop-types";
import { ReactComponent as ArrowRightIcon } from "Assets/img/icons/icon_Arrow_Right.svg";
import { makeStyles } from "@material-ui/core";
import { breadcrumbStyle } from "./breadcrumb.styles";
import { Fragment } from "react";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(breadcrumbStyle);

export default function Breadcrumb({ icon, title, className }) {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:800px)");

  const isPreviousTab = index => {
    return title.length === 1 || index !== title?.length - 1;
  };

  return (
    <div className={clsx([classes.breadcrumb_root, className])}>
      <div className="breadcrumb">
        <div className="icon">{icon}</div>
        {isMobile ? (
          title.length < 3 ? (
            title?.map((item, index) => (
              <Fragment key={index}>
                <span
                  // Does not work correctly for all pages so you should send item as <Link to={} /> for example checkout this file src/Pages/Invoices/ViewInvoice/index.jsx
                  // onClick={() => {
                  //   if (isPreviousTab(index)) {
                  //     history.push(
                  //       history.location.pathname
                  //         .split("/")
                  //         .slice(0, -(title.length - (index + 1)))
                  //         .join("/"),
                  //     );
                  //   }
                  // }}
                  className={
                    isPreviousTab(index) ? "previousTabs" : "activeTab"
                  }
                  key={index}
                >
                  {item}
                </span>
                {index !== title.length - 1 && <ArrowRightIcon />}
              </Fragment>
            ))
          ) : (
            <Fragment>
              <span className={"previousTabs"}>{title[0]}</span>
              <ArrowRightIcon />

              <span className={"previousTabs"}>...</span>
              <ArrowRightIcon />

              <span className={"activeTab"}>{title[title?.length - 1]}</span>
            </Fragment>
          )
        ) : (
          title?.map((item, index) => (
            <Fragment key={index}>
              <span
                // Does not work correctly for all pages so you should send item as <Link to={} /> for example checkout this file src/Pages/Invoices/ViewInvoice/index.jsx
                // onClick={() => {
                //   if (isPreviousTab(index)) {
                //     history.push(
                //       history.location.pathname
                //         .split("/")
                //         .slice(0, -(title.length - (index + 1)))
                //         .join("/"),
                //     );
                //   }
                // }}
                className={isPreviousTab(index) ? "previousTabs" : "activeTab"}
                key={index}
              >
                {item}
              </span>
              {index !== title.length - 1 && <ArrowRightIcon />}
            </Fragment>
          ))
        )}
      </div>
    </div>
  );
}

Breadcrumb.propTypes = {
  title: PropTypes.array.isRequired,
  icon: PropTypes.element.isRequired,
};
