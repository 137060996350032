// https://us1.locationiq.com/v1/search.php?key=pk.b70cc79a00fbdc30c3b70554a4e88865&q=722183&format=json&addressdetails=1
import React, { useState, useRef, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { AutoComplete, Input } from "antd";
import { inputsStyles } from "./styles/inputs.style";
import clsx from "clsx";
import axios from "axios";
import debounce from "lodash.debounce";

// Icons
import { ReactComponent as SearchIcon } from "Assets/img/icons/icon_search_input.svg";
import { SpinnerRoundFilled } from "spinners-react";

const useStyles = makeStyles(inputsStyles);

export default function PostCodeInput({
  placeholder,
  className,
  defaultValue,
  form,
  name,
  ...restProps
}) {
  const classes = useStyles();

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchRef = useRef(0);

  // api call
  async function apiCall(value) {
    return axios
      .get(
        `https://us1.locationiq.com/v1/search.php?key=pk.b70cc79a00fbdc30c3b70554a4e88865&q=${value}&format=json&addressdetails=1`,
      )
      .then(res => {
        let postCodes = [];
        if (res.data.length) {
          res.data.forEach(item => {
            if (
              postCodes.indexOf(item?.address?.postcode) === -1 &&
              item?.address?.postcode
            ) {
              postCodes.push(item?.address?.postcode);
            }
          });
          return postCodes.map(item => ({
            label: item,
            value: item,
          }));
        }
        return [];
      });
  }

  const handleSearch = useMemo(() => {
    const loadOptions = value => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setLoading(true);

      apiCall(value)
        .then(newOptions => {
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }
          setOptions(newOptions);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
        });
    };

    return debounce(loadOptions, 800);
  }, []);

  const handleKeyPress = ev => {
    // console.log("handleKeyPress", ev);
  };

  const onSelect = value => {
    form.setFieldsValue({
      [name]: value,
    });
  };
  return (
    <AutoComplete
      defaultValue={defaultValue || form.getFieldValue(name)}
      options={options}
      onSelect={onSelect}
      onSearch={handleSearch}
    >
      <Input
        className={clsx(classes.input, classes.inputGrayIcon, className)}
        placeholder={placeholder}
        defaultValue={defaultValue || form.getFieldValue(name)}
        onKeyPress={handleKeyPress}
        suffix={
          loading ? (
            <SpinnerRoundFilled
              size={20}
              thickness={100}
              speed={100}
              color="#FFF"
            />
          ) : (
            <SearchIcon />
          )
        }
        {...restProps}
      />
    </AutoComplete>
  );
}
