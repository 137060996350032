import { Modal } from "antd";
import { makeStyles } from "@material-ui/core";
import { modalStyles } from "./modal.styles";
import PropTypes from "prop-types";
import clsx from "clsx";

// Icons
import { ReactComponent as IconClose } from "Assets/img/icons/icon_close.svg";

const useStyles = makeStyles(modalStyles);

export default function ModalWrapper({
  title,
  children,
  className,
  removeHeaderBorder,
  wrapperClassName,
  isModalVisible,
  handleCancel,
  footer,
  width,
  ...rest
}) {
  const classes = useStyles();
  return (
    <Modal
      title={title}
      visible={isModalVisible}
      onCancel={handleCancel}
      className={clsx(
        classes.modal_wrapper,
        removeHeaderBorder && classes.removeHeaderBorder,
        className,
      )}
      footer={footer || false}
      width={width}
      closeIcon={<IconClose />}
      maskClosable={true}
      {...rest}
    >
      {children}
    </Modal>
  );
}

ModalWrapper.defaultProps = {
  rest: {},
};

ModalWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  isModalVisible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  footer: PropTypes.array,
  width: PropTypes.number,
};
