import React from "react";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

import { BlackButtonStyles } from "./styles/buttons.styles";

const useStyles = makeStyles(BlackButtonStyles);

const BlackButton = ({
  onClick = () => {},
  children,
  bordered,
  className,
  small,
  color,
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <button
      style={{
        color: bordered && color,
        border: `1px solid ${bordered && color}`,
      }}
      className={clsx(
        bordered ? classes.black_bordered_root : classes.black_root,
        small ? classes.small : "",
        className,
      )}
      onClick={onClick}
      {...restProps}
    >
      {children}
    </button>
  );
};

export default BlackButton;
