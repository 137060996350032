import { useEffect, useState, useRef } from "react";
// import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { topNavStyle } from "../styles/navigationbar.styles";
// import Badge from "@material-ui/core/Badge";
// import { useSelector } from "react-redux";
// import { NavLargeTooltip } from "Components/ToolTip";
// import { SupportToolTipBody } from "Components/Support";
import Notification from "./Notification";
import { useSelector } from "react-redux";

// Icons
import { ReactComponent as SupportIcon } from "Assets/img/icons/icon_support-headphone.svg";
import { ReactComponent as WhiteSmsIcon } from "Assets/img/icons/icon_sms_white.svg";
import { ReactComponent as CallIcon } from "Assets/img/icons/icon_call.svg";
import {mainRole} from "../../../../Utils/roles";

const useStyles = makeStyles(topNavStyle);

export default function TopNavButtons() {
  // const history = useHistory();
  // const location = useLocation();
  const classes = useStyles();
  const userRole = useSelector(store => mainRole(store?.auth?.user));

  const supportButton = useRef(null);

  const [positionOfSupportButton, setPositionOfSupportButton] =
    useState(undefined);

  useEffect(() => {
    if (!!supportButton) {
      let rect = supportButton?.current?.getBoundingClientRect();
      setPositionOfSupportButton(rect);
    }
  }, [supportButton]);

  // const handle_reloadPage = () => {
  //   window.history.replaceState({}, null); //clear location.state in react-router
  //   window.location.reload();
  // };
  return (
    <div className={classes.topNavButtons}>
      <div className="d-flex align-items-center justify-content-center">
        <Notification />
        {/* <NavLargeTooltip
          position={positionOfSupportButton}
          title={handleVisibleChange => (
            <SupportToolTipBody onClose={handleVisibleChange} />
          )}
        >
          <Badge variant="dot" overlap="circle" invisible={true}>
            <button ref={supportButton}>
              <SupportIcon />
            </button>
          </Badge>
        </NavLargeTooltip> */}
      </div>

      {/* <div className={"d-flex align-items-center justify-content-center"}>
        {userRole !== "buyer" && userRole !== "seller" && (
          <Badge>
            <button
              onClick={() =>
                userRole.includes("admin")
                  ? history.push(`/${userRole}/messenger/view`)
                  : location.pathname === "/agent/messenger"
                  ? handle_reloadPage()
                  : history.push(`/${userRole}/messenger`)
              }
            >
              <WhiteSmsIcon />
            </button>
          </Badge>
        )}

        <Badge variant="dot" overlap="circle">
          <button>
            <CallIcon />
          </button>
        </Badge>
      </div> */}
    </div>
  );
}
