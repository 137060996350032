import { makeStyles } from "@material-ui/core";
import MenuListBs from "./MenuListBS";
import { navigationSellerBuyerStyle } from "./navigationbarBS.style";
import MenuListBsMobile from "./MenuListBS_Mobile";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ReactComponent as LeftArrow } from "Assets/img/icons/icon_Arrow_Left.svg";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {mainRole} from "../../../Utils/roles";

const useStyles = makeStyles(navigationSellerBuyerStyle);

export default function BuyerSellerNavigationBar({ routes }) {
  const classes = useStyles();
  const history = useHistory();

  const routerInMenu = routes.filter(item => item?.isInMenu !== false);

  const isMobile = useMediaQuery("(max-width:992px)");

  // for login as
  const prevUser = useSelector(store => store?.auth?.prevUser);
  const user = useSelector(store => store?.auth?.user);
  const backToPrevUserDashboard = () => {
    const typeOfUser = mainRole(user) === "trader" ? "buyer" : mainRole(user);
    history.push(
      `/${typeOfUser}/handle-login-as/${prevUser.token}/${prevUser.role}/No/No`,
    );
  };
  // end for login as

  return (
    <div className={classes.root}>
      {/*start back to prev dashboard___________*/}
      {prevUser?.token && prevUser?.token !== "No" && (
        <div className={classes.backToYourDashboardWrapper}>
          <button
            className={classes.backToYourDashboard}
            onClick={backToPrevUserDashboard}
          >
            <LeftArrow />
            <span>Back to your dashboard</span>
          </button>

          <h5>
            You are login as <span>{user?.first_name}</span>{" "}
            <span>{user?.last_name}</span> in <span>{mainRole(user)}</span>{" "}
            dashboard
          </h5>
        </div>
      )}
      {/*___________end back to prev dashboard*/}
      {/*  menu*/}
      {isMobile ? (
        <MenuListBsMobile router={routerInMenu} />
      ) : (
        <MenuListBs router={routerInMenu} />
      )}
    </div>
  );
}
