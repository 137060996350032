import { useEffect, useState } from "react";
import Routes from "./Routes/Routes";
import { useLocation } from "react-router-dom";
//Material
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "Utils/theme";
import Maintenance from "./Components/Maintenance/Maintenance";
import { useDispatch, useSelector } from "react-redux";
import Spin from "Components/Spinner/Spin";
import axios from "axios";

import { successLogin, requestLogin } from "Store/_actions/auth.action";

function App() {
  const user = useSelector(state => state.auth?.user);
  const location = useLocation();
  const [maintenance, setMaintenance] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname.includes("auth-token")) {
      //loading
      dispatch(requestLogin());
      //send token to grab user
      axios
        .post(`${process.env.REACT_APP_URL_BASE}/login-via-token`, {
          token: location.hash.substring(1),
        })
        .then(async res => {
          await dispatch(successLogin(res.data));
          window.location = window.location.origin;
        })
    }

    axios.get(`${process.env.REACT_APP_URL_BASE}/test`).catch(function (error) {
      if (error?.response?.status === 503) {
        setMaintenance(true);
      }
    });
  }, []);

  return (
    <>
      {user?.loading ? (
        <Spin spinning size={20} />
      ) : (
        <ThemeProvider theme={theme(user?.theme_name, user?.theme_type)}>
          <CssBaseline />
          {maintenance ? <Maintenance /> : <Routes />}
        </ThemeProvider>
      )}
    </>
  );
}

export default App;
