import axios from "axios";
import { _axios } from "./Api";

export const loginUser = ({ email, password, rememberMe }) => {
  return axios
    .post(
      `${process.env.REACT_APP_URL_BASE}/login`,
        {
            email: email,
            password: password,
            remember_me: rememberMe ? 1 : 0,
        }
    )
    .then(res => res.data);
};

export const registerUser = body =>
  axios
    .post(`${process.env.REACT_APP_URL_BASE}/signup`, {
      ...body,
    })
    .then(res => res.data);

export const loginWithTokenApi = token =>
  _axios
    .get(`/get-me`, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    })
    .then(res => res.data);
