import React from "react";
import { makeStyles } from "@material-ui/core";
import { Input } from "antd";
import { inputsStyles } from "./styles/inputs.style";
import clsx from "clsx";

const { Password } = Input;

const useStyles = makeStyles(inputsStyles);

export default function NormalInput({
  placeholder,
  className,
  defaultValue,
  form,
  name,
  onChange,
  disabledFields = [],
  disabled,
  sendFullEvent = false,
  ...restProps
}) {
  const classes = useStyles();
  const handleChange = e => {
    form.setFieldsValue({[name]: e.target.value,});

    if (onChange) onChange(sendFullEvent ? e : e?.target?.value, name);
  };
  const InputComponent = restProps.type === "password" ? Password : Input;

  return (
    <InputComponent
      className={clsx(
        classes.input,
        className || "",
        (disabledFields?.includes(name) || disabled) &&
          "input-wrapper-disabled",
      )}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={handleChange}
      disabled={disabledFields?.includes(name) || disabled}
      {...restProps}
    />
  );
}
